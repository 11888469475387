import React from "react";

const MetaData = ({metaData,tcpa}) => {
    return (
        <>
            {metaData &&
            <>
                <div className="row mt-2">
                    <div className="col-auto" style={{width:"200px"}}>
                        <strong>IP</strong>
                    </div>
                    <div className="col">
                        {metaData.ipaddress??'-'}
                    </div>
                </div>
                <hr/>
                <div className="row mt-2">
                    <div className="col-auto" style={{width:"200px"}}>
                        <strong>User Agent</strong>
                    </div>
                    <div className="col">
                        {metaData.useragent??'-'}
                    </div>
                </div><hr/>
                <div className="row mt-2">
                    <div className="col-auto" style={{width:"200px"}}>
                        <strong>Source URL</strong>
                    </div>
                    <div className="col">
                        {metaData.sourceurl??'-'}
                    </div>
                </div><hr/>
            </>
            }
            {tcpa &&
                <>
                <div className="row mt-2">
                    <div className="col-auto" style={{width:"200px"}}>
                        <strong>TCPA</strong>
                    </div>
                    <div className="col">
                        {tcpa.tcpatext??'-'}
                    </div>
                </div>
                <hr/>
                </>
            }
        </>
    )
}

export default MetaData
