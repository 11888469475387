import React, { useState } from "react";
import {faCaretUp, faCaretDown} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ExpandableField = ({ reason, maxVisibleLength }) => {
    const [isExpanded, setIsExpanded] = useState(false);
  
    const toggleExpansion = () => {
      setIsExpanded(!isExpanded);
    };
  
    return (
      <div className={`reject-reason-column ${isExpanded ? 'expanded' : ''}`}>
        {reason.length > maxVisibleLength && !isExpanded ? `${reason.substring(0, maxVisibleLength)}...` : reason}
        {reason.length > maxVisibleLength && (
          <span style={{ cursor: "pointer", marginLeft: "5px" }} onClick={toggleExpansion}>
            <FontAwesomeIcon icon={isExpanded ? faCaretUp : faCaretDown} />
          </span>
        )}
      </div>
    );
  };

  export default ExpandableField;