import React,{useState,useEffect} from "react";
import {Edit2,X} from "react-feather";
import ReturnReason from "../../../components/formfields/ReturnReason";
import API from "../../../utils/API";
import {toastr} from "react-redux-toastr";

const ReasonEditable = ({returnitem,reasons,product}) => {
    const [editMode, setEditMode] = useState(false);
    const [reason, setReason] = useState(returnitem.reason);

    const saveReason = (name,newReason) => {
        if (newReason !== reason && newReason && newReason.length > 0) {
            API.post(`/admin/return/changereason/${returnitem.id}`, {reason: newReason}).then((response) => {
                setEditMode(false);
                setReason(newReason);
                toastr.success("Success","Reason was successfully updated");
            });
        }
    }

    return <>
        {returnitem.status === 'requested' ?
            <div className="parent-of-hidden-child cursor-pointer" onClick={()=>{if (!editMode) setEditMode(true)}}>
                {editMode ?
                    <div className="row" style={{minWidth:"250px"}}>
                        <div className="col"><ReturnReason reasons={(product in reasons)?reasons[product]:[]} value={reason} onChange={saveReason} name={'reason'}/></div>
                        <div className="col-auto"><X className="text-primary ml-1" onClick={()=>setEditMode(false)} size={14}/></div>
                    </div>
                    :
                    <nobr>
                        {reason}
                        <Edit2 className="text-primary ml-1 hidden-child" size={14}/>
                    </nobr>
                }
            </div>
            :
            <>{reason}</>
        }
        </>
}

export default ReasonEditable;
