import React, {useEffect, useState} from "react";
import {useHistory,useLocation} from "react-router-dom";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import API from "../utils/API";

const MFASetUpNotification = () => {
    const [mfaModal, setMfaModal] = useState(false);

    let history = useHistory();
    let location = useLocation();

    const startMFA = (version) => {
        history.push("/profile/mfa");
    }

    const checkMFA = () => {
        API.get('/profile/mfa').then((response)=>{
            if (response.data.data.isOn) {
                setMfaModal(false);
            } else {
                if (location.pathname && !location.pathname.includes('profile/mfa')) {
                    setMfaModal(true);
                }else {
                    setMfaModal(false);
                }
            }
        })
    }

    useEffect(() => {
        const interval = setInterval(() => {
            checkMFA();
        }, 60000)

        checkMFA();

        return () => {
            clearInterval(interval)
        }
    },[]);

    return <React.Fragment>
        <Modal
            isOpen={mfaModal}
            toggle={() => setMfaModal(false)}
            size="md"
        >
            <ModalHeader toggle={() => setMfaModal(false)} className="bg-danger">
                <span className="text-white">Multi-factor authentication is required</span>
            </ModalHeader>
            <ModalBody style={{backgroundColor:"#F7F9FC"}}>
                <p>Multi-factor authentication is required in order to use Volga system. Please click "Set up" button in order to set up multi-factor authentication.</p>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={() => startMFA()}>
                    Set up
                </Button>
                <Button color="secondary" onClick={() => setMfaModal(false)}>
                    Snooze
                </Button>
            </ModalFooter>
        </Modal>
    </React.Fragment>
}

export default MFASetUpNotification;
