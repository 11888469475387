import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Input,
  InputGroup,
  Row,
} from "reactstrap";
import { Edit2, Trash } from "react-feather";
import React, { useEffect, useState } from "react";

import API from "../../../utils/API";
import CSVDownlaod from "../../../components/CsvDownload";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Pagination from "../../../components/Pagination";
import SortableTable from "../../../components/SortableTable";
import { confirmAlert } from "react-confirm-alert";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { parseFilterValues } from "../../../utils/helpers/filterHelper";
import { useHistory } from "react-router-dom";

const Emailscrubbers = (props) => {
  let history = useHistory();
  const defaultFiltrationState = {
    page: 1,
    perPage: 50,
    column: "",
    order: "",
  };

  const urlParams = new URLSearchParams(history.location.search);
  const parsedParams = Object.fromEntries(urlParams.entries());
  const filtersParsed = parseFilterValues(parsedParams);

  const [emails, setEmails] = useState();
  const [filtration, setFiltration] = useState({
    ...defaultFiltrationState,
    ...filtersParsed,
  });
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState();
  const [dir, setDir] = useState("asc");
  const [filterText, setFilterText] = React.useState(
    filtersParsed?.email || ""
  );

  const updateFilterText = (value) => {
    changeData("email", value);
    setFilterText(value);
  };

  const newEmail = () => {
    history.push("/settings/emailscrubber/item");
  };

  const editEmail = (id) => {
    history.push("/settings/emailscrubber/item/" + id);
  };

  const deleteEmail = (id) => {
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure you want to delete this email?",
      buttons: [
        {
          label: "Yes",
          className: "btn btn-danger",
          onClick: () => {
            API.delete("/admin/bademail/" + id).then((response) => {
              loadEmails();
            });
          },
        },
        {
          label: "No",
          className: "btn btn-primary",
        },
      ],
    });
  };

  const loadEmails = () => {
    API.post("/admin/bademail/all", filtration).then((response) => {
      setEmails(response.data.data.data);
      setData(response.data.data);
      setLoading(false);
    });
  };

  const changeData = (name, value) => {
    setFiltration({ ...filtration, [name]: value });

    history.push({
      pathname: history.location.pathname,
      search: new URLSearchParams({ ...filtration, [name]: value }).toString(),
    });
  };

  useEffect(() => {
    loadEmails();
  }, [filtration]);

  const columns = [
    {
      name: "ID",
      selector: (row) => parseInt(row.id),
      sortable: true,
      sortField: "id",
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
      sortField: "email",
    },
    {
      name: "Description",
      selector: (row) => row.description,
      sortable: true,
      sortField: "description",
    },
    {
      name: "Date add",
      selector: (row) => row.dateadd,
      sortable: true,
      sortField: "dateadd",
    },
    {
      name: "Date update",
      selector: (row) => row.dateupdate,
      sortable: true,
      sortField: "dateupdate",
    },
    {
      name: "User",
      selector: (row) => row.user.name,
      sortable: true,
      sortField: "user",
    },
    {
      name: "Action",
      selector: (row) => (
        <Col>
          <Edit2
            onClick={() => {
              editEmail(row.id);
            }}
            className="align-middle mr-1 cursor-pointer text-primary"
            size={18}
          />
          <Trash
            onClick={() => {
              deleteEmail(row.id);
            }}
            className="cursor-pointer align-middle text-danger"
            size={18}
          />
        </Col>
      ),

      sortable: false,
    },
  ];

  const handleSort = (e) => {
    if (e.sortField && filtration.column === e.sortField.toLowerCase()) {
      if (dir === "asc") {
        setFiltration({
          ...filtration,
          column: e.sortField.toLowerCase(),
          order: "desc",
        });
        setDir("desc");
      } else {
        setFiltration({
          ...filtration,
          column: e.sortField.toLowerCase(),
          order: "asc",
        });
        setDir("asc");
      }
    } else {
      if (dir === "asc") {
        setFiltration({
          ...filtration,
          column: e.sortField.toLowerCase(),
          order: dir,
        });
      } else {
        setFiltration({
          ...filtration,
          column: e.sortField.toLowerCase(),
          order: dir,
        });
      }
    }
  };

  return (
    <Container fluid className="p-0">
      <div className="row justify-content-between">
        <div className="col-auto">
          <h3 className="h3 mb-3 float-left">Email Scrubbers</h3>
        </div>
        <div className="col-auto">
          <CSVDownlaod
            url="/admin/bademail/exportall"
            filename="BadEmailsList"
          />
        </div>
      </div>
      <Row form className="mt-1">
        <Col md={3}>
          <InputGroup>
            <Input
              name="email"
              onChange={(e) => updateFilterText(e.target.value)}
              placeholder="Search..."
              value={filterText}
            />
          </InputGroup>
        </Col>
        <Col className="text-right">
          <Button
            color="primary"
            onClick={newEmail}
            className="float-right mt-n1"
          >
            <FontAwesomeIcon icon={faPlus} /> New email
          </Button>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col>
          <Card>
            {!loading && emails && emails.length > 0 ? (
              <SortableTable
                columns={columns}
                data={emails}
                sortServer={true}
                sortFunction={handleSort}
                pagination={false}
              />
            ) : (
              !loading && (
                <CardBody className="text-center">
                  <h5>Data not found</h5>
                </CardBody>
              )
            )}
          </Card>
          {data?.page > 0 && data?.pages > 0 && (
            <Pagination
              onChange={changeData}
              name={"page"}
              page={data.page}
              totalPages={data.pages}
              loading={loading}
            />
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default Emailscrubbers;
