import { Link } from "react-router-dom";
import React from "react";

const Publisher = ({ publisher }) => {
  if (publisher) {
    return (
      <Link to={"/publisher/item/" + publisher.id} className="text-primary">
        {publisher.name}
      </Link>
    );
  } else {
    return "";
  }
};

export default Publisher;
