import React,{useState,useEffect} from "react";
import API from "../../../utils/API";
import {Link, useHistory} from "react-router-dom";
import {confirmAlert} from "react-confirm-alert";
import {Breadcrumb, BreadcrumbItem, Button, Card, CardBody, Col, Container, Row, Table} from "reactstrap";
import Loader from "../../../components/Loader";
import dateFormat from "dateformat";
import DateRange from "../../../components/formfields/DateRange";
import PublisherSelect from "../../../components/formfields/PublisherSelect";
import ListStat from "../../../components/ListStat";
import LeadID from "../../../components/fields/LeadID";
import Datetime from "../../../components/fields/Datetime";
import LeadStatus from "../../../components/status/LeadStatus";
import Product from "../../../components/fields/Product";
import Publisher from "../../../components/fields/Publisher";
import RCID from "../../../components/fields/RCID";
import Source from "../../../components/fields/Source";
import MoneyTotal from "../../../components/fields/MoneyTotal";
import MoneyPub from "../../../components/fields/MoneyPub";
import MoneySystem from "../../../components/fields/MoneySystem";
import Pagination from "../../../components/Pagination";
import CostUploadType from "../../../components/fields/CostUploadType";

const CostUploadLog = (props) => {

    const defaultDate = dateFormat((new Date()).setDate((new Date()).getDate() - 180),"mmm d, yyyy")+" to "+dateFormat(new Date(),"mmm d, yyyy")

    const [filtration,setFiltration] = useState({page:1,perPage:50,date:defaultDate})
    const [log, setLog] = useState(null)
    const [loading, setLoading] = useState(true)
    const [data,setData] = useState(null)

    const changeData = (name,value) => {
        setFiltration({ ...filtration, [name]: value })
    }

    useEffect(() => {
        setLoading(true)
        API.post('/tools/costupload/log',filtration).then((response) => {
            setLog(response.data.data.data)
            setData(response.data.data)
            setLoading(false)
        });
    }, [filtration])

    let history = useHistory()

    return <Container fluid className="p-0">
        <Breadcrumb>
            <BreadcrumbItem>
                <Link to="/tools/costupload">Cost upload</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>Cost upload log</BreadcrumbItem>
        </Breadcrumb>
        <Row form>
            <Col md={4}>
                <div className="row ml-2">
                    <div className="col-auto pt-1">
                        <label>Date range</label>
                    </div>
                    <div className="col">
                        <DateRange
                            onChange={changeData}
                            name={'date'}
                            value={defaultDate}
                        />
                    </div>
                </div>
            </Col>
        </Row>
        <Card className="mt-2">
            {loading &&
                <Loader absolute={true}/>
            }
            {log?.length > 0 ?
                <div className="table-responsive">
                    <ListStat data={data}/>
                    <Table>
                        <thead>
                        <tr>
                            <th>Id</th>
                            <th>Date</th>
                            <th>Leads date range</th>
                            <th>Publisher</th>
                            <th>RCID</th>
                            <th>New cost</th>
                            <th>User</th>
                            <th>Type</th>
                            <th>Detail log</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            log.map((logItem, index) => {
                                return (
                                    <tr key={index}>
                                        <td>{logItem.id}</td>
                                        <td><Datetime dateTime={logItem.date}/></td>
                                        <td>{logItem.dateinterval}</td>
                                        <td>
                                            {logItem.publishers &&
                                                logItem.publishers.map((publisherItem, publisherItemKey) => {
                                                    return <div key={publisherItemKey}><Publisher
                                                        publisher={publisherItem}/></div>
                                                })
                                            }
                                        </td>
                                        <td>
                                            {logItem.rcids &&
                                                logItem.rcids.map((rcidItem, rcidItemKey) => {
                                                    return <div key={rcidItemKey}><RCID id={rcidItem.id}/></div>
                                                })
                                            }
                                        </td>
                                        <td className="text-dark"><MoneyPub money={logItem.cost}/></td>
                                        <td>{logItem.user.name}</td>
                                        <td><CostUploadType type={logItem.distributiontype}/></td>
                                        <td><Link to={`/tools/costupload/logitem/${logItem.id}`}>Log</Link></td>
                                    </tr>
                                )
                            })
                        }
                        </tbody>
                    </Table>
                </div>
                :
                !loading &&
                <CardBody className="text-center"><h5>Data not found</h5></CardBody>
            }
        </Card>
        {(data?.page > 0 && data?.pages > 0) &&
            <Pagination
                onChange={changeData}
                name={"page"}
                page={data.page}
                totalPages={data.pages}/>
        }
    </Container>
}

export default CostUploadLog
