import { useState } from "react"
import { useIdleTimer } from "react-idle-timer/dist/index.legacy.cjs"
import {useDispatch} from "react-redux";
import { logout } from "../redux/actions/userActions";
import API from "../utils/API";

const useIdleTimeout = ({ onIdle, idleTime = 1800, promptTime = 30 }) => {
    const dispatch = useDispatch();

    const idleTimeout = 1000 * idleTime;
    const [isIdle, setIdle] = useState(false)

    const handleIdle = () => {
        API.post('/user/signout', {}).then(()=>{
            dispatch(logout());
            setIdle(true)
        });
    }

    const idleTimer = useIdleTimer({
        timeout: idleTimeout,
        promptTimeout: promptTime*1000,
        onPrompt: onIdle,
        onIdle: handleIdle,
        debounce: 500,
        crossTab: true,
        leaderElection: true,
        syncTimers: 200
    })

    return {
        isIdle,
        setIdle,
        idleTimer
    }
}

export default useIdleTimeout;
