import React, { useState } from 'react';
import {Table, FormGroup, Label, Input, Button, Row, Col} from 'reactstrap';
import API from '../../../utils/API';
import { useHistory } from 'react-router-dom';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import Loader from '../../../components/Loader';

const states = [
    "AL", "AK", "AZ", "AR", "CA", "CO", "CT", "DE", "FL", "GA",
    "HI", "ID", "IL", "IN", "IA", "KS", "KY", "LA", "ME", "MD",
    "MA", "MI", "MN", "MS", "MO", "MT", "NE", "NV", "NH", "NJ",
    "NM", "NY", "NC", "ND", "OH", "OK", "OR", "PA", "RI", "SC",
    "SD", "TN", "TX", "UT", "VT", "VA", "WA", "WV", "WI", "WY"
];

const ElectricityProviders = () => {
    const [providers, setProviders] = useState([]);
    const [selectedState, setSelectedState] = useState('');
    let history = useHistory();
    const [loading,setLoading] = useState(false)

    const newProvider = () => {
        history.push('/settings/electricityprovider');
    };

    const mapProvider = () => {
        history.push('/settings/electricityprovidermapping');
    };

    const fetchProvidersForState = (state) => {
        setLoading(true)
        API.get('/admin/electricityprovider/'+state).then(response => {
            setProviders(response.data.data);
            setLoading(false)
        })
    };

    const handleStateChange = (e) => {
        const newState = e.target.value;
        setSelectedState(newState);
        if (newState) {
            fetchProvidersForState(newState);
        } else {
            setProviders([]);
        }
    };

    return (
        <div>
            <Button color="primary" onClick={newProvider} className="float-right mt-n1 mx-2">
                <FontAwesomeIcon icon={faPlus}/> New electricity provider
            </Button>
            <Button color="primary" onClick={mapProvider} className="float-right mt-n1 mx-2">
                <FontAwesomeIcon icon={faPlus}/> Map electricity provider
            </Button>
            <h1 className="h3 mb-3">Electricity providers</h1>
            <FormGroup>
                <Label for="stateSelect">Select State:</Label>
                <Input type="select" name="state" id="stateSelect" value={selectedState} onChange={handleStateChange}>
                    <option value="">Select State</option>
                    {states.map((state, index) => (
                        <option key={index} value={state}>
                            {state} - {getStateFullName(state)}
                        </option>
                    ))}
                </Input>
            </FormGroup>
            <Table striped>
                <thead>
                    <tr>
                        <th>Provider</th>
                    </tr>
                </thead>
                {loading &&
                    <Loader/>
                }
                {!loading && providers.length > 0 &&
                    <tbody>
                        {providers.map((provider) => (
                            <tr key={provider.id}>
                                <td>{provider.title}</td>
                            </tr>
                        ))}
                    </tbody>
                }
            </Table>
        </div>
    );
};

function getStateFullName(abbreviation) {
    const stateNames = {
        AL: "Alabama", AK: "Alaska", AZ: "Arizona", AR: "Arkansas", CA: "California",
        CO: "Colorado", CT: "Connecticut", DE: "Delaware", FL: "Florida", GA: "Georgia",
        HI: "Hawaii", ID: "Idaho", IL: "Illinois", IN: "Indiana", IA: "Iowa",
        KS: "Kansas", KY: "Kentucky", LA: "Louisiana", ME: "Maine", MD: "Maryland",
        MA: "Massachusetts", MI: "Michigan", MN: "Minnesota", MS: "Mississippi", MO: "Missouri",
        MT: "Montana", NE: "Nebraska", NV: "Nevada", NH: "New Hampshire", NJ: "New Jersey",
        NM: "New Mexico", NY: "New York", NC: "North Carolina", ND: "North Dakota", OH: "Ohio",
        OK: "Oklahoma", OR: "Oregon", PA: "Pennsylvania", RI: "Rhode Island", SC: "South Carolina",
        SD: "South Dakota", TN: "Tennessee", TX: "Texas", UT: "Utah", VT: "Vermont",
        VA: "Virginia", WA: "Washington", WV: "West Virginia", WI: "Wisconsin", WY: "Wyoming"
    };
    return stateNames[abbreviation] || abbreviation;
}

export default ElectricityProviders;