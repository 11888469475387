import React from "react";
import {Badge} from "reactstrap";

const CostUploadType = ({type}) => {
    if (type === 'equally') {
        return <Badge color="primary" className="mr-1 mb-1">
            Same Cost Per lead
        </Badge>
    }else if (type === 'proportionally') {
        return <Badge color="success" className="mr-1 mb-1">
            Percentage Based Upload
        </Badge>
    }else {
        return <></>
    }
}

export default CostUploadType;
