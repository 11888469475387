import React,{useState,useEffect} from "react";
import API from "../../../utils/API";
import InputMask from "react-input-mask";
import {Input, Label} from "reactstrap";
import Select from "react-select";
import {Plus} from "react-feather"
import uuid from 'react-uuid';

const RowDate = ({value,setData,deleteRow}) => {
    const options = [
        { value: 1, label: "Monday" },
        { value: 2, label: "Tuesday" },
        { value: 3, label: "Wednesday" },
        { value: 4, label: "Thursday" },
        { value: 5, label: "Friday" },
        { value: 6, label: "Saturday" },
        { value: 7, label: "Sunday" }
    ];

    const setValue = (itemName,itemValue) => {
        setData(value.id,itemName,itemValue)
    }

    return <div className="row">
        <div className="col">
            <Label>Start time (24hr format)</Label>
            <InputMask mask="99:99" value={value.timeFrom} onChange={(element)=>setValue('timeFrom',element.target.value)}>
                {inputProps => <Input {...inputProps} type="text"/>}
            </InputMask>
            <span className="text-muted">e.g "HH:MM"</span>
        </div>
        <div className="col">
            <Label>End time (24hr format)</Label>
            <InputMask mask="99:99" value={value.timeTo} onChange={(element)=>setValue('timeTo',element.target.value)}>
                {inputProps => <Input {...inputProps} type="text"/>}
            </InputMask>
            <span className="text-muted">e.g "HH:MM"</span>
        </div>
        <div className="col">
            <Label>Days of the week</Label>
            <Select
                className="react-select-container"
                classNamePrefix="react-select"
                options={options}
                value={value.days}
                onChange={(value)=>setValue('days',value)}
                isMulti
            />
        </div>
        <div className="col">
            <Label>Cap</Label>
            <input className="form-control" type="number" value={value.cap} onChange={(e)=>setValue('cap',e.target.value)}/>
        </div>
        <div className="col-auto" style={{paddingTop:"28px"}}>
            <button className="btn btn-outline-secondary" onClick={()=>deleteRow(value.id)}>X</button>
        </div>
    </div>
}

const DateTime = ({title,type,elementId,onClose}) => {

    const [data,setData] = useState([])
    const [loading,setLoading] = useState(true)

    useEffect(() => {
        API.post('/admin/filter',{elementId:elementId,type:type,filterTitle:title}).then((response) => {
            if (response.data.data.data && response.data.data.data?.length > 0) {
                setData(response.data.data.data)
            }
            setLoading(false)
        })
    }, [title,type])

    const saveFilter = () => {
        API.put('/admin/filter',{elementId:elementId,type:type,filterTitle:title,data:data}).then((response) => {
            onClose()
        });
    }

    const addRule = () => {
        setData([...data, {id:uuid()}]);
    }

    const deleteRule = (id) => {
        setData((prevState) =>
            prevState.filter((prevItem) => prevItem.id !== id)
        );
    }

    const editRule = (id,name,value) => {
        setData(prevState => {
            return prevState.map((item) => {
                return item.id === id ? {...item, [name]: value} : item
            })
        })
    }

    return (
        <>
            <div className="row">
                <div className="col pt-2">
                    <h5>Filter by: {title}</h5>
                </div>
                <div className="col-auto text-right">
                    <button onClick={onClose} className="btn btn-outline-secondary mb-2">X</button><br/>
                </div>
            </div>
            {!loading &&
                <>
                    <div className="text-center pb-2">
                        <button className="btn btn-outline-secondary" onClick={(e) => addRule()}><Plus size={12}/> Add
                            rule
                        </button>
                    </div>
                    {data?.map((dataItem, key) => {
                        return <div key={key}><RowDate value={dataItem} deleteRow={deleteRule} setData={editRule}/></div>
                    })}
                </>
            }
            <div className="mt-3 text-center">
                <button className="btn btn-primary" onClick={saveFilter}>Save</button>
            </div>
        </>
    )
}

export default DateTime;
