import React,{useState,useEffect} from "react";
import useIdleTimeout from "../hooks/useIdleTimeout";
import {useDispatch} from "react-redux";
import { logout } from "../redux/actions/userActions";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import API from "../utils/API";

const IdleTimeOut = () => {
    const idleTimeout = process.env.REACT_APP_IDLE_TIMEOUT;
    const promptTimeout = process.env.REACT_APP_IDLE_TIMEOUT_PROMPT;

    const [openModal, setOpenModal] = useState(false)
    const [remaining, setRemaining] = useState(promptTimeout)

    const dispatch = useDispatch();

    const handleIdle = () => {
        setOpenModal(true);
    }

    const {idleTimer} = useIdleTimeout({ onIdle: handleIdle, idleTime: idleTimeout, promptTime: promptTimeout })

    const stay = () => {
        setOpenModal(false)
        idleTimer.reset()
    }

    const handleLogout = () => {
        API.post('/user/signout', {}).then(()=>{
            dispatch(logout());
            setOpenModal(false);
        });
    }

    useEffect(() => {
        const interval = setInterval(() => {
            let remaining = Math.ceil(idleTimer.getRemainingTime() / 1000);

            if (remaining > promptTimeout) {
                setOpenModal(false);
            }
            setRemaining(remaining)
        }, 500)

        return () => {
            clearInterval(interval)
        }
    })

    return <>
        <React.Fragment>
            <Modal
                isOpen={openModal}
                toggle={() => stay()}
                size="md"
            >
                <ModalHeader toggle={() => stay()}>
                    Session timeout
                </ModalHeader>
                <ModalBody style={{backgroundColor:"#F7F9FC"}}>
                    <p>You're being timed out due to inactivity. Please choose to stay signed in or to logoff. Otherwise you will logged off automatically.</p>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={() => handleLogout()}>
                        Log Off
                    </Button>
                    <Button color="primary" onClick={() => stay()}>
                        Stay Logged In ({remaining})
                    </Button>
                </ModalFooter>
            </Modal>
        </React.Fragment>
    </>
}

export default IdleTimeOut;
