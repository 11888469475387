import React, {useState} from "react";
import {Link, Redirect} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {
  Button,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";

import logo from "../../assets/img/logo.png";
import API from "../../utils/API";
import {login} from "../../redux/actions/userActions";

const SignIn = () => {
  const [email,setEmail] = useState(null);
  const [password,setPassword] = useState(null);
  const [error,setError] = useState(null);
  const [loading,setLoading] = useState(false);

  const [isMFA,setIsMFA] = useState(false);
  const [mfaSessionId,setMfaSessionId] = useState(null);
  const [mfaCode,setMfaCode] = useState(null);

  const dispatch = useDispatch();

  const sign = useSelector(state => {
    return state.user.sign
  });

  const submit = () => {
    if (!loading) {
      setLoading(true)
      API.post('/auth/signin', {email: email, password: password, mfa: localStorage.getItem('jwtMFA')})
          .then(data => {
            if (data.data.data.role === 'admin') {
              if (data.data.data.mfa) {
                setIsMFA(true);
                setMfaSessionId(data.data.data.mfaToken);
              }else {
                dispatch(login(data.data.data));
              }
            }else {
              setError('Invalid user role')
            }
            setLoading(false)
          })
          .catch(error => {
            setError('Invalid username/password')
            setLoading(false)
          })
    }
  }

  const validateCode = () => {
    if (!loading && mfaCode && mfaCode.length === 6) {
        setLoading(true)
        API.post('/auth/signin/mfa', {token: mfaSessionId, code: mfaCode})
            .then(data => {
                dispatch(login(data.data.data));
                setLoading(false)
            })
            .catch(error => {
                setError('Invalid code')
                setLoading(false)
            })
    }
  }

  const resendCode = () => {
      if (!loading) {
          setLoading(true)
          API.post('/auth/signin/mfa/resend', {token: mfaSessionId})
              .then(data => {
                  setLoading(false)
              })
      }
  }

  return (
      sign ?
          <Redirect to="/"/>
          :
      <React.Fragment>
        <Card>
          <CardBody>
            <div className="m-sm-4">
              <div className="text-center">
                <img
                    src={logo}
                    className="img-fluid rounded-circle"
                    width="132"
                    height="132"
                />
              </div>
              {error &&
                <div className="text-center text-danger">{error}</div>
              }
              {isMFA ?
                  <Form className="mt-3">
                    <strong>To continue, please enter the 6-digit verification code sent to your phone.</strong>
                    <FormGroup className="mt-2">
                      <input
                          type="text"
                          name="code"
                          placeholder="Verification code"
                          onChange={(e) => {setMfaCode(`${e.target.value}`);setError(null)}}
                          className="form-control"
                          maxLength={6}
                      />
                      <small>
                        Didn't receive a code? <a onClick={()=>resendCode()} className="text-primary">Resend.</a>
                      </small>
                    </FormGroup>
                    <div className="text-center mt-3">
                      <Button color="primary" size="lg" onClick={validateCode}>
                        {loading ?
                            <>Loading...</>
                            :
                            <>Validate</>
                        }
                      </Button>
                    </div>
                  </Form>
                  :
                  <Form>
                    <FormGroup>
                      <Label>Email</Label>
                      <Input
                          bsSize="lg"
                          type="email"
                          name="email"
                          placeholder="Enter your email"
                          onChange={(e) => {setEmail(`${e.target.value}`);setError(null)}}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label>Password</Label>
                      <Input
                          bsSize="lg"
                          type="password"
                          name="password"
                          placeholder="Enter your password"
                          onChange={(e) => {setPassword(`${e.target.value}`);setError(null)}}
                      />
                      <small>
                        <Link to="/auth/reset-password">Forgot password?</Link>
                      </small>
                    </FormGroup>
                    <div className="text-center mt-3">
                      <Button color="primary" size="lg" onClick={submit}>
                        {loading ?
                            <>Loading...</>
                            :
                            <>Sign in</>
                        }
                      </Button>
                    </div>
                  </Form>
              }
            </div>
          </CardBody>
        </Card>
      </React.Fragment>
  )
}

export default SignIn;
