import { Card, CardBody, Col, Container, Row, Table } from "reactstrap";
import React, { useEffect, useState } from "react";

import API from "../../utils/API";
import CSVDownlaod from "../../components/CsvDownload";
import DateRange from "../../components/formfields/DateRange";
import Dateday from "../../components/fields/Dateday";
import Loader from "../../components/Loader";
import NumberFormat from "react-number-format";
import PriceModelDisplay from "../../components/fields/PriceModelDisplay";
import Product from "../../components/fields/Product";
import ProductMulti from "../../components/formfields/ProductMulti";
import Publisher from "../../components/fields/Publisher";
import PublisherSelect from "../../components/formfields/PublisherSelect";
import RCID from "../../components/fields/RCID";
import RCIDSelect from "../../components/formfields/RCIDSelect";
import Select from "react-select";
import dateFormat from "dateformat";
import { parseFilterValues } from "../../utils/helpers/filterHelper";
import { useHistory } from "react-router-dom";
import SortableTable from "../../components/SortableTable";

const PingPostReport = () => {
  const history = useHistory();
  const defaultDate =
    dateFormat(new Date().setDate(new Date().getDate() - 6), "mmm d, yyyy") +
    " to " +
    dateFormat(new Date(), "mmm d, yyyy");
  const defaultFiltrationState = {
    type: { value: true, label: "Summary" },
    date: defaultDate,
  };

  const reportList = [
    { value: true, label: "Summary" },
    { value: false, label: "Details" },
  ];

  const urlParams = new URLSearchParams(history.location.search);
  const parsedParams = Object.fromEntries(urlParams.entries());
  let filtersParsed = parseFilterValues(parsedParams);

  const mappedReportList = reportList.find(
    (report) => report.label === filtersParsed?.type
  );

  const [filtration, setFiltration] = useState({
    ...defaultFiltrationState,
    ...filtersParsed,
    type: mappedReportList || reportList[0],
  });

  const [report, setReport] = useState(null);
  const [loading, setLoading] = useState(true);

  const changeData = (name, value) => {
    setReport(null);
    setFiltration({ ...filtration, [name]: value });

    history.push({
      pathname: history.location.pathname,
      search: new URLSearchParams({ ...filtration, [name]: value }).toString(),
    });
  };

  const updateReportList = (value) => {
    setFiltration({ ...filtration, type: value });

    history.push({
      pathname: history.location.pathname,
      search: new URLSearchParams({
        ...filtration,
        type: value.label,
      }).toString(),
    });
  };

  const columnsDetails = [
    {
      name: "Date",
      selector: (row) => row.date,
      sortable: true,
    },
    {
      name: "RCID",
      selector: (row) => row.publisherrcid,
      cell: (row) => (
        <span>
          <RCID id={row.publisherrcid} />,{" "}
          <PriceModelDisplay model={row.pricemodel} value={row.pricevalue} />
        </span>
      ),
      sortable: true,
    },
    {
      name: "Publisher",
      selector: (row) => row.publisher.id,
      cell: (row) => <Publisher publisher={row.publisher} />,
      sortable: true,
    },
    {
      name: "Product",
      selector: (row) => row.product.id,
      cell: (row) => <Product product={row.product} />,
      sortable: true,
    },
    {
      name: "Pings",
      selector: (row) => parseInt(row.pingreceived),
      cell: (row) => (
        <NumberFormat
          value={row.pingreceived}
          displayType={"text"}
          thousandSeparator={true}
        />
      ),
      sortable: true,
    },
    {
      name: "AVG ping PT",
      selector: (row) =>
        row.pingreceived > 0 && row.pingtime / row.pingreceived,
      cell: (row) =>
        row.pingreceived > 0 && (
          <>{Math.round((row.pingtime / row.pingreceived) * 100) / 100} sec</>
        ),
      sortable: true,
    },
    {
      name: "Successful pings",
      selector: (row) => parseInt(row.pingsuccess),
      cell: (row) => (
        <NumberFormat
          value={row.pingsuccess}
          displayType={"text"}
          thousandSeparator={true}
        />
      ),
      sortable: true,
    },
    {
      name: "AVG ping price",
      selector: (row) =>
        row.pingsuccess > 0 && row.pingpubprice / row.pingsuccess,
      cell: (row) =>
        row.pingsuccess > 0 && (
          <>${Math.round((row.pingpubprice / row.pingsuccess) * 100) / 100}</>
        ),
      sortable: true,
    },
    {
      name: "Pings won",
      selector: (row) => parseInt(row.postreceived),
      cell: (row) => (
        <NumberFormat
          value={row.postreceived}
          displayType={"text"}
          thousandSeparator={true}
        />
      ),
      sortable: true,
    },
    {
      name: "AVG Post PT",
      selector: (row) =>
        row.postreceived > 0 && row.posttime / row.postreceived,
      cell: (row) =>
        row.postreceived > 0 && (
          <>{Math.round((row.posttime / row.postreceived) * 100) / 100} sec</>
        ),
      sortable: true,
    },
    {
      name: "AVG Post price",
      selector: (row) =>
        row.postmatched > 0 && row.postpubearning / row.postmatched,
      cell: (row) =>
        row.postmatched > 0 && (
          <>${Math.round((row.postpubearning / row.postmatched) * 100) / 100}</>
        ),
      sortable: true,
    },
    {
      name: "% of Pings Won",
      selector: (row) =>
        row.pingsuccess > 0 && row.postreceived / row.pingsuccess,
      cell: (row) =>
        row.pingsuccess > 0 ? (
          <span className="text-primary">
            {Math.round((row.postreceived / row.pingsuccess) * 10000) / 100}%
          </span>
        ) : (
          ""
        ),
      sortable: true,
    },
    {
      name: "Profit",
      selector: (row) =>
        row.posttotalearning > 0 && row.posttotalearning - row.postpubearning,
      cell: (row) =>
        row.posttotalearning > 0 ? (
          <span className="text-success">
            $
            {Math.round(100 * (row.posttotalearning - row.postpubearning)) /
              100}
          </span>
        ) : (
          ""
        ),
      sortable: true,
    },
    {
      name: "Profit/successful pings",
      selector: (row) =>
        row.posttotalearning > 0 &&
        row.pingsuccess > 0 &&
        (row.posttotalearning - row.postpubearning) / row.pingsuccess,
      cell: (row) =>
        row.posttotalearning > 0 && row.pingsuccess > 0 ? (
          <span className="text-warning">
            $
            {Math.round(
              (100 * (row.posttotalearning - row.postpubearning)) /
                row.pingsuccess
            ) / 100}
          </span>
        ) : (
          ""
        ),
      sortable: true,
    },
  ];

  const columnsSummary = [
    {
      name: "Date",
      selector: (row) => row.date,
      sortable: true,
    },
    {
      name: "Pings",
      selector: (row) => parseInt(row.pingreceived),
      cell: (row) => (
        <NumberFormat
          value={row.pingreceived}
          displayType={"text"}
          thousandSeparator={true}
        />
      ),
      sortable: true,
    },
    {
      name: "AVG ping PT",
      selector: (row) =>
        row.pingreceived > 0 && row.pingtime / row.pingreceived,
      cell: (row) =>
        row.pingreceived > 0 && (
          <>{Math.round((row.pingtime / row.pingreceived) * 100) / 100} sec</>
        ),
      sortable: true,
    },
    {
      name: "Successful pings",
      selector: (row) => parseInt(row.pingsuccess),
      cell: (row) => (
        <NumberFormat
          value={row.pingsuccess}
          displayType={"text"}
          thousandSeparator={true}
        />
      ),
      sortable: true,
    },
    {
      name: "AVG ping price",
      selector: (row) =>
        row.pingsuccess > 0 && row.pingpubprice / row.pingsuccess,
      cell: (row) =>
        row.pingsuccess > 0 && (
          <>${Math.round((row.pingpubprice / row.pingsuccess) * 100) / 100}</>
        ),
      sortable: true,
    },
    {
      name: "Pings won",
      selector: (row) => parseInt(row.postreceived),
      cell: (row) => (
        <NumberFormat
          value={row.postreceived}
          displayType={"text"}
          thousandSeparator={true}
        />
      ),
      sortable: true,
    },
    {
      name: "AVG Post PT",
      selector: (row) =>
        row.postreceived > 0 && row.posttime / row.postreceived,
      cell: (row) =>
        row.postreceived > 0 && (
          <>{Math.round((row.posttime / row.postreceived) * 100) / 100} sec</>
        ),
      sortable: true,
    },
    {
      name: "AVG Post price",
      selector: (row) =>
        row.postmatched > 0 && row.postpubearning / row.postmatched,
      cell: (row) =>
        row.postmatched > 0 && (
          <>${Math.round((row.postpubearning / row.postmatched) * 100) / 100}</>
        ),
      sortable: true,
    },
    {
      name: "% of Pings Won",
      selector: (row) =>
        row.pingsuccess > 0 && row.postreceived / row.pingsuccess,
      cell: (row) =>
        row.pingsuccess > 0 ? (
          <span className="text-primary">
            {Math.round((row.postreceived / row.pingsuccess) * 10000) / 100}%
          </span>
        ) : (
          ""
        ),
      sortable: true,
    },
    {
      name: "Profit",
      selector: (row) =>
        row.posttotalearning > 0 && row.posttotalearning - row.postpubearning,
      cell: (row) =>
        row.posttotalearning > 0 ? (
          <span className="text-success">
            $
            {Math.round(100 * (row.posttotalearning - row.postpubearning)) /
              100}
          </span>
        ) : (
          ""
        ),
      sortable: true,
    },
    {
      name: "Profit/successful pings",
      selector: (row) =>
        row.posttotalearning > 0 &&
        row.pingsuccess > 0 &&
        (row.posttotalearning - row.postpubearning) / row.pingsuccess,
      cell: (row) =>
        row.posttotalearning > 0 && row.pingsuccess > 0 ? (
          <span className="text-warning">
            $
            {Math.round(
              (100 * (row.posttotalearning - row.postpubearning)) /
                row.pingsuccess
            ) / 100}
          </span>
        ) : (
          ""
        ),
      sortable: true,
    },
  ];

  useEffect(() => {
    setLoading(true);

    API.post("/admin/report/pingpost", filtration).then((response) => {
      setReport(response.data.data);
      setLoading(false);
    });
  }, [filtration]);

  return (
    <Container fluid className="p-0">
      <div className="row justify-content-between">
        <div className="row ml-1">
          <div className="col-auto">
            <h3 className="h3 mb-3">Ping/Post report</h3>
          </div>
          <div className="col-auto" style={{ width: "200px" }}>
            <Select
              className="react-select-container"
              classNamePrefix="react-select"
              options={reportList}
              value={filtration.type}
              onChange={(value) => updateReportList(value)}
              placeholder="Report type"
            />
          </div>
        </div>
        <div className="col-auto">
          <CSVDownlaod
            url="/admin/report/pingpostcsv"
            filename="PingPostReport"
            filtration={filtration}
          />
        </div>
      </div>
      <Row form>
        <Col md={3}>
          <DateRange
            onChange={changeData}
            name={"date"}
            value={filtration?.date}
          />
        </Col>
        <Col md={3}>
          <ProductMulti
            name={"product"}
            onChange={changeData}
            value={filtration?.product}
          />
        </Col>
        <Col md={3}>
          <PublisherSelect
            name={"publisher"}
            onChange={changeData}
            value={filtration?.publisher}
          />
        </Col>
        <Col md={3}>
          <RCIDSelect
            name={"rcid"}
            onChange={changeData}
            product={filtration?.product}
            publisher={filtration?.publisher}
            value={filtration?.rcid}
          />
        </Col>
      </Row>
      <Card className="mt-2">
        {loading && <Loader absolute={true} />}
        {report?.length > 0 ? (
          <div className="table-responsive">
            {filtration.type.value ? (
              <SortableTable
                columns={columnsSummary}
                data={report}
                pagination={false}
              />
            ) : (
              <SortableTable
                columns={columnsDetails}
                data={report}
                pagination={false}
              />
            )}
          </div>
        ) : (
          !loading && (
            <CardBody className="text-center">
              <h5>Data not found</h5>
            </CardBody>
          )
        )}
      </Card>
    </Container>
  );
};

export default PingPostReport;
