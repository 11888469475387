import React,{useState,useEffect} from "react";
import API from "../../utils/API";
import Select from "react-select";

const ElectricityProviderSelect = ({ name, value = [], onChange }) => {
    const [electricityProvider,setElectricityProvider] = useState(null)
    const [filterValue, setFilterValue] = useState([]);
  
    const mapValuesToFilters = (value = [], providerList) => {
      return value.map((item) => {
        const matchedFilter = providerList.find((provider) => provider.value === item);
        return matchedFilter || item;
      });
    };
  
    const changeSelect = (value) => {
        const electricityProviderId = value.value;
        onChange(name, electricityProviderId ? electricityProviderId : null);
        
        const mappedFilters = mapValuesToFilters([value], electricityProvider);
        setFilterValue(mappedFilters);
    };

    useEffect(() => {
        let data = {}
        if (electricityProvider) {
            data.electricityProvider = electricityProvider
        }
        API.get('/admin/electricityprovider').then((response) => {
            let options,i,electricityProviderList = []
            if (response.data.data) {
                options = response.data.data
                for (i=0;i<options.length;i++) {
                    electricityProviderList.push({value:options[i].id,label:options[i].state + ' - ' + options[i].title})
                }
            }
            setElectricityProvider(electricityProviderList)
            const mappedFilters = mapValuesToFilters(value, options);
            setFilterValue(mappedFilters);
        });
    },[])

    return (
        <Select
            className="react-select-container"
            classNamePrefix="react-select"
            options={electricityProvider}
            value={filterValue}
            onChange={(value)=>changeSelect(value)}
            isMulti={false}
            placeholder="Electricity Provider"
        />
    )
}

export default ElectricityProviderSelect;