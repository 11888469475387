import React, { useEffect, useState } from 'react'
import API from "../../utils/API";
import {confirmAlert} from "react-confirm-alert";
import {Badge, Button, Card, Col, Container, Row, Table} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import Loader from "../../components/Loader";
import {Edit2, Trash} from "react-feather";
import {useHistory} from "react-router-dom";
import User from "../../components/fields/User";
import {toastr} from "react-redux-toastr";

const Roles = () => {
    const [roles, setRoles] = useState(null);
    const [loading, setLoading] = useState(true);
    let history = useHistory();

    const loadRoles = () => {
        setLoading(true);
        API.get('/acl').then((response) => {
            setRoles(response.data.data);
            setLoading(false);
        });
    }

    useEffect(() => {
        loadRoles();
    }, []);

    const deleteRole = (roleId) => {
        confirmAlert({
            title: 'Confirm to delete',
            message: 'Are you sure you want to delete this role?',
            buttons: [
                {
                    label: 'Yes',
                    className: 'btn btn-danger',
                    onClick: () => {
                        API.delete('/acl/'+roleId).then((response) => {
                            loadRoles();
                            toastr.success('Success', 'Role was successfully deleted');
                        });
                    }
                },
                {
                    label: 'No',
                    className: 'btn btn-primary'
                }
            ]
        });
    }

    const editRole = (roleId) => {
        history.push("/user/aclroles/item/"+roleId);
    }

    const newRole = () => {
        history.push("/user/aclroles/item")
    }

    return (
        <Container fluid className="p-0">
            <Button color="primary" onClick={newRole} className="float-right mt-n1">
                <FontAwesomeIcon icon={faPlus}/> New role
            </Button>
            <h1 className="h3 mb-3">Access roles</h1>
            <Row>
                <Col>
                    {loading &&
                        <Loader />
                    }
                    {(roles && roles.length > 0) &&
                        <Card>
                            <div className="table-responsive">
                                <Table>
                                    <thead>
                                    <tr>
                                        <th>Title</th>
                                        <th>Date add</th>
                                        <th>Users</th>
                                        <th>Permissions</th>
                                        <th>Actions</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        roles.map((role, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{role.title}</td>
                                                    <td>{role.dateadd}</td>
                                                    <td>{role.users.length > 0 &&
                                                        role.users.map((user, index) => {
                                                            return <div key={index}>
                                                                <User user={user}/>
                                                            </div>
                                                        })
                                                    }</td>
                                                    <td>{role.permissions.length > 0 &&
                                                        role.permissions.map((permission, index) => {
                                                            return <div key={index}>
                                                                <Badge color="primary" className="mr-1 mb-1">
                                                                    {permission}
                                                                </Badge>
                                                            </div>
                                                        })
                                                    }</td>
                                                    <td className="table-action">
                                                        <Edit2 onClick={() => {
                                                            editRole(role.id)
                                                        }} className="align-middle mr-1 cursor-pointer text-primary"
                                                               size={18}/>
                                                        {!role.users.length &&
                                                            <Trash onClick={() => {
                                                                deleteRole(role.id)
                                                            }} className="cursor-pointer align-middle text-danger"
                                                                   size={18}/>
                                                        }
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                    </tbody>
                                </Table>
                            </div>
                        </Card>
                    }
                </Col>
            </Row>
        </Container>
    )
}

export default Roles;
