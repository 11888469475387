import React,{useState,useEffect} from "react";
import API from "../../utils/API";
import FailReason from "../../components/formfields/FailReason";

const FailLead = ({lead,runUpdate}) => {
    const [reasons,setReasons] = useState([])
    const [data,setData] = useState([])
    const [runFailProps,setRunFailProps] = useState(false)

    useEffect(()=>{
        API.get(`/system/failreasons/${lead.product.id}`).then((response) => {
            setReasons(response.data.data)
        });
    },[lead])

    const changeData = (name,value) => {
        setData({ ...data, [name]: value })
    }

    const runFailLead = () => {
        API.post(`/admin/lead/faillead/${lead.id}`,data).then((response) => {
            runUpdate();
        });
    }

    return <>
        {lead.status === 'flagged' &&
            <div className="mt-3">
                {!runFailProps &&
                <button className="btn btn-outline-danger" onClick={()=>setRunFailProps(true)}>Fail lead</button>
                }
                {runFailProps &&
                    <div className="row border-danger border p-2 shadow">
                        <div className="col-12 mb-2" style={{minWidth:"200px"}}>
                            <FailReason reasons={reasons} name={'reason'} onChange={changeData} value={data.reason}/>
                        </div>
                        <div className="col-auto text-center">
                            <button className="btn btn-danger" onClick={runFailLead}>Fail lead</button>
                        </div>
                        <div className="col-auto text-center">
                            <button className="btn btn-outline-secondary" onClick={()=>setRunFailProps(false)}>Cancel</button>
                        </div>
                    </div>
                }
            </div>
        }
    </>
}

export default FailLead
