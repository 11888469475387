import { eHawkRiskDescriptions, eHawkStatusCodes } from "../../utils/constants";

import React from "react";
import { Table } from "reactstrap";

const EHawkInfo = ({ eHawkScrubber = {} }) => {
  const scrubberData = eHawkScrubber?.data
    ? JSON.parse(eHawkScrubber.data)
    : {};

  const getColor = (score = 0) => {
    if (score >= 10) {
      return "#27ae60";
    } else if (score >= 0 && score <= 9) {
      return "#16a085";
    } else if (score >= -15 && score <= -1) {
      return "#f1c40f";
    } else if (score >= -30 && score <= -16) {
      return "#e67e22";
    } else if (score >= -70 && score <= -31) {
      return "#d35400";
    } else if (score >= -100 && score <= -71) {
      return "#c0392b";
    } else {
      return "#27ae60";
    }
  };

  return (
    <>
      <div className="table-responsive">
        <Table>
          <thead>
            <tr>
              <th>Field Name</th>
              <th> Value</th>
              <th>Description</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="tab__ewak-score__bold">E-Hawk Version</td>
              <td>{scrubberData?.version}</td>
              <td>The E-Hawk Version this lead was tested against</td>
            </tr>
            <tr>
              <td className="tab__ewak-score__bold">Transaction ID</td>
              <td>{scrubberData?.transaction_id}</td>
              <td>The E-Hawk Transaction ID</td>
            </tr>
            <tr>
              <td className="tab__ewak-score__bold"> Status Code</td>
              <td>{scrubberData?.status}</td>
              <td>
                Transaction Status{" "}
                {`(${scrubberData?.status} = ${
                  eHawkStatusCodes[scrubberData?.status]
                })`}
              </td>
            </tr>
            <tr>
              <td></td>
              <td className="tab__ewak-score__bold">Overall Scoring</td>
              <td></td>
            </tr>
            <tr>
              <td className="tab__ewak-score__bold">Risk Score</td>
              <td>
                <div
                  className="tab__ewak-score"
                  style={{
                    backgroundColor: getColor(scrubberData?.score?.risk),
                  }}
                >
                  {scrubberData?.score?.risk}
                </div>
              </td>
              <td>The net fraud risk score</td>
            </tr>
            <tr>
              <td className="tab__ewak-score__bold"> Risk Severity</td>
              <td>{scrubberData?.score?.type}</td>
              <td>E-Hawk's risk severity assessment</td>
            </tr>
            <tr>
              <td className="tab__ewak-score__bold">Gross Risk Score</td>
              <td>
                <div
                  className="tab__ewak-score"
                  style={{
                    backgroundColor: getColor(scrubberData?.score?.total),
                  }}
                >
                  {scrubberData?.score?.total}
                </div>
              </td>
              <td>The gross total risk</td>
            </tr>
            <tr>
              <td className="tab__ewak-score__bold"> IP Geolocation</td>
              <td>
                {scrubberData?.ip_location?.city},{" "}
                {scrubberData?.ip_location?.country}
              </td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td className="tab__ewak-score__bold">Score Breakdown</td>
              <td></td>
            </tr>
            <tr>
              <td className="tab__ewak-score__bold">Ip Score</td>
              <td>
                <div
                  className="tab__ewak-score"
                  style={{
                    backgroundColor: getColor(scrubberData?.area?.ip),
                  }}
                >
                  {scrubberData?.area?.ip}
                </div>
              </td>
              <td>The risk score for the lead's IP Address</td>
            </tr>
            {scrubberData?.risk_hits?.ip?.map((text, index) => (
              <tr key={index}>
                <td></td>
                <td>{text}</td>
                <td>{eHawkRiskDescriptions[text] || ""}</td>
              </tr>
            ))}
            <tr>
              <td className="tab__ewak-score__bold">Location Score</td>
              <td>
                <div
                  className="tab__ewak-score"
                  style={{
                    backgroundColor: getColor(scrubberData?.area?.location),
                  }}
                >
                  {scrubberData?.area?.location}
                </div>
              </td>
              <td>The risk score for the lead's Physical Address</td>
            </tr>
            <tr>
              <td className="tab__ewak-score__bold">Name Score</td>
              <td>
                <div
                  className="tab__ewak-score"
                  style={{
                    backgroundColor: getColor(scrubberData?.area?.name),
                  }}
                >
                  {scrubberData?.area?.name}
                </div>
              </td>
              <td>The risk score for the lead's Name</td>
            </tr>
            <tr>
              <td className="tab__ewak-score__bold">Email Score</td>
              <td>
                <div
                  className="tab__ewak-score"
                  style={{
                    backgroundColor: getColor(scrubberData?.area?.email),
                  }}
                >
                  {scrubberData?.area?.email}
                </div>
              </td>
              <td>The risk score for the lead's E-mail Address</td>
            </tr>
            {scrubberData?.risk_hits?.email?.map((item) => (
              <tr>
                <td></td>
                <td>{item}</td>
                <td>{eHawkRiskDescriptions[item] || ""}</td>
              </tr>
            ))}
            <tr>
              <td className="tab__ewak-score__bold">Phone Score</td>
              <td>
                <div
                  className="tab__ewak-score"
                  style={{
                    backgroundColor: getColor(scrubberData?.area?.phone),
                  }}
                >
                  {scrubberData?.area?.phone}
                </div>
              </td>
              <td>The risk score for the lead's Phone Number</td>
            </tr>
            {scrubberData?.risk_hits?.phone?.map((item) => (
              <tr>
                <td></td>
                <td>{item}</td>
                <td></td>
              </tr>
            ))}
            <tr>
              <td className="tab__ewak-score__bold">Domain Score</td>
              <td>
                <div
                  className="tab__ewak-score"
                  style={{
                    backgroundColor: getColor(scrubberData?.area?.domain),
                  }}
                >
                  {scrubberData?.area?.domain}
                </div>
              </td>
              <td>The risk score for the lead's Domain (i.e., google.com)</td>
            </tr>
            {scrubberData?.risk_hits?.domain?.map((item) => (
              <tr>
                <td></td>
                <td>{item}</td>
                <td>{eHawkRiskDescriptions[item] || ""}</td>
              </tr>
            ))}
            <tr>
              <td className="tab__ewak-score__bold">Geolocation Score</td>
              <td>
                <div
                  className="tab__ewak-score"
                  style={{
                    backgroundColor: getColor(scrubberData?.area?.geolocation),
                  }}
                >
                  {scrubberData?.area?.geolocation}
                </div>
              </td>
              <td>The risk score for the lead's Domain (i.e., google.com)</td>
            </tr>
            <tr>
              <td className="tab__ewak-score__bold">Activity Score</td>
              <td>
                <div
                  className="tab__ewak-score"
                  style={{
                    backgroundColor: getColor(scrubberData?.area?.activity),
                  }}
                >
                  {scrubberData?.area?.activity}
                </div>
              </td>
              <td>
                The lead's activity with us (multiple lead's with the same
                email, or phone, etc)
              </td>
            </tr>
            {scrubberData?.risk_hits?.activity?.map((item) => (
              <tr>
                <td></td>
                <td>{item}</td>
                <td>{eHawkRiskDescriptions[item] || ""}</td>
              </tr>
            ))}
            <tr>
              <td className="tab__ewak-score__bold">Lead Source Score</td>
              <td>
                <div
                  className="tab__ewak-score"
                  style={{
                    backgroundColor: getColor(scrubberData?.area?.lead_source),
                  }}
                >
                  {scrubberData?.area?.lead_source}
                </div>
              </td>
              <td>Scoring of the lead's fingerprint</td>
            </tr>
            <tr>
              <td className="tab__ewak-score__bold">Community Score</td>
              <td>
                <div
                  className="tab__ewak-score"
                  style={{
                    backgroundColor: getColor(scrubberData?.area?.community),
                  }}
                >
                  {scrubberData?.area?.community}
                </div>
              </td>
              <td>
                How this lead data has been scored by E-Hawk's other clients and
                community
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
    </>
  );
};

export default EHawkInfo;
