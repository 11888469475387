import React from "react";
import { Container, Row, Spinner } from "reactstrap";

const Loader = ({absolute}) => (
  <Container fluid className={absolute?"vh-50 d-flex position-absolute":"vh-50 d-flex"}>
    <Row className="justify-content-center align-self-center w-100 text-center">
      <Spinner color="primary" style={{zIndex:1}} />
    </Row>
  </Container>
);

export default Loader;
