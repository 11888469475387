import React, {useEffect, useState} from "react";
import {Breadcrumb, BreadcrumbItem, Button, Card, CardBody, Container, FormGroup, Input, Label} from "reactstrap";
import API from "../../utils/API";
import {toastr} from "react-redux-toastr";
import {AvField, AvForm, AvInput} from "availity-reactstrap-validation";
import {Link, useHistory} from "react-router-dom";
import Pricemodel from "../../components/formfields/Pricemodel";
import AdminSelect from "../../components/formfields/AdminSelect";

const PublisherForm = (props) => {
    const [publisher, setPublisher] = useState({name:null,status:'active',contactname:null,companyname:null,email:null,phone:null,address:null,city:null,state:null,zip:null,pricemodel:null,pricevalue:null})
    const [error, setError] = useState(null)
    let history = useHistory()

    const handleChange = (e) => {
        setPublisher({ ...publisher, [e.target.name]: e.target.value })
        setError(null)
    }

    useEffect(() => {
        API.get('/admin/publisher/'+props.match.params.id).then((response) => {
            if (response.data.data) {
                setPublisher(response.data.data)
            }
        });
    }, [props.match.params.id])

    const submit = () => {
        API.put(props.match.params.id?'/admin/publisher/save/'+props.match.params.id:'/admin/publisher/add',publisher)
            .then((response) => {
                toastr.success('Success', 'Publisher was successfully saved')
                history.push("/publisher/item/"+response.data.data.id)
            })
            .catch(error => {
                setError('Invalid publisher data')
            })
    }

    return (
        <Container fluid className="p-0">
            <Breadcrumb>
                <BreadcrumbItem>
                    <Link to="/publisher/list">Publishers</Link>
                </BreadcrumbItem>
                {(props.match.params.id && publisher) &&
                <BreadcrumbItem>
                    <Link to={"/publisher/item/"+publisher.id}>{publisher.name}</Link>
                </BreadcrumbItem>
                }
                <BreadcrumbItem active>{props.match.params.id?"Edit":"New"} publisher</BreadcrumbItem>
            </Breadcrumb>
            <Card>
                <CardBody>
                    {error &&
                    <div className="text-center text-danger">{error}</div>
                    }
                    <AvForm onValidSubmit={submit}>
                        <FormGroup>
                            <Label>Name</Label>
                            <AvInput onChange={handleChange} required type="text" name="name" value={publisher.name} placeholder="Publisher name" />
                        </FormGroup>
                        <FormGroup>
                            <Label>Status</Label>
                            <AvField
                                type="select"
                                name="status"
                                value={publisher.status}
                                onChange={handleChange}
                            >
                                <option value={'active'}>Active</option>
                                <option value={'inactive'}>Not active</option>
                            </AvField>
                        </FormGroup>
                        <FormGroup>
                            <Label>Company name</Label>
                            <AvInput onChange={handleChange} type="text" name="companyname" value={publisher.companyname} placeholder="Company name" />
                        </FormGroup>
                        <FormGroup>
                            <Label>Contact name</Label>
                            <AvInput onChange={handleChange} type="text" name="contactname" value={publisher.contactname} placeholder="First and last name" />
                        </FormGroup>
                        <FormGroup>
                            <Label>Address</Label>
                            <AvInput onChange={handleChange} type="text" name="address" value={publisher.address} placeholder="Address" />
                        </FormGroup>
                        <FormGroup>
                            <Label>City</Label>
                            <AvInput onChange={handleChange} type="text" name="city" value={publisher.city} placeholder="City" />
                        </FormGroup>
                        <FormGroup>
                            <Label>State</Label>
                            <AvInput onChange={handleChange} type="text" name="state" value={publisher.state} placeholder="State" />
                        </FormGroup>
                        <FormGroup>
                            <Label>Zip</Label>
                            <AvInput onChange={handleChange} type="text" name="zip" value={publisher.zip} placeholder="Zip" />
                        </FormGroup>
                        <FormGroup>
                            <Label>Email</Label>
                            <AvInput onChange={handleChange} type="email" name="email" value={publisher.email} placeholder="Publisher email" />
                        </FormGroup>
                        <FormGroup>
                            <Label>Phone</Label>
                            <AvInput onChange={handleChange} type="text" name="phone" value={publisher.phone} placeholder="Phone" />
                        </FormGroup>
                        <FormGroup>
                            <Label>Account manager</Label>
                            <AdminSelect value={publisher.accountmanager} name={'accountmanager'} required={false} onChange={handleChange}/>
                        </FormGroup>
                        <div className="text-center">
                            <Button color="primary">Save</Button>
                        </div>
                    </AvForm>
                </CardBody>
            </Card>
        </Container>
    )
}

export default PublisherForm
