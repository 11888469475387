import React,{useState,useEffect} from "react";
import API from "../../utils/API";

const MatchedNoBuyer = ({lead,runUpdate}) => {
    const [runMatchedNoBuyer,setRunMatchedNoBuyer] = useState(false)

    const runMatchedNoBuyerLead = () => {
        API.post(`/admin/lead/matchednobuyer/${lead.id}`).then((response) => {
            runUpdate();
        });
    }

    return <>
        {lead.status === 'unmatched' &&
            <div className="mt-3">
                {!runMatchedNoBuyer &&
                    <button className="btn btn-outline-success" onClick={()=>setRunMatchedNoBuyer(true)}>Set as Matched No Buyer</button>
                }
                {runMatchedNoBuyer &&
                    <div className="row border-success border p-2 shadow">
                        <div className="col-auto text-center">
                            <button className="btn btn-success" onClick={runMatchedNoBuyerLead}>Matched No Buyer</button>
                        </div>
                        <div className="col-auto text-center">
                            <button className="btn btn-outline-secondary" onClick={()=>setRunMatchedNoBuyer(false)}>Cancel</button>
                        </div>
                    </div>
                }
            </div>
        }
    </>
}

export default MatchedNoBuyer
