import { Link } from "react-router-dom";
import React from "react";

const RCID = ({ id }) => {
  if (id) {
    return (
      <Link to={"/publisher/rcid/item/" + id} className="text-primary">
        {id}
      </Link>
    );
  } else {
    return "";
  }
};

export default RCID;
