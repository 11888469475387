import React,{useState,useEffect} from "react";
import {
    Breadcrumb,
    BreadcrumbItem, Button,
    Container, Nav, NavItem, NavLink, TabContent, TabPane
} from "reactstrap";
import API from "../../utils/API";
import Loader from "../../components/Loader";
import {
    User,
    MapPin,
    Mail,
    Phone,
    Edit2,

} from "react-feather";
import {Link, useHistory} from "react-router-dom";
import PublisherStatus from "../../components/status/PublisherStatus";
import classnames from "classnames";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus,faBuilding, faRecycle} from "@fortawesome/free-solid-svg-icons";
import RcidPerPublisher from "./rcid/RcidPerPublisher";
import Subids from "./Subids";
import Settings from "./Settings";
import Scrubbers from "./Scrubbers";
import UserField from "../../components/fields/User";
import UserForm from "./UserForm";
import Users from "./Users";
import BordeloConnect from "../bordelo/publisher/BordeloConnect";
import UserAssignForm from "./UserAssignForm";
import Filters from "./Filters";
import Memo from "../../components/memo/Memo";
import SystemLog from "../../components/SystemLog";

const Publisher = (props) => {
    const [publisher,setPublisher] = useState(null)
    const [activeTab,setActiveTab] = useState("settings")

    const [userForm,setUserForm] = useState(false)
    const [userFormId,setUserFormId] = useState(null)

    let history = useHistory();

    useEffect(() => {
        API.get('/admin/publisher/'+props.match.params.id).then((response) => {
            setPublisher(response.data.data)
        });
    }, [props.match.params.id])

    const editPublisher = (publisherId) => {
        history.push("/publisher/form/"+publisherId)
    }

    const newRcid = (publisherId) => {
        history.push("/publisher/rcid/form/"+publisherId)
    }

    const editUser = (userId) => {
        setUserFormId(userId)
        setUserForm(true)
    }

    useEffect(()=>{
        if (!userForm) {
            setUserFormId(null)
        }
    },[userForm])

    return (
        <Container fluid className="p-0">
            {publisher ?
                <>
                    <Breadcrumb>
                        <BreadcrumbItem>
                            <Link to="/publisher/list">Publishers</Link>
                        </BreadcrumbItem>
                        <BreadcrumbItem active>{publisher.name}</BreadcrumbItem>
                    </Breadcrumb>
                    <div className="row">
                        <div className="col-md-4 col-sm-12">
                            <div className="card">
                                <div className="card-body">
                                    <h1 className="h3 mb-3">{publisher.name}</h1>
                                    <h4 className="h4 mb-3">Id# {publisher.id}</h4>
                                    <div className="card-actions float-right">
                                        <Edit2 onClick={() => {editPublisher(publisher.id)}} className="align-middle mr-1 cursor-pointer" size={12} />
                                    </div>
                                    <PublisherStatus status={publisher.status}/>
                                    <ul className="list-unstyled mb-0">
                                        {publisher.accmanager &&
                                            <li className="mb-1">
                                                Account manager: <strong><UserField user={publisher.accmanager}/></strong>
                                            </li>
                                        }
                                        { (publisher.address || publisher.city || publisher.state || publisher.zip) &&
                                            <li className="mb-1">
                                                <MapPin width={14} height={14}
                                                        className="mr-1"/> {publisher.address} {publisher.city} {publisher.state} {publisher.zip}
                                            </li>
                                        }
                                        { publisher.contactname &&
                                            <li className="mb-1">
                                                <User width={14} height={14} className="mr-1"/> {publisher.contactname}
                                            </li>
                                        }
                                        { publisher.companyname &&
                                        <li className="mb-1">
                                            <FontAwesomeIcon icon={faBuilding}/> {publisher.companyname}
                                        </li>
                                        }
                                        { publisher.email &&
                                            <li className="mb-1">
                                                <Mail width={14} height={14} className="mr-1"/> {publisher.email}
                                            </li>
                                        }
                                        { publisher.phone &&
                                            <li className="mb-1">
                                                <Phone width={14} height={14} className="mr-1"/> {publisher.phone}
                                            </li>
                                        }
                                    </ul>
                                    <Memo typeId={publisher.id} type={'publisher'}/>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8 col-sm-12">
                            <div className={"tab default"}>
                                <Nav tabs>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: activeTab === "settings" })}
                                            onClick={() => {
                                                setActiveTab("settings");
                                            }}
                                        >
                                            Settings
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: activeTab === "filters" })}
                                            onClick={() => {
                                                setActiveTab("filters");
                                            }}
                                        >
                                            Filters
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: activeTab === "scrubbers" })}
                                            onClick={() => {
                                                setActiveTab("scrubbers");
                                            }}
                                        >
                                            Scrubbers
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: activeTab === "rcid" })}
                                            onClick={() => {
                                                setActiveTab("rcid");
                                            }}
                                        >
                                            RCID
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: activeTab === "users" })}
                                            onClick={() => {
                                                setActiveTab("users");
                                            }}
                                        >
                                            <strong>Users</strong>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: activeTab === "subid" })}
                                            onClick={() => {
                                                setActiveTab("subid");
                                            }}
                                        >
                                            SubId
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: activeTab === "log" })}
                                            onClick={() => {
                                                setActiveTab("log");
                                            }}
                                        >
                                            <strong>Log</strong>
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                                <TabContent activeTab={activeTab}>
                                    <TabPane tabId="settings">
                                        <Settings id={publisher.id}/>
                                    </TabPane>
                                    <TabPane tabId="filters">
                                        <Filters publisher={publisher}/>
                                    </TabPane>
                                    <TabPane tabId="scrubbers">
                                        <Scrubbers publisherId={publisher.id}/>
                                    </TabPane>
                                    <TabPane tabId="rcid">
                                        <div className="text-right">
                                            <Button color="primary" onClick={() => {newRcid(publisher.id)}}>
                                                <FontAwesomeIcon icon={faPlus}/> New rcid
                                            </Button>
                                        </div>
                                        <RcidPerPublisher publisher={props.match.params.id}/>
                                    </TabPane>
                                    <TabPane tabId="users" style={{minHeight:"30px"}}>
                                        {userForm ?
                                            <button onClick={() => {
                                                setUserForm(false)
                                            }} className="float-right btn btn-outline-secondary mb-2">X</button>
                                            :
                                            <>
                                                <Button color="warning" onClick={() => {
                                                    setUserForm(2)
                                                }} className="float-right mt-n1 ml-1">
                                                    <FontAwesomeIcon icon={faRecycle}/> Assign existing user
                                                </Button>
                                                <Button color="primary" onClick={() => {
                                                    setUserForm(1)
                                                }} className="float-right mt-n1">
                                                    <FontAwesomeIcon icon={faPlus}/> New user
                                                </Button>
                                            </>
                                        }
                                        {userForm ?
                                            <>
                                                {userForm === 1 ?
                                                    <UserForm closeForm={setUserForm} publisherId={publisher.id} id={userFormId}/>
                                                    :
                                                    <UserAssignForm closeForm={setUserForm} publisherId={publisher.id}/>
                                                }
                                            </>
                                            :
                                            <Users publisherId={publisher.id} startEditUser={editUser}/>
                                        }
                                    </TabPane>
                                    <TabPane tabId="subid">
                                        <Subids publisher={props.match.params.id}/>
                                    </TabPane>
                                    <TabPane tabId="log">
                                        <SystemLog publisher={[props.match.params.id]}/>
                                    </TabPane>
                                </TabContent>
                            </div>
                        </div>
                    </div>
                </>
                :
                <Loader/>
            }
        </Container>
    )
}

export default Publisher
