import React, { useEffect, useState } from "react";
import API from "../../utils/API";
import Select from "react-select";

const SubIDByPublisher = ({ name, value = [], onChange, product, publisher }) => {
    const [subid, setSubid] = useState(null);
    const [filterValue, setFilterValue] = useState([]);
  
    const mapValuesToFilters = (value = [], subidList) => {
      return value.map((item) => {
        const matchedFilter = subidList.find((subid) => subid.value == item);
        return matchedFilter || item;
      });
    };
  
    const changeSelect = (value) => {
      let i,
        values = [];
      for (i = 0; i < value?.length; i++) {
        values.push(value[i].value);
      }
      if (name) {
        onChange(name, values);
        const mappedFilters = mapValuesToFilters(values, subid);
        setFilterValue(mappedFilters);
      }
    };
  
    useEffect(() => {
      let data = {};

      if (publisher && publisher.length > 0) {
        data.publisher = publisher;
        API.get("/subid/"+data.publisher).then((response) => {
          let options,
            i,
            subidList = [];
          if (response.data.data) {
            options = response.data.data;
            for (i = 0; i < options.length; i++) {
                subidList.push({value:options[i].id,label:'#' + options[i].id + ', ' + options[i].subid})
            }
          }
          const mappedFilters = mapValuesToFilters(value, subidList);
          setFilterValue(mappedFilters);
          setSubid(subidList);
        });
      }else {
        changeSelect('')
        const mappedFilters = mapValuesToFilters([], null);
        setFilterValue(mappedFilters);
        setSubid([]);
      }

    }, [publisher]);
  
    return (
      <Select
        className="react-select-container"
        classNamePrefix="react-select"
        options={subid}
        value={filterValue}
        onChange={(value) => changeSelect(value)}
        isMulti
        placeholder="SubID"
      />
    );
  };

  export default SubIDByPublisher;
