import React,{useState,useEffect} from "react";
import ProductMulti from "../../../components/formfields/ProductMulti";
import LenderSelect from "../../../components/formfields/LenderSelect";
import OrderStatusSelect from "../../../components/formfields/OrderStatusSelect";
import API from "../../../utils/API";
import Loader from "../../../components/Loader";
import {Spinner, Table} from "reactstrap";
import Order from "../../../components/fields/Order";
import LenderTitle from "../../../components/fields/LenderTitle";
import Product from "../../../components/fields/Product";
import OrderStatus from "../../../components/status/OrderStatus";
import {toastr} from "react-redux-toastr";

const FilterCopy = ({filter,orderId}) => {
    const [filtration,setFiltration] = useState({filter:filter.title})
    const [orders,setOrders] = useState(null)
    const [loading, setLoading] = useState(true)
    const [ordersSelected,setOrdersSelected] = useState([])
    const [checkedAll,setCheckedAll] = useState(false)
    const [saving,setSaving] = useState(false)

    const changeData = (name,value) => {
        setFiltration({ ...filtration, [name]: value })
    }

    useEffect(() => {
        setLoading(true)
        API.post('/admin/filtercopy/orders', filtration).then((response) => {
            let list = response.data.data;
            list = list.filter(data => data.lenderorder.id !== orderId);
            setOrders(list)
            setLoading(false)
            setOrdersSelected([])
            setCheckedAll(false)
        })
    },[filtration]);

    const selectAll = (checked) => {
        if (checked && orders && orders.length > 0) {
            setCheckedAll(true)
            setOrdersSelected(orders.map(data => data.lenderorder.id))
        }else {
            setCheckedAll(false)
            setOrdersSelected([])
        }
    }

    const changeSelection = (e,id) => {
        if (e.target.checked) {
            setOrdersSelected([...ordersSelected,id])
        }else {
            setCheckedAll(false)
            setOrdersSelected(ordersSelected.filter(elementId => elementId!== id))
        }
    }

    const saveChanges = () => {
        if (ordersSelected.length > 0 && !saving) {
            setSaving(true)
            API.put('/admin/filtercopy/save', {orders:ordersSelected,order:orderId,filter:filter.title}).then((response) => {
                setOrdersSelected([]);
                toastr.success("Success","Filter was successfully copied");
                setSaving(false)
            })
        }
    }

    return <div style={{minHeight:"500px"}}>
        <div className="row mb-2">
            <div className="col-3">
                <ProductMulti
                    name={"product"}
                    onChange={changeData}
                />
            </div>
            <div className="col-3">
                <LenderSelect
                    name={"lender"}
                    onChange={changeData}
                />
            </div>
            <div className="col-3">
                <OrderStatusSelect
                    name={"status"}
                    onChange={changeData}
                />
            </div>
            <div className="col-3">
                <input type="text" className="form-control" placeholder="Keyword" onChange={(e)=>changeData('keyword',e.target.value)}/>
            </div>
        </div>
        {(ordersSelected && ordersSelected.length > 0) &&
            <div className="mb-2 mt-2 text-right" style={{position:"sticky",top:"10px",right:"10px",zIndex:1000}}>
                <button className="btn btn-primary" onClick={()=>saveChanges()} style={{width:"200px"}}>{saving? <Spinner color="white" size="sm" style={{zIndex:1}} /> : <>Save changes, {ordersSelected.length}</>}</button>
            </div>
        }
        {loading &&
            <Loader/>}
        {(!loading && orders && orders.length > 0) ?
            <div className="table-responsive">
                <Table striped className="my-0">
                    <thead>
                    <tr>
                        <th><input type="checkbox" checked={checkedAll} onChange={(e)=>selectAll(e.target.checked)}/></th>
                        <th>Id</th>
                        <th>Title</th>
                        <th>Lender</th>
                        <th>Product</th>
                        <th>Price</th>
                        <th>Status</th>
                    </tr>
                    </thead>
                    <tbody>
                    {orders.map(data => {
                            return (
                                <tr>
                                    <td><input type="checkbox" checked={ordersSelected.includes(data.lenderorder.id)} onChange={(e)=>changeSelection(e,data.lenderorder.id)}/></td>
                                    <td>{data.lenderorder.id}</td>
                                    <td><Order order={data.lenderorder} tags={true}/></td>
                                    <td><LenderTitle title={data.lender.title} id={data.lender.id}/></td>
                                    <td><Product product={data.product}/></td>
                                    <td><strong>{(data.lenderorder?.price?.length > 0) &&
                                        <>${data.lenderorder.price}</>
                                    }</strong></td>
                                    <td><OrderStatus status={data.lenderorder.status}/></td>
                                </tr>
                            )
                        }
                    )}
                    </tbody>
                </Table>
            </div>
            : !loading &&
            <div className="text-center mt-3"><h5>Orders not found</h5></div>
        }
    </div>;
}

export default FilterCopy;
