import React,{useState,useEffect} from "react";
import API from "../../../utils/API";
import {useHistory} from "react-router-dom";
import {confirmAlert} from "react-confirm-alert";
import {Button, Card, CardBody, Col, Container, Row, Table} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import {Edit2, Trash, Clock} from "react-feather";
import Loader from "../../../components/Loader";

const PagesContent = (props) => {

    const [pages,setPages] = useState(null)
    const [loading,setLoading] = useState(true)

    let history = useHistory()

    const newPage = () => {
        history.push("/settings/landingpagecontent/new")
    }

    const editPage = (id) => {
        history.push("/settings/landingpagecontent/item/"+id)
    }

    const historyPage = (id) => {
        history.push("/settings/landingpagecontent/itemhistory/"+id)
    }

    const deletePage = (id) => {
        confirmAlert({
            title: 'Confirm to delete',
            message: 'Are you sure you want to delete this content?',
            buttons: [
                {
                    label: 'Yes',
                    className: 'btn btn-danger',
                    onClick: () => {
                        API.delete('/admin/landingpagecontent/'+id).then((response) => {
                            loadPages()
                        })
                    }
                },
                {
                    label: 'No',
                    className: 'btn btn-primary'
                }
            ]
        });
    }

    const loadPages = () => {
        API.get('/admin/landingpagecontent/all').then((response) => {
            setPages(response.data.data)
            setLoading(false)
        })
    }

    useEffect(()=>{
        loadPages()
    },[])

    return <Container fluid className="p-0">
        <Button color="primary" onClick={newPage} className="float-right mt-n1">
            <FontAwesomeIcon icon={faPlus}/> New content
        </Button>
        <h1 className="h3 mb-3">Landing page content management</h1>
        <Row>
            <Col>
                {loading &&
                        <Loader />
                }
                <Card>
                    {pages &&
                        pages.length > 0 &&
                        <div className="table-responsive">
                            <Table>
                                <thead>
                                <tr>
                                    <th>Title</th>
                                    <th>Slug</th>
                                    <th>Date add</th>
                                    <th>Date update</th>
                                    <th>Actions</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    pages.map((page, index) => {
                                        return (
                                            <tr key={index}>
                                                <td><strong>{page.title}</strong></td>
                                                <td>{page.slug}</td>
                                                <td>{page.dateadd}</td>
                                                <td>{page.dateupdate}</td>
                                                <td className="table-action">
                                                    <Clock onClick={() => {historyPage(page.id)}} className="align-middle mr-1 cursor-pointer text-success" size={18} />
                                                    <Edit2 onClick={() => {editPage(page.id)}} className="align-middle mr-1 cursor-pointer text-primary" size={18} />
                                                    <Trash onClick={() => {deletePage(page.id)}} className="cursor-pointer align-middle text-danger" size={18} />
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                                </tbody>
                            </Table>
                        </div>
                    }
                </Card>
            </Col>
        </Row>
    </Container>
}

export default PagesContent
