import React,{useState,useEffect} from "react";
import {
    Card, CardBody,
    Col,
    Container,
    Row,
    Table
} from "reactstrap";
import Loader from "../../../components/Loader";
import API from "../../../utils/API";
import LeadID from "../../../components/fields/LeadID";
import Datetime from "../../../components/fields/Datetime";
import LeadStatus from "../../../components/status/LeadStatus";
import Product from "../../../components/fields/Product";
import Publisher from "../../../components/fields/Publisher";
import Source from "../../../components/fields/Source";
import MoneyTotal from "../../../components/fields/MoneyTotal";
import MoneyPub from "../../../components/fields/MoneyPub";
import MoneySystem from "../../../components/fields/MoneySystem";
import RCID from "../../../components/fields/RCID";
import DateRange from "../../../components/formfields/DateRange";
import PublisherSelect from "../../../components/formfields/PublisherSelect";
import RCIDSelect from "../../../components/formfields/RCIDSelect";
import LeadStatusSelect from "../../../components/formfields/LeadStatusSelect";
import LeadSourceSelect from "../../../components/formfields/LeadSourceSelect";
import Pagination from "../../../components/Pagination";
import dateFormat from "dateformat";
import ListStat from "../../../components/ListStat";
import SubID from "../../../components/fields/SubID";
import CSVDownlaod from "../../../components/CsvDownload";
import SortDirection from "../../../components/SortDirection";
import usePermissions from "../../../hooks/usePermissions";

const DetailsSolar = () => {

    const defaultDate = dateFormat((new Date()).setDate((new Date()).getDate() - 6),"mmm d, yyyy")+" to "+dateFormat(new Date(),"mmm d, yyyy")

    const [filtration,setFiltration] = useState({page:1,perPage:100,date:defaultDate,product:5})
    const [leads, setLeads] = useState(null)
    const [loading, setLoading] = useState(true)
    const [data,setData] = useState(null)

    const changeData = (name,value) => {
        setFiltration({ ...filtration, [name]: value })
    }

    useEffect(() => {
        setLoading(true)

        API.post('/admin/lead/detailreportfulldata',filtration).then((response) => {
            setLeads(response.data.data.data)
            setData(response.data.data)
            setLoading(false)
        });

    }, [filtration])

    const canLeadDownload = usePermissions('download_fullleadinfo');

    return (
        <Container fluid className="p-0">
            <div className="row justify-content-between">
                <div className="col-auto">
                    <h3 className="h3 mb-3 float-left">Leads full data, Solar</h3>
                </div>
                <div className="col-auto">
                    {canLeadDownload &&
                    <CSVDownlaod url='/admin/lead/detailreportfulldatacsv' filename='SolarLeadsFullData' filtration={filtration} />
                    }
                </div>
            </div>
            <Row form>
                <Col md={6}>
                    <DateRange
                        onChange={changeData}
                        name={'date'}
                        value={defaultDate}
                    />
                </Col>
                <Col md={6}>
                    <PublisherSelect
                        name={"publisher"}
                        onChange={changeData}
                    />
                </Col>
            </Row>
            <Row form className="mt-1">
                <Col md={4}>
                    <LeadStatusSelect
                        name={"status"}
                        onChange={changeData}
                    />
                </Col>
                <Col md={4}>
                    <LeadSourceSelect
                        name={"source"}
                        onChange={changeData}
                    />
                </Col>
                <Col md={4}>
                    <RCIDSelect
                        name={"rcid"}
                        onChange={changeData}
                        product={filtration?.product}
                        publisher={filtration?.publisher}
                    />
                </Col>
            </Row>
            {loading &&
                <Loader />
            }
            <Card className="mt-2">
                {!loading && leads?.length > 0 ?
                    <div className="table-responsive">
                        <ListStat data={data}/>
                        <Table>
                            <thead>
                            <tr>
                                <th>Id<SortDirection fieldName={'id'} filters={filtration} changeFilter={changeData}/></th>
                                <th>Date<SortDirection fieldName={'date'} filters={filtration} changeFilter={changeData}/></th>
                                <th>Status<SortDirection fieldName={'status'} filters={filtration} changeFilter={changeData}/></th>
                                <th>Reason</th>
                                <th>Product<SortDirection fieldName={'product'} filters={filtration} changeFilter={changeData}/></th>
                                <th>Publisher<SortDirection fieldName={'publisher'} filters={filtration} changeFilter={changeData}/></th>
                                <th>RCID<SortDirection fieldName={'rcid'} filters={filtration} changeFilter={changeData}/></th>
                                <th>Source<SortDirection fieldName={'source'} filters={filtration} changeFilter={changeData}/></th>
                                <th>SubId<SortDirection fieldName={'subid'} filters={filtration} changeFilter={changeData}/></th>
                                <th>Name<SortDirection fieldName={'name'} filters={filtration} changeFilter={changeData}/></th>
                                <th>Email<SortDirection fieldName={'email'} filters={filtration} changeFilter={changeData}/></th>
                                <th>Phone<SortDirection fieldName={'phone'} filters={filtration} changeFilter={changeData}/></th>
                                <th>State<SortDirection fieldName={'state'} filters={filtration} changeFilter={changeData}/></th>
                                <th>Zip<SortDirection fieldName={'zip'} filters={filtration} changeFilter={changeData}/></th>
                                <th>Address<SortDirection fieldName={'address'} filters={filtration} changeFilter={changeData}/></th>
                                <th>Homeowner<SortDirection fieldName={'homeowner'} filters={filtration} changeFilter={changeData}/></th>
                                <th>
                                    <nobr>Power bill</nobr><SortDirection fieldName={'powerbill'} filters={filtration} changeFilter={changeData}/>
                                </th>
                                <th>
                                    <nobr>Electricity provider</nobr><SortDirection fieldName={'electricityprovider'} filters={filtration} changeFilter={changeData}/>
                                </th>
                                <th>
                                    <nobr>Roof shade</nobr><SortDirection fieldName={'roofshade'} filters={filtration} changeFilter={changeData}/>
                                </th>
                                <th>
                                    <nobr>Credit score</nobr><SortDirection fieldName={'creditscore'} filters={filtration} changeFilter={changeData}/>
                                </th>
                                <th>
                                    <nobr>Property type</nobr><SortDirection fieldName={'propertytype'} filters={filtration} changeFilter={changeData}/>
                                </th>
                                <th>Total<SortDirection fieldName={'total'} filters={filtration} changeFilter={changeData}/></th>
                                <th>Pub<SortDirection fieldName={'pub'} filters={filtration} changeFilter={changeData}/></th>
                                <th>System<SortDirection fieldName={'system'} filters={filtration} changeFilter={changeData}/></th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                leads.map((lead, index) => {
                                    return (
                                        <tr key={index}>
                                            <td><LeadID id={lead.lead.id}/></td>
                                            <td><Datetime dateTime={lead.lead.dateadd}/></td>
                                            <td><LeadStatus status={lead.lead.status}/></td>
                                            <td>
                                                {lead.lead.exceptions.map((exception, excKey) => {
                                                    return <div key={excKey}>
                                                        <nobr>{exception}</nobr>
                                                    </div>
                                                })}
                                            </td>
                                            <td><Product product={lead.lead.product}/></td>
                                            <td><Publisher publisher={lead.lead.publisher}/></td>
                                            <td><RCID id={lead.lead.rcid}/></td>
                                            <td><Source source={lead.lead.leadsource}/></td>
                                            <td><SubID subid={lead.lead.subid}/></td>
                                            <td>{lead.leaddata.firstname} {lead.leaddata.lastname}</td>
                                            <td>{lead.leaddata.email}</td>
                                            <td>{lead.leaddata.phone}</td>
                                            <td>{lead.leaddata.state}</td>
                                            <td>{lead.leaddata.zip}</td>
                                            <td>{lead.leaddata.address}</td>
                                            <td>{lead.leaddata.homeowner}</td>
                                            <td>{lead.leaddata.powerbill}</td>
                                            <td>
                                                <nobr>{lead.leaddata.electricityprovider}</nobr>
                                            </td>
                                            <td>
                                                <nobr>{lead.leaddata.roofshade}</nobr>
                                            </td>
                                            <td>{lead.leaddata.creditscore}</td>
                                            <td>{lead.leaddata.propertytype}</td>
                                            <td><MoneyTotal money={lead.lead.earntotal}
                                                            moneyOriginal={lead.lead.earntotaloriginal}/></td>
                                            <td><MoneyPub money={lead.lead.earnpub}
                                                          moneyOriginal={lead.lead.earnpuboriginal}/></td>
                                            <td><MoneySystem money={lead.lead.earntotal - lead.lead.earnpub}/></td>
                                        </tr>
                                    )
                                })
                            }
                            </tbody>
                        </Table>
                    </div>
                    :
                    !loading &&
                    <CardBody className="text-center"><h5>Data not found</h5></CardBody>
                }
            </Card>
            {(data?.page > 0 && data?.pages > 0) &&
                <Pagination
                    onChange={changeData}
                    name={"page"}
                    page={data.page}
                    totalPages={data.pages}
                    loading={loading}/>
            }
        </Container>
    )
}

export default DetailsSolar
