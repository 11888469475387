import React, {useEffect, useState} from "react";
import {
    Breadcrumb,
    BreadcrumbItem,
    Button, Card,
    CardBody,
    Container,
    FormGroup,
    Label,
} from "reactstrap";
import {
    AvForm,
    AvField
} from "availity-reactstrap-validation";
import API from "../../../utils/API";
import {Link, useHistory} from "react-router-dom";
import {toastr} from "react-redux-toastr";

const BadFakeWordScrubber = (props) => {
    const [word, setWord] = useState({word:null,actiontype:null});
    const [error, setError] = useState(null)

    let history = useHistory();

    const handleChange = (e) => {
        setWord({ ...word, [e.target.name]: e.target.value.toLowerCase() })
        console.log(word)
        setError(null)
    };

    useEffect(()=>{
        if (props.match.params.id) {
            API.get('/admin/badfakeword/'+props.match.params.id).then((response) => {
                setWord(response.data.data)
            })
            
        }
    },[])

    const submit = () => {
        if (props.match.params.id) {
            API.put('/admin/badfakeword/'+props.match.params.id,word)
                .then((response) => {
                    toastr.success('Success', 'Word was successfully saved')
                    history.push("/settings/badfakewordscrubber")
                })
                .catch(error => {
                    setError('Duplicate word')
                })
        }else {
            API.post('/admin/badfakeword',word)
                .then((response) => {
                    toastr.success('Success', 'Word was successfully saved')
                    history.push("/settings/badfakewordscrubber")
                })
                .catch(error => {
                    setError('Duplicate word')
                })
        }
    }

    return (
        <Container fluid className="p-0">
            <Breadcrumb>
                <BreadcrumbItem>
                    <Link to="/settings/badfakewordscrubber">Bad/Fake Word Scrubber</Link>
                </BreadcrumbItem>
                <BreadcrumbItem active>{props.match.params.id?'Edit':'New'} word</BreadcrumbItem>
            </Breadcrumb>
            <Card>
                <CardBody>
                    {error &&
                        <div className="text-center text-danger">{error}</div>
                    }
                    <AvForm onValidSubmit={submit}>
                        <FormGroup>
                            <Label>Word</Label>
                            <AvField onChange={handleChange} type="text" name="word" value={word.word} placeholder="Bad/Fake Word"                                 
                            validate={{
                                    required: {value: true, errorMessage: 'Please enter a word'},
                                }}/>
                        </FormGroup>
                        <FormGroup>
                            <Label>Action Type</Label>
                            <AvField
                                type="select"
                                name="actiontype"
                                value={word.actiontype}
                                onChange={handleChange}
                                validate={{
                                    required: {value: true, errorMessage: 'Please select an actiontype'},

                                }}
                                placeholder={<div>Action Type</div>}
                            >
                                <option value={''} hidden>Select...</option>
                                <option value={'fail'}>Fail</option>
                                <option value={'flag'}>Flag</option>
                            </AvField>
                        </FormGroup>
                        
                        <div className="text-center">
                            <Button color="primary">Save</Button>
                        </div>
                    </AvForm>
                </CardBody>
            </Card>
        </Container>
    )
};

export default BadFakeWordScrubber;
