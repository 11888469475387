import React,{useState,useEffect} from "react";
import API from "../../../utils/API";
import {Badge, Breadcrumb, BreadcrumbItem, Container, Nav, NavItem, NavLink, TabContent, TabPane} from "reactstrap";
import {Link, useHistory} from "react-router-dom";
import {Edit2} from "react-feather";
import classnames from "classnames";
import RCIDStatus from "../../../components/status/RCIDStatus";
import Publisher from "../../../components/fields/Publisher";
import PriceModelDisplay from "../../../components/fields/PriceModelDisplay";
import Datetime from "../../../components/fields/Datetime";
import RCIDFilters from "./RCIDFilters";
import Product from "../../../components/fields/Product";
import RCIDTestLeads from "./RCIDTestLeads";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFilePdf} from "@fortawesome/free-solid-svg-icons";
import JanglConnect from "./JanglConnect";
import FieldsConfig from "./FieldsConfig";
import BordeloConnect from "../../bordelo/publisherrcid/BordeloConnect";
import PostingSpecification from "./PostingSpecification";
import Memo from "../../../components/memo/Memo";
import SystemLog from "../../../components/SystemLog";

const Rcid = (props) => {
    const [rcid,setRcid] = useState(null)

    const [activeTab,setActiveTab] = useState("filters")

    const [specs,setSpecs] = useState(null)

    let history = useHistory();

    useEffect(()=>{
        if (props.match.params.id) {
            API.get('/rcid/item/' + props.match.params.id).then((response) => {
                setRcid(response.data.data)
            });
            API.get('/rcid/postingspecs/' + props.match.params.id).then((response) => {
                setSpecs(response.data.data)
            });
        }
    },[])

    return (
        <Container fluid className="p-0">
            {rcid &&
                <>
                    <Breadcrumb>
                        <BreadcrumbItem>
                            <Link to="/publisher/list">Publishers</Link>
                        </BreadcrumbItem>
                        <BreadcrumbItem>
                            <Link to={"/publisher/item/"+rcid.publisher.id}>{rcid.publisher.name}</Link>
                        </BreadcrumbItem>
                        <BreadcrumbItem active>RCID</BreadcrumbItem>
                    </Breadcrumb>
                    <div className="row">
                        <div className="col-md-4 col-sm-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-auto">
                                            <h4 className="h4 mb-3">#{rcid.id}, {rcid.title}</h4>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <h5><Publisher publisher={rcid.publisher}/></h5>
                                        </div>
                                    </div>
                                    <div className="card-actions float-right">
                                        <Edit2 onClick={() => {history.push("/publisher/rcid/form/"+rcid.publisher.id+"/"+rcid.id)}} className="align-middle mr-1 cursor-pointer" size={12} />
                                    </div>
                                    <ul className="list-unstyled mb-0">
                                        <li className="mb-1">
                                            Status: <RCIDStatus status={rcid.status}/>
                                        </li>
                                        <li className="mb-1">
                                            Product: <Product product={rcid.product}/>
                                        </li>
                                        <li className="mb-1">
                                            Price model: <PriceModelDisplay model={rcid.pricemodel}
                                                                            value={rcid.pricevalue}/>
                                        </li>
                                        <li className="mb-1">
                                            Date add: <string><Datetime dateTime={rcid.dateadd}/></string>
                                        </li>
                                        <li className="mb-1">
                                            Duplicate checker within a vertical: {(rcid?.duplicate && rcid?.duplicate*1 > 0)?
                                            <span className="font-weight-bold">{rcid?.duplicate} Days</span>
                                            :
                                            <Badge className="m-1" color={"secondary"}>Off</Badge>
                                        }
                                        </li>
                                    </ul>
                                    {(specs && (specs.pingpost || specs.directpost)) &&
                                        <div className="mt-3">
                                        <h5>Posting specifications</h5>
                                            {specs.pingpost &&
                                                <div>
                                                    <PostingSpecification rcid={rcid.id} type={'pingpost'}/>
                                                </div>
                                            }
                                            {specs.directpost &&
                                                <div>
                                                    <PostingSpecification rcid={rcid.id} type={'directpost'}/>
                                                </div>
                                            }
                                        </div>
                                    }
                                    <Memo typeId={rcid.id} type={'publisherrcid'}/>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8 col-sm-12">
                            <div className={"tab default"}>
                                <Nav tabs>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: activeTab === "filters" })}
                                            onClick={() => {
                                                setActiveTab("filters");
                                            }}
                                        >
                                            Filters
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: activeTab === "fields" })}
                                            onClick={() => {
                                                setActiveTab("fields");
                                            }}
                                        >
                                            <strong>Required fields</strong>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: activeTab === "testlead" })}
                                            onClick={() => {
                                                setActiveTab("testlead");
                                            }}
                                        >
                                            Test leads
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: activeTab === "log" })}
                                            onClick={() => {
                                                setActiveTab("log");
                                            }}
                                        >
                                            <strong>Log</strong>
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                                <TabContent activeTab={activeTab}>
                                    <TabPane tabId="filters">
                                        <RCIDFilters rcid={rcid}/>
                                    </TabPane>
                                    <TabPane tabId="fields">
                                        <FieldsConfig id={rcid.id}/>
                                    </TabPane>
                                    <TabPane tabId="jangl">
                                        <JanglConnect id={rcid.id}/>
                                    </TabPane>
                                    <TabPane tabId="testlead">
                                        <RCIDTestLeads id={rcid.id}/>
                                    </TabPane>
                                    <TabPane tabId="log">
                                        <SystemLog rcid={[rcid.id]}/>
                                    </TabPane>
                                </TabContent>
                            </div>
                        </div>
                    </div>
                </>
            }
        </Container>
    )
}

export default Rcid
