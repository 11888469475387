import React from "react";
import { useTable, useExpanded } from "react-table";
import { Card, Table } from "react-bootstrap";
import { PlusCircle, MinusCircle } from "react-feather";
import LeadID from "./fields/LeadID";
import {faCheck} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const RowExpandingTable = ({ columns: userColumns, data }) => {
    const { getTableProps, getTableBodyProps, headerGroups, footerGroups, rows, prepareRow } =
        useTable(
            {
                columns: userColumns,
                data,
            },
            useExpanded // Use the useExpanded plugin hook
        );

    return (
        <Card>
            <Card.Body>
                <div className="table-responsive">
                <Table striped {...getTableProps()}>
                    <thead>
                    {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                                <th {...column.getHeaderProps()}>
                                    {column.render("Header")}
                                </th>
                            ))}
                        </tr>
                    ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                    {rows.map((row, i) => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map((cell) => {
                                    return (
                                        <td {...cell.getCellProps()} className={`${cell.column.className ?? ""}`}>
                                            {cell.column.id === 'leadid' ?
                                                <LeadID id={cell.value}/>:
                                                <>
                                                {cell.value === ':checkmark:' ?
                                                    <><FontAwesomeIcon icon={faCheck} className="text-success" /></>
                                                    :<>{cell.render("Cell")}</>
                                                }</>
                                            }
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                    </tbody>
                    <tfoot>
                    {footerGroups.map(group => (
                        <tr {...group.getFooterGroupProps()}>
                            {group.headers.map(column => (
                                <td {...column.getFooterProps()}>{column.render('Footer')}</td>
                            ))}
                        </tr>
                    ))}
                    </tfoot>
                </Table>
                </div>
            </Card.Body>
        </Card>
    );
};

const RowExpanding = ({data,header}) => {

    const tableColumnsExpandable = [
        {
            // Build our expander column
            id: "expander", // Make sure it has an ID
            Header: ({getToggleAllRowsExpandedProps, isAllRowsExpanded}) => (
                <span {...getToggleAllRowsExpandedProps()}>
            {isAllRowsExpanded ? (
                <MinusCircle className="feather"/>
            ) : (
                <PlusCircle className="feather"/>
            )}
          </span>
            ),
            Cell: ({row}) =>
                // Use the row.canExpand and row.getToggleRowExpandedProps prop getter
                // to build the toggle for expanding a row
                row.canExpand ? (
                    <span
                        {...row.getToggleRowExpandedProps({
                            style: {
                                // We can even use the row.depth property
                                // and paddingLeft to indicate the depth
                                // of the row
                                paddingLeft: `${row.depth * 2}rem`,
                            },
                        })}
                    >
              {row.isExpanded ? (
                  <MinusCircle className="feather"/>
              ) : (
                  <PlusCircle className="feather"/>
              )}
            </span>
                ) : null,
        },
        ...header,
    ];

    return <RowExpandingTable
        columns={tableColumnsExpandable}
        data={data.slice(0, 10)}
    />
}

export default RowExpanding;
