import React, { useEffect, useState } from "react";

import API from "../../utils/API";
import Select from "react-select";

const LeadSourceSelect = ({ name, value = [], onChange }) => {
  const [source, setSource] = useState(null);
  const [filterValue, setFilterValue] = useState([]);

  const mapValuesToFilters = (value = [], sourceList) => {
    return value.map((item) => {
      const matchedFilter = sourceList.find((source) => source.value == item);
      return matchedFilter || item;
    });
  };

  const changeSelect = (value) => {
    let i,
      values = [];
    for (i = 0; i < value?.length; i++) {
      values.push(value[i].value);
    }
    if (name) {
      onChange(name, values);
      const mappedFilters = mapValuesToFilters(values, source);
      setFilterValue(mappedFilters);
    }
  };

  useEffect(() => {
    API.get("/system/leadsource").then((response) => {
      let options,
        i,
        optionsList = [];
      if (response.data.data) {
        options = response.data.data;
        for (i = 0; i < options.length; i++) {
          optionsList.push({ value: options[i].id, label: options[i].title });
        }
      }
      const mappedFilters = mapValuesToFilters(value, optionsList);
      setFilterValue(mappedFilters);
      setSource(optionsList);
    });
  }, []);

  return (
    <Select
      className="react-select-container"
      classNamePrefix="react-select"
      options={source}
      value={filterValue}
      onChange={(value) => changeSelect(value)}
      isMulti
      placeholder="Source"
    />
  );
};

export default LeadSourceSelect;
