import React,{useState,useEffect} from "react";
import API from "../../../utils/API";
import {Breadcrumb, BreadcrumbItem, Button, Card, CardBody, Container, FormGroup, Label} from "reactstrap";
import {Link, useHistory} from "react-router-dom";
import {toastr} from "react-redux-toastr";
import {AvField, AvForm, AvInput} from "availity-reactstrap-validation";

const ConfigurationEdit = (props) => {
    const [order,setOrder] = useState(null)
    const [fields,setFields] = useState(null)
    const [error, setError] = useState(null)
    const [config, setConfig] = useState(null)
    let history = useHistory();

    const handleChange = (e) => {
        setConfig({ ...config, [e.target.name]: e.target.value })
        setError(null)
    };

    useEffect(()=>{
        API.get('/admin/order/'+props.match.params.id).then((response) => {
            setOrder(response.data.data)
        });
    },[props.match.params.id])

    useEffect(()=>{
        API.get('/admin/order/configurations/'+props.match.params.id).then((response) => {
            setFields(response.data.data)
        });

        API.get('/admin/order/configuration/'+props.match.params.id).then((response) => {
            setConfig(response.data.data)
        });
    },[])

    const submit = () => {
        API.post('/admin/order/configuration/'+props.match.params.id,config)
            .then((response) => {
                toastr.success('Success', 'Configuration was successfully saved')
                history.push("/lender/order/item/"+props.match.params.id)
            })
            .catch(error => {
                setError('Configuration data')
            })
    }

    return (
        <Container fluid className="p-0">
            {order &&
            <>
                <Breadcrumb>
                    <BreadcrumbItem>
                        <Link to="/lender/list">Lenders</Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem>
                        <Link to={"/lender/item/" + order.lender.id}>{order.lender.title}</Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem>
                        <Link to={"/lender/order/item/" + order.id}>{order.title}</Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem active>Configuration</BreadcrumbItem>
                </Breadcrumb>
                {(fields!==null && config!==null) &&
                <Card>
                    <CardBody>
                        {error &&
                        <div className="text-center text-danger">{error}</div>
                        }
                        <AvForm onValidSubmit={submit}>
                            <FormGroup>
                                <Label>Destination (url or email)</Label>
                                <AvInput onChange={handleChange} required type="text" name="destination" value={config.destination} placeholder="Enter url or email" />
                            </FormGroup>
                            <FormGroup>
                                <Label>Timeout (seconds)</Label>
                                <AvInput onChange={handleChange} validate={{number: true}} max={300} type="text" name="timeout" value={config.timeout} placeholder="Enter send timeout" />
                            </FormGroup>
                            {order.ispingpost &&
                                <>
                                <FormGroup>
                                    <Label>Ping Destination (url or email)</Label>
                                    <AvInput onChange={handleChange} required type="text" name="destinationping" value={config.destinationping} placeholder="Enter url or email" />
                                </FormGroup>
                                <FormGroup>
                                    <Label>Ping Timeout (seconds)</Label>
                                    <AvInput onChange={handleChange} validate={{number: true}} max={300} type="text" name="timeoutping" value={config.timeoutping} placeholder="Enter send timeout" />
                                </FormGroup>
                                </>
                            }
                            {
                                fields.map((field, index) => {
                                    if (field.type==='string') {
                                        return (
                                            <FormGroup key={index}>
                                                <Label>{field.name}</Label>
                                                <AvInput onChange={handleChange} required type="text" name={field.name} value={config[field.name]} placeholder={"Enter "+field.name} />
                                            </FormGroup>
                                        )
                                    }else if (field.type==='options') {
                                        return (
                                            <FormGroup key={index}>
                                                <Label>{field.name}</Label>
                                                <AvField
                                                    type="select"
                                                    name={field.name}
                                                    value={config[field.name]}
                                                    onChange={handleChange}
                                                    required
                                                >
                                                    <option value="">Select...</option>
                                                    {
                                                        field.options.map((option, index) => (
                                                            <option key={index} value={option}>{option}</option>
                                                        ))
                                                    }
                                                </AvField>
                                            </FormGroup>
                                        )
                                    }
                                })
                            }
                            <div className="text-center">
                                <Button color="primary">Save</Button>
                            </div>
                        </AvForm>
                    </CardBody>
                </Card>
                }
            </>
            }
        </Container>
    )
}

export default ConfigurationEdit
