import { Link } from "react-router-dom";
import React from "react";

const Pixel = ({ pixel }) => {
    if (pixel) {
        return (
            <Link to={"/publisher/pixel/item/" + pixel.id} className="text-primary">
                {pixel.title}
            </Link>
        );
    } else {
        return "";
    }
};

export default Pixel;
