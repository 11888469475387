import React,{useState,useEffect} from "react";
import API from "../../../utils/API";
import {Link} from "react-router-dom";
import {Breadcrumb, BreadcrumbItem, Card, CardBody, Col, Container, Row, Table} from "reactstrap";
import Loader from "../../../components/Loader";
import ListStat from "../../../components/ListStat";
import LeadID from "../../../components/fields/LeadID";
import Datetime from "../../../components/fields/Datetime";
import LeadStatus from "../../../components/status/LeadStatus";
import Product from "../../../components/fields/Product";
import Publisher from "../../../components/fields/Publisher";
import RCID from "../../../components/fields/RCID";
import Source from "../../../components/fields/Source";
import MoneyTotal from "../../../components/fields/MoneyTotal";
import MoneyPub from "../../../components/fields/MoneyPub";
import MoneySystem from "../../../components/fields/MoneySystem";
import Pagination from "../../../components/Pagination";
import {toastr} from "react-redux-toastr";
import {AlertCircle} from "react-feather";
import CostUploadType from "../../../components/fields/CostUploadType";

const CostUploadLogItem = (props) => {

    const [filtration,setFiltration] = useState({page:1,perPage:100})
    const [leads, setLeads] = useState(null)
    const [loading, setLoading] = useState(true)
    const [data,setData] = useState(null)
    const [upload,setUpload] = useState(null)

    const changeData = (name,value) => {
        setFiltration({ ...filtration, [name]: value })
    }

    useEffect(()=>{
        const queryParameters = new URLSearchParams(window.location.search)
        if (queryParameters.get("new") && queryParameters.get("new") === 'true') {
            toastr.success("Success","Cost was successfully uploaded");
        }
        API.get('/tools/costupload/log/'+props?.match?.params?.id).then((response) => {
            setUpload(response.data.data)
        });
    },[])

    useEffect(() => {
        setLoading(true)
        API.post('/tools/costupload/logleads/'+props?.match?.params?.id,filtration).then((response) => {
            setLeads(response.data.data.data)
            setData(response.data.data)
            setLoading(false)
        });
    }, [filtration])

    return <Container fluid className="p-0">
        <Breadcrumb>
            <BreadcrumbItem>
                <Link to="/tools/costupload">Cost upload</Link>
            </BreadcrumbItem>
            <BreadcrumbItem>
                <Link to="/tools/costupload/log">Cost upload log</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>Upload {props?.match?.params?.id}, details</BreadcrumbItem>
        </Breadcrumb>
        {upload &&
            <>
                <h5>Upload details</h5>
                <Card className="mt-2 p-2">
                    <div className="row">
                        <div className="col-2">
                            <label>Date:</label>
                            <div><strong>{upload.date}</strong></div>
                        </div>
                        <div className="col-3">
                            <label>Leads date range:</label>
                            <div><strong>{upload.dateinterval}</strong></div>
                        </div>
                        {(upload.publishers && upload?.publishers?.length > 0) &&
                            <div className="col-2">
                                <label>Publishers:</label>
                                {upload.publishers.map((publisherItem, publisherItemKey) => {
                                    return <div key={publisherItemKey}><Publisher publisher={publisherItem}/></div>
                                })}
                            </div>
                        }
                        {(upload.rcids && upload?.rcids?.length > 0) &&
                            <div className="col-2">
                                <label>RCID:</label>
                                {upload.rcids.map((rcidItem, rcidItemKey) => {
                                    return <div key={rcidItemKey}><RCID id={rcidItem.id}/></div>
                                })}
                            </div>
                        }
                        <div className="col-1">
                            <label>New cost:</label>
                            <div><strong>{upload.cost}</strong></div>
                        </div>
                        {upload.distributiontype &&
                        <div className="col-2">
                            <label>Type:</label>
                            <div><CostUploadType type={upload.distributiontype}/></div>
                        </div>
                        }
                        <div className="col-2">
                            <label>Performed by:</label>
                            <div><strong>{upload.user.name}</strong></div>
                        </div>
                    </div>
                    {(upload.memotext && upload.memotext.length > 0) &&
                        <div className="mt-3">
                            <AlertCircle size={14} color={"red"}/> {upload.memotext}
                        </div>
                    }
                </Card>
            </>
        }
        <h5>Affected leads</h5>
        <Card className="mt-2">
            {loading &&
                <Loader absolute={true}/>
            }
            {leads?.length > 0 ?
                <>
                <div className="table-responsive">
                    <ListStat data={data}/>
                    <Table>
                        <thead>
                        <tr>
                            <th>Id</th>
                            <th>Date</th>
                            <th>Status</th>
                            <th>Product</th>
                            <th>Publisher</th>
                            <th>RCID</th>
                            <th>Source</th>
                            <th>Total</th>
                            <th>Original pub cost</th>
                            <th className="text-primary">New pub cost</th>
                            <th>System</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            leads.map((lead, index) => {
                                return (
                                    <tr key={index}>
                                        <td><LeadID id={lead.lead.id}/></td>
                                        <td><Datetime dateTime={lead.lead.dateadd}/></td>
                                        <td><LeadStatus status={lead.lead.status}/></td>
                                        <td><Product product={lead.lead.product}/></td>
                                        <td><Publisher publisher={lead.lead.publisher}/></td>
                                        <td><RCID id={lead.lead.rcid}/></td>
                                        <td><Source source={lead.lead.leadsource}/></td>
                                        <td><MoneyTotal money={lead.lead.earntotal}/></td>
                                        <td><MoneyPub money={lead.lead.earnpuboriginal}/></td>
                                        <td><strong><MoneyPub money={lead.costuploadloglead.newcost}/></strong></td>
                                        <td><MoneySystem money={lead.lead.earntotal-lead.lead.earnpub}/></td>
                                    </tr>
                                )
                            })
                        }
                        </tbody>
                    </Table>
                </div>
                </>
                :
                !loading &&
                <CardBody className="text-center"><h5>Data not found</h5></CardBody>
            }
        </Card>
        {(data?.page > 0 && data?.pages > 0) &&
            <Pagination
                onChange={changeData}
                name={"page"}
                page={data.page}
                totalPages={data.pages}/>
        }
    </Container>
}

export default CostUploadLogItem
