import React,{useState,useEffect} from "react";
import API from "../../../utils/API";

const PriceInput = ({orderId,currentPrice,save,updateChangedData,removeChangedData}) => {
    const [value,setValue] = useState('')
    const [prevValue,setPrevValue] = useState('')
    const [serverCapData, setServerCapData] = useState(null)
    const [priceChanged, setPriceChanged] = useState(false);

    const handleChange = (e) => {
        const changedValue = e.target.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');
        if (changedValue.length) {
            if (countDecimals(changedValue) > 0) {
                setValue(parseFloat(changedValue).toFixed(2));
            }else {
                setValue(changedValue);
            }
        }else {
            setValue('');
        }
    };

    useEffect(() => {
        setValue(currentPrice);
        setPrevValue(currentPrice);
    }, []);

    useEffect(() => {
        setPriceChanged(isPricesDifferent(value,prevValue));
    },[value,prevValue])

    useEffect(()=>{
        if (priceChanged) {
            updateChangedData(orderId,'price');
        }else {
            removeChangedData(orderId,'price');
        }
    },[priceChanged])

    const countDecimals = (value) => {
        if ((value % 1) !== 0) {
            return value.toString().split(".")[1].length;
        }
        return 0;
    }

    const isPricesDifferent = (price1,price2) => {
        if (price1 === '' || price1 === null) {
            price1 = 0;
        }
        if (price2 === '' || price2 === null) {
            price2 = 0;
        }
        return (parseFloat(price1).toFixed(2) !== parseFloat(price2).toFixed(2));
    }

    useEffect(()=> {
        if (save && priceChanged) {
            API.post('/admin/capmanagement/save', {lenderorder:orderId,type:'price',value:value}).then((response) => {
                setServerCapData(response.data.data)
                removeChangedData(orderId,'price')
            })
        }
    },[save])

    useEffect(()=> {
        if(serverCapData && serverCapData?.value){
            setPrevValue(serverCapData?.value);
        }
    },[serverCapData])

    return <div>
        <input style={{width:"80px"}} className={`form-control ${priceChanged?'bg-warning':''}`} value={value} onChange={handleChange}/>
    </div>
}

export default PriceInput;
