import React,{useState,useEffect} from "react";
import API from "../../../utils/API";
import {titleFormatter} from "../utils";

const LeadAge = ({title,type,elementId,onClose}) => {

    const [value, setValue] = useState(null)

    const [minutes, setMinutes] = useState(null)
    const [hours, setHours] = useState(null)

    useEffect(() => {
        API.post('/admin/filter',{elementId:elementId,type:type,filterTitle:title}).then((response) => {
            if (response.data.data.data) {
                if (response.data.data.data.value) {
                    setValue(response.data.data.data.value)

                    setMinutes(response.data.data.data.value % 60);
                    setHours(Math.floor(response.data.data.data.value / 60));
                }
            }
        })
    }, [title,type])

    const saveFilter = () => {
        API.put('/admin/filter',{elementId:elementId,type:type,filterTitle:title,data:{value:(1*minutes + hours * 60)}}).then((response) => {
            onClose()
        });
    }

    return (
        <>
            <div className="row">
                <div className="col pt-2">
                    <h5>Filter by: {titleFormatter(title)}</h5>
                </div>
                <div className="col-auto text-right">
                    <button onClick={onClose} className="btn btn-outline-secondary mb-2">X</button><br/>
                </div>
            </div>
            <div className="row">
                <div className="col-auto">
                    Hours
                    <input className="form-control" style={{width:"80px"}} type="number" value={hours} onChange={(e)=>setHours(e.target.value)}/>
                </div>
                <div className="col-auto">
                    Minutes
                    <input className="form-control" max={60} style={{width:"80px"}} type="number" value={minutes} onChange={(e)=>setMinutes(e.target.value)}/>
                </div>
            </div>
            <div className="mt-3 text-center">
                <button className="btn btn-primary" onClick={saveFilter}>Save</button>
            </div>
        </>
    )
}

export default LeadAge;
