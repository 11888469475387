import React,{useState} from "react";
import {faFolderOpen} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader, Badge} from "reactstrap";
import API from "../../utils/API";
import Loader from "../../components/Loader";
import Datetime from "./Datetime";
import PostingStatus from "../status/PostingStatus";

const OrderTestLog = ({id}) => {
    const [modalshow,setModalshow] = useState(false)
    const [loading,setLoading] = useState(true)
    const [log,setLog] = useState(false)

    const showLog = () => {
        setModalshow(true)
        setLoading(true)
        API.get('/admin/order/testlog/item/'+id).then((response) => {
            setLog(response.data.data)
            setLoading(false)
        });
    }

    return (
        <>
            <span onClick={()=>showLog()}>
                <FontAwesomeIcon
                    className="align-middle mr-2 cursor-pointer"
                    icon={faFolderOpen}
                    fixedWidth
                />
            </span>
            <React.Fragment>
                <Modal
                    isOpen={modalshow}
                    toggle={() => setModalshow(!modalshow)}
                    size="lg"
                >
                    <ModalHeader toggle={() => setModalshow(!modalshow)}>
                        Test log
                    </ModalHeader>
                    <ModalBody>
                        {loading &&
                        <Loader/>
                        }
                        {log &&
                        <>
                            {log.pingstatus &&
                                <>
                                    <Badge color="secondary" className="mr-1 mb-1">Ping</Badge>
                                    <ul className="list-unstyled">
                                        <li className="mt-1"><PostingStatus status={log.pingstatus}/></li>
                                        <li className="mt-1"><Datetime dateTime={log.date}/></li>
                                        <li className="mt-1"><strong>{log.pingtimesent}</strong> seconds</li>
                                        <li className="mt-1">URL: <strong>{log.pingurl}</strong></li>
                                    </ul>
                                    {log.pingcomment &&
                                        <div className="mt-3">
                                        <strong>Comment</strong>
                                        <textarea className="form-control" readOnly={true}>{log.pingcomment}</textarea>
                                        </div>
                                    }
                                    <div className="mt-3">
                                        <strong>Request</strong>
                                        <textarea className="form-control" readOnly={true}>{log.pingrequest}</textarea>
                                    </div>

                                    <div className="mt-3">
                                        <strong>Response</strong>
                                        <textarea className="form-control" readOnly={true}>{log.pingresponse}</textarea>
                                    </div>
                                </>
                            }
                            {log.status &&
                                <>
                                    {log.pingstatus &&
                                        <>
                                            <hr/>
                                        <Badge color="secondary" className="mr-1 mb-1">Post</Badge>
                                        </>
                                    }
                                    <ul className="list-unstyled">
                                        <li className="mt-1"><PostingStatus status={log.status}/></li>
                                        <li className="mt-1"><Datetime dateTime={log.date}/></li>
                                        <li className="mt-1"><strong>{log.timesent}</strong> seconds</li>
                                        <li className="mt-1">URL: <strong>{log.url}</strong></li>
                                    </ul>
                                    {log.comment &&
                                        <div className="mt-3">
                                            <strong>Comment</strong>
                                            <textarea className="form-control" readOnly={true}>{log.comment}</textarea>
                                        </div>
                                    }
                                    <div className="mt-3">
                                        <strong>Request</strong>
                                        <textarea className="form-control" readOnly={true}>{log.request}</textarea>
                                    </div>

                                    <div className="mt-3">
                                        <strong>Response</strong>
                                        <textarea className="form-control" readOnly={true}>{log.response}</textarea>
                                    </div>
                                </>
                            }
                        </>
                        }
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={() => setModalshow(!modalshow)}>
                            Close
                        </Button>
                    </ModalFooter>
                </Modal>
            </React.Fragment>
        </>
    )
};

export default OrderTestLog;
