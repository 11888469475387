import React,{useState,useEffect} from "react";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader, FormGroup, Label, Input} from "reactstrap";
import API from "../../../utils/API";
import {useHistory} from "react-router-dom";

const CopyFunctionality = ({order,copy,setCopy}) => {
    const [types,setTypes] = useState(null)
    const [settings, setSettings] = useState([])
    const [title, setTitle] = useState(null)
    const [price, setPrice] = useState(null)
    let history = useHistory();

    const handleCheckboxChange = (e) => {
        let tempSettings = [...settings]
        if (!tempSettings.includes(e.target.value)) {
            tempSettings.push(e.target.value)
        }else {
            tempSettings = tempSettings.filter(item => item !== e.target.value)
        }

        setSettings(tempSettings)
    }

    const runCopy = () => {
        API.post('/admin/copying/order/'+order.id,{types:settings,params:{title:title,price:price}}).then((response) => {
            setCopy(false)
            history.push("/lender/order/item/"+response.data.data)
        });
    }

    useEffect(()=>{
        if (copy && order) {
            API.get('/admin/copying/order').then((response) => {
                setTypes(response.data.data)
                setSettings(response.data.data)
            });
        }
    },[copy])

    return (<>
        {copy &&
            <Modal
                isOpen={copy}
                toggle={() => setCopy(!copy)}
                size={"sm"}
            >
                <ModalHeader toggle={() => setCopy(!copy)}>
                    Copy order
                </ModalHeader>
                <ModalBody className="m-1">
                    <FormGroup>
                        <Label>New order title</Label>
                        <Input type="text" name="title" value={title?title:''} onChange={(e)=>setTitle(e.target.value)}/>
                    </FormGroup>
                    <FormGroup>
                        <Label>New order price</Label>
                        <Input type="number" step="0.1" name="price" value={price?price:''} pattern={/^[0-9]\d*(((,\d{3}){1})?(\.\d{0,2})?)$/} onChange={(e)=>setPrice(e.target.value)}/>
                    </FormGroup>
                    <div><strong>Copy elements</strong></div>
                    {(types) &&
                        types.map((type,index)=>{
                            return (
                                <div className="row mt-3" key={index}>
                                    <div className="col">
                                        <Label>{type}</Label>
                                        <input type="checkbox" className="ml-3 mp-1" size={30} name={type}
                                               onChange={handleCheckboxChange} value={type} checked={settings.includes(type)}/>
                                    </div>
                                </div>
                            )
                        })
                    }
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={()=>runCopy()}>
                        Copy
                    </Button>
                    {' '}
                    <Button onClick={() => setCopy(false)}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
        }
    </>)
}

export default CopyFunctionality
