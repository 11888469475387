import React from "react";
import {useHistory} from "react-router-dom";
import {Edit2} from "react-feather";
import {Button} from "reactstrap";

const Configuration = ({order}) => {
    let history = useHistory();

    const edit = () => {
        history.push("/lender/order/configuration/"+order.id)
    }

    return (
        <div className="p-2">
            {(function() {
                if (order.config || order.timeout || order.destination) {
                    return <div className="card-actions float-right">
                        <Edit2 className="cursor-pointer zoomHover" size={12} onClick={edit}/>
                    </div>;
                }
            })()}
            {(order.config || order.timeout || order.destination)
                ?
                <ul className="list-unstyled mb-0">
                    <li className="mb-1"><strong>URL:</strong> {order.destination}</li>
                    <li className="mb-1"><strong>Timeout (seconds):</strong> {order.timeout}</li>
                    {order.ispingpost &&
                        <>
                            <li className="mb-1"><strong>Ping URL:</strong> {order.destinationping}</li>
                            <li className="mb-1"><strong>Ping Timeout (seconds):</strong> {order.timeoutping}</li>
                        </>
                    }
                    {order.config &&
                        Object.keys(order.config).map(key => {
                            return (
                                <li className="mb-1" key={key}><strong>{key}:</strong> {order.config[key]}</li>
                            )
                        })
                    }
                </ul>
                :
                <div className="text-center"><Button onClick={edit} color='primary'>Set up</Button></div>
            }
        </div>
    )
}

export default Configuration
