import React, {useEffect, useState} from "react";
import API from "../../utils/API";
import Scrubber from "./Scrubber";

const Scrubbers = ({publisherId}) => {
    const [data,setData] = useState(null)

    useEffect(()=>{
        API.get('/admin/scrubber/'+publisherId).then((response) => {
            setData(response.data.data)
        });
    },[])

    return (
        <>
            {data &&
                <div className="row">
                    {data.map((scrubber,key)=>{
                        return (
                            <Scrubber publisherId={publisherId} data={scrubber} key={key}/>
                        )
                    })}
                </div>
            }
        </>
    )
}

export default Scrubbers
