import React,{useState,useEffect} from "react";
import {AvForm, AvInput, AvGroup, AvField} from "availity-reactstrap-validation";
import {Container, Button, Card, FormGroup, Label} from "reactstrap";
import {toastr} from "react-redux-toastr";
import API from "../../utils/API";
import MFANewPhone from "./MFANewPhone";
import {Shield} from "react-feather";

const MultiFactorAuth = () => {
    const [mfaSetUp,setMfaSetup] = useState(false);
    const [mfaSetupProcessing,setMfaSetupProcessing] = useState(false);
    const [finishMfaSetup,setFinishMfaSetup] = useState(false);

    useEffect(()=>{
        API.get('/profile/mfa').then((response)=>{
            setMfaSetup(response.data.data);
        })
    },[finishMfaSetup]);

    const finishSetUp = () => {
        setFinishMfaSetup(true);
        setMfaSetupProcessing(false);
    }


    return <Container fluid className="p-0">
        <h1 className="h3 mb-3">Multi-Factor Authentication</h1>
            {mfaSetUp &&
                <>
                {mfaSetupProcessing ?
                    <Card className="p-2">
                        <div className="row">
                            <div className="col-auto"><MFANewPhone setFinishSetup={finishSetUp}/></div>
                        </div>
                    </Card>
                    :
                    <>
                    {mfaSetUp.isOn ?
                        <Card className="p-2">
                        <div className="row">
                            <div className="col-auto mr-0 pr-0">
                                <Shield className="text-success"/>
                            </div>
                            <div className="col-auto">
                                <span className="text-success font-weight-bold">MFA is on</span>
                            </div>
                            <div className="col-auto">
                                Phone number: ************{mfaSetUp.phone}
                            </div>
                        </div>
                            * Please contact tech in order to change phone number
                        </Card>
                        :
                        <button className="btn btn-primary" style={{width:"200px"}} onClick={()=>setMfaSetupProcessing(true)}>Setup</button>
                    }
                    </>
                }
                </>
            }
    </Container>
}

export default MultiFactorAuth
