import React, {useEffect, useState} from "react";
import {
    Breadcrumb,
    BreadcrumbItem,
    Button, Card,
    CardBody,
    Container,
    FormGroup,
    Label,
} from "reactstrap";
import {
    AvForm,
    AvInput,
    AvField
} from "availity-reactstrap-validation";
import API from "../../../utils/API";
import {Link, useHistory} from "react-router-dom";
import {toastr} from "react-redux-toastr";

const AreaCodeScrubber = (props) => {
    const [areaCode, setAreaCode] = useState({areacode:null,state:null});
    const [error, setError] = useState(null)

    let history = useHistory();

    const handleChange = (e) => {
        setAreaCode({ ...areaCode, [e.target.name]: e.target.value })
        setError(null)
    };

    useEffect(()=>{
        if (props.match.params.id) {
            API.get('/admin/invalidareacode/'+props.match.params.id).then((response) => {
                setAreaCode(response.data.data)
            })
            
        }
    },[])

    const submit = () => {
        if (props.match.params.id) {
            API.put('/admin/invalidareacode/'+props.match.params.id,areaCode)
                .then((response) => {
                    toastr.success('Success', 'Area code was successfully saved')
                    history.push("/settings/areacodescrubber")
                })
                .catch(error => {
                    setError('Duplicate area code')
                })
        }else {
            API.post('/admin/invalidareacode',areaCode)
                .then((response) => {
                    toastr.success('Success', 'Area code was successfully saved')
                    history.push("/settings/areacodescrubber")
                })
                .catch(error => {
                    setError('Duplicate area code')
                })
        }
    }

    return (
        <Container fluid className="p-0">
            <Breadcrumb>
                <BreadcrumbItem>
                    <Link to="/settings/areacodescrubber">Area Code Scrubber</Link>
                </BreadcrumbItem>
                <BreadcrumbItem active>{props.match.params.id?'Edit':'New'} area code</BreadcrumbItem>
            </Breadcrumb>
            <Card>
                <CardBody>
                    {error &&
                        <div className="text-center text-danger">{error}</div>
                    }
                    <AvForm onValidSubmit={submit}>
                        <FormGroup>
                            <Label>Area Code</Label>
                            <AvInput onChange={handleChange} required type="areacode" name="areacode" value={areaCode.areacode} pattern={/^[0-9]{3}$/} placeholder="Area Code" />
                        </FormGroup>
                        <FormGroup>
                            <Label>State</Label>
                            <AvField
                                type="select"
                                name="state"
                                required
                                value={areaCode.state}
                                onChange={handleChange}
                                placeholder={<div>Type to search</div>}
                            >
                                <option value={''} hidden>Select...</option>
                                <option value={'AL'}>AL</option>
                                <option value={'AK'}>AK</option>
                                <option value={'AZ'}>AZ</option>
                                <option value={'AR'}>AR</option>
                                <option value={'CA'}>CA</option>
                                <option value={'CO'}>CO</option>
                                <option value={'CT'}>CT</option>
                                <option value={'DE'}>DE</option>
                                <option value={'FL'}>FL</option>
                                <option value={'GA'}>GA</option>
                                <option value={'HI'}>HI</option>
                                <option value={'ID'}>ID</option>
                                <option value={'IL'}>IL</option>
                                <option value={'IN'}>IN</option>
                                <option value={'IA'}>IA</option>
                                <option value={'KS'}>KS</option>
                                <option value={'KY'}>KY</option>
                                <option value={'LA'}>LA</option>
                                <option value={'ME'}>ME</option>
                                <option value={'MD'}>MD</option>
                                <option value={'MA'}>MA</option>
                                <option value={'MI'}>MI</option>
                                <option value={'MN'}>MN</option>
                                <option value={'MS'}>MS</option>
                                <option value={'MO'}>MO</option>
                                <option value={'MT'}>MT</option>
                                <option value={'NE'}>NE</option>
                                <option value={'NV'}>NV</option>
                                <option value={'NH'}>NH</option>
                                <option value={'NJ'}>NJ</option>
                                <option value={'NM'}>NM</option>
                                <option value={'NY'}>NY</option>
                                <option value={'NC'}>NC</option>
                                <option value={'ND'}>ND</option>
                                <option value={'OH'}>OH</option>
                                <option value={'OK'}>OK</option>
                                <option value={'OR'}>OR</option>
                                <option value={'PA'}>PA</option>
                                <option value={'RI'}>RI</option>
                                <option value={'SC'}>SC</option>
                                <option value={'SD'}>SD</option>
                                <option value={'TN'}>TN</option>
                                <option value={'TX'}>TX</option>
                                <option value={'UT'}>UT</option>
                                <option value={'VT'}>VT</option>
                                <option value={'VA'}>VA</option>
                                <option value={'WA'}>WA</option>
                                <option value={'WV'}>WV</option>
                                <option value={'WI'}>WI</option>
                                <option value={'WY'}>WY</option>
                            </AvField>
                        </FormGroup>
                        
                        <div className="text-center">
                            <Button color="primary">Save</Button>
                        </div>
                    </AvForm>
                </CardBody>
            </Card>
        </Container>
    )
};

export default AreaCodeScrubber;