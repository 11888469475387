import {
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  FormGroup, Input, Label
} from "reactstrap";
import React, { useState } from "react";
import AdminSelect from "../../../components/formfields/AdminSelect";
import BillingReportDetail from "./displaytype/BillingReportDetail";
import BillingReportDetailByDate from "./displaytype/BillingReportDetailByDate";
import BillingReportDetailByOrder from "./displaytype/BillingReportDetailByOrder";
import BillingReportSummary from "./displaytype/BillingReportSummary";
import DateRange from "../../../components/formfields/DateRange";
import InvoiceMethod from "../../../components/formfields/InvoiceMethod";
import LenderSelect from "../../../components/formfields/LenderSelect";
import ProductMulti from "../../../components/formfields/ProductMulti";
import classnames from "classnames";
import dateFormat from "dateformat";
import { parseFilterValues } from "../../../utils/helpers/filterHelper";
import { useHistory } from "react-router-dom";
import { AvForm } from "availity-reactstrap-validation";

const BillingReport = () => {
  const history = useHistory();
  const defaultDate =
    dateFormat(new Date().setDate(new Date().getDate() - 6), "mmm d, yyyy") +
    " to " +
    dateFormat(new Date(), "mmm d, yyyy");
  const defaultFiltrationState = {
    date: defaultDate,
    billingtype: "return",
  };

  const urlParams = new URLSearchParams(history.location.search);
  const parsedParams = Object.fromEntries(urlParams.entries());
  const filtersParsed = parseFilterValues(parsedParams);

  const [generate, setGenerate] = useState(false);

  const [filtration, setFiltration] = useState({
    ...defaultFiltrationState,
    ...filtersParsed,
  });

  const [activeTab, setActiveTab] = useState("summary");

  const changeData = (name, value) => {
    setFiltration({ ...filtration, [name]: value });

    history.push({
      pathname: history.location.pathname,
      search: new URLSearchParams({ ...filtration, [name]: value }).toString(),
    });
  };

  const changeNativeData = (e) => {
    setFiltration({ ...filtration, [e.target.name]: e.target.value });

    history.push({
      pathname: history.location.pathname,
      search: new URLSearchParams({
        ...filtration,
        [e.target.name]: e.target.value,
      }).toString(),
    });
  };

  return (
    <Container fluid className="p-0">
      <h1 className="h3 mb-3">Billing report</h1>
      <Row form className={"mb-2"}>
        <Col md={4}>
          <label>Date range</label>
          <DateRange
            onChange={changeData}
            name={"date"}
            value={filtration?.date}
          />
        </Col>
        <Col md={4}>
          <label>Product</label>
          <ProductMulti
            name={"product"}
            onChange={changeData}
            value={filtration?.product}
          />
        </Col>
        <Col md={4}>
          <label>Lender</label>
          <LenderSelect
            name={"lender"}
            onChange={changeData}
            value={filtration.lender}
          />
        </Col>
      </Row>
      <Row form className={"mb-2"}>
        <Col md={4}>
          <AvForm>
            <Label>Invoice method</Label>
            <InvoiceMethod value={filtration?.invoicemethod} name={'invoicemethod'} required={false} placeholder={"All"} onChange={changeNativeData}/>
          </AvForm>
        </Col>
        <Col md={4}>
          <label>Representative</label>
          <AdminSelect
            name={"representative"}
            onChange={changeNativeData}
            value={filtration?.representative}
            required={false}
          />
        </Col>
        <Col md={4}>
          <label>Billing type</label>
          <select
            name={"billingtype"}
            value={filtration.billingtype}
            onChange={changeNativeData}
            className="form-control"
          >
            <option
              selected={filtration.billingtype === "return"}
              value={"return"}
            >
              Date Returned - Standard
            </option>
            <option selected={filtration.billingtype === "sent"} value={"sent"}>
              Date Sent
            </option>
          </select>
        </Col>
      </Row>
      {!generate && (
        <div className="row justify-content-center">
          <div className="col-auto">
            <button
              className="btn btn-primary"
              onClick={() => {
                setGenerate(true);
              }}
            >
              Generate
            </button>
          </div>
        </div>
      )}
      {generate && (
        <div className={"tab default mt-3"}>
          <Nav tabs>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "summary" })}
                onClick={() => {
                  setActiveTab("summary");
                }}
              >
                <strong>Summary</strong>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "byorder" })}
                onClick={() => {
                  setActiveTab("byorder");
                }}
              >
                <strong>Detail by order</strong>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "bydate" })}
                onClick={() => {
                  setActiveTab("bydate");
                }}
              >
                <strong>Detail by date</strong>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "detail" })}
                onClick={() => {
                  setActiveTab("detail");
                }}
              >
                <strong>Detail</strong>
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="summary" style={{ minHeight: "100%" }}>
              <BillingReportSummary filtration={filtration} />
            </TabPane>
            <TabPane tabId="byorder" style={{ minHeight: "100%" }}>
              <BillingReportDetailByOrder filtration={filtration} />
            </TabPane>
            <TabPane tabId="bydate" style={{ minHeight: "100%" }}>
              <BillingReportDetailByDate filtration={filtration} />
            </TabPane>
            <TabPane tabId="detail" style={{ minHeight: "100%" }}>
              <BillingReportDetail filtration={filtration} />
            </TabPane>
          </TabContent>
        </div>
      )}
    </Container>
  );
};

export default BillingReport;
