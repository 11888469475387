import React,{useState,useEffect} from "react";
import ProgressBar from "react-bootstrap/ProgressBar";
import API from "../../../utils/API";

const CapInput = ({orderId,capStat,capFilter,saveCap,setCap,capType,updateChangedData,removeChangedData}) => {
    const [filterSet, setFilterSet] = useState(false)
    const [filterVal, setFilterVal] = useState(false)
    const [usagePercent,setUsagePercentage] = useState(0)
    const [value,setValue] = useState(null)
    const [serverCapData, setServerCapData] = useState(null)

    const handleChange = (e) => {
        const changedValue = e.target.value.replace(/\D/g, "");
        setValue(changedValue.length?parseInt(changedValue):'');
    };

    useEffect(() => {
        if(capFilter?.filterdata?.isOn){
            setFilterSet(true);
            setFilterVal(parseInt(capFilter?.filterdata?.value));
            setValue(capFilter?.filterdata?.value);
            if (capStat && parseInt(capStat) > 0) {
                setUsagePercentage(parseInt(100*parseInt(capStat) / parseInt(capFilter?.filterdata?.value)));
            }
        }
    },[capFilter,capStat])

    useEffect(()=>{
        if ((filterSet && value!==filterVal) || (!filterSet && value)) {
            updateChangedData(orderId,capType)
        }else {
            removeChangedData(orderId,capType)
        }
    },[value])

    useEffect(()=> {
        if (saveCap && ((filterSet && value!==filterVal) || (!filterSet && value))) {
            API.post('/admin/capmanagement/save', {lenderorder:orderId,type:capType,value:value}).then((response) => {
                setServerCapData(response.data.data)
                removeChangedData(orderId,capType)
            })
        }
    },[saveCap])

    useEffect(()=> {
        if(serverCapData && serverCapData?.isOn){
            setFilterSet(true);
            setFilterVal(parseInt(serverCapData?.value));
            setValue(serverCapData?.value);
            if (capStat && parseInt(capStat) > 0) {
                setUsagePercentage(parseInt(100*parseInt(capStat) / parseInt(serverCapData?.value)));
            }
        }else if (serverCapData) {
            setFilterSet(false);
            setFilterVal(null);
            setValue(null);
        }
    },[serverCapData])

    return <div>
        <input className={`form-control ${((filterSet && value!==filterVal) || (!filterSet && value))?'bg-warning':''}`} value={value} onChange={handleChange}/>
        {filterSet &&
        <div>
            <ProgressBar variant={usagePercent>=90?'danger':'primary'} now={usagePercent} label={usagePercent>20?(usagePercent+"%"):''}/>
        </div>
        }
    </div>
}

export default CapInput;
