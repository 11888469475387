import React,{useState} from "react";
import Select from "react-select";

const PerPageSelect = ({name,value,onChange}) => {

    return (
        <Select
            className="react-select-container"
            classNamePrefix="react-select"
            options={[
                {value:100,label:"100 per page"},
                {value:200,label: "200 per page"},
            ]}
            value={{value:value,label:`${value} per page`}}
            onChange={(value)=>onChange(name,value.value)}
            placeholder="Records per page"
        />
    )

}

export default PerPageSelect;
