import { Card, CardBody, Col, Container, Row, Table } from "reactstrap";
import React, { useEffect, useState } from "react";

import API from "../../utils/API";
import AdminSelect from "../../components/formfields/AdminSelect";
import { Edit2 } from "react-feather";
import LenderTitle from "../../components/fields/LenderTitle";
import Loader from "../../components/Loader";
import Order from "../../components/fields/Order";
import OrderStatus from "../../components/status/OrderStatus";
import OrderStatusSelect from "../../components/formfields/OrderStatusSelect";
import Pagination from "../../components/Pagination";
import Product from "../../components/fields/Product";
import ProductMulti from "../../components/formfields/ProductMulti";
import { parseFilterValues } from "../../utils/helpers/filterHelper";
import { useHistory } from "react-router-dom";
import SortDirection from "../../components/SortDirection";
import CSVDownlaod from "../../components/CsvDownload";

const Orders = () => {
  let history = useHistory();

  const defaultFiltrationState = {
    page: 1,
    perPage: 100,
  };

  const urlParams = new URLSearchParams(history.location.search);
  const parsedParams = Object.fromEntries(urlParams.entries());
  const filtersParsed = parseFilterValues(parsedParams);

  const viewStatusList = [
    { value: "active", label: "Active" },
    { value: "paused", label: "Paused" },
  ];

  const mappedOptionList = filtersParsed?.status?.map((item) => {
    const matchedFilter = viewStatusList.find(
      (option) => option.value === item
    );
    return matchedFilter || item;
  });

  const [filtration, setFiltration] = useState({
    ...defaultFiltrationState,
    ...filtersParsed,
  });
  const [data, setData] = useState(null);
  const [orders, setOrders] = useState(null);
  const [loading, setLoading] = useState(true);

  const changeData = (name, value) => {
    setFiltration({ ...filtration, [name]: value });

    history.push({
      pathname: history.location.pathname,
      search: new URLSearchParams({ ...filtration, [name]: value }).toString(),
    });
  };

  const changeNativeData = (e) => {
    setFiltration({ ...filtration, [e.target.name]: e.target.value });

    history.push({
      pathname: history.location.pathname,
      search: new URLSearchParams({
        ...filtration,
        [e.target.name]: e.target.value,
      }).toString(),
    });
  };

  useEffect(() => {
    setLoading(true);
    API.post("/admin/order", filtration).then((response) => {
      setOrders(response.data.data.data);
      setData(response.data.data);
      setLoading(false);
    });
  }, [filtration]);

  const editOrder = (orderId) => {
    history.push("/lender/order/edit/" + orderId);
  };

  return (
    <Container fluid className="p-0">
      <div className="row justify-content-between">
        <div className="col-auto">
          <h3 className="h3 mb-3 float-left">Orders</h3>
        </div>
        <div className="col-auto">
          <CSVDownlaod
              url="/admin/order/orderlist"
              filename="OrderList"
              filtration={filtration}
          />
        </div>
      </div>
      <Row form>
        <Col md={2}>
          <ProductMulti
            name={"product"}
            onChange={changeData}
            value={filtration?.product}
          />
        </Col>
        <Col md={4}>
          <OrderStatusSelect
            name={"status"}
            onChange={changeData}
            value={mappedOptionList || viewStatusList}
          />
        </Col>
        <Col md={3}>
          <AdminSelect
            name={"representative"}
            onChange={changeNativeData}
            value={filtration?.representative}
            required={false}
            placeholder={"Account representative..."}
          />
        </Col>
      </Row>
      <Row className="mt-2">
        <Col>
          {loading && <Loader />}
          <Card>
            {!loading && orders && orders.length > 0 ? (
              <div className="table-responsive">
                <Table>
                  <thead>
                  <tr>
                    <th>Id<SortDirection fieldName={'id'} filters={filtration} changeFilter={changeData}/></th>
                    <th>Title<SortDirection fieldName={'title'} filters={filtration} changeFilter={changeData}/></th>
                    <th>Lender<SortDirection fieldName={'lender'} filters={filtration} changeFilter={changeData}/></th>
                    <th>Product<SortDirection fieldName={'product'} filters={filtration} changeFilter={changeData}/>
                    </th>
                    <th>Price<SortDirection fieldName={'price'} filters={filtration} changeFilter={changeData}/></th>
                    <th>Override price<SortDirection fieldName={'overrideprice'} filters={filtration}
                                                     changeFilter={changeData}/></th>
                    <th>Return rate price adj<SortDirection fieldName={'returnpriceadj'} filters={filtration}
                                                            changeFilter={changeData}/></th>
                    <th>Minimum ping price<SortDirection fieldName={'minpingprice'} filters={filtration}
                                                            changeFilter={changeData}/></th>
                    <th>Status<SortDirection fieldName={'status'} filters={filtration} changeFilter={changeData}/></th>
                    <th></th>
                  </tr>
                  </thead>
                  <tbody>
                  {orders.map((order, index) => {
                    return (
                        <tr key={index}>
                          <td>{order.id}</td>
                          <td>
                            <Order order={order} tags={true}/>
                          </td>
                          <td>
                            <LenderTitle
                                title={order.lender.title}
                                id={order.lender.id}
                            />
                          </td>
                          <td>
                            <Product product={order.product}/>
                          </td>
                          <td>
                            <strong>
                              {order?.price?.length > 0 && <>${order.price}</>}
                            </strong>
                          </td>
                          <td>
                            <strong>
                              {order?.overrideprice?.length > 0 && (
                                  <>${order.overrideprice}</>
                              )}
                            </strong>
                          </td>
                          <td>
                            <strong>
                              {order?.returnpriceadj && (
                                <>{order.returnpriceadj}%</>
                              )}
                            </strong>
                          </td>
                          <td>
                            <strong>
                              {(order?.minpingprice?.length > 0 && order.ispingpost) && (
                                  <>${order.minpingprice}</>
                              )}
                            </strong>
                          </td>
                          <td>
                            <OrderStatus status={order.status}/>
                          </td>
                          <td className="table-action">
                            <Edit2
                                onClick={() => {
                                  editOrder(order.id);
                                }}
                                className="align-middle mr-1 cursor-pointer text-primary"
                                size={18}
                            />
                          </td>
                        </tr>
                    );
                  })}
                  </tbody>
                </Table>
              </div>
            ) : (
                !loading && (
                    <CardBody className="text-center">
                      <h5>Data not found</h5>
                    </CardBody>
              )
            )}
          </Card>
          {data?.page > 0 && data?.pages > 0 && (
            <Pagination
              onChange={changeData}
              name={"page"}
              page={data.page}
              totalPages={data.pages}
              loading={loading}
            />
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default Orders;
