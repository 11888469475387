import React from "react";
import {Badge} from 'reactstrap';

const AdminStatus = ({ status}) => {
    if (status === 1) {
        return <Badge color="success" className="mr-1 mb-1">
            Active
        </Badge>
    }else if (status === 2) {
        return <Badge color="danger" className="mr-1 mb-1">
            Deactivated
        </Badge>
    }else if (!status || status === 0) {
        return <span className="mr-1 mb-1">
            Not activated
        </span>
    }
};

export default AdminStatus;
