import React, {useEffect, useState} from "react";
import {
    Button,
    FormGroup,
    Label,
} from "reactstrap";
import {
    AvField,
    AvForm,
    AvInput
} from "availity-reactstrap-validation";
import API from "../../utils/API";
import {toastr} from "react-redux-toastr";

const UserForm = ({id,publisherId,closeForm}) => {
    const [user, setUser] = useState({name:null,email:null,status:null,sendemail:1,mfadisable:null});
    const [error, setError] = useState(null)

    const handleChange = (e) => {
        setUser({ ...user, [e.target.name]: e.target.value })
        setError(null)
    };

    useEffect(()=>{
        if (id) {
            API.get('/users/'+id).then((response) => {
                setUser(response.data.data)
            });
        }
    },id)

    const handleCheckboxChange = (e) => {
        setUser({...user, [e.target.name]: e.target.checked ? e.target.value : null})
    }

    const submit = () => {
        if (id) {
            API.put('/users/'+id,{name:user.name,status:user.status,mfadisable:user.mfadisable})
                .then((response) => {
                    toastr.success('Success', 'User was successfully saved')
                    closeForm(false)
                })
                .catch(error => {
                    setError('User data')
                })
        }else {
            API.post('/admin/publisher/user',{name:user.name,email:user.email,publisher:publisherId,sendemail:user.sendemail,mfadisable:user.mfadisable})
                .then((response) => {
                    if (user.sendemail == 1) {
                        toastr.success('Success', 'Invitation was successfully sent')
                    }else {
                        toastr.success('Success', 'User was successfully added')
                    }
                    closeForm(false)
                })
                .catch(error => {
                    setError('Duplicate email')
                })

        }
    }

    return (
        <>
            <div className="mb-2 card-title">{id?'Edit':'New'} User</div>
            {error &&
                <div className="text-center text-danger">{error}</div>
            }
            <AvForm onValidSubmit={submit}>
                <FormGroup>
                    <Label>Name</Label>
                    <AvInput onChange={handleChange} required type="text" name="name" value={user.name} placeholder="User full name" />
                </FormGroup>
                <FormGroup>
                    <Label>Email</Label>
                    {id ?
                        <input readOnly={true} value={user.email} className="form-control"/>
                        :
                        <AvInput onChange={handleChange} required type="email" name="email" value={user.email} placeholder="User email" />
                    }
                </FormGroup>
                {id &&
                    <FormGroup>
                        <Label>Status</Label>
                        <AvField
                            type="select"
                            name="status"
                            value={user.status}
                            onChange={handleChange}
                        >
                            <option value={0}>Not active</option>
                            <option value={1}>Active</option>
                        </AvField>
                    </FormGroup>
                }
                {!id &&
                    <FormGroup>
                        <Label>Send invitation email</Label>
                        <AvField
                            type="select"
                            name="sendemail"
                            value={user.sendemail}
                            onChange={handleChange}
                        >
                            <option value={1}>Yes</option>
                            <option value={0}>No</option>
                        </AvField>
                    </FormGroup>
                }
                <FormGroup>
                    <Label>Disable mfa</Label>
                    <input type="checkbox" className="ml-3 mp-1" size={30} name="mfadisable"
                           onChange={handleCheckboxChange} value="1" checked={user.mfadisable == 1}/>
                </FormGroup>
                <div className="text-center">
                    <Button color="primary">{id ? 'Save' : 'Create'}</Button>
                </div>
            </AvForm>
        </>
    )
};

export default UserForm;
