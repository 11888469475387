import React,{useState,useEffect} from "react";
import API from "../../utils/API";
import {AvField} from "availity-reactstrap-validation";

const AdminSelect = ({name,value,onChange,required,placeholder}) => {
    const [data,setData] = useState(null)

    useEffect(() => {
        API.get('/system/admins').then((response) => {
            setData(response.data.data)
        });
    }, [])

    return (
        <select
            name={name}
            value={value}
            onChange={onChange}
            required={required}
            className="form-control"
        >
            <option value="">{placeholder?placeholder:'Select...'}</option>
            {data &&
                data.map((dataItem, index) => (
                    <option key={index} selected={value === dataItem.id} value={dataItem.id}>{dataItem.title}</option>
                ))
            }
        </select>
    )
}

export default AdminSelect;
