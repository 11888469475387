import React from 'react';
import { detect } from 'un-detector'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMobileAlt, faTabletAlt, faDesktop} from "@fortawesome/free-solid-svg-icons";
import {faWindows, faLinux, faApple, faAndroid, faEdge, faChrome, faSafari, faFirefox, faOpera} from "@fortawesome/free-brands-svg-icons";

const Device = ({userAgent}) => {
    const detected = detect(userAgent)

    return <span>
        {(detected.is.mobile && !detected.is.tablet) &&
            <FontAwesomeIcon color={"#000000"} icon={faMobileAlt}/>
        }
        {detected.is.tablet &&
            <FontAwesomeIcon color={"#000000"} icon={faTabletAlt}/>
        }
        {(!detected.is.mobile && !detected.is.tablet) &&
            <FontAwesomeIcon color={"#000000"} icon={faDesktop}/>
        }
        {(detected.is.windows || detected.is.winPhone) &&
            <FontAwesomeIcon color={"#000000"} icon={faWindows} style={{marginLeft: "5px"}}/>
        }
        {detected.is.linux &&
            <FontAwesomeIcon color={"#000000"} icon={faLinux} style={{marginLeft: "5px"}}/>
        }
        {(detected.is.mac || detected.is.iOS) &&
            <FontAwesomeIcon color={"#000000"} icon={faApple} style={{marginLeft: "5px"}}/>
        }
        {detected.is.android &&
            <FontAwesomeIcon color={"#000000"} icon={faAndroid} style={{marginLeft: "5px"}}/>
        }
        {detected.is.edge &&
            <FontAwesomeIcon color={"#000000"} icon={faEdge} style={{marginLeft: "5px"}}/>
        }
        {detected.is.chrome &&
            <FontAwesomeIcon color={"#000000"} icon={faChrome} style={{marginLeft: "5px"}}/>
        }
        {detected.is.safari &&
            <FontAwesomeIcon color={"#000000"} icon={faSafari} style={{marginLeft: "5px"}}/>
        }
        {detected.is.firefox &&
            <FontAwesomeIcon color={"#000000"} icon={faFirefox} style={{marginLeft: "5px"}}/>
        }
        {detected.is.opera &&
            <FontAwesomeIcon color={"#000000"} icon={faOpera} style={{marginLeft: "5px"}}/>
        }
    </span>
}

export default Device;
