import "tippy.js/dist/tippy.css";

import React, { useEffect, useState } from "react";

import API from "../../../../utils/API";
import Lender from "../../../../components/fields/Lender";
import Loader from "../../../../components/Loader";
import Order from "../../../../components/fields/Order";
import Product from "../../../../components/fields/Product";
import Tippy from "@tippyjs/react";
import SortableTable from "../../../../components/SortableTable";

const ByOrder = ({ filtration }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const columns = [
    {
      name: "Lender",
      selector: (row) => row.lenderorder.lender.title.toLowerCase(),
      cell: (row) => <Lender lender={row.lenderorder.lender} />,
      sortable: true,
    },
    {
      name: "Order",
      selector: (row) => row.lenderorder.title.toLowerCase(),
      cell: (row) => <Order order={row.lenderorder} tags={false} />,
      sortable: true,
    },
    {
      name: "Product",
      selector: (row) => row.lenderorder.product.title.toLowerCase(),
      cell: (row) => <Product product={row.lenderorder.product} />,
      sortable: true,
    },
    {
      name: "Pings",
      selector: (row) => parseInt(row.pingsent),
      cell: (row) => <span className="text-center nobr">{row.pingsent}</span>,
      sortable: true,
    },
    {
      name: "Avg ping response",
      selector: (row) =>
        parseInt(row.pingsent) > 0 &&
        parseFloat(row.pingtime) / parseInt(row.pingsent),
      cell: (row) => (
        <span className="text-center expression nobr">
          {parseInt(row.pingsent) > 0 && (
            <Tippy
              content={
                <span>For successful and unsuccessful pings altogether.</span>
              }
            >
              <div>
                {Math.round(
                  (parseFloat(row.pingtime) / parseInt(row.pingsent)) * 100
                ) / 100}{" "}
                sec
              </div>
            </Tippy>
          )}
          <span className="supsub">
            {parseInt(row.pingsuccess) > 0 && (
              <Tippy content={<span>For successful pings only.</span>}>
                <span className="text-success superscript nobr">
                  {Math.round(
                    (parseFloat(row.pingsuccesstime) /
                      parseInt(row.pingsuccess)) *
                      100
                  ) / 100}{" "}
                  sec
                </span>
              </Tippy>
            )}
            {parseInt(row.pingsent) > 0 &&
              parseInt(row.pingsent) > parseInt(row.pingsuccess) && (
                <Tippy content={<span>For unsuccessful pings only.</span>}>
                  <span className="text-danger subscript nobr">
                    {Math.round(
                      (parseFloat(row.pingrejecttime) /
                        (parseInt(row.pingsent) - parseInt(row.pingsuccess))) *
                        100
                    ) / 100}{" "}
                    sec
                  </span>
                </Tippy>
              )}
          </span>
        </span>
      ),
      sortable: true,
    },
    {
      name: "Successful pings",
      selector: (row) => parseInt(row.pingsuccess),
      cell: (row) => (
        <span className="text-center nobr">{row.pingsuccess}</span>
      ),
      sortable: true,
    },
    {
      name: "Avg ping price",
      selector: (row) =>
        parseInt(row.pingsuccess) > 0 && row.pingsuccessprice / row.pingsuccess,
      cell: (row) => (
        <span className="text-center nobr">
          {row.pingsuccess > 0 && (
            <>
              $
              {Math.round((row.pingsuccessprice / row.pingsuccess) * 100) / 100}
            </>
          )}
        </span>
      ),
      sortable: true,
    },
    {
      name: "Pings won",
      selector: (row) => parseInt(row.postsent),
      cell: (row) => <span className="text-center nobr">{row.postsent}</span>,
      sortable: true,
    },
    {
      name: "Avg post response",
      selector: (row) =>
        parseInt(row.postsent) > 0 &&
        parseFloat(row.posttime) / parseInt(row.postsent),
      cell: (row) => (
        <span className="text-center expression nobr">
          {parseInt(row.postsent) > 0 && (
            <Tippy
              content={
                <span>For successful and unsuccessful posts altogether.</span>
              }
            >
              <div>
                {Math.round(
                  (parseFloat(row.posttime) / parseInt(row.postsent)) * 100
                ) / 100}{" "}
                sec
              </div>
            </Tippy>
          )}
          <span className="supsub nobr">
            {parseInt(row.postsuccess) > 0 && (
              <Tippy content={<span>For successful posts only.</span>}>
                <span className="text-success superscript nobr">
                  {Math.round(
                    (parseFloat(row.postsuccesstime) /
                      parseInt(row.postsuccess)) *
                      100
                  ) / 100}{" "}
                  sec
                </span>
              </Tippy>
            )}
            {parseInt(row.postsent) > 0 &&
              parseInt(row.postsent) > parseInt(row.postsuccess) && (
                <Tippy content={<span>For unsuccessful posts only.</span>}>
                  <span className="text-danger subscript nobr">
                    {Math.round(
                      (parseFloat(row.postrejecttime) /
                        (parseInt(row.postsent) - parseInt(row.postsuccess))) *
                        100
                    ) / 100}{" "}
                    sec
                  </span>
                </Tippy>
              )}
          </span>
        </span>
      ),
      sortable: true,
    },
    {
      name: "Avg post price",
      selector: (row) =>
        parseInt(row.postsuccess) > 0 &&
        parseFloat(row.postsuccessprice) / parseInt(row.postsuccess),
      cell: (row) => (
        <span className="text-center nobr">
          {parseInt(row.postsuccess) > 0 && (
            <>
              $
              {Math.round(
                (parseFloat(row.postsuccessprice) / parseInt(row.postsuccess)) *
                  100
              ) / 100}
            </>
          )}
        </span>
      ),
      sortable: true,
    },
    {
      name: "% of pings won",
      selector: (row) =>
        parseInt(row.postsent) > 0 &&
        parseInt(row.postsent) / parseInt(row.pingsuccess),
      cell: (row) => (
        <span className="text-center nobr">
          {parseInt(row.postsent) > 0 && (
            <>
              {Math.round(
                (parseInt(row.postsent) / parseInt(row.pingsuccess)) * 100
              )}
              %
            </>
          )}
        </span>
      ),
      sortable: true,
    },
    {
      name: "Revenue",
      selector: (row) => parseInt(row.postsuccessprice),
      cell: (row) => (
        <span className="text-center nobr">${row.postsuccessprice}</span>
      ),
      sortable: true,
    },
  ];

  useEffect(() => {
    setLoading(true);
    API.post("/admin/lender/pingpostreport", {
      ...filtration,
      ...{ type: "byOrder" },
    }).then((response) => {
      setData(response.data.data);
      setLoading(false);
    });
  }, [filtration]);

  return (
    <>
      {loading && <Loader />}
      {!loading && data && data.length > 0 && (
        <SortableTable columns={columns} data={data} pagination={false} />
      )}
      {!loading && data && data.length === 0 && <h3>Data not found</h3>}
    </>
  );
};

export default ByOrder;
