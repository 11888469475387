import React,{useEffect, useState} from "react";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader, Table, Card, CardBody} from "reactstrap";
import API from "../../utils/API";
import Loader from "../../components/Loader";
import Datetime from "./Datetime";
import dateFormat from "dateformat";
import ListStat from "../ListStat";
import LeadID from "./LeadID";
import LeadStatus from "../status/LeadStatus";
import Product from "./Product";
import Publisher from "./Publisher";
import RCID from "./RCID";
import Source from "./Source";
import SubID from "./SubID";
import MoneyPub from "./MoneyPub";
import MoneySystem from "./MoneySystem";
import MoneyTotal from "./MoneyTotal";
import Pagination from "../Pagination";
import CSVDownlaod from "../CsvDownload";

const SubIDDetail = ({subid, item}) => {
    const [filtration,setFiltration] = useState({page:1,perPage:100})
    const [modalshow,setModalshow] = useState(false)
    const [loading,setLoading] = useState(true)
    const [leads,setLeads] = useState(null)
    const [data,setData] = useState(null)

      useEffect(() => {
        if (filtration !== null) {
          setLoading(true);
          apiCall();
        }
      }, [filtration]);
    
      const showLog = (e) => {
        setModalshow(true);
        setLoading(true);

        if(item) {
            setFiltration({
                page: 1,
                perPage: 100,
                date: item.date ? 
                  dateFormat(item.date, "mmm d, yyyy", true) +
                  " to " +
                  dateFormat(item.date, "mmm d, yyyy", true) : null,
                rcid: item.rcid ? [item.rcid.id] : null,
                publisher: [item.publisher.id],
                product: item.rcid ? item.rcid.product : null,
                subid: item.subid ? [item.subid.id] : null,
            });
        }

        if (item && filtration.date)
          apiCall();
      };
    
      const apiCall = () => {
        if(filtration.date) {
            API.post("/admin/lead/detailreportbysubid", filtration)
            .then((response) => {
              setLeads(response.data.data.data);
              setData(response.data.data);
              setLoading(false);
            })
            .catch((error) => {
              console.error("Error fetching data:", error);
              setLoading(false);
            });
        } 
      };
    
      const changeData = (name, value) => {
        setLoading(true);
        setFiltration({ ...filtration, [name]: value });
      };
    
    return (
        <>
            <a className="font-weight-bold cursor-pointer link-underline" onClick={(e)=>showLog(e)}>
                {subid?subid.subid:'No subid'}
            </a>
            <React.Fragment>
                <Modal
                    isOpen={modalshow}
                    toggle={() => setModalshow(!modalshow)}
                    style={{maxWidth: '1600px', width: '90%'}}
                    scrollable={true}
                >
                    <ModalHeader toggle={() => setModalshow(!modalshow)}>
                        {item.date + ' / ' + item.publisher.name + ' / '}
                        {item.rcid?item.rcid.id:'No rcid'}
                        {' / '}
                        {item.subid?item.subid.subid:'No subid'}
                    </ModalHeader>
                    <ModalBody>
                    <div className="row justify-content-between">
                        <div className="col-auto">
                            <h3 className="h3 mb-3 float-left">Subid Report - Lead List</h3>
                        </div>
                        <div className="col-auto">
                            <CSVDownlaod url='/admin/lead/detailreportcsvbysubid' filename='Subid Report - Lead List' filtration={filtration}/>
                        </div>
                    </div>
                    <Card className="mt-2">
                        {loading &&
                        <Loader />
                        }
                        {!loading && leads?.length > 0 ?
                            <div className="table-responsive">
                                <ListStat data={data}/>
                                <Table>
                                    <thead>
                                    <tr>
                                        <th>Id</th>
                                        <th>Date</th>
                                        <th>Status</th>
                                        <th>Reason</th>
                                        <th>Product</th>
                                        <th>Publisher</th>
                                        <th>RCID</th>
                                        <th>Source</th>
                                        <th>SubId</th>
                                        <th>Name</th>
                                        <th>Email</th>
                                        <th>Phone</th>
                                        <th>State</th>
                                        <th>Zip</th>
                                        <th>Address</th>
                                        <th>Homeowner</th>
                                        <th>Project</th>
                                        <th>Add/Remove walls</th>
                                        <th>Total</th>
                                        <th>Pub</th>
                                        <th>System</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        leads.map((lead, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td><LeadID id={lead.lead.id}/></td>
                                                    <td><Datetime dateTime={lead.lead.dateadd}/></td>
                                                    <td><LeadStatus status={lead.lead.status}/></td>
                                                    <td>
                                                        {lead.lead.exceptions.map((exception,excKey)=>{
                                                            return <div key={excKey}><nobr>{exception}</nobr></div>
                                                        })}
                                                    </td>
                                                    <td><Product product={lead.lead.product}/></td>
                                                    <td><Publisher publisher={lead.lead.publisher}/></td>
                                                    <td><RCID id={lead.lead.rcid}/></td>
                                                    <td><Source source={lead.lead.leadsource}/></td>
                                                    <td><SubID subid={lead.lead.subid}/></td>
                                                    <td>{lead.leaddata.firstname} {lead.leaddata.lastname}</td>
                                                    <td>{lead.leaddata.email}</td>
                                                    <td>{lead.leaddata.phone}</td>
                                                    <td>{lead.leaddata.state}</td>
                                                    <td>{lead.leaddata.zip}</td>
                                                    <td>{lead.leaddata.address}</td>
                                                    <td>{lead.leaddata.homeowner}</td>
                                                    <td>{lead.leaddata.projecttype}</td>
                                                    <td>{lead.leaddata.addremovewalls}</td>
                                                    <td><MoneyTotal money={lead.lead.earntotal} moneyOriginal={lead.lead.earntotaloriginal}/></td>
                                                    <td><MoneyPub money={lead.lead.earnpub} moneyOriginal={lead.lead.earnpuboriginal}/></td>
                                                    <td><MoneySystem money={lead.lead.earntotal-lead.lead.earnpub}/></td>
                                                </tr>
                                            )
                                        })
                                    }
                                    </tbody>
                                </Table>
                            </div>
                            :
                            !loading &&
                            <CardBody className="text-center"><h5>Data not found</h5></CardBody>
                        }
                    </Card>
                    {(data?.page > 0 && data?.pages > 0) &&
                        <Pagination
                            onChange={changeData}
                            name={"page"}
                            page={data.page}
                            totalPages={data.pages}
                            loading={loading}/>
                    }
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={() => setModalshow(!modalshow)}>
                            Close
                        </Button>
                    </ModalFooter>
                </Modal>
            </React.Fragment>
        </>
    )
};

export default SubIDDetail;