import React,{useState,useEffect} from "react";
import Select from "react-select";

const SendFormatSelect = ({ name, value = [], onChange, data }) => {
    const [classes,setClasses] = useState(null)
    const [filterValue, setFilterValue] = useState([]);
  
    const mapValuesToFilters = (value = [], classList) => {
        return classList.find((sendformat) => sendformat.value === value);
    };
  
    const changeSelect = (value) => {
        const classValue = value.value;
        onChange(name, classValue ? classValue : null);
        
        const mappedFilters = mapValuesToFilters(value.value, classes);
        setFilterValue(mappedFilters);
    };

    useEffect(()=>{
        let options,i,classList = []

        options = data
        
        for (i=0;i<options.length;i++) {
            classList.push({value:options[i].id,label:options[i].title })
        }

        classList.sort((a, b) => a.label.localeCompare(b.label));

        setClasses(classList)
        const mappedFilters = mapValuesToFilters(value, classList);
        setFilterValue(mappedFilters);
    },[])

    return (
        <Select
            className="react-select-container"
            classNamePrefix="react-select"
            options={classes}
            value={filterValue}
            onChange={(value)=>changeSelect(value)}
            isMulti={false}
            placeholder="Select..."
        />
    )
}

export default SendFormatSelect;