export const titleFormatter = (title) => {
    switch (title) {
        case 'Weekly RCID Cap':
            return 'Weekly Cap'
        case 'Monthly RCID Cap':
            return 'Monthly Cap'
        case 'Daily RCID Cap':
            return 'Daily Cap'
        case 'Overall RCID Cap':
            return 'Overall Cap'
        case 'Weekly Publisher Cap':
            return 'Weekly Cap'
        case 'Monthly Publisher Cap':
            return 'Monthly Cap'
        case 'Daily Publisher Cap':
            return 'Daily Cap'
        case 'Overall Publisher Cap':
            return 'Overall Cap'
        case 'Overall Order Cap':
            return 'Overall Cap'
        case 'Overall Cap Lender':
            return 'Overall Cap'
        case 'Cap Per Day Lender':
            return 'Cap Per Day'
        case 'Cap Per Month Lender':
            return 'Cap Per Month'
        case 'Electricity provider order':
            return 'Electricity provider'
        case 'Property Type (Home Seller)':
            return 'Property Type'
        default:
            return title
    }
}
