import React from "react";
import ReactJsonPrint from "react-json-print";

const Tokens = ({data}) => {
    return (
        <>
            {data.data &&
                <table className="table table-borderless w-auto">
                    {data.data.trustedform &&
                        <tr><td><strong>Trusted from token:</strong></td><td>{data.data.trustedform}</td></tr>
                    }
                    {data.data.leadid &&
                        <tr><td><strong>LeadID:</strong></td><td>{data.data.leadid}</td></tr>
                    }
                    {data.data.anura &&
                        <tr><td><strong>Anura:</strong></td><td>{data.data.anura}</td></tr>
                    }
                </table>
            }
            {data.trustedformdata &&
                <div className="m-3">
                    <h3>Trusted form data:</h3>
                    <ul className="list-unstyled">
                        <li>Create date: <strong>{data.trustedformdata.createdat}</strong></li>
                        <li>IP: <strong>{data.trustedformdata.ip}</strong></li>
                        <li>location: <strong>{data.trustedformdata.location}</strong></li>
                        <li>Browser: <strong>{data.trustedformdata.browser}</strong></li>
                        <li>Operatingsystem: <strong>{data.trustedformdata.operatingsystem}</strong></li>
                        <li>Useragent: <strong>{data.trustedformdata.useragent}</strong></li>
                        <li>City: <strong>{data.trustedformdata.city}</strong></li>
                        <li>State: <strong>{data.trustedformdata.state}</strong></li>
                        <li className="mt-2"><strong>Shareurl</strong> <textarea className="form-control">{data.trustedformdata.shareurl}</textarea></li>
                    </ul>
                </div>
            }
            {data.leadiddata &&
                <div className="m-3">
                    <h3>LeadId data:</h3>
                    <ul className="list-unstyled">
                        <li>Audit date: <strong>{data.leadiddata.dateadd}</strong></li>
                        <li className="mt-2"><strong>Audit url</strong> <textarea className="form-control">{data.leadiddata.auditurl}</textarea></li>
                    </ul>
                    <ReactJsonPrint dataObject={JSON.parse(data.leadiddata.response)} expanded={true}/>
                </div>
            }
        </>
    )
}

export default Tokens
