import React,{useState,useEffect} from "react";
import API from "../../../utils/API";
import {Table} from "reactstrap";
import Datetime from "../../../components/fields/Datetime";
import LeadStatus from "../../../components/status/LeadStatus";
import RCIDTestLog from "../../../components/fields/RCIDTestLog";

const RCIDTestLeads = (props) => {
    const [leads,setLeads] = useState(null)

    useEffect(()=>{
        API.get('/rcid/testleads/'+props.id).then((response) => {
            setLeads(response.data.data)
        });
    },props.id)

    return (
        <>
            { (leads && leads.length > 0) &&
            <div className="table-responsive mt-2">
                <Table>
                    <thead>
                    <tr>
                        <th>Date</th>
                        <th>Type</th>
                        <th>Status</th>
                        <th>Log</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        leads.map((lead,index)=>{
                            return (
                                <tr key={index}>
                                    <td><Datetime dateTime={lead.dateadd}/></td>
                                    <td>{lead.requesttype}</td>
                                    <td><LeadStatus status={lead.status}/></td>
                                    <td><RCIDTestLog id={lead.id}/></td>
                                </tr>
                            )
                        })
                    }
                    </tbody>
                </Table>
            </div>
            }
        </>
    )
}

export default RCIDTestLeads
