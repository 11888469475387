import React,{useState,useEffect} from "react";
import API from "../../../utils/API";
import {Link, useHistory} from "react-router-dom";
import {
    Breadcrumb,
    BreadcrumbItem,
    Button,
    Card,
    CardBody,
    Col,
    Container,
    Modal, ModalBody, ModalFooter,
    ModalHeader,
    Row, Spinner,
    Table
} from "reactstrap";
import {Eye, CornerUpLeft} from "react-feather";
import Loader from "../../../components/Loader";
import User from "../../../components/fields/User";
import Pagination from "../../../components/Pagination";
import parse from 'html-react-parser';

const PageContentHistory = (props) => {

    const [historyList,setHistoryList] = useState(null)
    const [filtration,setFiltration] = useState({page:1,perPage:100,id:props.match.params.id})
    const [loading, setLoading] = useState(true)
    const [data,setData] = useState(null)
    const [page, setPage] = useState({title:null,slug:null,content:null});
    const [modalshow,setModalshow] = useState(false)
    const [diff,setDiff] = useState(null)

    const changeData = (name,value) => {
        setFiltration({ ...filtration, [name]: value })
    }

    let history = useHistory()

    const startReverse = (reverse) => {
        history.push("/settings/landingpagecontent/item/"+props.match.params.id+"/"+reverse)
    }

    const loadHistory = () => {
        setLoading(true)
        API.post('/admin/landingpagecontent/history',filtration).then((response) => {
            setHistoryList(response.data.data.data)
            setData(response.data.data)
            setLoading(false)
        })
    }

    useEffect(()=>{
        API.get('/admin/landingpagecontent/item/'+props.match.params.id).then((response) => {
            setPage(response.data.data)
        })
        loadHistory()
    },[])

    return <Container fluid className="p-0">
        <Breadcrumb>
            <BreadcrumbItem>
                <Link to="/settings/landingpagecontent">Landing page content</Link>
            </BreadcrumbItem>
            <BreadcrumbItem>{page.title}</BreadcrumbItem>
            <BreadcrumbItem active>Change log</BreadcrumbItem>
        </Breadcrumb>
        {loading &&
            <Card className="mt-2">
                <Loader absolute={true}/>
            </Card>
        }
        {(historyList !== null && historyList.length > 0) &&
            <>
                <Card>
                    <div className="table-responsive">
                        <Table>
                            <thead>
                            <tr>
                                <th>Date</th>
                                <th>User</th>
                                <th>Note</th>
                                <th>Actions</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                historyList.map((historyItem, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{historyItem.dateadd}</td>
                                            <td><User user={historyItem.user}/></td>
                                            <td className="text-wrap">{historyItem.note}</td>
                                            <td className="table-action">
                                                {historyItem.contentdiff &&
                                                    <Eye onClick={() => {
                                                        setDiff(historyItem.contentdiff)
                                                        setModalshow(true)
                                                    }} className="align-middle mr-1 cursor-pointer text-primary"
                                                         size={18}/>
                                                }
                                                <CornerUpLeft onClick={() => {
                                                    startReverse(historyItem.id)
                                                }} className="cursor-pointer align-middle text-danger"
                                                       size={18}/>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                            </tbody>
                        </Table>
                    </div>
                </Card>
                {(data?.page > 0 && data?.pages > 0) &&
                    <Pagination
                        onChange={changeData}
                        name={"page"}
                        page={data.page}
                        totalPages={data.pages}/>
                }
            </>
        }
        <React.Fragment>
            <Modal
                isOpen={modalshow}
                toggle={() => setModalshow(!modalshow)}
                size="lg"
                style={{maxWidth: '800px', width: '800px'}}
            >
                <ModalHeader toggle={() => setModalshow(!modalshow)}>
                    Changes
                </ModalHeader>
                <ModalBody>
                    {diff &&
                        parse(diff)
                    }
                </ModalBody>
            </Modal>
        </React.Fragment>
    </Container>
}

export default PageContentHistory
