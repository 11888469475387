import React,{useState,useEffect} from "react";
import API from "../../../utils/API";
import {Link, useHistory} from "react-router-dom";
import {
    Breadcrumb,
    BreadcrumbItem, Button, Card, CardBody,
    Container, FormGroup, Label
} from "reactstrap";
import {AvField, AvForm, AvInput} from "availity-reactstrap-validation";
import Product from "../../../components/formfields/Product";
import Pricemodel from "../../../components/formfields/Pricemodel";
import {toastr} from "react-redux-toastr";

const RcidForm = (props) => {
    const [publisher,setPublisher] = useState(null)
    const [rcid,setRcid] = useState({id:null,publisher:props.match.params.publisherid,product:null,status:'testing',pricemodel:null,pricevalue:null,duplicate:!props.match.params.id?"30":"0"})
    const [rcidloaded,setRcidloaded] = useState(false)
    const [error, setError] = useState(null)

    let history = useHistory()

    useEffect(() => {
        if (props.match.params.id) {
            API.get('/rcid/get/' + props.match.params.id).then((response) => {
                setRcid(response.data.data)
                setRcidloaded(true)
            });
        }else{
            setRcidloaded(true)
        }
    }, [props.match.params.id])

    useEffect(() => {
        API.get('/admin/publisher/'+props.match.params.publisherid).then((response) => {
            setPublisher(response.data.data)
        });
    }, [props.match.params.publisherid])

    const submit = () => {
        API.put(props.match.params.id?('/rcid/'+props.match.params.id):'/rcid',rcid)
            .then((response) => {
                toastr.success('Success', 'RCID was successfully saved')
                history.push("/publisher/rcid/item/"+response.data.data.id)
            })
            .catch(error => {
                setError('Invalid rcid data')
            })
    }

    const handleChange = (e) => {
        setRcid({ ...rcid, [e.target.name]: e.target.value })
        setError(null)
    };

    return (
        <Container fluid className="p-0">
            {publisher &&
            <Breadcrumb>
                <BreadcrumbItem>
                    <Link to="/publisher/list">Publishers</Link>
                </BreadcrumbItem>
                <BreadcrumbItem>
                    <Link to={"/publisher/item/"+publisher.id}>{publisher.name}</Link>
                </BreadcrumbItem>
                <BreadcrumbItem active>{props.match.params.id?"Edit":"New"} RCID</BreadcrumbItem>
            </Breadcrumb>
            }
            {rcidloaded &&
            <Card>
                <CardBody>
                    {error &&
                    <div className="text-center text-danger">{error}</div>
                    }
                    <AvForm onValidSubmit={submit}>
                        {rcid.id &&
                        <FormGroup>
                            <Label>RCID</Label>
                            <input type="text" readOnly={true} value={rcid.id} className="form-control"/>
                        </FormGroup>
                        }
                        <FormGroup>
                            <Label>Title</Label>
                            <input type="text" required={true} name={'title'} value={rcid.title} onChange={handleChange} className="form-control"/>
                        </FormGroup>
                        <FormGroup>
                            <Label>Product</Label>
                            <Product value={rcid.product} required={true} name={'product'} onChange={handleChange}/>
                        </FormGroup>
                        <FormGroup>
                            <Label>Status</Label>
                            <AvField
                                type="select"
                                name="status"
                                value={rcid.status}
                                onChange={handleChange}
                            >
                                <option value={'active'}>Active</option>
                                <option value={'inactive'}>Not active</option>
                                <option value={'testing'}>Testing</option>
                            </AvField>
                        </FormGroup>
                        <FormGroup>
                            <Label>Price model</Label>
                            <Pricemodel value={rcid.pricemodel} required={true} name={'pricemodel'} onChange={handleChange}/>
                        </FormGroup>
                        <FormGroup>
                            <Label>Price model value</Label>
                            <AvInput onChange={handleChange} required type="text" name="pricevalue" value={rcid.pricevalue}
                                     placeholder="Price value" pattern={/^[0-9]\d*(((,\d{3}){1})?(\.\d{0,2})?)$/}/>
                        </FormGroup>
                        <FormGroup>
                            <Label>Duplicate checker within a vertical</Label>
                            <AvField
                                type="select"
                                name="duplicate"
                                value={rcid.duplicate}
                                onChange={handleChange}
                            >
                                <option value={"0"}>Off</option>
                                <option value={"1"}>1 day</option>
                                <option value={"2"}>2 days</option>
                                <option value={"3"}>3 days</option>
                                <option value={"4"}>4 days</option>
                                <option value={"5"}>5 days</option>
                                <option value={"6"}>6 days</option>
                                <option value={"7"}>7 days</option>
                                <option value={"8"}>8 days</option>
                                <option value={"9"}>9 days</option>
                                <option value={"10"}>10 days</option>
                                <option value={"11"}>11 days</option>
                                <option value={"12"}>12 days</option>
                                <option value={"13"}>13 days</option>
                                <option value={"14"}>14 days</option>
                                <option value={"15"}>15 days</option>
                                <option value={"20"}>20 days</option>
                                <option value={"25"}>25 days</option>
                                <option value={"30"}>30 days</option>
                                <option value={"60"}>60 days</option>
                                <option value={"90"}>90 days</option>
                            </AvField>
                        </FormGroup>
                        <div className="text-center">
                            <Button color="primary">Save</Button>
                        </div>
                    </AvForm>
                </CardBody>
            </Card>
            }
        </Container>
    )
}

export default RcidForm
