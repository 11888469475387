import React from "react";
import { Provider } from "react-redux";
import ReduxToastr from "react-redux-toastr";
import store from "./redux/store/index";
import Routes from "./routes/Routes";
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import AppUpdateReload from "./components/AppUpdateReload";

const App = () => (
  <Provider store={store}>
    <AppUpdateReload />
    <Routes />
    <ReduxToastr
      timeOut={5000}
      newestOnTop={true}
      position="top-right"
      transitionIn="fadeIn"
      transitionOut="fadeOut"
      progressBar
      closeOnToastrClick
    />
  </Provider>
);

export default App;
