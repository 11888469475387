import React,{useState,useEffect} from "react";
import {
    CardBody, Spinner,
    Table
} from "reactstrap";
import API from "../../utils/API";
import Loader from "../../components/Loader";
import {UserMinus} from "react-feather";
import { confirmAlert } from 'react-confirm-alert';
import AdminStatus from "../../components/status/AdminStatus";
import {LogIn} from "react-feather";
import {toastr} from "react-redux-toastr";

const Users = ({lenderId,startEditUser}) => {
    const [users, setUsers] = useState(null)

    const loadUsers = () => {
        API.get('/admin/lender/user/all/'+lenderId).then((response) => {
            setUsers(response.data.data)
        });
    }

    useEffect(() => {
        loadUsers()
    }, [])

    const deleteUser = (userId) => {
        confirmAlert({
            title: 'Confirm to unassign user',
            message: 'Are you sure you want to unassign this user from this lender?',
            buttons: [
                {
                    label: 'Yes',
                    className: 'btn btn-danger',
                    onClick: () => {
                        API.put('/admin/lender/user/unassign/'+lenderId,{userId:userId}).then((response) => {
                            toastr.success('Success', 'User was successfully un-assigned');
                            loadUsers()
                        });
                    }
                },
                {
                    label: 'No',
                    className: 'btn btn-primary'
                }
            ]
        });
    }

    const oneClickLogIn = (userId) => {
        API.get('/admin/lender/oneclicklogin/'+userId).then((response) => {
            window.open(response.data.data.url);
        });
    }

    return (
        <>
            {users ?
                <>
                    {users.length > 0 &&
                        <>
                            <div className="mb-2 card-title">Users</div>
                            <div className="table-responsive">
                                <Table>
                                    <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Email</th>
                                        <th>Status</th>
                                        <th>Date add</th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        users.map((user, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{user.name}</td>
                                                    <td>{user.email}</td>
                                                    <td><AdminStatus status={user.status}/></td>
                                                    <td>{user.dateadd}</td>
                                                    <td className="table-action">
                                                        <button className="btn btn-danger ml-3 btn-sm" onClick={() => {
                                                            deleteUser(user.id)
                                                        }}>
                                                            <nobr><UserMinus className="cursor-pointer align-middle" size={12}/> Un-assign</nobr>
                                                        </button>
                                                    </td>
                                                    <td>
                                                        {(user.status === 1) &&
                                                            <button className="btn btn-warning ml-3 btn-sm" onClick={() => {
                                                                oneClickLogIn(user.id)
                                                            }}>
                                                                <nobr><LogIn className="cursor-pointer align-middle" size={12}/> Log In</nobr>
                                                            </button>
                                                        }
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                    </tbody>
                                </Table>
                            </div>
                        </>
                    }
                </>
                :
                <CardBody><Loader/></CardBody>
            }
        </>
    )
}

export default Users;
