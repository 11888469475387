import React, {useState} from "react";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import Lead from "../../pages/leads/Lead";

const LeadID = ({id, reloadList}) => {
    const [modalshow,setModalshow] = useState(false)
    const [needReloadList, setNeedReloadList] = useState(false)

    const closeModal = () => {
        if (needReloadList && reloadList) {
            reloadList(true);
        }
    }

    return <>
        <a onClick={() => {setModalshow(true)}} className="text-primary">{id}</a>
        <React.Fragment>
            <Modal
                isOpen={modalshow}
                toggle={() => {
                    if (modalshow) {
                        closeModal();
                    }
                    setModalshow(!modalshow)
                }}
                size="lg"
                style={{maxWidth: '1600px', width: '90%'}}
                onClose={() => closeModal()}
            >
                <ModalHeader toggle={() => {
                    if (modalshow) {
                        closeModal();
                    }
                    setModalshow(!modalshow)
                }}>
                    Lead # {id}
                </ModalHeader>
                <ModalBody style={{backgroundColor:"#F7F9FC"}}>
                    <Lead id={id} setNeedReloadList={setNeedReloadList}/>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={() => {closeModal();setModalshow(!modalshow);}}>
                        Close
                    </Button>
                </ModalFooter>
            </Modal>
        </React.Fragment>
    </>;
};

export default LeadID;
