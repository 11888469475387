import React from "react";
import {Badge} from 'reactstrap';

const ReturnStatus = ({ status}) => {
    if (status === 'denied') {
        return <Badge color="danger" className="mr-1 mb-1">
            Denied
        </Badge>
    }else if (status === 'accepted') {
        return <Badge color="success" className="mr-1 mb-1">
            Accepted
        </Badge>
    }else if (status === 'requested') {
        return <Badge color="primary" className="mr-1 mb-1">
            Requested
        </Badge>
    }else if (status === 'accepted credited') {
        return <Badge color="warning" className="mr-1 mb-1">
            Accepted, credited
        </Badge>
    }
};

export default ReturnStatus;
