import React from "react";
import EllipsisText from "react-ellipsis-text";

const SubID = ({subid}) => {
    if (subid) {
        return <EllipsisText text={subid.subid} length={8} />;
    }else {
        return '';
    }
};

export default SubID;
