import React,{useState,useEffect} from "react";
import API from "../utils/API";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import Timer from "./Timer";

const AppUpdateReload = () => {

    const [currentVersion, setCurrentVersion] = useState(null);
    const [confirmModal, setConfirmModal] = useState(false);

    const handleReload = (version) => {
        localStorage.setItem('appVersion',version??currentVersion);
        window.location.reload();
    }

    const handleVersion = () => {
        API.get(`/frontend/version/${process.env.REACT_APP_NAME}`).then((response) => {
            setCurrentVersion(response.data.data);
            if (response.data.data !== localStorage.getItem('appVersion')) {
                if (!localStorage.getItem('user')) {
                    handleReload(response.data.data);
                }else {
                    setConfirmModal(true);
                }
            }else {
                setConfirmModal(false);
            }
        })
    }

    useEffect(() => {
        const interval = setInterval(() => {
            handleVersion();
        }, 60000)

        handleVersion();

        return () => {
            clearInterval(interval)
        }
    },[]);

    return <React.Fragment>
        <Modal
            isOpen={confirmModal}
            toggle={() => setConfirmModal(false)}
            size="md"
        >
            <ModalHeader toggle={() => setConfirmModal(false)}>
                New Application Updates Available
            </ModalHeader>
            <ModalBody style={{backgroundColor:"#F7F9FC"}}>
                <p>New updates are available for the Volga System. Please click on reload below to get the latest version of the application.</p>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={() => handleReload()}>
                    Reload (<Timer value={60} onExpire={()=>handleReload()}/>)
                </Button>
                <Button color="secondary" onClick={() => setConfirmModal(false)}>
                    Snooze
                </Button>
            </ModalFooter>
        </Modal>
    </React.Fragment>
}

export default AppUpdateReload;
