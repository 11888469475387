import React,{useState,useEffect} from 'react';
import Loader from "../../../../components/Loader";
import API from "../../../../utils/API";
import {Table,Col} from "reactstrap";
import Lender from "../../../../components/fields/Lender";
import Order from "../../../../components/fields/Order";
import "bootstrap/js/src/collapse.js";
import CSVDownlaod from '../../../../components/CsvDownload';

const BillingReportDetailByOrder = ({filtration}) => {
    const [loading,setLoading] = useState(false);
    const [data,setData] = useState([]);

    useEffect(() => {
        setLoading(true);
        API.post('admin/billingreport/detailsbyorder', filtration).then((response) => {
            setData(response.data.data)
            setLoading(false)
        })
    },[filtration])

    return <>
        {loading && <Loader/>}
        {!loading && data.length > 0 &&
            <div className="row mb-4 mt-1">
                <Col md={12}>
                    <div className="d-flex justify-content-end align-items-center">
                        <span className='font-weight-bold'>Detail by order</span>&nbsp;<span>Report - </span>
                        <CSVDownlaod
                            url="/admin/billingreport/detailsbyorderexport"
                            filename="BillingReportDetailsByOrder"
                            filtration={filtration}
                        />
                    </div>
                </Col>
            </div>
        }
        {!loading && data.length > 0 &&
            <div className="table-responsive">
                <Table striped className="my-0">
                    <thead>
                    <tr>
                        <th>Lender</th>
                        <th>Order</th>
                        <th>Attempted Posts</th>
                        <th>Rejected Posts</th>
                        <th>Credited</th>
                        <th>Net</th>
                        <th>Price per lead</th>
                        <th>Net owed</th>
                        <th>Invoice method</th>
                    </tr>
                    </thead>
                    <tbody>
                    {data.map((item,index) => {
                        return (<>
                            {item.lender && index>1 &&
                                <tr></tr>
                            }
                        <tr key={index}>
                            <td className="font-size-lg">{item.lender && <strong>{item.lender.title}</strong>}</td>
                            <td>{item.order && <Order order={item.order}/>}</td>
                            <td className={item.lender ? "font-weight-bold" : "text-primary"}>{item.attemptedposts}</td>
                            <td className={item.lender ? "font-weight-bold" : "text-secondary"}>{item.rejectedposts}</td>
                            <td className={item.lender ? "font-weight-bold" : "text-danger"}>{item.credited}</td>
                            <td className={item.lender ? "font-weight-bold" : ""}>{item.net !== 0 && <>{item.net}</>}</td>
                            <td className={item.lender ? "font-weight-bold" : ""}>{item.grossprice && <>${item.grossprice}</>}</td>
                            <td className={item.lender ? "font-weight-bold" : ""}>{item.netowned !== 0 && <>${item.netowned}</>}</td>
                            <td>{item?.lender?.invoicemethod}</td>
                        </tr>
                    </>)})}
                    </tbody>
                </Table>
            </div>
        }
        {!loading && data.length == 0 &&
            <h3>Data not found</h3>
        }
    </>
}

export default BillingReportDetailByOrder;
