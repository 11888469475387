import React, { useEffect, useState } from "react";

import API from "../../utils/API";
import Select from "react-select";

const RCIDSelect = ({ name, value = [], onChange, product, publisher }) => {
  const [rcid, setRcid] = useState(null);
  const [filterValue, setFilterValue] = useState([]);

  const mapValuesToFilters = (value = [], rcidList) => {
    return value.map((item) => {
      const matchedFilter = rcidList.find((rcid) => rcid.value == item);
      return matchedFilter || item;
    });
  };

  const changeSelect = (value) => {
    let i,
      values = [];
    for (i = 0; i < value?.length; i++) {
      values.push(value[i].value);
    }
    if (name) {
      onChange(name, values);
      const mappedFilters = mapValuesToFilters(values, rcid);
      setFilterValue(mappedFilters);
    }
  };

  useEffect(() => {
    let data = {};
    if (product) {
      data.product = product;
    }
    if (publisher) {
      data.publisher = publisher;
    }
    API.post("/system/rcid", data).then((response) => {
      let options,
        i,
        rcidList = [];
      if (response.data.data) {
        options = response.data.data;
        for (i = 0; i < options.length; i++) {
          rcidList.push({ value: options[i].id, label: options[i].title });
        }
      }
      const mappedFilters = mapValuesToFilters(value, rcidList);
      setFilterValue(mappedFilters);
      setRcid(rcidList);
    });
  }, [product, publisher]);

  return (
    <Select
      className="react-select-container"
      classNamePrefix="react-select"
      options={rcid}
      value={filterValue}
      onChange={(value) => changeSelect(value)}
      isMulti
      placeholder="RCID"
    />
  );
};

export default RCIDSelect;
