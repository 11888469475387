import React from "react";
import {Edit2} from "react-feather";
import {Button} from "reactstrap";
import {useHistory} from "react-router-dom";
import SendFormatClass from "../../../components/fields/SendFormatClass";
import SendFormatPreview from "../../../components/fields/SendFormatPreview";

const SendFormat = ({order}) => {
    let history = useHistory();

    const edit = () => {
        history.push("/lender/order/sendformat/"+order.id)
    }

    return (
        <div className="p-2">
            {order.sendconfig &&
            <div className="card-actions float-right">
                <Edit2 className="cursor-pointer zoomHover" size={12} onClick={edit}/>
            </div>
            }
            {order.sendconfig
                ?
                <ul className="list-unstyled mb-0">
                    <li className="mb-1"><strong>Data:</strong> <SendFormatClass className={order.sendconfig.content}/><SendFormatPreview namespace={order.sendconfig.content}/></li>
                    <li className="mb-1"><strong>Send:</strong> <SendFormatClass className={order.sendconfig.send}/><SendFormatPreview namespace={order.sendconfig.send}/></li>
                    <li className="mb-1"><strong>Result:</strong> <SendFormatClass className={order.sendconfig.result}/><SendFormatPreview namespace={order.sendconfig.result}/></li>
                </ul>
                :
                <div className="text-center"><Button onClick={edit} color='primary'>Set up</Button></div>
            }
        </div>
    )
}

export default SendFormat
