import React,{useState,useEffect} from "react";
import API from "../../../utils/API";
import {FormGroup, Input, Label} from "reactstrap";

const IncludeExclude = ({title,type,elementId,onClose}) => {

    const [includetype,setIncludetype] = useState(null)
    const [data,setData] = useState([])
    const [options,setOptions] = useState([])

    useEffect(() => {
        API.post('/admin/filter',{elementId:elementId,type:type,filterTitle:title}).then((response) => {

            setData(response.data.data.data.list)
            if (response.data.data.data.type) {
                setIncludetype(response.data.data.data.type)
            }else {
                setIncludetype('include')
            }
            if (response.data.data.options) {
                setOptions(response.data.data.options)
            }
        });
    }, [title,type])

    const onChange = (event) => {
        if (event.target.checked) {
            if (options.length === 2) {
                setData([event.target.value])
            }else {
                setData([...data, event.target.value]);
            }
        }else {
            setData(data.filter((function(state) {
                return state !== event.target.value
            })))
        }
    }

    const saveFilter = () => {
        API.put('/admin/filter',{elementId:elementId,type:type,filterTitle:title,data:{list:data,type:includetype}}).then((response) => {
            onClose()
        });
    }

    function contains(a, obj) {
        let i = a.length;
        while (i--) {
            if (a[i] === obj.toString()) {
                return true;
            }
        }
        return false;
    }

    return (
        <>
            <div className="row">
                <div className="col pt-2">
                    <h5>Filter by: {title}</h5>
                </div>
                <div className="col-auto text-right">
                    <button onClick={onClose} className="btn btn-outline-secondary mb-2">X</button><br/>
                </div>
            </div>
            <div className="mt-3">
                <select onChange={(event)=>setIncludetype(event.target.value)} className="form-control" value={includetype}>
                    <option value="include">Include</option>
                    <option value="exclude">Exclude</option>
                </select>
            </div>
            <div className="mt-3">
                {options.length > 0 ?
                    <div className="row m-1">
                        {
                            options.map((option, index) => {
                                if (option?.title?.length > 0) {
                                    return (
                                        <div className="col-3 p-1" key={"option_" + index}>
                                            <FormGroup check>
                                                <Label check>
                                                    <Input type="checkbox" value={option.value} onChange={onChange}
                                                           checked={contains(data, option.value)}/>
                                                    {option.title}
                                                </Label>
                                            </FormGroup>
                                        </div>
                                    )
                                }else {
                                    return (
                                        <div className="col-3 p-1" key={"option_" + index}>
                                            <FormGroup check>
                                                <Label check>
                                                    <Input type="checkbox" value={option} onChange={onChange}
                                                           checked={contains(data, option)}/>
                                                    {option}
                                                </Label>
                                            </FormGroup>
                                        </div>
                                    )
                                }
                            })
                        }
                    </div>
                    :
                    <textarea className="form-control" onChange={(event)=>setData(event.target.value)} value={Array.isArray(data)?data.join(', '):data}/>
                }
            </div>
            <div className="mt-3 text-center">
                <button className="btn btn-primary" onClick={saveFilter}>Save</button>
            </div>
        </>
    )
}

export default IncludeExclude;
