import React,{useState,useEffect} from "react";
import API from "../../utils/API";
import {AvField} from "availity-reactstrap-validation";

const PermissionRoleSelect = ({name,value,onChange,required}) => {
    const [statuses,setStatuses] = useState(null)

    useEffect(() => {
        API.get('/system/getpermissionroles').then((response) => {
            setStatuses(response.data.data)
        });
    }, [])

    return (
        <AvField
            type="select"
            name={name}
            value={value}
            onChange={onChange}
            required={required}
        >
            <option value="">Select...</option>
            {statuses &&
                statuses.map((status, index) => (
                    <option key={index} selected={value === status.id} value={status.id}>{status.title}</option>
                ))
            }
        </AvField>
    )

}

export default PermissionRoleSelect;
