import React,{useState,useEffect} from "react";
import CreatableSelect from 'react-select/creatable';

const FailReason = ({name,value,onChange,reasons}) => {
    const [defaultValue,setDefaultValue] = useState(null)
    const [options,setOptions] = useState(null)

    useEffect(()=>{
        const optionsTemp = []

        reasons.forEach(element => {
            optionsTemp.push({value:element,label:element})
        })

        if (value) {
            optionsTemp.push({value:value,label:value})
            setDefaultValue({value:value,label:value})
            onChange(name,value)
        }

        setOptions(optionsTemp)
    },[value,reasons])

    const changeSelect = (value) => {
        setDefaultValue(value)
        onChange(name,value?.value)
    }

    return (
        <>
            {options &&
                <CreatableSelect
                    isClearable
                    createOptionPosition="first"
                    formatCreateLabel={(string)=>{return string}}
                    className="react-select-container text-left"
                    classNamePrefix="react-select"
                    options={options}
                    value={defaultValue}
                    onChange={(value)=>changeSelect(value)}
                    placeholder="Select reason"

                />
            }
        </>
    )
}

export default FailReason;
