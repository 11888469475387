import React from "react";
import {Container} from "reactstrap";
import SystemLog from "../components/SystemLog";

const Log = () => {
    return (
        <Container fluid className="p-0">
            <h1 className="h3 mb-3">System log</h1>
            <div>
                <SystemLog urlParse={true} hideindividual={1}/>
            </div>
        </Container>
    )
}

export default Log;
