import React from "react"
import {Table} from "reactstrap";
import Datetime from "../../../components/fields/Datetime";
import LeadStatus from "../../../components/status/LeadStatus";
import Order from "../../../components/fields/Order";
import MoneyTotal from "../../../components/fields/MoneyTotal";
import MoneyPub from "../../../components/fields/MoneyPub";
import Lender from "../../../components/fields/Lender";

const RevenueUploadLog = ({log}) => {
    return <>
        <div className="table-responsive">
            <Table>
                <thead>
                <tr>
                    <th>Date</th>
                    <th>Lender</th>
                    <th>Order</th>
                    <th>Total revenue</th>
                    <th>Publisher revenue</th>
                    <th>Previous total revenue</th>
                </tr>
                </thead>
                <tbody>
                {
                    log.map((item, index) => {
                        return (
                            <tr key={index}>
                                <td><Datetime dateTime={item.revenueupload.dateadd}/></td>
                                <td><Lender lender={item.lender}/></td>
                                <td><Order order={item.lenderorder}/></td>
                                <td><MoneyTotal money={item.lenderposting.earntotal} showZero={true}/></td>
                                <td><MoneyPub money={item.lenderposting.earnpub} showZero={true}/></td>
                                <td><MoneyTotal money={item.revenueuploadlog.originalamount} showZero={true}/></td>
                            </tr>
                        )
                    })
                }
                </tbody>
            </Table>
        </div>
    </>
}

export default RevenueUploadLog