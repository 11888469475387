import React from "react";
import {Badge} from 'reactstrap';

const PixelStatus = ({ status}) => {
    if (status === 1) {
        return <Badge color="success" className="mr-1 mb-1">
            Active
        </Badge>
    }else if (status === 2) {
        return <Badge color="warning" className="mr-1 mb-1">
            Paused
        </Badge>
    }
};

export default PixelStatus;
