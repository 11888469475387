import { AvForm, AvInput } from "availity-reactstrap-validation";
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Col,
  Container,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import React, { useState } from "react";

import API from "../../../utils/API";
import DateRange from "../../../components/formfields/DateRange";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Loader from "../../../components/Loader";
import ProductMulti from "../../../components/formfields/ProductMulti";
import PublisherSelect from "../../../components/formfields/PublisherSelect";
import RCIDSelect from "../../../components/formfields/RCIDSelect";
import RowExpanding from "../../../components/RowExpanding";
import dateFormat from "dateformat";
import { faHistory } from "@fortawesome/free-solid-svg-icons";
import { parseFilterValues } from "../../../utils/helpers/filterHelper";
import { toastr } from "react-redux-toastr";
import { useHistory } from "react-router-dom";
import CostUploadType from "../../../components/formfields/CostUploadType";

const CostUpload = (props) => {
  let history = useHistory();

  const defaultDate =
    dateFormat(new Date().setDate(new Date().getDate() - 6), "mmm d, yyyy") +
    " to " +
    dateFormat(new Date(), "mmm d, yyyy");
  const defaultFiltrationState = {
    page: 1,
    perPage: 100,
    date: defaultDate,
    type: 'equally'
  };

  const urlParams = new URLSearchParams(history.location.search);
  const parsedParams = Object.fromEntries(urlParams.entries());
  const filtersParsed = parseFilterValues(parsedParams);

  const [modalshow, setModalshow] = useState(false);

  const [costData, setCostData] = useState({
    ...defaultFiltrationState,
    ...filtersParsed,
  });
  const [approvedData, setApprovedData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [data, setData] = useState(null);
  const [header, setHeader] = useState(null);

  const [canUpload, setCanUpload] = useState(false);

  const changeData = (name, value) => {
    setCostData({ ...costData, [name]: value });

    history.push({
      pathname: history.location.pathname,
      search: new URLSearchParams({ ...costData, [name]: value }).toString(),
    });
  };

  const handleChange = (e) => {
    setCostData({ ...costData, [e.target.name]: e.target.value });

    history.push({
      pathname: history.location.pathname,
      search: new URLSearchParams({
        ...costData,
        [e.target.name]: e.target.value,
      }).toString(),
    });
  };

  const submit = () => {
    if (
      (!costData.rcid || !costData?.rcid?.length) &&
      (!costData.publisher || !costData?.publisher?.length)
    ) {
      toastr.error("Error", "Select RCID or Publisher");
      return;
    }
    if (!costData?.cost && costData?.cost !== "0") {
      toastr.error("Error", "Enter new cost");
      return;
    }
    setLoading(true);
    setCanUpload(false);

    API.post("/tools/costupload/preview", costData)
      .then((response) => {
        setData(response.data.data.data);
        setHeader(response.data.data.header);
        setLoading(false);

        if (response.data.data.result?.length > 0) {
          setCanUpload(true);
        }

        setApprovedData(costData);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const save = () => {
    setSaving(true);
    API.post("/tools/costupload/save", {
      ...approvedData,
      ["memo"]: costData.memo,
    })
      .then((response) => {
        setCostData({ date: defaultDate });
        setApprovedData(null);
        setData(null);
        setHeader(null);
        setSaving(false);
        history.push(
          "/tools/costupload/logitem/" + response.data.data.id + "?new=true"
        );
      })
      .catch((error) => {
        setSaving(false);
      });
  };

  return (
    <Container fluid className="p-0">
      <Button
        color="secondary"
        onClick={() => history.push("/tools/costupload/log")}
        className="float-right mt-2"
      >
        <FontAwesomeIcon icon={faHistory} /> Log
      </Button>
      <h1 className="h3 mb-3">Cost upload</h1>
      <AvForm onValidSubmit={submit}>
        <Row form>
          <Col md={2}>
            <DateRange
              onChange={changeData}
              name={"date"}
              value={costData?.date}
            />
          </Col>
          <Col md={2}>
            <ProductMulti
              name={"product"}
              onChange={changeData}
              value={costData?.product}
            />
          </Col>
          <Col md={2}>
            <PublisherSelect
              name={"publisher"}
              onChange={changeData}
              value={costData?.publisher}
            />
          </Col>
          <Col md={2}>
            <RCIDSelect
              name={"rcid"}
              onChange={changeData}
              product={costData?.product}
              publisher={costData?.publisher}
              value={costData?.rcid}
            />
          </Col>
          <Col md={2}>
            <AvInput
              onChange={handleChange}
              type="text"
              name="cost"
              value={costData.cost}
              placeholder="New cost"
              pattern={/^[0-9]\d*(((,\d{3}){1})?(\.\d{0,2})?)$/}
            />
          </Col>
          <Col md={2}>
            <CostUploadType
                name={"type"}
                onChange={handleChange}
                required={false}
                value={costData?.type}
                placeholder={"Upload type..."}
            />
          </Col>
        </Row>
        <div className="row mt-3 justify-content-center">
          <div className="col-auto">
            <Button color="primary">Preview</Button>
          </div>
        </div>
      </AvForm>
      {loading && <Loader />}
      {!loading && data?.length > 0 && header?.length > 0 && (
        <div className="mt-3">
          <RowExpanding data={data} header={header} />
          <div className="row justify-content-center">
            <div className="col-auto">
              <Button
                color="success"
                disabled={!canUpload}
                onClick={() => setModalshow(true)}
              >
                Save cost
              </Button>
            </div>
          </div>
        </div>
      )}
      <React.Fragment>
        <Modal
          isOpen={modalshow}
          toggle={() => setModalshow(!modalshow)}
          size="lg"
          style={{ maxWidth: "600px", width: "600px" }}
        >
          <ModalHeader toggle={() => setModalshow(!modalshow)}>
            Cost upload
          </ModalHeader>
          <ModalBody style={{ backgroundColor: "#F7F9FC" }}>
            <label>Memo (not required)</label>
            <textarea
              rows={5}
              className="form-control"
              name="memo"
              onChange={handleChange}
              value={costData.memo}
            />
          </ModalBody>
          <ModalFooter className="justify-content-center">
            {saving ? (
              <Spinner color="primary" />
            ) : (
              <Button color="secondary" onClick={() => save()}>
                Upload
              </Button>
            )}
          </ModalFooter>
        </Modal>
      </React.Fragment>
    </Container>
  );
};

export default CostUpload;
