import React, {useState} from "react";
import {Label} from "reactstrap";
import API from "../../../utils/API";

const SettingsForm = ({id,token,close}) => {
    const [formToken, setFormToken] = useState(token)

    const save = () => {
        if (formToken) {
            API.post('/rcid/jangl/' + id, {token:formToken}).then((response) => {
                close()
            });
        }
    }

    return (
        <>
            <div className="row mt-3">
                <div className="col">
                    <Label>Jangl token</Label>
                    <input className="form-control" onChange={(e)=>setFormToken(e.target.value)} type="text" name="leadidentityid"
                           value={formToken} placeholder="Enter Jangl token"/>
                </div>
            </div>
            <div className="row mt-2">
                <div className="col text-center">
                    <button className="btn btn-primary" onClick={save}>Save</button>
                </div>
            </div>
        </>
    )
}

export default SettingsForm
