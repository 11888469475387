import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Spinner,
  Table,
} from "reactstrap";
import React, { useEffect, useState } from "react";

import API from "../../utils/API";
import DateRange from "../../components/formfields/DateRange";
import Datetime from "../../components/fields/Datetime";
import LeadID from "../../components/fields/LeadID";
import LeadStatus from "../../components/status/LeadStatus";
import ListStat from "../../components/ListStat";
import MoneyPub from "../../components/fields/MoneyPub";
import MoneySystem from "../../components/fields/MoneySystem";
import MoneyTotal from "../../components/fields/MoneyTotal";
import Pagination from "../../components/Pagination";
import Product from "../../components/fields/Product";
import ProductMulti from "../../components/formfields/ProductMulti";
import Publisher from "../../components/fields/Publisher";
import RCID from "../../components/fields/RCID";
import Source from "../../components/fields/Source";
import dateFormat from "dateformat";
import { parseFilterValues } from "../../utils/helpers/filterHelper";
import { useHistory } from "react-router-dom";
import SortDirection from "../../components/SortDirection";

const Search = () => {
  const history = useHistory();
  const defaultDate =
    dateFormat(new Date().setDate(new Date().getDate() - 180), "mmm d, yyyy") +
    " to " +
    dateFormat(new Date(), "mmm d, yyyy");
  const defaultFiltrationState = {
    page: 1,
    perPage: 100,
    date: defaultDate,
  };

  const urlParams = new URLSearchParams(history.location.search);
  const parsedParams = Object.fromEntries(urlParams.entries());
  const filtersParsed = parseFilterValues(parsedParams);

  const [filtration, setFiltration] = useState({
    ...defaultFiltrationState,
    ...filtersParsed,
  });
  const [leads, setLeads] = useState(null);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [initialLoad, setInitialLoad] = useState(true);

  const changeData = (name, value) => {
    setFiltration({ ...filtration, [name]: value });

    history.push({
      pathname: history.location.pathname,
      search: new URLSearchParams({ ...filtration, [name]: value }).toString(),
    });
  };

  const runSearch = () => {
    setLoading(true);
    API.post("/admin/search/lead", filtration).then((response) => {
      setLeads(response.data.data.data);
      setData(response.data.data);
      setLoading(false);
      setInitialLoad(false);
    });
  };

  useEffect(() => {
    if (!initialLoad) {
      runSearch();
    }
  }, [filtration.page,filtration.sort, initialLoad]);

  return (
    <Container fluid className="p-0">
      <h1 className="h3 mb-3">Lead search</h1>
      <Row form>
        <Col md={3}>
          <DateRange
            onChange={changeData}
            name={"date"}
            value={filtration?.date}
          />
        </Col>
        <Col md={3}>
          <ProductMulti
            name={"product"}
            onChange={changeData}
            value={filtration?.product}
          />
        </Col>
        <Col md={3}>
          <input
            placeholder="Email"
            className="form-control"
            name="email"
            value={filtration.email}
            onChange={(e) => changeData(e.target.name, e.target.value)}
          />
        </Col>
        <Col md={3}>
          <input
            placeholder="Phone"
            className="form-control"
            name="phone"
            value={filtration.phone}
            onChange={(e) => changeData(e.target.name, e.target.value)}
          />
        </Col>
      </Row>
      <Row form className="mt-1">
        <Col md={6}>
          <input
            placeholder="Trusted form token"
            className="form-control"
            id="trustedform"
            name="trustedform"
            value={filtration.trustedform}
            onChange={(e) => changeData(e.target.name, e.target.value)}
          />
        </Col>
        <Col md={6}>
          <input
            placeholder="LeadID token"
            className="form-control"
            name="leadid"
            value={filtration.leadid}
            onChange={(e) => changeData(e.target.name, e.target.value)}
          />
        </Col>
      </Row>
      <Row form className="mt-1">
        <Col md={12}>
          <textarea
            className="form-control"
            rows={2}
            value={filtration.id}
            placeholder="Lead id's"
            onChange={(e) => changeData("id", e.target.value)}
          ></textarea>
        </Col>
      </Row>
      <Row form className="mt-1">
        <Col md={4}>
          <input
            placeholder="First name"
            className="form-control"
            name="firstname"
            value={filtration.firstname}
            onChange={(e) => changeData(e.target.name, e.target.value)}
          />
        </Col>
        <Col md={4}>
          <input
            placeholder="Last name"
            className="form-control"
            name="lastname"
            value={filtration.lastname}
            onChange={(e) => changeData(e.target.name, e.target.value)}
          />
        </Col>
        <Col md={4}>
          <input
            placeholder="Address"
            className="form-control"
            name="address"
            value={filtration.address}
            onChange={(e) => changeData(e.target.name, e.target.value)}
          />
        </Col>
      </Row>
      <div className="row mt-3 mb-3 justify-content-center">
        <div className="col-auto">
          {loading ? (
            <Spinner color={"primary"} />
          ) : (
            <Button color="primary" onClick={runSearch}>
              Search
            </Button>
          )}
        </div>
      </div>
      {!initialLoad && (
        <Card className="mt-2">
          {!loading && leads?.length > 0 ? (
            <div className="table-responsive">
              <ListStat data={data} />
              <Table>
                <thead>
                  <tr>
                    <th>Id<SortDirection fieldName={'id'} filters={filtration} changeFilter={changeData}/></th>
                    <th>Date<SortDirection fieldName={'dateadd'} filters={filtration} changeFilter={changeData}/></th>
                    <th>Status<SortDirection fieldName={'status'} filters={filtration} changeFilter={changeData}/></th>
                    <th>Reason</th>
                    <th>Product<SortDirection fieldName={'product'} filters={filtration} changeFilter={changeData}/></th>
                    <th>Publisher<SortDirection fieldName={'publisher'} filters={filtration} changeFilter={changeData}/></th>
                    <th>RCID<SortDirection fieldName={'rcid'} filters={filtration} changeFilter={changeData}/></th>
                    <th>Source<SortDirection fieldName={'source'} filters={filtration} changeFilter={changeData}/></th>
                    <th>Total<SortDirection fieldName={'earntotal'} filters={filtration} changeFilter={changeData}/></th>
                    <th>Pub<SortDirection fieldName={'earnpub'} filters={filtration} changeFilter={changeData}/></th>
                    <th>System<SortDirection fieldName={'earnsystem'} filters={filtration} changeFilter={changeData}/></th>
                    <th>Name<SortDirection fieldName={'name'} filters={filtration} changeFilter={changeData}/></th>
                    <th>Email<SortDirection fieldName={'email'} filters={filtration} changeFilter={changeData}/></th>
                    <th>Phone<SortDirection fieldName={'phone'} filters={filtration} changeFilter={changeData}/></th>
                  </tr>
                </thead>
                <tbody>
                  {leads.map((lead, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          <LeadID id={lead.lead.id} />
                        </td>
                        <td>
                          <Datetime dateTime={lead.lead.dateadd} />
                        </td>
                        <td>
                          <LeadStatus status={lead.lead.status} />
                        </td>
                        <td>
                          {lead.lead.exceptions.map((exception, excKey) => {
                            return (
                              <div key={excKey}>
                                <nobr>{exception}</nobr>
                              </div>
                            );
                          })}
                        </td>
                        <td>
                          <Product product={lead.lead.product} />
                        </td>
                        <td>
                          <Publisher publisher={lead.lead.publisher} />
                        </td>
                        <td>
                          <RCID id={lead.lead.rcid} />
                        </td>
                        <td>
                          <Source source={lead.lead.leadsource} />
                        </td>
                        <td>
                          <MoneyTotal
                            money={lead.lead.earntotal}
                            moneyOriginal={lead.lead.earntotaloriginal}
                          />
                        </td>
                        <td>
                          <MoneyPub
                            money={lead.lead.earnpub}
                            moneyOriginal={lead.lead.earnpuboriginal}
                          />
                        </td>
                        <td>
                          <MoneySystem
                            money={lead.lead.earntotal - lead.lead.earnpub}
                          />
                        </td>
                        <td>
                          <nobr>
                            {lead.leadsearchdata.firstname}{" "}
                            {lead.leadsearchdata.lastname}
                          </nobr>
                        </td>
                        <td>{lead.leadsearchdata.email}</td>
                        <td>{lead.leadsearchdata.phone}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          ) : (
            !loading && (
              <CardBody className="text-center">
                <h5>Data not found</h5>
              </CardBody>
            )
          )}
        </Card>
      )}
      {!loading && data?.page > 0 && data?.pages > 0 && (
        <Pagination
          onChange={changeData}
          name={"page"}
          page={data.page}
          totalPages={data.pages}
        />
      )}
    </Container>
  );
};

export default Search;
