import React from "react";

const LeadIOLog = ({iolog}) => {
    return (
        <>
            <div>
                <strong>Request</strong>
                <textarea className="form-control" readOnly={true}>{iolog.request}</textarea>
            </div>

            <div className="mt-3">
                <strong>Response</strong>
                <textarea className="form-control" readOnly={true}>{iolog.response}</textarea>
            </div>
        </>
    )
}

export default LeadIOLog
