import React,{useEffect, useState} from "react";
import API from "../../../utils/API";
import {Col, Row, Spinner, Table} from "reactstrap";
import DateRange from "../../../components/formfields/DateRange";
import dateFormat from "dateformat";
import ListStat from "../../../components/ListStat";
import LeadID from "../../../components/fields/LeadID";
import Datetime from "../../../components/fields/Datetime";
import Pagination from "../../../components/Pagination";

const PixelLog = ({pixel}) => {

    const defaultDate = dateFormat((new Date()).setDate((new Date()).getDate() - 6),"mmm d, yyyy")+" to "+dateFormat(new Date(),"mmm d, yyyy")

    const [filtration,setFiltration] = useState({page:1,perPage:100,date:defaultDate,pixel:pixel.id})

    const [log, setLog] = useState(null)
    const [loading, setLoading] = useState(true)
    const [data,setData] = useState(null)

    const changeData = (name,value) => {
        setFiltration({ ...filtration, [name]: value })
    }

    useEffect(() => {
        setLoading(true)
        API.post('/admin/pixel/statistic',filtration).then((response) => {
            setLog(response.data.data.data)
            setData(response.data.data)
            setLoading(false)
        });
    }, [filtration])

    return (
        <>
            <Row form>
                <Col md={4}>
                    <DateRange
                        onChange={changeData}
                        name={'date'}
                        value={defaultDate}
                    />
                </Col>
            </Row>
            {loading &&
                <div className="row justify-content-center">
                    <div className="col-auto p-3">
                        <Spinner color="primary"/>
                    </div>
                </div>
            }
            {log?.length > 0 ?
                <>
                    <div className="table-responsive">
                        <ListStat data={data}/>
                        <Table>
                            <thead>
                            <tr>
                                <th>Date</th>
                                <th>Lead</th>
                                <th>Pixel</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                log.map((logItem, index) => {
                                    return (
                                        <tr key={index}>
                                            <td><Datetime dateTime={logItem.date}/></td>
                                            <td><LeadID id={logItem.lead}/></td>
                                            <td><nobr>{logItem.pixelcode}</nobr></td>
                                        </tr>
                                    )
                                })
                            }
                            </tbody>
                        </Table>
                    </div>
                    {(data?.page > 0 && data?.pages > 0) &&
                        <Pagination
                            onChange={changeData}
                            name={"page"}
                            page={data.page}
                            totalPages={data.pages}/>
                    }
                </>
                :
                !loading &&
                <strong>No activity</strong>
            }
        </>
    )
}

export default PixelLog
