import React, {useState} from "react";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import Ping from "../../pages/leads/Ping";
import Lead from "../../pages/leads/Lead";

const PingID = ({ id }) => {
  const [modalshow,setModalshow] = useState(false)

  return <>
    <a onClick={() => {setModalshow(true)}} className="text-primary">{id}</a>
    <React.Fragment>
      <Modal
          isOpen={modalshow}
          toggle={() => {
            setModalshow(!modalshow)
          }}
          size="lg"
          style={{maxWidth: '1600px', width: '90%'}}
      >
        <ModalHeader toggle={() => {
          setModalshow(!modalshow)
        }}>
          Ping # {id}
        </ModalHeader>
        <ModalBody style={{backgroundColor:"#F7F9FC"}}>
          <Ping id={id}/>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => {setModalshow(!modalshow);}}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  </>;
};

export default PingID;
