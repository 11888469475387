import React from "react";

function LeadLinkInformation({ leadBody, linkPage }) {
  const encodedAddress = encodeURIComponent(
    `${leadBody.address},${leadBody.city},${leadBody.state},${leadBody.zip}`
  );

  let mapsUrl;
  if (linkPage === "address") {
    mapsUrl = `https://www.google.com/maps/search/?api=1&query=${encodedAddress}`;
  } else {
    mapsUrl = `https://www.zillow.com/homes/${encodedAddress}`;
  }

  const textLabel = linkPage === "address" ? "(View Google Maps)" : "Zillow";

  return (
    <a href={mapsUrl} target="_blank" rel="noopener noreferrer">
      {textLabel}
    </a>
  );
}

export default LeadLinkInformation;
