import React, { useState, useEffect } from "react";
import {faCaretUp, faCaretDown} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SortDirection = ({fieldName,filters, changeFilter}) => {
    const [isSortedByThisField, setIsSortedByThisField] = useState(false)
    const [sortDirection, setSortDirection] = useState("asc")

    useEffect(() => {
        if (filters.sort?.length > 0) {
            const sortData = filters.sort.split(" | ");

            if (sortData[0] === fieldName) {
                setIsSortedByThisField(true);
                setSortDirection(sortData[1]);
            }
        }else {
            setIsSortedByThisField(false);
            setSortDirection(null)
        }
    },[fieldName,filters])

    const toggleSortDirection = (direction) => {
        changeFilter("sort", fieldName + " | " + direction);
    }

    return <span className={`${!isSortedByThisField?'sortControl':''} cursor-pointer`}>
        {isSortedByThisField ?
            <>
                {sortDirection === 'desc' ?
                    <FontAwesomeIcon className="align-middle font-size-lg" icon={faCaretDown} fixedWidth onClick={()=>toggleSortDirection('asc')}/>
                    :
                    <FontAwesomeIcon className="align-middle font-size-lg" icon={faCaretUp} fixedWidth onClick={()=>toggleSortDirection('desc')}/>
                }
            </>
            :
            <FontAwesomeIcon className="align-middle font-size-lg text-muted" icon={faCaretUp} fixedWidth onClick={()=>toggleSortDirection('asc')}/>
        }
    </span>
}

export default SortDirection;
