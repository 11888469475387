import React from "react";
import {Badge} from 'reactstrap';

const LenderStatus = ({ status}) => {
    if (status === 'active') {
        return <Badge color="success" className="mr-1 mb-1">
            Active
        </Badge>
    }else if (status === 'inactive') {
        return <Badge color="warning" className="mr-1 mb-1">
            Not active
        </Badge>
    }
};

export default LenderStatus;
