import React, {useState, useEffect, memo} from 'react';
import API from "../../utils/API";
import {Spinner} from "react-bootstrap";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import User from "../fields/User";
import {Edit2, Trash} from "react-feather";

const Memo=({type,typeId})=>{
    const [memos,setMemos]=useState([]);
    const [newMemoModal,setNewMemoModal]=useState(false);
    const [editMemoModal,setEditMemoModal]=useState(false);
    const [memo, setMemo] = useState(null);

    const [loading,setLoading]=useState(false);
    const [saving,setSaving]=useState(false);

    const [editMemo,setEditMemo]=useState(null);
    const [editMemoId,setEditMemoId]=useState(null);

    const [deleteMemoId,setDeleteMemoId]=useState(null);
    const [deleteMemoModal,setDeleteMemoModal]=useState(false);

    const fetchMemos = ()=> {
        setLoading(true);
        API.get(`/admin/memo/all/${type}/${typeId}`).then((response) => {
            setMemos(response.data.data);
            setLoading(false);
        });
    }

    useEffect(() => {
        fetchMemos();
    }, [type,typeId]);

    const addMemo = () => {
        if (memo && memo.length > 0) {
            setSaving(true);
            API.post(`/admin/memo/add`,{memo,type,typeId}).then((response) => {
                setSaving(false);
                setMemo(null);
                setNewMemoModal(false);
                fetchMemos();
            });
        }
    }

    const saveEditMemo = () => {
        if (editMemo && editMemo.length > 0) {
            setSaving(true);
            API.put(`/admin/memo/${editMemoId}`,{memo:editMemo}).then((response) => {
                setSaving(false);
                setEditMemo(null);
                setEditMemoId(null);
                setEditMemoModal(false);
                fetchMemos();
            });
        }
    }

    const startEditMemo = (id,memo) => {
        setEditMemoId(id);
        setEditMemo(memo);
        setEditMemoModal(true);
    }

    const startDeleteMemo = (id) => {
        setDeleteMemoModal(true);
        setDeleteMemoId(id);
    }

    const deleteMemo = () => {
        setSaving(true);
        API.delete(`/admin/memo/${deleteMemoId}`).then((response) => {
            setSaving(false);
            setDeleteMemoId(null);
            setDeleteMemoModal(false);
            fetchMemos();
        });
    }

    return <div className="m-1 text-center">
        {loading ?
            <Spinner size="sm" variant="primary" />
            :
            <>
                <button className="btn btn-outline-primary btn-sm" onClick={()=>setNewMemoModal(true)}>Add new memo</button>
                {memos?.length > 0 &&
                <div className="text-center mt-2">
                    {memos.map((memo,index)=> {
                        return <div key={index} className="memoItem mb-3 p-1 shadow border border-light text-left">
                            <div className="float-right memoManagement">
                                <Edit2 onClick={() => {startEditMemo(memo.memo.id,memo.memo.memo)}} className="align-middle mr-1 cursor-pointer zoomHover" size={14} />
                                <Trash onClick={() => {startDeleteMemo(memo.memo.id)}} className="text-danger align-middle mr-1 cursor-pointer zoomHover" size={14} />
                            </div>
                            <div className="mb-2"><small>{memo.memo.dateadd} by <span className="text-primary"><User user={memo.user}/></span></small></div>
                            <div style={{whiteSpace: "pre-line"}}>{memo.memo.memo}</div>
                        </div>
                    })}
                </div>
                }
            </>
        }
        <React.Fragment>
            <Modal
                isOpen={newMemoModal}
                toggle={() => setNewMemoModal(!newMemoModal)}
                size="lg"
                style={{ maxWidth: "600px", width: "600px" }}
            >
                <ModalHeader toggle={() => setNewMemoModal(!newMemoModal)}>
                    Add memo
                </ModalHeader>
                <ModalBody style={{ backgroundColor: "#F7F9FC" }}>
                    <textarea
                        rows={5}
                        className="form-control"
                        onChange={(e)=>setMemo(e.target.value)}
                        value={memo}
                    />
                </ModalBody>
                <ModalFooter className="justify-content-center">
                    {saving ? (
                        <Spinner color="primary" />
                    ) : (
                        <Button color="secondary" onClick={() => addMemo()}>
                            Add
                        </Button>
                    )}
                </ModalFooter>
            </Modal>
        </React.Fragment>

        <React.Fragment>
            <Modal
                isOpen={editMemoModal}
                toggle={() => setEditMemoModal(!editMemoModal)}
                size="lg"
                style={{ maxWidth: "600px", width: "600px" }}
            >
                <ModalHeader toggle={() => setEditMemoModal(!editMemoModal)}>
                    Edit memo
                </ModalHeader>
                <ModalBody style={{ backgroundColor: "#F7F9FC" }}>
                    <textarea
                        rows={5}
                        className="form-control"
                        onChange={(e)=>setEditMemo(e.target.value)}
                        value={editMemo}
                    />
                </ModalBody>
                <ModalFooter className="justify-content-center">
                    {saving ? (
                        <Spinner color="primary" />
                    ) : (
                        <Button color="secondary" onClick={() => saveEditMemo()}>
                            Save
                        </Button>
                    )}
                </ModalFooter>
            </Modal>
        </React.Fragment>

        <React.Fragment>
            <Modal
                isOpen={deleteMemoModal}
                toggle={() => setDeleteMemoModal(!deleteMemoModal)}
                size="lg"
                style={{ maxWidth: "600px", width: "600px" }}
            >
                <ModalHeader className="bg-danger" toggle={() => setDeleteMemoModal(!deleteMemoModal)}>
                    <span className="text-white">Delete memo</span>
                </ModalHeader>
                <ModalBody style={{ backgroundColor: "#F7F9FC" }}>
                    Are you sure you want to delete memo?
                </ModalBody>
                <ModalFooter className="justify-content-center">
                    {saving ? (
                        <Spinner color="primary" />
                    ) : (
                        <Button color="danger" onClick={() => deleteMemo()}>
                            Delete
                        </Button>
                    )}
                </ModalFooter>
            </Modal>
        </React.Fragment>
    </div>
}

export default Memo;
