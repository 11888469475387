import React from "react";
import {Badge} from "reactstrap";

const BillingType = ({billingType}) => {
    if (billingType === 'dateSent') {
        return <Badge color="primary" className="mr-1 mb-1">
            Date sent
        </Badge>
    }else if (billingType === 'dateReturned') {
        return <Badge color="success" className="mr-1 mb-1">
            Date Returned
        </Badge>
    }else {
        return <></>
    }
}

export default BillingType;
