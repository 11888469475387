import axios from "axios";

const API = axios.create({
    baseURL: process.env.REACT_APP_API,
    timeout: 600000,
});

API.interceptors.request.use(
    (config) => {
        let jwt = localStorage.getItem('jwt');
        if (jwt) {
            let jwtMFA = localStorage.getItem('jwtMFA');
            if (jwtMFA) {
                config.headers.Authorization = `Bearer ${jwt} ${jwtMFA}`;
            }else {
                config.headers.Authorization = `Bearer ${jwt}`;
            }
        }

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

API.interceptors.response.use(response => {
    return response;
}, error => {
    if (error.response.status === 401) {
        let refreshPage = false;

        console.log(error?.response?.data);

        if (error?.response?.data?.NeedMFATokenRefresh === 1) {
            localStorage.removeItem('jwtMFA');
            refreshPage = true;
        }
        if (error?.response?.data?.NeedTokenRefresh === 1) {
            localStorage.removeItem('jwt');
            refreshPage = true;
        }

        if (refreshPage) {
            localStorage.removeItem('user');
            window.location.reload();
        }else {
            return Promise.reject(error);
        }
    }else {
        return Promise.reject(error);
    }
});

export default API;
