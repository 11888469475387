import { Card, CardBody, Col, Container, Row } from "reactstrap";
import React, { useEffect, useState } from "react";

import API from "../../utils/API";
import AdminSelect from "../../components/formfields/AdminSelect";
import CSVDownlaod from "../../components/CsvDownload";
import DateRange from "../../components/formfields/DateRange";
import LeadSourceSelect from "../../components/formfields/LeadSourceSelect";
import Loader from "../../components/Loader";
import MoneyPub from "../../components/fields/MoneyPub";
import MoneyTotal from "../../components/fields/MoneyTotal";
import ProductMulti from "../../components/formfields/ProductMulti";
import Publisher from "../../components/fields/Publisher";
import PublisherSelect from "../../components/formfields/PublisherSelect";
import SortableTable from "../../components/SortableTable";
import dateFormat from "dateformat";
import { parseFilterValues } from "../../utils/helpers/filterHelper";
import { useHistory } from "react-router-dom";

const SummaryReport = () => {
  const history = useHistory();
  const defaultDate =
    dateFormat(new Date().setDate(new Date().getDate() - 6), "mmm d, yyyy") +
    " to " +
    dateFormat(new Date(), "mmm d, yyyy");
  const defaultFiltrationState = {
    date: defaultDate,
  };
  const urlParams = new URLSearchParams(history.location.search);
  const parsedParams = Object.fromEntries(urlParams.entries());
  const filtersParsed = parseFilterValues(parsedParams);

  const [filtration, setFiltration] = useState({
    ...defaultFiltrationState,
    ...filtersParsed,
  });
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  let totalLeads = 0,
    totalMatched = 0,
    totalUnmatched = 0,
    totalFlagged = 0,
    totalDuplicate = 0,
    totalProfit = 0,
    totalTotal = 0,
    totalPub = 0,
    totalFailed = 0;

  const changeData = (name, value) => {
    setFiltration({ ...filtration, [name]: value });

    history.push({
      pathname: history.location.pathname,
      search: new URLSearchParams({ ...filtration, [name]: value }).toString(),
    });
  };

  const changeNativeData = (e) => {
    setFiltration({ ...filtration, [e.target.name]: e.target.value });

    history.push({
      pathname: history.location.pathname,
      search: new URLSearchParams({
        ...filtration,
        [e.target.name]: e.target.value,
      }).toString(),
    });
  };

  useEffect(() => {
    setLoading(true);
    if (filtration.date) {
      API.post("/admin/publisher/report/summary", filtration).then(
        (response) => {
          setData(response.data.data);
          setLoading(false);
        }
      );
    }
  }, [filtration]);

  const columns = [
    {
      name: "Publisher",
      selector: (row) => row.publisher.name.toLowerCase(),
      sortable: true,
      style: { overflowWrap: "break-word" },
      cell: (row) => (
        <div style={{ overflow: "auto" }}>
          <Publisher publisher={row.publisher} />
        </div>
      ),
    },
    {
      name: "Total leads",
      selector: (row) => parseInt(row.leads),
      cell: (row) => <div className="font-weight-bold">{row.leads}</div>,
      sortable: true,
    },
    {
      name: "Matched",
      selector: (row) => parseInt(row.matched),
      cell: (row) => (
        <div
          className={`font-weight-bold ${row.matched ? "text-success" : ""}`}
        >
          {row.matched}
        </div>
      ),
      sortable: true,
    },
    {
      name: "Unmatched",
      selector: (row) => parseInt(row.unmatched),
      sortable: true,
    },
    {
      name: "Flagged",
      selector: (row) => parseInt(row.flagged),
      cell: (row) => (
        <div className={`${row.flagged ? "text-primary" : ""}`}>
          {row.flagged}
        </div>
      ),
      sortable: true,
    },
    {
      name: "Failed",
      selector: (row) => parseInt(row.failed),
      sortable: true,
    },
    {
      name: "Success Rate",
      selector: (row) => parseFloat(row.successRate),
      cell: (row) => <div>{row.successRate}%</div>,
      sortable: true,
    },
    {
      name: "Total",
      selector: (row) => parseFloat(row.total),
      cell: (row) => (
        <div>
          {row.total ? (
            <MoneyTotal money={row.total} moneyOriginal={row.totaloriginal} />
          ) : (
            "$0"
          )}
        </div>
      ),
      sortable: true,
    },
    {
      name: "Publisher",
      selector: (row) => parseFloat(row.pub),
      cell: (row) => (
        <td>
          {row.pub ? (
            <MoneyPub money={row.pub} moneyOriginal={row.puboriginal} />
          ) : (
            "$0"
          )}
        </td>
      ),
      sortable: true,
    },
    {
      name: "Profit",
      selector: (row) => parseFloat(row.total) - parseFloat(row.pub),
      cell: (row) => (
        <div>
          {row.total || row.pub ? (
            <MoneyTotal money={row.total - row.pub} />
          ) : (
            "$0"
          )}
        </div>
      ),
      sortable: true,
    },
    {
      name: "Margin",
      selector: (row) => parseFloat(row.margin),
      cell: (row) => <td>{row.margin}%</td>,
      sortable: true,
    },
  ];

  return (
    <Container fluid className="p-0">
      <div className="row justify-content-between">
        <div className="col-auto">
          <h1 className="h3 mb-3">Publisher summary report</h1>
        </div>
        <div className="col-auto">
          <CSVDownlaod
            url="/admin/publisher/report/summaryreportcsv"
            filename="PublisherSummaryReport"
            filtration={filtration}
          />
        </div>
      </div>
      <Row form className={"mb-2"}>
        <Col md={3}>
          <DateRange
            onChange={changeData}
            name={"date"}
            value={filtration?.date}
          />
        </Col>
        <Col md={3}>
          <PublisherSelect
            name={"publisher"}
            onChange={changeData}
            value={filtration?.publisher}
          />
        </Col>
        <Col md={2}>
          <ProductMulti
            name={"product"}
            onChange={changeData}
            value={filtration?.product}
          />
        </Col>
        <Col md={2}>
          <LeadSourceSelect
            name={"source"}
            onChange={changeData}
            value={filtration?.product}
          />
        </Col>
        <Col md={2}>
          <AdminSelect
            name={"accountmanager"}
            onChange={changeNativeData}
            required={false}
            value={filtration?.accountmanager}
            placeholder={"Account manager..."}
          />
        </Col>
      </Row>
      {loading && <Loader />}
      {!loading && data && data.length > 0 ? (
        <>
          <Card className="flex-fill w-100">
            <div className="table-responsive">
              <SortableTable columns={columns} data={data} pagination={false} />
            </div>
          </Card>
          <Card className="flex-fill w-100 p-3">
            <h4>Summary:</h4>
            {data.map((dataItem, dataIndex) => {
              totalLeads += dataItem.leads;
              totalMatched += dataItem.matched;
              totalUnmatched += dataItem.unmatched;
              totalFlagged += dataItem.flagged;
              totalDuplicate += dataItem.duplicate;
              totalFailed += dataItem.failed;
              totalProfit += dataItem.total - dataItem.pub;
              totalTotal += dataItem.total;
              totalPub += dataItem.pub;
            })}
            <ul className="bull list-unstyled mb-0">
              <li>
                Leads: <strong>{totalLeads}</strong>
              </li>
              <li>
                Matched: <strong>{totalMatched}</strong>
              </li>
              <li>
                Unmatched: <strong>{totalUnmatched}</strong>
              </li>
              <li>
                Flagged: <strong>{totalFlagged}</strong>
              </li>
              <li>
                Failed: <strong>{totalFailed}</strong>
              </li>
              <li>
                Revenue: {totalTotal ? <MoneyTotal money={totalTotal} /> : "$0"}
              </li>
              <li>
                Publisher revenue:{" "}
                {totalPub ? <MoneyPub money={totalPub} /> : "$0"}
              </li>
              <li>
                Profit:{" "}
                {totalProfit ? <MoneyTotal money={totalProfit} /> : "$0"}
              </li>
              <li>
                Margin:{" "}
                {totalTotal
                  ? parseFloat((100 * totalProfit) / totalTotal).toFixed(2)
                  : "0"}
                %
              </li>
              <li>
                Success rate:{" "}
                {totalLeads
                  ? parseFloat((100 * totalMatched) / totalLeads).toFixed(2)
                  : "0"}
                %
              </li>
            </ul>
          </Card>
        </>
      ) : (
        !loading && (
          <CardBody className="text-center">
            <h5>Data not found</h5>
          </CardBody>
        )
      )}
    </Container>
  );
};

export default SummaryReport;
