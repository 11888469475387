import React, {useEffect, useState} from "react";
import {
    Breadcrumb,
    BreadcrumbItem,
    Button, Card,
    CardBody,
    Container,
    FormGroup,
    Label, Table,
} from "reactstrap";
import {
    AvForm,
    AvField
} from "availity-reactstrap-validation";
import API from "../../../utils/API";
import {Link, useHistory} from "react-router-dom";
import {toastr} from "react-redux-toastr";
import {FileUploader} from "react-drag-drop-files";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamation} from "@fortawesome/free-solid-svg-icons";
import csvExample from "../../../assets/img/phoneupload.png";
import Loader from "../../../components/Loader";

const PhoneNumberScrubber = (props) => {
    const [phone, setPhone] = useState({phone:null,description:null});
    const [error, setError] = useState(null);
    const [file, setFile] = useState(null);
    const [loading, setLoading] = useState(false);
    const [logs, setLogs] = useState(null);
    const [summary, setSummary] = useState(null);

    let history = useHistory();

    const handleChange = (e) => {
        setPhone({ ...phone, [e.target.name]: e.target.value })
        setError(null)
    };

    const handleFileUpload = (uploadedFile) => {
        let reader = new FileReader();
        reader.onload = function (event) {
            setFile(event.target.result);
        };
        reader.readAsText(uploadedFile);
    };

    const uploadPhones = () => {
        setLoading(true);
        API.post("/admin/badphone/upload", { csv: file }).then((response) => {
            setLoading(false);
            setFile(false)
            setLogs(response.data.data.result)
            setSummary(response.data.data.summary)
        }).catch(error => {
            setError('Failed uploading')
        });
    };

    useEffect(()=>{
        if (props.match.params.id) {
            API.get('/admin/badphone/'+props.match.params.id).then((response) => {
                setPhone(response.data.data)
            })
        }
    },[])

    const submit = () => {
        if (props.match.params.id) {
            API.put('/admin/badphone/'+props.match.params.id,phone)
                .then((response) => {
                    toastr.success('Success', 'Phone was successfully saved')
                    history.push("/settings/phonenumberscrubber")
                })
                .catch(error => {
                    setError('Input invalid value')
                })
        }else {
            API.post('/admin/badphone',phone)
                .then((response) => {
                    toastr.success('Success', 'Phone was successfully saved')
                    history.push("/settings/phonenumberscrubber")
                })
                .catch(error => {
                    setError('Input invalid value')
                })
        }
    }

    return (
        <Container fluid className="p-0">
            <Breadcrumb>
                <BreadcrumbItem>
                    <Link to="/settings/phonenumberscrubber">Phone Number Scrubber</Link>
                </BreadcrumbItem>
                <BreadcrumbItem active>{props.match.params.id?'Edit':'New'} bad phone number</BreadcrumbItem>
            </Breadcrumb>
            <Card>
                <CardBody>
                    {error &&
                        <div className="text-center text-danger">{error}</div>
                    }
                    <AvForm onValidSubmit={submit}>
                        <FormGroup>
                            <Label>Phone number(s) separated by commas, with either one space or no space between them</Label>
                            <AvField 
                                onChange={handleChange}
                                required
                                type="text"
                                name="phone"
                                value={phone.phone}
                                placeholder="Phone number"
                                validate={{
                                    required: {value: true, errorMessage: 'Please enter a phone number'},
                                    pattern: {value: /^\d{10}(?:, ?\d{10})*$/, errorMessage: 'Please enter 10-digit phone number(s), separated by a comma, with either one space or no space between the numbers.'},

                                }}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label>Description</Label>
                            <AvField
                                type="select"
                                required
                                name="description"
                                value={phone.description}
                                onChange={handleChange}
                                placeholder={<div>Type to search</div>}
                                validate= {{required: {value: true, errorMessage: 'Please select a description'},}}
                            >
                                <option value={''} hidden>Select...</option>
                                <option value={'Litigator'}>Litigator</option>
                                <option value={'DNC'}>DNC</option>
                            </AvField>
                        </FormGroup>
                        
                        <div className="text-center">
                            <Button color="primary">Save</Button>
                        </div>
                    </AvForm>
                    <div className="mt-4 mb-2 row justify-content-center">
                        <div className="col-auto">
                            <FileUploader
                                handleChange={handleFileUpload}
                                name="file"
                                types={["CSV"]}
                                label={"Upload or drop a CSV file right here"}
                            />
                            {loading && <Loader absolute={true} />}
                            <div className="mt-2 mb-2 text-center">
                                <FontAwesomeIcon icon={faExclamation}/> Example
                                format <br/>
                                <img src={csvExample} style={{width: "300px"}}/>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-2 justify-content-center">
                        <div className="col-auto">
                            <button
                                className="btn btn-success"
                                onClick={() => {
                                    uploadPhones();
                                }}
                                disabled={!file}
                            >
                                Upload
                            </button>
                        </div>
                    </div>
                    {summary && <Card className="mt-4 flex-fill w-100 p-3">
                        <h4>Summary:</h4>
                        <ul className="bull list-unstyled mb-0">
                            <li>
                                Total records: <strong>{summary.total}</strong>
                            </li>
                            <li>
                                Successful records: <strong>{summary.success}</strong>
                            </li>
                            <li>
                                Unsuccessful records: <strong>{summary.failure}</strong>
                            </li>
                        </ul>
                    </Card>}
                    {logs?.length > 0 && (
                        <div className="mb-2 table-responsive">
                            <Table>
                                <thead>
                                <tr>
                                    <th>Phone number</th>
                                    <th>Description</th>
                                    <th>Result</th>
                                    <th>Reason</th>
                                </tr>
                                </thead>
                                <tbody>
                                {logs.filter(log => log.result === 'failure').map((log, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{log.phone}</td>
                                            <td>{log.description}</td>
                                            <td>{log.result}</td>
                                            <td>{log.reason}</td>
                                        </tr>
                                    );
                                })}
                                </tbody>
                            </Table>
                        </div>
                        )}
                </CardBody>
            </Card>
        </Container>
)
};

export default PhoneNumberScrubber;