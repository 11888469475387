import React from "react";
import {Badge} from 'reactstrap';

const OrderStatus = ({ status}) => {
    if (status === 'active') {
        return <Badge color="success" className="mr-1 mb-1">
            Active
        </Badge>
    }else if (status === 'paused') {
        return <Badge color="warning" className="mr-1 mb-1">
            Paused
        </Badge>
    }else if (status === 'archive') {
        return <Badge color="secondary" className="mr-1 mb-1">
            Archive
        </Badge>
    }
};

export default OrderStatus;
