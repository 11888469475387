import { Card, CardBody, Col, Container, Input, Row, Table } from "reactstrap";
import React, { useEffect, useState } from "react";

import API from "../../utils/API";
import AdminSelect from "../../components/formfields/AdminSelect";
import CSVDownlaod from "../../components/CsvDownload";
import DateRange from "../../components/formfields/DateRange";
import Datetime from "../../components/fields/Datetime";
import LeadID from "../../components/fields/LeadID";
import LenderSelect from "../../components/formfields/LenderSelect";
import LenderTitle from "../../components/fields/LenderTitle";
import ListStat from "../../components/ListStat";
import Loader from "../../components/Loader";
import MoneyTotal from "../../components/fields/MoneyTotal";
import Order from "../../components/fields/Order";
import OrderPostingLog from "../../components/fields/OrderPostingLog";
import Pagination from "../../components/Pagination";
import PostingStatus from "../../components/status/PostingStatus";
import PostingStatusSelect from "../../components/formfields/PostingStatusSelect";
import Product from "../../components/fields/Product";
import ProductMulti from "../../components/formfields/ProductMulti";
import Publisher from "../../components/fields/Publisher";
import PublisherSelect from "../../components/formfields/PublisherSelect";
import dateFormat from "dateformat";
import { parseFilterValues } from "../../utils/helpers/filterHelper";
import { useHistory } from "react-router-dom";
import SortDirection from "../../components/SortDirection";
import ExpandableField from "../../components/fields/ExpandableField";

const LendersDetailsReport = () => {
  const history = useHistory();
  const defaultDate =
    dateFormat(new Date().setDate(new Date().getDate() - 6), "mmm d, yyyy") +
    " to " +
    dateFormat(new Date(), "mmm d, yyyy");
  const defaultFiltrationState = {
    page: 1,
    perPage: 100,
    date: defaultDate,
  };

  const urlParams = new URLSearchParams(history.location.search);
  const parsedParams = Object.fromEntries(urlParams.entries());
  const filtersParsed = parseFilterValues(parsedParams);

  const [filtration, setFiltration] = useState({
    ...defaultFiltrationState,
    ...filtersParsed,
  });
  const [loading, setLoading] = useState(true);
  const [log, setLog] = useState(false);
  const [data, setData] = useState(null);

  const changeData = (name, value) => {
    setFiltration({ ...filtration, [name]: value });

    history.push({
      pathname: history.location.pathname,
      search: new URLSearchParams({ ...filtration, [name]: value }).toString(),
    });
  };

  const changeNativeData = (e) => {
    setFiltration({ ...filtration, [e.target.name]: e.target.value });

    history.push({
      pathname: history.location.pathname,
      search: new URLSearchParams({
        ...filtration,
        [e.target.name]: e.target.value,
      }).toString(),
    });
  };

  useEffect(() => {
    setLoading(true);

    API.post("/admin/lender/detailsreport", filtration).then((response) => {
      setLog(response.data.data.data);
      setData(response.data.data);
      setLoading(false);
    });
  }, [filtration]);

  return (
    <Container fluid className="p-0">
      <div className="row justify-content-between">
        <div className="col-auto">
          <h3 className="h3 mb-3 float-left">Detail report</h3>
        </div>
        <div className="col-auto">
          <CSVDownlaod
            url="/admin/lender/detailsreportexport"
            filename="LenderDetailsReport"
            filtration={filtration}
          />
        </div>
      </div>
      <Row form>
        <Col>
          <DateRange
            onChange={changeData}
            name={"date"}
            value={filtration?.date}
          />
        </Col>
        <Col>
          <ProductMulti
            name={"product"}
            onChange={changeData}
            value={filtration?.product}
          />
        </Col>
        <Col>
          <LenderSelect
            name={"lender"}
            onChange={changeData}
            value={filtration?.lender}
          />
        </Col>
        <Col>
          <PublisherSelect
            name={"publisher"}
            onChange={changeData}
            value={filtration?.publisher}
          />
        </Col>
        <Col>
          <PostingStatusSelect
            name={"status"}
            onChange={changeData}
            value={filtration?.status}
          />
        </Col>
        <Col>
          <AdminSelect
            name={"representative"}
            onChange={changeNativeData}
            required={false}
            value={filtration?.representative}
            placeholder={"Account representative..."}
          />
        </Col>
      </Row>
      <Row className="mt-2">
        <Col>
          {loading && <Loader />}
          <Card>
            {!loading && log && log.length ? (
              <div className="table-responsive">
                <ListStat data={data} />
                <Table>
                  <thead>
                    <tr>
                      <th>Date<SortDirection fieldName={'date'} filters={filtration} changeFilter={changeData}/></th>
                      <th>Product<SortDirection fieldName={'product'} filters={filtration} changeFilter={changeData}/></th>
                      <th>Lead Id<SortDirection fieldName={'leadid'} filters={filtration} changeFilter={changeData}/></th>
                      <th>Publisher<SortDirection fieldName={'publisher'} filters={filtration} changeFilter={changeData}/></th>
                      <th>Status<SortDirection fieldName={'status'} filters={filtration} changeFilter={changeData}/></th>
                      <th>Sold for<SortDirection fieldName={'soldfor'} filters={filtration} changeFilter={changeData}/></th>
                      <th>Attempted Posts<SortDirection fieldName={'attemptedposts'} filters={filtration} changeFilter={changeData}/></th>
                      <th>Lender<SortDirection fieldName={'lender'} filters={filtration} changeFilter={changeData}/></th>
                      <th>Order<SortDirection fieldName={'order'} filters={filtration} changeFilter={changeData}/></th>
                      <th className="reject-reason-column">Reject Reason</th>
                      <th>Return Reason</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {log.map((logItem, index) => {
                      const reason = (logItem.status === "unmatched" || logItem.status === "error") && logItem.comment ? logItem.comment : (logItem.status === "unmatched" || logItem.status === "error") && logItem.responsedata ? logItem.responsedata : "";
                      return (
                        <tr key={index}>
                          <td>
                            <Datetime dateTime={logItem.date} />
                          </td>
                          <td>
                            <Product product={logItem.product} />
                          </td>
                          <td>
                            <LeadID id={logItem.lead} />
                          </td>
                          <td>
                            <Publisher publisher={logItem.publisher} />
                          </td>
                          <td>
                            <PostingStatus status={logItem.status} />
                          </td>
                          <td>
                            <MoneyTotal money={logItem.earntotal} />
                          </td>
                          <td>
                            {logItem.status === 'matched' ? logItem.attemptedposts : ''}
                          </td>
                          <td>
                            <LenderTitle
                              title={logItem.lenderorder.lender.title}
                              id={logItem.lenderorder.lender.id}
                            />
                          </td>
                          <td>
                            <Order order={logItem.lenderorder} />
                          </td>
                          <td>
                            <ExpandableField reason={reason} maxVisibleLength={25} />
                          </td>
                          <td>
                            {logItem.status === 'returned' ? logItem.returnreason : ''}
                          </td>
                          <td>
                            <OrderPostingLog id={logItem.id} />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            ) : (
              !loading && (
                <CardBody className="text-center">
                  <h5>Data not found</h5>
                </CardBody>
              )
            )}
          </Card>
          {data?.page > 0 && data?.pages > 0 && (
            <Pagination
              onChange={changeData}
              name={"page"}
              page={data.page}
              totalPages={data.pages}
              loading={loading}
            />
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default LendersDetailsReport;
