import { Button, Card, CardBody, Col, Container, Row, Table } from "reactstrap";
import { Eye, Trash } from "react-feather";
import React, { useEffect, useState } from "react";

import API from "../../utils/API";
import Datetime from "../../components/fields/Datetime";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Loader from "../../components/Loader";
import PixelStatus from "../../components/status/PixelStatus";
import PixelStatusSelect from "../../components/formfields/PixelStatusSelect";
import PixelType from "../../components/fields/PixelType";
import PixelTypeSelect from "../../components/formfields/PixelTypeSelect";
import Product from "../../components/fields/Product";
import ProductMulti from "../../components/formfields/ProductMulti";
import Publisher from "../../components/fields/Publisher";
import PublisherSelect from "../../components/formfields/PublisherSelect";
import { confirmAlert } from "react-confirm-alert";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { parseFilterValues } from "../../utils/helpers/filterHelper";
import { useHistory } from "react-router-dom";

const Pixels = () => {
  let history = useHistory();

  const defaultFiltrationState = { page: 1, perPage: 100 };
  const urlParams = new URLSearchParams(history.location.search);
  const parsedParams = Object.fromEntries(urlParams.entries());
  const filtersParsed = parseFilterValues(parsedParams);

  const [filtration, setFiltration] = useState({
    ...defaultFiltrationState,
    ...filtersParsed,
  });

  const [data, setData] = useState(null);
  const [pixels, setPixels] = useState(null);
  const [loading, setLoading] = useState(true);

  const changeData = (name, value) => {
    setFiltration({ ...filtration, [name]: value });

    history.push({
      pathname: history.location.pathname,
      search: new URLSearchParams({ ...filtration, [name]: value }).toString(),
    });
  };

  const loadPixels = () => {
    setLoading(true);
    API.post("/admin/pixel/search", filtration).then((response) => {
      setPixels(response.data.data.data);
      setData(response.data.data);
      setLoading(false);
    });
  };

  useEffect(() => {
    loadPixels();
  }, [filtration]);

  const newPixel = () => {
    history.push("/publisher/pixel/form");
  };

  const editPixel = (pixelId) => {
    history.push("/publisher/pixel/item/" + pixelId);
  };

  const deletePixel = (pixelId) => {
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure you want to delete this pixel?",
      buttons: [
        {
          label: "Yes",
          className: "btn btn-danger",
          onClick: () => {
            API.delete("/admin/pixel/item/" + pixelId).then((response) => {
              loadPixels();
            });
          },
        },
        {
          label: "No",
          className: "btn btn-primary",
        },
      ],
    });
  };

  return (
    <Container fluid className="p-0 pt-1">
      <Button color="primary" onClick={newPixel} className="float-right mt-n1">
        <FontAwesomeIcon icon={faPlus} /> New pixel
      </Button>
      <h1 className="h3 mb-3">Pixels</h1>
      <Row form>
        <Col md={3}>
          <PixelTypeSelect
            onChange={changeData}
            name={"pixelType"}
            value={filtration?.pixelType}
          />
        </Col>
        <Col md={3}>
          <PixelStatusSelect
            onChange={changeData}
            name={"status"}
            value={filtration?.status}
          />
        </Col>
        <Col md={3}>
          <ProductMulti
            name={"product"}
            onChange={changeData}
            value={filtration?.product}
          />
        </Col>
        <Col md={3}>
          <PublisherSelect
            name={"publisher"}
            onChange={changeData}
            value={filtration?.publisher}
          />
        </Col>
      </Row>
      <Row className="mt-2">
        <Col>
          <Card>
            {loading ? (
              <Loader absolute={true} />
            ) : (
              <>
                {pixels && pixels.length > 0 ? (
                  <div className="table-responsive">
                    <Table>
                      <thead>
                        <tr>
                          <th>Title</th>
                          <th>Type</th>
                          <th>Product</th>
                          <th>Status</th>
                          <th>Publisher</th>
                          <th>Date add/update</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {pixels.map((pixel, index) => {
                          return (
                            <tr key={index}>
                              <td>
                                <strong>{pixel.title}</strong>
                              </td>
                              <td>
                                <PixelType type={pixel.pixeltype} />
                              </td>
                              <td>
                                <Product product={pixel.product} />
                              </td>
                              <td>
                                <PixelStatus status={pixel.status} />
                              </td>
                              <td>
                                <Publisher publisher={pixel.publisher} />
                              </td>
                              <td>
                                <Datetime dateTime={pixel.dateadd} />
                                <br />
                                <Datetime dateTime={pixel.dateupdate} />
                              </td>
                              <td className="table-action">
                                <Eye
                                  onClick={() => {
                                    editPixel(pixel.id);
                                  }}
                                  className="align-middle mr-1 cursor-pointer text-primary"
                                  size={18}
                                />
                                <Trash
                                  onClick={() => {
                                    deletePixel(pixel.id);
                                  }}
                                  className="cursor-pointer align-middle text-danger"
                                  size={18}
                                />
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </div>
                ) : (
                  <CardBody className="text-center">
                    <h5>Data not found</h5>
                  </CardBody>
                )}
              </>
            )}
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Pixels;
