import React, {useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus, faTimes} from "@fortawesome/free-solid-svg-icons";
import {Button, Table} from "reactstrap";
import {useHistory} from "react-router-dom";
import NewTestLead from "./NewTestLead";
import API from "../../../utils/API";
import Datetime from "../../../components/fields/Datetime";
import User from "../../../components/fields/User";
import PostingStatus from "../../../components/status/PostingStatus";
import OrderTestLog from "../../../components/fields/OrderTestLog";

const TestLeads = ({order}) => {
    let history = useHistory();
    const [newTest,setNewTest] = useState(false)
    const [testlog,setTestlog] = useState(false)

    useEffect(()=>{
        getLog(1)
    },[order.id])

    const getLog = (page) => {
        API.post('/admin/order/testlog/'+order.id,{page:1,perPage:50}).then((response) => {
            setTestlog(response.data.data.data)
        });
    }

    return (
        <div className="row">
            <div className="col-12 text-center">
                {newTest ?
                    <>
                        <div className="text-right">
                            <FontAwesomeIcon style={{cursor:"pointer"}} onClick={() => {setNewTest(false);getLog(1);}} icon={faTimes}/>
                        </div>
                        <NewTestLead order={order}/>
                    </>
                    :
                    <>
                        <div className="text-right">
                            <Button color="primary" onClick={() => {setNewTest(true)}}>
                                <FontAwesomeIcon icon={faPlus}/> Send test
                            </Button>
                        </div>
                        {testlog.length > 0 &&
                            <div className="row mt-2">
                        <div className="col-12">
                            <div className="table-responsive">
                                <Table>
                                    <thead>
                                    <tr>
                                        <th>Date</th>
                                        <th>User</th>
                                        <th>Status</th>
                                        <th>Time</th>
                                        <th>Log</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        testlog.map((testlogItem, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td><Datetime dateTime={testlogItem.date}/></td>
                                                    <td><User user={testlogItem.user}/></td>
                                                    <td><PostingStatus status={testlogItem.status?testlogItem.status:testlogItem.pingstatus}/></td>
                                                    <td>{testlogItem.timesent?testlogItem.timesent:testlogItem.pingtimesent} sec</td>
                                                    <td><OrderTestLog id={testlogItem.id}/></td>
                                                </tr>
                                            )
                                        })
                                    }
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                            </div>
                        }
                    </>
                }
            </div>
        </div>
    )
}

export default TestLeads
