import React from "react";

const MoneySystem = ({money}) => {
    const value = (Math.round(money * 100) / 100).toFixed(2)

    if (value > 0) {
        return <span className="font-weight-bold">${value}</span>;
    }else if (value < 0) {
        return <span className="text-danger font-weight-bold">${value}</span>;
    }else {
        return <></>;
    }
};

export default MoneySystem;
