import React,{useState,useEffect} from "react";
import API from "../../../utils/API";
import {FormGroup, Input, Label} from "reactstrap";
import IndicatedPublisherSelect from "../../formfields/IndicatedPublisherSelect"

const Publisher = ({title,type,elementId,onClose}) => {

    const [includetype,setIncludetype] = useState(null)
    const [data,setData] = useState('')
    const [options,setOptions] = useState([])
    const [products,setProducts] = useState(null)
    const [error,setError] = useState(null)
    const [select,setSelect] = useState(false)

    useEffect(() => {
        API.post('/admin/filter',{elementId:elementId,type:type,filterTitle:title}).then((response) => {
            setData(response.data.data.data.list)

            if(type === 'order') {
                setProducts(response.data.data.getEntity.product)
            }

            if(type === 'lender') { 
                API.get('/admin/order/lender/' + elementId).then((response) => {
                    const list = response.data.data;
                    let ids = []
                    list.forEach((order) => ids.push(order.product.id))
                    setProducts(ids)
                });
            }

            if (response.data.data.data.type) {
                setIncludetype(response.data.data.data.type)
            }else {
                setIncludetype('include')
            }
            if (response.data.data.options) {
                setOptions(response.data.data.options)
            }
        });

    }, [title,type])

    const changeData = (name, value) => {
        setSelect(true)
        setData(value)
    }

    const onChange = (event) => {
        if (event.target.checked) {
            if (options.length === 2) {
                setData([event.target.value])
            }else {
                setData([...data, event.target.value]);
            }
        }else {
            setData(data.filter((function(state) {
                return state !== event.target.value
            })))
        }
    }

    const saveFilter = () => {
        const { isValid, error } = validateData(data, false);
        if (isValid) {
            setError(null);
            
            API.put('/admin/filter',{elementId:elementId,type:type,filterTitle:title,data:{list:data,type:includetype}}).then((response) => {
                onClose();
            });
        } else {
            setError(error);
        }
    }

    function contains(a, obj) {
        let i = a.length;
        while (i--) {
            if (a[i] === obj.toString()) {
                return true;
            }
        }
        return false;
    }

    const valueValidator = (value) => {
        if (Array.isArray(data)) {
            return data.join(', ');
        } else {
            return data;
        }
    };

    const handleTextareaChange = (event) => {
        const value = event.target.value;
        setSelect(false);
        const { isValid, error } = validateData(value, select);
    
        if (isValid) {
            setError(null);
        } else {
            setError(error);
        }
    
        setData(value);
    };

    const validateData = (data, select) => {
        const re = /^\d+(?:, ?\d+)*$/;
        if ((typeof data === 'string' && data.length === 0) || typeof data === 'object') {
            return { isValid: true, error: null };
        } else if (re.test(data) || (data === '' && !select)) {
            return { isValid: true, error: null };
        } else if (!re.test(data) && !select) {
            return { isValid: false, error: 'Invalid input. Please enter numbers separated by a comma only between numbers with one space or no space between them.' };
        } else {
            return { isValid: true, error: null };
        }
    }
    
    const handleTextareaBlur = () => {
        setData(valueValidator(data));
    };

    return (
        <>
            <div className="row">
                <div className="col pt-2">
                    <h5>Filter by: {title}</h5>
                </div>
                <div className="col-auto text-right">
                    <button onClick={onClose} className="btn btn-outline-secondary mb-2">X</button><br/>
                </div>
            </div>
            <div className="mt-3">
                <select onChange={(event)=>setIncludetype(event.target.value)} className="form-control" value={includetype}>
                    <option value="include">Include</option>
                    <option value="exclude">Exclude</option>
                </select>
            </div>
            <div className="mt-3">
                {options.length > 0 ?
                    <div className="row m-1">
                        {
                            options.map((option, index) => {
                                if (option?.title?.length > 0) {
                                    return (
                                        <div className="col-3 p-1" key={"option_" + index}>
                                            <FormGroup check>
                                                <Label check>
                                                    <Input type="checkbox" value={option.value} onChange={onChange}
                                                           checked={contains(data, option.value)}/>
                                                    {option.title}
                                                </Label>
                                            </FormGroup>
                                        </div>
                                    )
                                }else {
                                    return (
                                        <div className="col-3 p-1" key={"option_" + index}>
                                            <FormGroup check>
                                                <Label check>
                                                    <Input type="checkbox" value={option} onChange={onChange}
                                                           checked={contains(data, option)}/>
                                                    {option}
                                                </Label>
                                            </FormGroup>
                                        </div>
                                    )
                                }
                            })
                        }
                    </div>
                    :
                    <>
                        <textarea 
                            className="form-control" 
                            onChange={handleTextareaChange}
                            onBlur={handleTextareaBlur}
                            value={Array.isArray(data)?data.join(', '):data}
                        />
                        {error && <div className="text-danger mt-2">{error}</div>}
                    </>
                }
                <div className="mt-3">
                    {elementId && products && <IndicatedPublisherSelect products={products} name={type} onChange={changeData} value={data} elementId={elementId}/>}
                </div>
            </div>
            <div className="mt-3 text-center">
                <button className="btn btn-primary" onClick={saveFilter}>Save</button>
            </div>
        </>
    )
}

export default Publisher;
