export const parseFilterValues = (filter) => {
  const propertiesToParse = [
    "product",
    "publisher",
    "rcid",
    "status",
    "source",
    "viewOption",
    "lender",
    "lenderorder",
    "pixelType",
    "order",
    "link",
    "subid",
    "category"
  ];

  const stringPropertiesToParse = [
    "perPage",
    "page",
    "date",
    "type",
    "email",
    "invoicemethod",
    "cost",
    "phone",
    "billingtype",
    "trustedform",
    "leadid",
    "id",
    "firstname",
    "lastname",
    "address",
    "searchText",
    "representative",
    "keyword",
    "accountManager",
    "accountmanager",
    "word",
    "user",
  ];

  return propertiesToParse
    .concat(stringPropertiesToParse)
    .reduce((parsedValues, property) => {
      if (filter.hasOwnProperty(property)) {
        const value = filter[property];

        if (propertiesToParse.includes(property)) {
          const parsedArray = value.split(",").map((item) => item.trim());
          if (parsedArray.length && parsedArray.some((item) => item)) {
            parsedValues[property] = parsedArray;
          }
        } else {
          parsedValues[property] = value;
        }
      }
      return parsedValues;
    }, {});
};
