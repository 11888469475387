import React, { useEffect, useState } from "react";

import API from "../../utils/API";
import Select from "react-select";

const LogCategorySelect = ({ name, value = [], onChange, isMulti = true, isClearable = true }) => {
    const [publishers, setPublishers] = useState(null);
    const [filterValue, setFilterValue] = useState([]);

    const mapValuesToFilters = (value = [], publisherList) => {
        return value.map((item) => {
            const matchedFilter = publisherList.find((pub) => pub.value == item);
            return matchedFilter || item;
        });
    };

    const changeSelect = (value) => {
        let i,
            values = [];
        if(isMulti === true) {
            for (i = 0; i < value?.length; i++) {
                values.push(value[i].value);
            }
        }else {
            if(value) {
                values.push(value.value);
            }else {
                values = [];
            }
            setFilterValue(value?value:null);
        }
        if (name) {
            onChange(name, values);
            const mappedFilters = mapValuesToFilters(values, publishers);
            setFilterValue(mappedFilters);
        }
    };

    useEffect(() => {
        API.get("/system/logcategories").then((response) => {
            let options,
                i,
                publisherList = [];
            if (response.data.data) {
                options = response.data.data;
                for (i = 0; i < options.length; i++) {
                    publisherList.push({ value: options[i].id, label: options[i].title });
                }
            }
            const mappedFilters = mapValuesToFilters(value, publisherList);
            setFilterValue(mappedFilters);
            setPublishers(publisherList);
        });
    }, []);

    return (
        <Select
            className="react-select-container"
            classNamePrefix="react-select"
            options={publishers}
            value={filterValue}
            onChange={(value) => changeSelect(value)}
            isMulti={isMulti}
            placeholder="Category"
            isClearable={isClearable}
        />
    );
};

export default LogCategorySelect;
