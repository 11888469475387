import React, { useState } from "react";
import {Button, Form, FormGroup, Label, Input, Breadcrumb, BreadcrumbItem, Container} from 'reactstrap';
import {Link, useHistory} from "react-router-dom";
import API from "../../../utils/API";
import {toastr} from "react-redux-toastr";

const ElectricityProvider = () => {
    const [provider, setProvider] = useState('');
    const [state, setState] = useState('');
    let history = useHistory();

    const handleSubmit = (e) => {
        e.preventDefault();

        API.post('/admin/electricityprovider', { provider: provider, state: state })
            .then(response => {
                toastr.success('Success', response.data.data)
                history.push("/settings/electricityproviders")
            })
            .catch(error => {
                if (error.response) {
                    toastr.error('Error', error.response.data.data.message)
                }else {
                    toastr.error('Error', 'Error occurred')
                }
            });
    };

    return (
        <>
            <Container fluid className="p-0">
                <Breadcrumb>
                    <BreadcrumbItem>
                        <Link to="/settings/electricityproviders">Electricity providers</Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem active>Add New Provider</BreadcrumbItem>
                </Breadcrumb>
            </Container>
            <Form onSubmit={handleSubmit}>
                <FormGroup>
                    <Label for="state">Select State:</Label>
                    <Input type="select" name="state" id="state" value={state} onChange={(e) => setState(e.target.value)}>
                        <option value="">Select State</option>
                        <option value="AK">AK - Alaska</option>
                        <option value="AL">AL - Alabama</option>
                        <option value="AR">AR - Arkansas</option>
                        <option value="AZ">AZ - Arizona</option>
                        <option value="CA">CA - California</option>
                        <option value="CO">CO - Colorado</option>
                        <option value="CT">CT - Connecticut</option>
                        <option value="DE">DE - Delaware</option>
                        <option value="FL">FL - Florida</option>
                        <option value="GA">GA - Georgia</option>
                        <option value="HI">HI - Hawaii</option>
                        <option value="IA">IA - Iowa</option>
                        <option value="ID">ID - Idaho</option>
                        <option value="IL">IL - Illinois</option>
                        <option value="IN">IN - Indiana</option>
                        <option value="KS">KS - Kansas</option>
                        <option value="KY">KY - Kentucky</option>
                        <option value="LA">LA - Louisiana</option>
                        <option value="MA">MA - Massachusetts</option>
                        <option value="MD">MD - Maryland</option>
                        <option value="ME">ME - Maine</option>
                        <option value="MI">MI - Michigan</option>
                        <option value="MN">MN - Minnesota</option>
                        <option value="MO">MO - Missouri</option>
                        <option value="MS">MS - Mississippi</option>
                        <option value="MT">MT - Montana</option>
                        <option value="NC">NC - North Carolina</option>
                        <option value="ND">ND - North Dakota</option>
                        <option value="NE">NE - Nebraska</option>
                        <option value="NH">NH - New Hampshire</option>
                        <option value="NJ">NJ - New Jersey</option>
                        <option value="NM">NM - New Mexico</option>
                        <option value="NV">NV - Nevada</option>
                        <option value="NY">NY - New York</option>
                        <option value="OH">OH - Ohio</option>
                        <option value="OK">OK - Oklahoma</option>
                        <option value="OR">OR - Oregon</option>
                        <option value="PA">PA - Pennsylvania</option>
                        <option value="RI">RI - Rhode Island</option>
                        <option value="SC">SC - South Carolina</option>
                        <option value="SD">SD - South Dakota</option>
                        <option value="TN">TN - Tennessee</option>
                        <option value="TX">TX - Texas</option>
                        <option value="UT">UT - Utah</option>
                        <option value="VA">VA - Virginia</option>
                        <option value="VT">VT - Vermont</option>
                        <option value="WA">WA - Washington</option>
                        <option value="WI">WI - Wisconsin</option>
                        <option value="WV">WV - West Virginia</option>
                        <option value="WY">WY - Wyoming</option>
                    </Input>
                </FormGroup>
                <FormGroup>
                    <Label for="provider">Electricity Provider Name:</Label>
                    <Input type="text" name="provider" id="provider" value={provider} onChange={(e) => setProvider(e.target.value)} />
                </FormGroup>
                <Button type="submit">Submit</Button>
            </Form>
        </>
    );
};

export default ElectricityProvider;
