import React, { useState, useEffect } from "react";
import Loader from "../../../../components/Loader";
import API from "../../../../utils/API";
import SortableTable from "../../../../components/SortableTable";
import LenderTitle from "../../../../components/fields/LenderTitle";
import CSVDownlaod from "../../../../components/CsvDownload";
import { Col } from "reactstrap";

const BillingReportDetailByDate = ({ filtration }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const columns = [
    {
      name: "Lender",
      selector: (row) => row.lender.title.toLowerCase(),
      cell: (row) => (
        <LenderTitle title={row.lender.title} id={row.lender.id} />
      ),
      sortable: true,
    },
    {
      name: "Date",
      selector: (row) => row.day,
      sortable: true,
    },
    {
      name: "Attempted Posts",
      selector: (row) => row.attemptedposts,
      cell: (row) => <span className="text-primary">{row.attemptedposts}</span>,
      sortable: true,
    },
    {
      name: "Rejected Posts",
      selector: (row) => row.rejectedposts,
      cell: (row) => (
        <span className="text-secondary">{row.rejectedposts}</span>
      ),
      sortable: true,
    },
    {
      name: "Credited",
      selector: (row) => row.credited,
      cell: (row) => <span className="text-danger">{row.credited}</span>,
      sortable: true,
    },
    {
      name: "Net",
      selector: (row) => row.net !== 0 && row.net,
      sortable: true,
    },
    {
      name: "Price per lead",
      selector: (row) => row.grossprice && row.grossprice,
      cell: (row) => (row.grossprice ? "$" + row.grossprice : "$0"),
      sortable: true,
    },
    {
      name: "Net owed",
      selector: (row) => row.netowned && row.netowned,
      cell: (row) => (row.netowned ? "$" + row.netowned : "$0"),
      sortable: true,
    },
    {
      name: "Invoice method",
      selector: (row) => row.lender.invoicemethod ?? "",
      sortable: true,
    },
  ];

  useEffect(() => {
    setLoading(true);
    API.post("admin/billingreport/detailsbydate", filtration).then(
      (response) => {
        setData(response.data.data);
        setLoading(false);
      }
    );
  }, [filtration]);

  return (
    <>
      {loading && <Loader />}
      {!loading && data.length > 0 &&
        <div className="row mb-4 mt-1">
          <Col md={12}>
            <div className="d-flex justify-content-end align-items-center">
            <span className='font-weight-bold'>Detail by date</span>&nbsp;<span>Report - </span>
              <CSVDownlaod
                url="/admin/billingreport/detailsbydateexport"
                filename="BillingReportDetailsByDate"
                filtration={filtration}
              />
            </div>
          </Col>
        </div>
      }
      {!loading && data.length > 0 && (
        <SortableTable columns={columns} data={data} pagination={false} />
      )}
      {!loading && data.length == 0 && <h3>Data not found</h3>}
    </>
  );
};

export default BillingReportDetailByDate;
