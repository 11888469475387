import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Card,
  CardBody,
  Container,
  Nav,
  NavItem,
  NavLink,
  Spinner,
  TabContent,
  TabPane,
  Table,
} from "reactstrap";
import React, { useEffect, useState } from "react";
import {
  faCheck,
  faExclamation,
  faHistory,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";

import API from "../../../utils/API";
import DateRange from "../../../components/formfields/DateRange";
import Datetime from "../../../components/fields/Datetime";
import { FileUploader } from "react-drag-drop-files";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LeadID from "../../../components/fields/LeadID";
import LeadStatus from "../../../components/status/LeadStatus";
import LenderSelect from "../../../components/formfields/LenderSelect";
import LenderTitle from "../../../components/fields/LenderTitle";
import Loader from "../../../components/Loader";
import Order from "../../../components/fields/Order";
import OrderSelect from "../../../components/formfields/OrderSelect";
import ReturnReason from "../../../components/formfields/ReturnReason";
import classnames from "classnames";
import csvExample from "../../../assets/img/returnexample.png";
import dateFormat from "dateformat";
import { parseFilterValues } from "../../../utils/helpers/filterHelper";
import { toastr } from "react-redux-toastr";
import { useHistory } from "react-router-dom";

const Returns = () => {
  const history = useHistory();
  const defaultDate =
    dateFormat(new Date().setDate(new Date().getDate() - 6), "mmm d, yyyy") +
    " to " +
    dateFormat(new Date(), "mmm d, yyyy");
  const defaultFiltrationState = {
    date: defaultDate,
  };

  const urlParams = new URLSearchParams(history.location.search);
  const parsedParams = Object.fromEntries(urlParams.entries());
  const filtersParsed = parseFilterValues(parsedParams);

  const [activeTab, setActiveTab] = useState("search");
  const [searchData, setSearchData] = useState({
    ...defaultFiltrationState,
    ...filtersParsed,
  });
  const [file, setFile] = useState(null);
  const [leads, setLeads] = useState(null);
  const [loading, setLoading] = useState(false);
  const [leadType, setLeadType] = useState(null);

  const [disputeList, setDisputeList] = useState([]);

  const [reasons, setReasons] = useState(null);

  const [returnInProcess, setReturnInProcess] = useState(false);
  const [returnResult, setReturnResult] = useState(null);
  const [showBlankDesputeError, setShowBlankDesputeError] = useState(false);

  const init = () => {
    setActiveTab("search");
    setSearchData({ date: defaultDate });
    setFile(null);
    setLeads(null);
    setLoading(false);
    setLeadType(null);
    setDisputeList([]);
    setReturnInProcess(false);
    setReturnResult(null);
    setShowBlankDesputeError(false);
  };

  useEffect(() => {
    API.get("/system/returnreason").then((response) => {
      setReasons(response.data.data);
    });
  }, []);

  const changeData = (name, value) => {
    setSearchData({ ...searchData, [name]: value });

    history.push({
      pathname: history.location.pathname,
      search: new URLSearchParams({ ...searchData, [name]: value }).toString(),
    });
  };

  const handleChange = (e) => {
    setSearchData({ ...searchData, [e.target.name]: e.target.value });

    history.push({
      pathname: history.location.pathname,
      search: new URLSearchParams({
        ...searchData,
        [e.target.name]: e.target.value,
      }).toString(),
    });
  };

  const handleFileUpload = (uploadedFile) => {
    let reader = new FileReader();

    reader.onload = function (event) {
      setFile(event.target.result);
    };

    reader.readAsText(uploadedFile);
  };

  const searchLeads = () => {
    setLeadType("search");
    setLoading(true);
    API.post("/admin/return/search", searchData).then((response) => {
      setLeads(response.data.data);
      setDisputeList([]);
      setLoading(false);
    });
  };

  const uploadLeads = () => {
    setLeadType("upload");
    setLoading(true);
    API.post("/admin/return/upload", { csv: file }).then((response) => {
      setLoading(false);
      let returnList = [];
      setLeads(response.data.data);
      response.data.data.map((returnItem) => {
        if (returnItem.allowed) {
          returnList.push({
            id: returnItem.data.lenderposting.id,
            reason: returnItem.reason,
          });
        }
      });
      setDisputeList(returnList);
    });
  };

  const disputeLead = (postingId) => {
    setDisputeList([...disputeList, { id: postingId, reason: null }]);
  };

  const cancelDisputeLead = (postingId) => {
    setShowBlankDesputeError(false);
    setDisputeList(disputeList.filter((a) => a.id !== postingId));
  };

  const setReturnStatus = (postingId, reason) => {
    setShowBlankDesputeError(false);
    const tempData = disputeList.map((disputeListItem) => {
      if (disputeListItem.id === postingId) {
        return {
          ...disputeListItem,
          reason: reason,
        };
      } else {
        return disputeListItem;
      }
    });

    setDisputeList(tempData);
  };

  const submit = () => {
    let blankDesputeError = false;
    for (let i = 0; i < disputeList.length; i++) {
      if (disputeList[i].reason === null) {
        setShowBlankDesputeError(true);
        blankDesputeError = true;
      }
    }
    if (!blankDesputeError) {
      setReturnInProcess(true);
      API.post("/admin/return/submit", { disputeList }).then((response) => {
        setReturnResult(response.data.data);
        setReturnInProcess(false);
      });
    } else {
      toastr.error("Error", "Please select a reason for all disputes");
    }
  };

  console.log("searchData", searchData);

  return (
    <Container fluid className="p-0">
      <Button
        color="secondary"
        onClick={() => history.push("/lender/returnrequests")}
        className="float-right mt-2"
      >
        <FontAwesomeIcon icon={faHistory} /> Log
      </Button>
      {returnResult ? (
        <>
          <Breadcrumb>
            <BreadcrumbItem>
              <a className="text-primary" onClick={() => init()}>
                Returns
              </a>
            </BreadcrumbItem>
            <BreadcrumbItem active>Return result</BreadcrumbItem>
          </Breadcrumb>
          <Card className="mt-2">
            <div className="table-responsive" style={{ maxHeight: "500px" }}>
              <Table className="pb-4">
                <thead>
                  <tr>
                    <th>Id</th>
                    <th>Date</th>
                    <th>Status</th>
                    <th>Email</th>
                    <th>Phone</th>
                    <th>Order</th>
                    <th>Return status</th>
                  </tr>
                </thead>
                <tbody>
                  {returnResult.map((lead, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          {lead.data?.lead ? (
                            <LeadID id={lead.data.lead.id} />
                          ) : (
                            "-"
                          )}
                        </td>
                        {lead.data?.lead ? (
                          <>
                            <td>
                              <Datetime dateTime={lead.data.lead.dateadd} />
                            </td>
                            <td>
                              <LeadStatus status={lead.data.lead.status} />
                            </td>
                            <td>{lead.data.leaddata.email}</td>
                            <td>{lead.data.leaddata.phone}</td>
                            <td>
                              <LenderTitle
                                id={lead.data.lender.id}
                                title={lead.data.lender.title}
                              />
                              <br />
                              <Order order={lead.data.lenderorder} />
                            </td>
                            <td>
                              {lead.status ? (
                                <span className="text-success">
                                  <FontAwesomeIcon icon={faCheck} /> Success
                                </span>
                              ) : (
                                <span className="text-danger">
                                  <FontAwesomeIcon icon={faTimes} /> Decline
                                  <br />
                                  {lead.note}
                                </span>
                              )}
                            </td>
                          </>
                        ) : (
                          <>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td className="text-center">Lead not found</td>
                          </>
                        )}
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          </Card>
        </>
      ) : (
        <>
          <h1 className="h3 mb-3">Returns</h1>
          {returnInProcess ? (
            <div className="row justify-content-center mt-7">
              <div className="col-auto text-center">
                <Spinner color="primary" />
                <br />
                <div className="font-weight-bold text-center">
                  Return in process, please wait
                </div>
              </div>
            </div>
          ) : (
            <>
              <div className={"tab default"}>
                <Nav tabs>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === "search" })}
                      onClick={() => {
                        setActiveTab("search");
                      }}
                    >
                      <strong>Search</strong>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === "upload" })}
                      onClick={() => {
                        setActiveTab("upload");
                      }}
                    >
                      <strong>Upload</strong>
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent activeTab={activeTab}>
                  <TabPane tabId="search">
                    <div className="row">
                      <div className="col-md-4 col-sm-12">
                        <label>Date range</label>
                        <DateRange
                          onChange={changeData}
                          name={"date"}
                          value={searchData?.date}
                        />
                      </div>
                      <div className="col-md-4 col-sm-12">
                        <label>Lender</label>
                        <LenderSelect
                          name={"lender"}
                          onChange={changeData}
                          value={searchData?.lender}
                        />
                      </div>
                      <div className="col-md-4 col-sm-12">
                        <label>Order</label>
                        <OrderSelect
                          onChange={changeData}
                          name={"order"}
                          lender={searchData.lender}
                          value={searchData?.order}
                        />
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-md-6 col-sm-12">
                        <label>Lead Id's (comma separated)</label>
                        <textarea
                          className="form-control"
                          rows="4"
                          name="leadid"
                          value={searchData?.leadid}
                          onChange={handleChange}
                        ></textarea>
                      </div>
                      <div className="col-md-6 col-sm-12">
                        <label>Email</label>
                        <input
                          type="text"
                          className="form-control"
                          name="email"
                          value={searchData?.email}
                          onChange={handleChange}
                        ></input>
                        <label>Phone</label>
                        <input
                          type="text"
                          className="form-control"
                          name="phone"
                          value={searchData?.phone}
                          onChange={handleChange}
                        ></input>
                      </div>
                    </div>
                    <div className="row mt-4 mb-1 justify-content-center">
                      <div className="col-auto">
                        <button
                          className="btn btn-success"
                          onClick={() => {
                            searchLeads();
                          }}
                        >
                          Search
                        </button>
                      </div>
                    </div>
                  </TabPane>
                  <TabPane tabId="upload">
                    <div className="row justify-content-center">
                      <div className="col-auto">
                        <FileUploader
                          handleChange={handleFileUpload}
                          name="file"
                          types={["CSV"]}
                          label={"Upload or drop a CSV file right here"}
                        />
                        <div className="mt-2 mb-2 text-center">
                          <FontAwesomeIcon icon={faExclamation} /> Example
                          format <br />
                          <img src={csvExample} style={{ width: "300px" }} />
                        </div>
                      </div>
                    </div>
                    <div className="row mt-2 justify-content-center">
                      <div className="col-auto">
                        <button
                          className="btn btn-success"
                          onClick={() => {
                            uploadLeads();
                          }}
                          disabled={!file}
                        >
                          Upload
                        </button>
                      </div>
                    </div>
                  </TabPane>
                </TabContent>
              </div>

              {reasons && leadType === activeTab && (
                <Card className="mt-2">
                  {loading ? (
                    <Loader absolute={true} />
                  ) : leads?.length > 0 ? (
                    <>
                      <div
                        className="table-responsive pb-5"
                        style={{ maxHeight: "500px" }}
                      >
                        <Table className="pb-4">
                          <thead>
                            <tr>
                              <th>Id</th>
                              <th>Date</th>
                              <th>Status</th>
                              <th>Email</th>
                              <th>Phone</th>
                              <th>Order</th>
                              <th
                                className="text-center"
                                style={{ width: "200px" }}
                              >
                                Dispute
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {leads.map((lead, index) => {
                              return (
                                <tr key={index}>
                                  <td>
                                    {lead.data?.lead ? (
                                      <LeadID id={lead.data.lead.id} />
                                    ) : (
                                      lead.id
                                    )}
                                  </td>
                                  {lead.data?.lead ? (
                                    <>
                                      <td>
                                        <Datetime
                                          dateTime={lead.data.lead.dateadd}
                                        />
                                      </td>
                                      <td>
                                        <LeadStatus
                                          status={lead.data.lead.status}
                                        />
                                      </td>
                                      <td>{lead.data.leaddata.email}</td>
                                      <td>{lead.data.leaddata.phone}</td>
                                      <td>
                                        <LenderTitle
                                          id={lead.data.lender.id}
                                          title={lead.data.lender.title}
                                        />
                                        <br />
                                        <Order order={lead.data.lenderorder} />
                                      </td>
                                      <td className="text-center">
                                        {lead.allowed ? (
                                          <>
                                            {disputeList.some(
                                              (disputeListItem) =>
                                                disputeListItem.id ===
                                                lead.data.lenderposting.id
                                            ) ? (
                                              <>
                                                <ReturnReason
                                                  name={
                                                    lead.data.lenderposting.id
                                                  }
                                                  onChange={setReturnStatus}
                                                  value={lead.reason}
                                                  reasons={
                                                    lead.data.product.id in
                                                    reasons
                                                      ? reasons[
                                                          lead.data.product.id
                                                        ]
                                                      : []
                                                  }
                                                />
                                                <div className="text-right mt-1">
                                                  <a
                                                    className="text-primary"
                                                    onClick={() =>
                                                      cancelDisputeLead(
                                                        lead.data.lenderposting
                                                          .id
                                                      )
                                                    }
                                                  >
                                                    unselect
                                                  </a>
                                                </div>
                                              </>
                                            ) : (
                                              <button
                                                className="btn btn-primary"
                                                onClick={() =>
                                                  disputeLead(
                                                    lead.data.lenderposting.id
                                                  )
                                                }
                                              >
                                                Dispute
                                              </button>
                                            )}
                                          </>
                                        ) : (
                                          <>Not allowed by policy</>
                                        )}
                                      </td>
                                    </>
                                  ) : (
                                    <>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                      <td className="text-center">
                                        Lead not found
                                      </td>
                                    </>
                                  )}
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                      </div>
                      {disputeList && disputeList.length > 0 && (
                        <div
                          className="text-center mb-3 mt-2"
                          onClick={() => submit()}
                        >
                          <a className="btn btn-primary">Submit</a>
                        </div>
                      )}
                    </>
                  ) : (
                    !loading &&
                    leads !== null && (
                      <CardBody className="text-center">
                        <h5>Data not found</h5>
                      </CardBody>
                    )
                  )}
                </Card>
              )}
            </>
          )}
        </>
      )}
    </Container>
  );
};

export default Returns;
