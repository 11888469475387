import React from "react";
import {InputGroup, Input, Button} from "reactstrap";

const FilterComponent = ({ placeHolder, filterText, onFilter }) => (
    <InputGroup>
        <Input
          type="text" placeholder={placeHolder} value={filterText} onChange={onFilter}
        />
    </InputGroup>    
);

export default FilterComponent;
