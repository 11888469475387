import React, { useState, useEffect } from "react";
import { Button, FormGroup, Label, Breadcrumb, BreadcrumbItem, Container, CardBody, Card, ListGroup, ListGroupItem} from 'reactstrap';
import { Link } from "react-router-dom";
import API from "../../../utils/API";
import { toastr } from "react-redux-toastr";
import ElectricityProviderSelect from "../../../components/formfields/ElectricityProviderSelect";
import { AvForm, AvInput } from "availity-reactstrap-validation";
import { Spinner } from "reactstrap";

const ElectricityProviderMapping = () => {
    const [filtration,setFiltration] = useState({electricityProvider:null, title:null})
    const [mappedProvider, setMappedProvider] = useState(null);
    const [loading, setLoading] = useState(false)

    const loadData = () => {
        setLoading(true)
        API.get('/admin/mappedelectricityprovider/'+filtration.electricityProvider).then((response) => {
            setMappedProvider(response.data.data)
            setLoading(false)
        });
    };

    useEffect(() => {
        if(filtration.electricityProvider) {
            loadData();
        }
    }, [filtration.electricityProvider]);

    const handleChange = (e) => {
        setFiltration({ ...filtration, [e.target.name]: e.target.value })
    };

    const changeData = (name,value) => {
        setFiltration({ ...filtration, [name]: value })
    }

    const submit = () => {
        if(filtration.electricityProvider === '' || filtration.title === '') {
            toastr.error('Error', 'Please enter required information')
            return ;
        }

        API.post('/admin/mappedelectricityprovider', filtration).then(response => {
            toastr.success('Success', response.data.data)
            loadData();
        })
        .catch(error => {
            if (error.response) {
                toastr.error('Error', error.response.data.data.message)
            }else {
                toastr.error('Error', 'Error occurred')
            }
        });
        setFiltration({...filtration, title:''})
    }

    return (
        <>
            <Container fluid className="p-0">
                <Breadcrumb>
                    <BreadcrumbItem>
                        <Link to="/settings/electricityproviders">Electricity providers</Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem active>Map Electricity Providers</BreadcrumbItem>
                </Breadcrumb>
            </Container>
            <Card>
                <CardBody>
                    <AvForm onValidSubmit={submit}>
                        <FormGroup className="mb-4">
                            <Label>Select Electricity Provider</Label>
                            <ElectricityProviderSelect
                                name={"electricityProvider"}
                                onChange={changeData}
                            />
                        </FormGroup>
                        {loading && <Spinner color="primary" />}
                        {!loading && filtration.electricityProvider && mappedProvider &&
                            <>
                                <Label className='mt-2'>Name(s) currently mapped to this provider:</Label>
                                <ListGroup className='mb-4'>
                                    {mappedProvider.length > 0 ? mappedProvider.map((provider, index) => {
                                        return (
                                            <ListGroupItem key={index}>{provider.title}</ListGroupItem>
                                        )
                                    }): <div className='mb-2'>No names currently mapped</div>}
                                </ListGroup>
                            </>
                        }
                        {filtration.electricityProvider && 
                            <>
                                <FormGroup className='mt-2'>
                                    <Label>Enter New Name to Map:</Label>
                                    <AvInput onChange={handleChange} type="text" name="title" value={filtration.title} placeholder="New name for this provider"/>
                                </FormGroup>
                                <div className="text-center">
                                    <Button color="primary">Submit</Button>
                                </div>
                            </>
                        }
                    </AvForm>
                </CardBody>
            </Card>
        </>
    );
};

export default ElectricityProviderMapping;
