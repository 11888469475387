import React, {useState, useEffect, useRef} from "react";
import API from "../../utils/API";
import {Edit2,Info} from "react-feather";
import JoditEditor from 'jodit-react';
import {AvField,AvForm} from "availity-reactstrap-validation";

const LenderSettings = ({lenderId}) => {
    const [settings,setSettings] = useState(null)
    const editor = useRef(null);
    const [edit,setEdit] = useState(false)

    const loadSettings = () => {
        API.get('/admin/lender/settings/'+lenderId).then((response) => {
            setSettings(response.data.data)
        });
    }

    useEffect(()=>{
        loadSettings()
    },[lenderId])

    useEffect(()=>{
        loadSettings()
    },[edit])

    const handleChange = (e) => {
        setSettings({ ...settings, [e.target.name]: e.target.value })
    };

    const save = () => {
        API.post('/admin/lender/settings/'+lenderId,settings).then((response) => {
            setEdit(false)
        });
    }

    const handleImageSelect = (event) => {
        let files = event.target.files;
        let reader = new FileReader();
        if (files[0]) {
            reader.readAsDataURL(files[0]);

            reader.onload = (e) => {
                API.post('/admin/image', {image: e.target.result}).then((response) => {
                    handleChange({target: {name: "logo", value: response.data.data.url}})
                });
            }
        }
    }

    return <>
        {settings &&
            <div style={{minHeight:"25px"}}>
                {
                    edit ?
                        <>
                            <AvForm>
                                <div className="row justify-content-end">
                                    <div className="col-auto">
                                        <button onClick={() => {setEdit(false)}} className="btn btn-outline-secondary mb-2">X</button>
                                    </div>
                                </div>
                                <div className="row justify-content-center">
                                    <div className="col-2 text-right">
                                        Display name:
                                    </div>
                                    <div className="col-8 text-left">
                                        <input type="text" onChange={handleChange} name="displayname" className="form-control" value={settings.displayname}/>
                                    </div>
                                </div>
                                <div className="row mt-2 justify-content-center">
                                    <div className="col-2 text-right">
                                        Phone number:
                                    </div>
                                    <div className="col-8 text-left">
                                        <input type="text" onChange={handleChange} name="phone" className="form-control" value={settings.phone}/>
                                    </div>
                                </div>
                                <div className="row justify-content-center mt-2">
                                    <div className="col-2 text-right">
                                        Hide name:
                                    </div>
                                    <div className="col-8 text-left">
                                        <AvField
                                            type="select"
                                            name="hidename"
                                            value={settings.hidename}
                                            onChange={handleChange}
                                        >
                                            <option value={'no'}>No</option>
                                            <option value={'yes'}>Yes</option>
                                        </AvField>
                                    </div>
                                </div>
                                <div className="row justify-content-center">
                                    <div className="col-2 text-right">
                                        Link:
                                    </div>
                                    <div className="col-8 text-left">
                                        <input type="text" onChange={handleChange} name="link" className="form-control" value={settings.link}/>
                                    </div>
                                </div>
                                <div className="row mt-2 justify-content-center">
                                    <div className="col-2 text-right">
                                        Bullet points:
                                    </div>
                                    <div className="col-8 text-left">
                                        <JoditEditor
                                            ref={editor}
                                            value={settings.bulletpoints}
                                            tabIndex={1}
                                            onBlur={newContent => setSettings({ ...settings, ['bulletpoints']: newContent })}
                                            onChange={newContent => {}}
                                        />
                                    </div>
                                </div>
                                <div className="row mt-2 justify-content-center">
                                    <div className="col-2 text-right">
                                        Logo:
                                    </div>
                                    <div className="col-8 text-left">
                                        {settings.logo &&
                                            <img src={settings.logo} className="img-fluid" style={{maxWidth:"400px"}}/>
                                        }
                                        <label>
                                            <Edit2 className="align-middle mr-1 cursor-pointer zoomHover" color={"black"} size={24}/>
                                            <input type="file" onChange={handleImageSelect} style={{display:"none"}}/>
                                        </label>
                                    </div>
                                </div>
                                <div className="row mt-2 justify-content-center">
                                    <div className="col-auto">
                                        <button className="btn btn-primary" onClick={()=>save()}>Save</button>
                                    </div>
                                </div>
                            </AvForm>
                        </>
                        :
                        <>
                            <div className="float-right">
                                <Edit2 onClick={() => {setEdit(true)}} className="align-middle mr-1 cursor-pointer zoomHover" size={14} />
                            </div>
                            {settings.displayname &&
                                <div className="row justify-content-center">
                                    <div className="col-2 text-right">
                                        Display name:
                                    </div>
                                    <div className="col-8 text-left">
                                        {settings.displayname}
                                    </div>
                                </div>
                            }
                            {settings.hidename &&
                                <div className="row mt-2 justify-content-center">
                                    <div className="col-2 text-right">
                                        Hide name:
                                    </div>
                                    <div className="col-8 text-left">
                                        {settings.hidename}
                                    </div>
                                </div>
                            }
                            {settings.link &&
                                <div className="row mt-2 justify-content-center">
                                    <div className="col-2 text-right">
                                        Link:
                                    </div>
                                    <div className="col-8 text-left">
                                        {settings.link}
                                    </div>
                                </div>
                            }
                            {settings.phone &&
                                <div className="row justify-content-center">
                                    <div className="col-2 text-right">
                                        Phone number:
                                    </div>
                                    <div className="col-8 text-left">
                                        {settings.phone}
                                    </div>
                                </div>
                            }
                            {settings.bulletpoints &&
                                <div className="row mt-2 justify-content-center">
                                    <div className="col-2 text-right">
                                        Bullet points:
                                    </div>
                                    <div className="col-8 text-left">
                                        <div dangerouslySetInnerHTML={{__html: settings.bulletpoints }} />
                                    </div>
                                </div>
                            }
                            {settings.logo &&
                                <div className="row mt-2 justify-content-center">
                                    <div className="col-2 text-right">
                                        Logo:
                                    </div>
                                    <div className="col-8 text-left">
                                        <img src={settings.logo} className="img-fluid" style={{maxWidth:"400px"}}/>
                                    </div>
                                </div>
                            }
                            {(settings.bulletpoints || settings.logo) &&
                                <div className="row justify-content-center mt-4">
                                    <div className="col-12">
                                        <h5><Info/> How to preview on real landing page</h5>
                                        <p>Fill out the form on landing page with following information:</p>
                                        First name: <strong>test</strong><br/>
                                        Last name: <strong>test</strong><br/>
                                        Email: <strong>lender{lenderId}@test.com</strong>
                                    </div>
                                </div>
                            }
                        </>
                }
            </div>
        }
    </>
}

export default LenderSettings
