import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";
import React, { useEffect, useState } from "react";

import API from "../../utils/API";
import AdminSelect from "../../components/formfields/AdminSelect";
import { Edit2 } from "react-feather";
import FilterComponent from "../../components/FilterComponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Loader from "../../components/Loader";
import Product from "../../components/fields/Product";
import ProductMulti from "../../components/formfields/ProductMulti";
import PublisherStatus from "../../components/status/PublisherStatus";
import PublisherTitle from "../../components/fields/PublisherTitle";
import SortableTable from "../../components/SortableTable";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { parseFilterValues } from "../../utils/helpers/filterHelper";
import { useHistory } from "react-router-dom";

const Publishers = () => {
  let history = useHistory();
  const defaultFiltrationState = {
    page: 1,
    perPage: 1000000,
  };
  const urlParams = new URLSearchParams(history.location.search);
  const parsedParams = Object.fromEntries(urlParams.entries());
  const filtersParsed = parseFilterValues(parsedParams);

  const [publishers, setPublishers] = useState(null);
  const [loading, setLoading] = useState(true);
  const [filtration, setFiltration] = useState({
    ...defaultFiltrationState,
    ...filtersParsed,
  });
  const [filterText, setFilterText] = React.useState(
    filtersParsed?.searchText || ""
  );

  const columns = [
    {
      name: "ID",
      selector: (row) => parseInt(row.id),
      sortable: true,
    },
    {
      name: "Name",
      selector: (row) => row.name.toLowerCase(),
      cell: (row) => <PublisherTitle title={row.name} id={row.id} />,
      sortable: true,
    },
    {
      name: "Type",
      cell: (row) => (
        <div>
          {row.product.map((value) => (
            <Product key={value.id} product={value} />
          ))}
        </div>
      ),
      sortable: false,
    },
    {
      name: "Status",
      selector: (row) => row.status,
      cell: (row) => <PublisherStatus status={row.status} />,
      sortable: true,
    },
    {
      name: "Date add",
      selector: (row) => row.dateadd,
      sortable: true,
    },
    {
      name: "Action",
      selector: (row) => (
        <Edit2
          onClick={() => {
            editPublisher(row.id);
          }}
          className="align-middle mr-1 cursor-pointer text-primary"
          size={18}
        />
      ),
      sortable: false,
    },
  ];
  const newPublisher = () => {
    history.push("/publisher/form");
  };
  const editPublisher = (publisherId) => {
    history.push("/publisher/form/" + publisherId);
  };

  const changeData = (name, value) => {
    setFiltration({ ...filtration, [name]: value });

    history.push({
      pathname: history.location.pathname,
      search: new URLSearchParams({ ...filtration, [name]: value }).toString(),
    });
  };

  const changeNativeData = (e) => {
    setFiltration({ ...filtration, [e.target.name]: e.target.value });

    history.push({
      pathname: history.location.pathname,
      search: new URLSearchParams({
        ...filtration,
        [e.target.name]: e.target.value,
      }).toString(),
    });
  };

  const updateFilterText = (value) => {
    setFiltration({ ...filtration, searchText: value });
    setFilterText(value);

    history.push({
      pathname: history.location.pathname,
      search: new URLSearchParams({
        ...filtration,
        searchText: value,
      }).toString(),
    });
  };

  const filteredItems =
    publishers &&
    publishers.length > 0 &&
    publishers.filter(
      (item) =>
        item["id"]
          .toString()
          .toLowerCase()
          .indexOf(filterText.toLowerCase()) !== -1 ||
        item["name"].toLowerCase().indexOf(filterText.toLowerCase()) !== -1
    );
  useEffect(() => {
    setLoading(true);
    API.post("/admin/publisher/list", filtration)
      .then((response) => {
        const res = response.data.data.data;
        let result = [];
        res.forEach((item) => {
          if (result.find((el) => el.id === item.publisher.id)) {
            const idx = result.findIndex((el) => el.id === item.publisher.id);
            result[idx]["product"].push(item.product);
          } else {
            result.push(item.publisher);
            result[result.indexOf(item.publisher)]["product"] = [];
            if (item.product) {
              result[result.indexOf(item.publisher)]["product"].push(
                item.product
              );
            }
          }
        });
        setPublishers(result);
        setLoading(false);
      })
      .catch((error) => {
        console.error("An error occurred:", error);
        setLoading(false);
      });
  }, [filtration]);

  return (
    <Container fluid className="p-0">
      <h1 className="h3 mb-3">Publishers</h1>
      <Row form className="mt-1">
        <Col md={3}>
          <FilterComponent
            placeHolder={"Search..."}
            onFilter={(e) => updateFilterText(e.target.value)}
            filterText={filterText}
          />
        </Col>
        <Col md={3}>
          <ProductMulti
            name={"product"}
            onChange={changeData}
            value={filtration?.product}
          />
        </Col>
        <Col md={3}>
          <AdminSelect
            name={"accountManager"}
            onChange={changeNativeData}
            required={false}
            placeholder={"Account manager..."}
            value={filtration?.accountManager}
          />
        </Col>
        <Col className="text-right">
          <Button
            color="primary"
            onClick={newPublisher}
            className="float-right mt-n1"
          >
            <FontAwesomeIcon icon={faPlus} /> New publisher
          </Button>
        </Col>
      </Row>
      <Row form className="mt-2">
        <Col>
          {loading && <Loader />}
          <Card>
            {!loading && publishers && publishers.length > 0 ? (
              <SortableTable
                columns={columns}
                data={filteredItems}
                pagination={false}
              />
            ) : (
              !loading && (
                <CardBody className="text-center">
                  <h5>Data not found</h5>
                </CardBody>
              )
            )}
          </Card>
        </Col>
      </Row>
    </Container>
  );
};
export default Publishers;
