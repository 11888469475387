import React,{useState,useEffect} from "react";
import API from "../../../utils/API";
import {Spinner, Table} from "reactstrap";

const Log = ({leadId,pingId,update}) => {

    const [processes, setProcesses] = useState(null)
    const [processId, setProcessId] = useState(null)
    const [logs, setLogs] = useState(null)

    useEffect(()=>{
        if (leadId) {
            API.get('/admin/log/process/'+leadId).then((response) => {
                setProcesses(response.data.data)
            });
        }
    },[leadId,update])

    useEffect(()=>{
        if (pingId) {
            API.get('/admin/log/processping/'+pingId).then((response) => {
                setProcesses(response.data.data)
            });
        }
    },[pingId,update])

    useEffect(()=>{
        if (processId) {
            API.get('/admin/log/'+processId).then((response) => {
                setLogs(response.data.data)
            });
        }
    },[processId])

    return (
        <div className="row">
            <div className="col-12">
            {(processes && processes.length > 0) &&
            <div className="row">
                {processes.map((process,index)=>{
                    return (
                        <div className="col-auto p-2" key={index}>
                            <a onClick={(e)=>{setProcessId(process.id)}} className={`btn ${processId===process.id?'btn-secondary':'btn-outline-secondary'}`}>{process.date}<br/><strong>{process.title}</strong></a>
                        </div>
                    )
                }
                )}
            </div>
            }
            </div>
            <div className="col-12">
            {processId &&
                <>
                    {(logs && logs.length > 0) &&
                        <div className="table-responsive mt-2">
                            <Table>
                                <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Duration</th>
                                    <th>Log</th>
                                </tr>
                                </thead>
                                <tbody>
                                {logs.map((log,logId)=>{
                                  return (
                                      <tr key={logId}>
                                          <td>{log.date}</td>
                                          <td>{log.duration}</td>
                                          <td><pre>{log.data}</pre></td>
                                      </tr>
                                  )
                                })}
                                </tbody>
                            </Table>
                        </div>
                    }
                </>
            }
            </div>
        </div>
    )
}

export default Log
