import React,{useEffect, useState} from "react";
import API from "../../utils/API";
import Filter from "../../components/filter/Filter";
import {titleFormatter} from "../../components/filter/utils";

const Filters = ({lender}) => {

    const [filters,setFilters] = useState(null)
    const [currentFilterTitle,setCurrentFilterTitle] = useState(null)
    const [reRender,setReRender] = useState(true)

    useEffect(() => {
        API.get('/admin/filter/'+lender.id+'/lender').then((response) => {
            setFilters(response.data.data)
        });
    }, [lender.id,reRender])

    return (
        <>
            {currentFilterTitle ?
                <Filter title={currentFilterTitle} type={"lender"} elementId={lender.id} onClose={()=>{setCurrentFilterTitle(null);setReRender(!reRender)}}/>
                :
                filters &&
                filters.map((filter, index) => {
                    return (
                        <>
                        <div className="row m-3 border-bottom pb-1" key={"filter_"+index}>
                            <div className="col-auto">
                                <strong>{titleFormatter(filter.title)}</strong>
                            </div>
                            <div className="col" dangerouslySetInnerHTML={{__html: filter.displayData}}/>
                            <div className="col-auto text-right">
                                <button className="btn btn-primary" onClick={()=>{setCurrentFilterTitle(filter.title)}}>Set up</button>
                            </div>
                        </div>
                        </>
                    )
                })
            }
        </>
    )
}

export default Filters
