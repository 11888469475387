import React,{useState,useEffect} from 'react';
import Loader from "../../../../components/Loader";
import API from "../../../../utils/API";
import {Table,Col} from "reactstrap";
import Lender from "../../../../components/fields/Lender";
import ListStat from "../../../../components/ListStat";
import Datetime from "../../../../components/fields/Datetime";
import Product from "../../../../components/fields/Product";
import LeadID from "../../../../components/fields/LeadID";
import Publisher from "../../../../components/fields/Publisher";
import PostingStatus from "../../../../components/status/PostingStatus";
import LenderTitle from "../../../../components/fields/LenderTitle";
import Order from "../../../../components/fields/Order";
import OrderPostingLog from "../../../../components/fields/OrderPostingLog";
import Pagination from "../../../../components/Pagination";
import SortDirection from "../../../../components/SortDirection";
import {useHistory} from "react-router-dom";
import CSVDownlaod from '../../../../components/CsvDownload';
import MoneyTotal from "../../../../components/fields/MoneyTotal";

const BillingReportDetail = ({filtration}) => {
    const [loading,setLoading] = useState(false);
    const [data,setData] = useState([]);
    const [page,setPage] = useState(1);
    const [log,setLog] = useState(false)
    const [sort,setSort] = useState('');
    let history = useHistory();

    useEffect(() => {
        setLoading(true);
        API.post('admin/billingreport/details', {...filtration,...{page:page,perPage:100,sort}}).then((response) => {
            setLog(response.data.data.data)
            setData(response.data.data)
            setLoading(false)
        })
    },[filtration,page,sort])

    const changeData = (name,value) => {
        setPage(value)
    }

    const changeSortData = (name, value) => {
        setSort(value);

        history.push({
            pathname: history.location.pathname,
            search: new URLSearchParams({ ...filtration, [name]: value }).toString(),
        });
    };

    return <>
        {loading && <Loader/>}
        {!loading && log && log.length > 0 &&
            <>
            <div className="table-responsive">
                <div className="row mb-2">
                    <Col md={12}>
                        <div className="d-flex justify-content-between align-items-center">
                            <ListStat data={data} />
                            <div className="d-flex align-items-center">
                            <span className='font-weight-bold'>Detail</span>&nbsp;<span>Report - </span>
                                <CSVDownlaod
                                    url="/admin/billingreport/detailsexport"
                                    filename="BillingReportDetails"
                                    filtration={filtration}
                                />
                            </div>
                        </div>
                    </Col>
                </div>
                <Table>
                    <thead>
                    <tr>
                        <th>Date<SortDirection fieldName={'date'} filters={{sort}} changeFilter={changeSortData}/></th>
                        <th>Product<SortDirection fieldName={'product'} filters={{sort}} changeFilter={changeSortData}/>
                        </th>
                        <th>Price<SortDirection fieldName={'price'} filters={{sort}} changeFilter={changeSortData}/>
                        </th>
                        <th>Lead Id<SortDirection fieldName={'leadid'} filters={{sort}} changeFilter={changeSortData}/>
                        </th>
                        <th>Publisher<SortDirection fieldName={'publisher'} filters={{sort}}
                                                    changeFilter={changeSortData}/></th>
                        <th>Status<SortDirection fieldName={'status'} filters={{sort}} changeFilter={changeSortData}/>
                        </th>
                        <th>Lender<SortDirection fieldName={'lender'} filters={{sort}} changeFilter={changeSortData}/>
                        </th>
                        <th>Order<SortDirection fieldName={'order'} filters={{sort}} changeFilter={changeSortData}/>
                        </th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        log.map((logItem, index) => {
                            return (
                                <tr key={index}>
                                    <td><Datetime dateTime={logItem.date}/></td>
                                    <td><Product product={logItem.product}/></td>
                                    <td><MoneyTotal money={logItem.earntotal}/></td>
                                    <td><LeadID id={logItem.lead}/></td>
                                    <td><Publisher publisher={logItem.publisher}/></td>
                                    <td><PostingStatus status={logItem.status}/></td>
                                    <td><LenderTitle title={logItem.lenderorder.lender.title}
                                                     id={logItem.lenderorder.lender.id}/></td>
                                    <td><Order order={logItem.lenderorder}/></td>
                                    <td><OrderPostingLog id={logItem.id}/></td>
                                </tr>
                            )
                        })
                    }
                    </tbody>
                </Table>
            </div>
                {(data?.page > 0 && data?.pages > 0) &&
                    <Pagination
                        onChange={changeData}
                        name={"page"}
                        page={page}
                        totalPages={data.pages}
                        loading={loading}/>
                }
            </>
        }
        {!loading && log && log.length === 0  &&
            <h3>Data not found</h3>
        }
    </>
}

export default BillingReportDetail;
