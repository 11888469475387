import React, { useEffect, useState } from "react";
import API from "../../utils/API";
import Select from "react-select";

const RCIDByPublisher = ({ name, value = [], onChange, product, publisher }) => {
    const [rcid, setRcid] = useState(null);
    const [filterValue, setFilterValue] = useState([]);
  
    const mapValuesToFilterss = (value = [], rcidList) => {
      return value.map((item) => {
        const matchedFilter = rcidList.find((rcid) => rcid.value == item);
        return matchedFilter || item;
      });
    };
  
    const changeSelect = (value) => {
      let i,
        values = [];
      for (i = 0; i < value?.length; i++) {
        values.push(value[i].value);
      }
      if (name) {
        onChange(name, values);
        const mappedFilters = mapValuesToFilterss(values, rcid);
        setFilterValue(mappedFilters);
      }
    };

    useEffect(() => {
      let data = {};

      if (publisher && publisher.length > 0) {
        data.publisher = publisher;
        API.get("/rcid/"+data.publisher).then((response) => {
          let options,
            i,
            rcidList = [];
          if (response.data.data) {
            options = response.data.data;
            for (i = 0; i < options.length; i++) {
              rcidList.push({value:options[i].id,label:'#' + options[i].id + ', ' + options[i].title})
            }
          }
          const mappedFilters = mapValuesToFilterss(value, rcidList);
          setFilterValue(mappedFilters);
          setRcid(rcidList);
        });
      }else {
        changeSelect(null)
        const mappedFilters = mapValuesToFilterss([], null);
        setFilterValue(mappedFilters);
        setRcid([]);
      }

    }, [publisher]);
  
    return (
      <Select
        className="react-select-container"
        classNamePrefix="react-select"
        options={rcid}
        value={filterValue}
        onChange={(value) => changeSelect(value)}
        isMulti
        placeholder="RCID"
      />
    );
  };

  export default RCIDByPublisher;