import {
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import React, { useState } from "react";

import ByLender from "./types/ByLender";
import ByLenderDate from "./types/ByLenderDate";
import ByOrder from "./types/ByOrder";
import ByOrderDate from "./types/ByOrderDate";
import DateRange from "../../../components/formfields/DateRange";
import LenderSelect from "../../../components/formfields/LenderSelect";
import OrderSelect from "../../../components/formfields/OrderSelect";
import classnames from "classnames";
import dateFormat from "dateformat";
import { parseFilterValues } from "../../../utils/helpers/filterHelper";
import { useHistory } from "react-router-dom";

const LenderPingPostReport = () => {
  const history = useHistory();
  const defaultDate =
    dateFormat(new Date().setDate(new Date().getDate() - 6), "mmm d, yyyy") +
    " to " +
    dateFormat(new Date(), "mmm d, yyyy");
  const defaultFiltrationState = {
    date: defaultDate,
  };
  const urlParams = new URLSearchParams(history.location.search);
  const parsedParams = Object.fromEntries(urlParams.entries());
  const filtersParsed = parseFilterValues(parsedParams);

  const [filtration, setFiltration] = useState({
    ...defaultFiltrationState,
    ...filtersParsed,
  });

  const [activeTab, setActiveTab] = useState("lenderDate");

  const changeData = (name, value) => {
    setFiltration({ ...filtration, [name]: value });

    history.push({
      pathname: history.location.pathname,
      search: new URLSearchParams({ ...filtration, [name]: value }).toString(),
    });
  };

  return (
    <Container fluid className="p-0">
      <h1 className="h3 mb-3">Lender Ping/Post report</h1>
      <Row form>
        <Col md={3}>
          <DateRange
            onChange={changeData}
            name={"date"}
            value={filtration?.date}
          />
        </Col>
        <Col md={3}>
          <LenderSelect
            name={"lender"}
            onChange={changeData}
            value={filtration?.lender}
          />
        </Col>
        <Col md={3}>
          <OrderSelect
            name={"lenderorder"}
            onChange={changeData}
            value={filtration?.lenderorder}
            lender={filtration?.lender}
          />
        </Col>
      </Row>
      <div className={"tab default mt-3"}>
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "lenderDate" })}
              onClick={() => {
                setActiveTab("lenderDate");
              }}
            >
              <strong>Lender/Date</strong>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "orderDate" })}
              onClick={() => {
                setActiveTab("orderDate");
              }}
            >
              <strong>Order/Date</strong>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "lender" })}
              onClick={() => {
                setActiveTab("lender");
              }}
            >
              <strong>Group by lender</strong>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "order" })}
              onClick={() => {
                setActiveTab("order");
              }}
            >
              <strong>Group by order</strong>
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="lenderDate" style={{ minHeight: "100%" }}>
            <ByLenderDate filtration={filtration} />
          </TabPane>
          <TabPane tabId="orderDate" style={{ minHeight: "100%" }}>
            <ByOrderDate filtration={filtration} />
          </TabPane>
          <TabPane tabId="lender" style={{ minHeight: "100%" }}>
            <ByLender filtration={filtration} />
          </TabPane>
          <TabPane tabId="order" style={{ minHeight: "100%" }}>
            <ByOrder filtration={filtration} />
          </TabPane>
        </TabContent>
      </div>
    </Container>
  );
};

export default LenderPingPostReport;
