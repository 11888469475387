import React,{useState,useEffect} from "react";
import API from "../../../utils/API";
import {useHistory} from "react-router-dom";
import {confirmAlert} from "react-confirm-alert";
import {Button, Card, CardBody, Col, Container, Row, Table} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus, faCheck} from "@fortawesome/free-solid-svg-icons";
import {Edit2, Trash} from "react-feather";
import Loader from "../../../components/Loader";
import Product from "../../../components/fields/Product";
import {parseFilterValues} from "../../../utils/helpers/filterHelper";
import ProductMulti from "../../../components/formfields/ProductMulti";
import SortDirection from "../../../components/SortDirection";

const Returnreasons = (props) => {

    const [reasons,setReasons] = useState(null)

    const history = useHistory();
    const defaultFiltrationState = {};

    const urlParams = new URLSearchParams(history.location.search);
    const parsedParams = Object.fromEntries(urlParams.entries());
    const filtersParsed = parseFilterValues(parsedParams);

    const [filtration, setFiltration] = useState({
        ...defaultFiltrationState,
        ...filtersParsed,
    });

    const [loading, setLoading] = useState(true);

    const changeData = (name, value) => {
        setFiltration({ ...filtration, [name]: value });

        history.push({
            pathname: history.location.pathname,
            search: new URLSearchParams({ ...filtration, [name]: value }).toString(),
        });
    };

    const newReason = () => {
        history.push("/settings/returnreason/item")
    }

    const editReason = (id) => {
        history.push("/settings/returnreason/item/"+id)
    }

    const deleteReason = (id) => {
        confirmAlert({
            title: 'Confirm to delete',
            message: 'Are you sure you want to delete this return reason?',
            buttons: [
                {
                    label: 'Yes',
                    className: 'btn btn-danger',
                    onClick: () => {
                        API.delete('/admin/returnreason/'+id).then((response) => {
                            loadReasons()
                        })
                    }
                },
                {
                    label: 'No',
                    className: 'btn btn-primary'
                }
            ]
        });
    }

    const loadReasons = () => {
        setLoading(true);
        API.post('/admin/returnreason/all',filtration).then((response) => {
            setReasons(response.data.data);
            setLoading(false);
        })
    }

    useEffect(()=>{
        loadReasons()
    },[filtration])

    return <Container fluid className="p-0">
        <Button color="primary" onClick={newReason} className="float-right mt-n1">
            <FontAwesomeIcon icon={faPlus}/> New return reason
        </Button>
        <h1 className="h3 mb-3">Return reasons</h1>
        <Row form className={"mb-2"}>
            <Col md={3}>
                <ProductMulti
                    name={"product"}
                    onChange={changeData}
                    value={filtration?.product}
                />
            </Col>
            <Col md={3}>
            <input
                placeholder="Search by keyword"
                className="form-control"
                id="keyword"
                name="keyword"
                value={filtration.keyword}
                onChange={(e) => changeData(e.target.name, e.target.value)}
            />
            </Col>
        </Row>
        {loading && <Loader/>}

        {!loading && reasons && reasons.length > 0 &&
            <div className="table-responsive">
                <Table>
                    <thead>
                    <tr>
                        <th>Title<SortDirection fieldName={'title'} filters={filtration} changeFilter={changeData}/></th>
                        <th>Product</th>
                        <th className="text-center">Internal<SortDirection fieldName={'isinternal'} filters={filtration} changeFilter={changeData}/></th>
                        <th>Date add<SortDirection fieldName={'dateadd'} filters={filtration} changeFilter={changeData}/></th>
                        <th>Date update<SortDirection fieldName={'dateupdate'} filters={filtration} changeFilter={changeData}/></th>
                        <th>Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        reasons.map((reason, index) => {
                            return (
                                <tr key={index}>
                                    <td><strong>{reason.title}</strong></td>
                                    <td>{reason.products &&
                                        reason.products.map((product, key) => {
                                            return <Product product={product} key={key}/>
                                        })
                                    }</td>
                                    <td className="text-center">{parseInt(reason.internal) === 1 &&
                                        <FontAwesomeIcon icon={faCheck} className="text-success"/>
                                    }</td>
                                    <td>{reason.dateadd}</td>
                                    <td>{reason.dateupdate}</td>
                                    <td className="table-action">
                                        <Edit2 onClick={() => {
                                            editReason(reason.id)
                                        }} className="align-middle mr-1 cursor-pointer text-primary"
                                               size={18}/>
                                        <Trash onClick={() => {
                                            deleteReason(reason.id)
                                        }} className="cursor-pointer align-middle text-danger" size={18}/>
                                    </td>
                                </tr>
                            )
                        })
                    }
                    </tbody>
                </Table>
            </div>
        }
    </Container>
}

export default Returnreasons
