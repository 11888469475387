import React from "react";
import {
    Table
} from "reactstrap";
import Datetime from "../../../components/fields/Datetime";
import MoneyTotal from "../../../components/fields/MoneyTotal";
import MoneyPub from "../../../components/fields/MoneyPub";
import PostingStatus from "../../../components/status/PostingStatus";
import Order from "../../../components/fields/Order";
import LenderTitle from "../../../components/fields/LenderTitle";
import OrderPostingLog from "../../../components/fields/OrderPostingLog";


const LeadPostLog = ({postlog,isPing}) => {
    return (
        <>
            {postlog.length > 0 &&
            <div className="table-responsive">
                <Table>
                    <thead>
                    <tr>
                        <th>Date</th>
                        <th>Status</th>
                        <th>Order</th>
                        <th>Lender</th>
                        <th>Publisher earning</th>
                        <th>Total earning</th>
                        <th>Comment</th>
                        {!isPing&&<th>Return reason</th>}
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        postlog.map((log, index) => {
                            return (
                                <tr key={index}>
                                    <td><Datetime dateTime={log.date}/></td>
                                    <td><PostingStatus status={log.status}/></td>
                                    <td><Order order={log.lenderorder}/></td>
                                    <td><LenderTitle title={log.lenderorder.lender.title} id={log.lenderorder.lender.id}/></td>
                                    {isPing ?
                                        <>
                                            <td><MoneyPub money={log.earnpub}/></td>
                                            <td><MoneyTotal money={log.earntotal}/></td>
                                        </>
                                        :
                                        <>
                                            <td><MoneyPub money={log.earnpub} moneyOriginal={log.earnpuboriginal}/></td>
                                            <td><MoneyTotal money={log.earntotal} moneyOriginal={log.earntotaloriginal}/></td>
                                        </>
                                    }
                                    <td>{log.comment}</td>
                                    {!isPing&&<td>{log.returnreason}</td>}
                                    <td>
                                        <OrderPostingLog id={log.id} isPing={isPing}/>
                                    </td>
                                </tr>
                            )
                        })
                    }
                    </tbody>
                </Table>
            </div>
            }
        </>
    )
}

export default LeadPostLog
