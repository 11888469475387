import React,{useState,useEffect} from "react";
import API from "../../../utils/API";

const Duplicate = ({title,type,elementId,onClose}) => {

    const [days, setDays] = useState(0)

    useEffect(() => {
        API.post('/admin/filter',{elementId:elementId,type:type,filterTitle:title}).then((response) => {
            if (response.data.data.data) {
                if (response.data.data.data.days) {
                    setDays(response.data.data.data.days)
                }
            }
        })
    }, [title,type])

    const saveFilter = () => {
        API.put('/admin/filter',{elementId:elementId,type:type,filterTitle:title,data:{days:days}}).then((response) => {
            onClose()
        });
    }

    return (
        <>
            <div className="row">
                <div className="col pt-2">
                    <h5>Filter by: {title}</h5>
                </div>
                <div className="col-auto text-right">
                    <button onClick={onClose} className="btn btn-outline-secondary mb-2">X</button><br/>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <select className="form-control" value={days} onChange={(e)=>setDays(e.target.value)}>
                        <option value={0}>Off</option>
                        <option value={1}>1 day</option>
                        <option value={2}>2 days</option>
                        <option value={3}>3 days</option>
                        <option value={4}>4 days</option>
                        <option value={5}>5 days</option>
                        <option value={6}>6 days</option>
                        <option value={7}>7 days</option>
                        <option value={8}>8 days</option>
                        <option value={9}>9 days</option>
                        <option value={10}>10 days</option>
                        <option value={11}>11 days</option>
                        <option value={12}>12 days</option>
                        <option value={13}>13 days</option>
                        <option value={14}>14 days</option>
                        <option value={15}>15 days</option>
                        <option value={30}>30 days</option>
                        <option value={45}>45 days</option>
                        <option value={60}>60 days</option>
                        <option value={90}>90 days</option>
                        <option value={120}>120 days</option>
                    </select>
                </div>
            </div>
            <div className="mt-3 text-center">
                <button className="btn btn-primary" onClick={saveFilter}>Save</button>
            </div>
        </>
    )
}

export default Duplicate;