import { Card, CardBody, Container, Table } from "reactstrap";
import React, { useEffect, useState } from "react";

import API from "../../../utils/API";
import CapInput from "./CapInput";
import LenderSelect from "../../../components/formfields/LenderSelect";
import LenderTitle from "../../../components/fields/LenderTitle";
import Loader from "../../../components/Loader";
import Order from "../../../components/fields/Order";
import OrderStatus from "../../../components/status/OrderStatus";
import OrderStatusSelect from "../../../components/formfields/OrderStatusSelect";
import Product from "../../../components/fields/Product";
import ProductMulti from "../../../components/formfields/ProductMulti";
import { parseFilterValues } from "../../../utils/helpers/filterHelper";
import { toastr } from "react-redux-toastr";
import { useHistory } from "react-router-dom";
import PriceInput from "./PriceInput";
import StatusInput from "./StatusInput";

const CapManagement = () => {
  const history = useHistory();

  const urlParams = new URLSearchParams(history.location.search);
  const parsedParams = Object.fromEntries(urlParams.entries());
  const filtersParsed = parseFilterValues(parsedParams);

  const [filtration, setFiltration] = useState({
    ...filtersParsed,
  });

  const [orders, setOrders] = useState(null);
  const [loading, setLoading] = useState(true);

  const [allOverallCap, setAllOverallCap] = useState(null);
  const [saveData, setSaveData] = useState(null);

  const [changedData, setChangedData] = useState([]);

  const changeData = (name, value) => {
    setFiltration({ ...filtration, [name]: value });

    history.push({
      pathname: history.location.pathname,
      search: new URLSearchParams({ ...filtration, [name]: value }).toString(),
    });
  };

  const updateChangedData = (orderId, filterType) => {
    let index = orderId + filterType;
    let currentChangedData = [...changedData];
    if (!currentChangedData.includes(index)) {
      currentChangedData.push(index);

      setChangedData(currentChangedData);
    }
  };

  const removeChangedData = (orderId, filterType) => {
    let index = orderId + filterType;
    setChangedData((prevState) =>
      prevState.filter((prevItem) => prevItem !== index)
    );
  };

  useEffect(() => {
    setLoading(true);
    API.post("/admin/capmanagement/orders", filtration).then((response) => {
      setOrders(response.data.data);
      setLoading(false);
      setChangedData([]);
    });
  }, [filtration]);

  useEffect(() => {
    if (saveData && changedData.length === 0) {
      setSaveData(false);
      toastr.success("Success", "Cap was successfully uploaded");
    }
  }, [saveData, changedData]);

  return (
    <Container fluid className="p-0">
      <h1 className="h3 mb-3">Cap management</h1>
      <div className="row mb-2">
        <div className="col-3">
          <ProductMulti
            name={"product"}
            onChange={changeData}
            value={filtration?.product}
          />
        </div>
        <div className="col-3">
          <LenderSelect
            name={"lender"}
            onChange={changeData}
            value={filtration?.lender}
          />
        </div>
        <div className="col-3">
          <OrderStatusSelect
            name={"status"}
            onChange={changeData}
            value={filtration?.status}
          />
        </div>
        <div className="col-3">
          <input
            type="text"
            className="form-control"
            placeholder="Keyword"
            value={filtration?.keyword}
            onChange={(e) => changeData("keyword", e.target.value)}
          />
        </div>
      </div>
      {changedData && changedData.length > 0 && (
        <div
          className="mb-2 mt-2"
          style={{
            position: "sticky",
            top: "10px",
            right: "10px",
            zIndex: 1000,
          }}
        >
          <button className="btn btn-primary" onClick={() => setSaveData(true)}>
            Save changes, {changedData.length}
          </button>
        </div>
      )}
      {loading && <Loader />}
      {!loading && orders && orders.length > 0 ? (
        <Card className="flex-fill w-100">
          <div className="table-responsive">
            <Table striped className="my-0">
              <thead>
                <tr>
                  <th>Id</th>
                  <th>Title</th>
                  <th>Lender</th>
                  <th>Product</th>
                  <th>Price</th>
                  <th>Status</th>
                  <th>Leads</th>
                  <th>Overall Order Cap</th>
                  <th>Daily Cap</th>
                  <th>Monthly Cap</th>
                </tr>
              </thead>
              <tbody>
                {orders.map((data) => {
                  return (
                    <tr>
                      <td>{data.lenderorder.id}</td>
                      <td>
                        <Order order={data.lenderorder} tags={true} />
                      </td>
                      <td>
                        <LenderTitle
                          title={data.lender.title}
                          id={data.lender.id}
                        />
                      </td>
                      <td>
                        <Product product={data.product} />
                      </td>
                      <td>
                        <PriceInput
                            updateChangedData={updateChangedData}
                            removeChangedData={removeChangedData}
                            orderId={data.lenderorder.id}
                            currentPrice={data.lenderorder?.price??''}
                            save={saveData}
                        />
                      </td>
                      <td>
                        <StatusInput
                            updateChangedData={updateChangedData}
                            removeChangedData={removeChangedData}
                            orderId={data.lenderorder.id}
                            currentStatus={data.lenderorder?.status}
                            save={saveData}
                        />
                      </td>
                      <td>
                        <nobr>
                          <span className="small">
                            Overall: {data?.caplenderordercache?.matchedoverall}
                          </span>
                        </nobr>
                        <br />
                        <nobr>
                          <span className="small">
                            Today: {data?.caplenderordercache?.matchedtoday}
                          </span>
                        </nobr>
                        <br />
                        <nobr>
                          <span className="small">
                            Calendar Months:{" "}
                            {data?.caplenderordercache?.matchedcurrentmonths}
                          </span>
                        </nobr>
                        <br />
                      </td>
                      <td>
                        <CapInput
                          updateChangedData={updateChangedData}
                          removeChangedData={removeChangedData}
                          orderId={data.lenderorder.id}
                          capFilter={data.lenderorderfiltercap}
                          capStat={data?.caplenderordercache?.matchedoverall}
                          saveCap={saveData}
                          setCap={allOverallCap}
                          capType={"Overall Order Cap"}
                        />
                      </td>
                      <td>
                        <CapInput
                          updateChangedData={updateChangedData}
                          removeChangedData={removeChangedData}
                          orderId={data.lenderorder.id}
                          capFilter={data.lenderorderfiltercapday}
                          capStat={data?.caplenderordercache?.matchedtoday}
                          saveCap={saveData}
                          setCap={allOverallCap}
                          capType={"Cap Per Day"}
                        />
                      </td>
                      <td>
                        <CapInput
                          updateChangedData={updateChangedData}
                          removeChangedData={removeChangedData}
                          orderId={data.lenderorder.id}
                          capFilter={data.lenderorderfiltercapmonths}
                          capStat={
                            data?.caplenderordercache?.matchedcurrentmonths
                          }
                          saveCap={saveData}
                          setCap={allOverallCap}
                          capType={"Cap Per Month"}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </Card>
      ) : (
        !loading && (
          <Card>
            <CardBody className="text-center">
              <h5>Data not found</h5>
            </CardBody>
          </Card>
        )
      )}
    </Container>
  );
};

export default CapManagement;
