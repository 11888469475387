import React from "react";
import { Table } from "reactstrap";
import { infutorPhoneTypes } from "../../utils/constants";
import upperFirst from "lodash/upperFirst";

const InfutorScores = ({ infutorScrubber = {} }) => {
  const scrubberData = infutorScrubber?.data
    ? JSON.parse(infutorScrubber.data)
    : {};

  return (
    <>
      <div className="table-responsive">
        <Table>
          <thead>
            <tr>
              <th>Field Name</th>
              <th> Value</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="tab__ewak-score__bold">Score Values</td>
              <td></td>
            </tr>
            <tr>
              <td className="tab__ewak-score__bold">ID Verify Score</td>
              <td>
                {scrubberData?.idscorerequest?.detail?.idscores?.idverifyscore}
              </td>
            </tr>
            <tr>
              <td className="tab__ewak-score__bold">Valid Address Score</td>
              <td>
                {scrubberData?.idscorerequest?.detail?.idscores?.validaddress}
              </td>
            </tr>
            <tr>
              <td className="tab__ewak-score__bold">Valid Phone Score</td>
              <td>
                {scrubberData?.idscorerequest?.detail?.idscores?.validphone}
              </td>
            </tr>
            <tr>
              <td className="tab__ewak-score__bold">Valid Email Score</td>
              <td>
                {scrubberData?.idscorerequest?.detail?.idscores?.validemail}
              </td>
            </tr>
            <tr>
              <td className="tab__ewak-score__bold">Valid Name Score</td>
              <td>
                {scrubberData?.idscorerequest?.detail?.idscores?.validname}
              </td>
            </tr>
            <tr>
              <td className="tab__ewak-score__bold"> Name-To-Phone Score</td>
              <td>
                {scrubberData?.idscorerequest?.detail?.idscores?.nametophone}
              </td>
            </tr>
            <tr>
              <td className="tab__ewak-score__bold"> Name-To-Email Score</td>
              <td>
                {scrubberData?.idscorerequest?.detail?.idscores?.nametoemail}
              </td>
            </tr>
            <tr>
              <td className="tab__ewak-score__bold"> Name-To-Address Score</td>
              <td>
                {scrubberData?.idscorerequest?.detail?.idscores?.nametoaddress}
              </td>
            </tr>
            <tr>
              <td className="tab__ewak-score__bold"> Address-To-Phone Score</td>
              <td>
                {scrubberData?.idscorerequest?.detail?.idscores?.addresstophone}
              </td>
            </tr>
            <tr>
              <td className="tab__ewak-score__bold"> Address-To-Email Score</td>
              <td>
                {scrubberData?.idscorerequest?.detail?.idscores?.addresstoemail}
              </td>
            </tr>
            <tr>
              <td className="tab__ewak-score__bold"> Phone-To-Email Score</td>
              <td>
                {scrubberData?.idscorerequest?.detail?.idscores?.phonetoemail}
              </td>
            </tr>
            <tr>
              <td className="tab__ewak-score__bold"> Phone Type</td>
              <td>
                {infutorPhoneTypes[
                  scrubberData?.idscorerequest?.detail?.raw
                    ?.phoneowneridvalidation?.phone?.phonetype
                ] || ""}
              </td>
            </tr>
            <tr>
              <td className="tab__ewak-score__bold">
                Property (Attribute) Values
              </td>
              <td></td>
            </tr>
            <tr>
              <td className="tab__ewak-score__bold">Property Type</td>
              <td>{scrubberData?.parseddatascore?.property_type || "N/A"}</td>
            </tr>
            <tr>
              <td className="tab__ewak-score__bold">Property Living Sqft</td>
              <td>
                {scrubberData?.parseddatascore?.property_living_sqft
                  ? `${Number(
                      scrubberData?.parseddatascore?.property_living_sqft
                    ).toFixed(2)} sq ft`
                  : "N/A"}
              </td>
            </tr>
            <tr>
              <td className="tab__ewak-score__bold">Home Owner</td>
              <td>{scrubberData?.parseddatascore?.home_owner || "N/A"}</td>
            </tr>
            <tr>
              <td className="tab__ewak-score__bold">Market Home Value</td>
              <td>{scrubberData?.parseddatascore?.market_home_val || "N/A"}</td>
            </tr>
            <tr>
              <td className="tab__ewak-score__bold">FICO/Income Values</td>
              <td></td>
            </tr>
            <tr>
              <td className="tab__ewak-score__bold">Purchase Power</td>
              <td>{scrubberData?.parseddatascore?.purchase_power || "N/A"}</td>
            </tr>
            <tr>
              <td className="tab__ewak-score__bold">
                Estimated Household Income
              </td>
              <td>
                {scrubberData?.parseddatascore?.est_household_income || "N/A"}
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
    </>
  );
};

export default InfutorScores;
