import IsDataFeed from "./IsDataFeed";
import IsPingPost from "./IsPingPost";
import { Link } from "react-router-dom";
import React from "react";

const Order = ({ order, tags }) => {
  return (
    <Link to={"/lender/order/item/" + order.id}>
      {order.title}{" "}
      {tags && (
        <>
          <IsPingPost order={order} /> <IsDataFeed order={order} />
        </>
      )}
    </Link>
  );
};

export default Order;
