import React from "react";
import {Badge} from "reactstrap";

const PixelType = ({type}) => {
    if (type === "Lead submit") {
        return <Badge color="primary" className="mr-1 mb-1 badge-pill">{type}</Badge>
    }else if (type === "Page view") {
        return <Badge color="info" className="mr-1 mb-1 badge-pill">{type}</Badge>
    }else if (type === "Lead submit, post back") {
        return <Badge color="warning" className="mr-1 mb-1 badge-pill">{type}</Badge>
    }else if (type === "Page view, post back") {
        return <Badge color="secondary" className="mr-1 mb-1 badge-pill">{type}</Badge>
    }

    return type;
};

export default PixelType;
