import React, {useEffect, useState, useMemo, useRef} from "react";
import {
    Breadcrumb,
    BreadcrumbItem,
    Button, Card,
    CardBody,
    Container,
    FormGroup,
    Label, Modal, ModalBody, ModalFooter, ModalHeader, Spinner,
} from "reactstrap";
import {
    AvForm,
    AvInput
} from "availity-reactstrap-validation";
import API from "../../../utils/API";
import {Link, useHistory} from "react-router-dom";
import {toastr} from "react-redux-toastr";
import JoditEditor from 'jodit-react';

const PageContent = (props) => {
    const [page, setPage] = useState({title:null,slug:null,content:null});
    const [error, setError] = useState(null)
    const editor = useRef(null);
    const [saving,setSaving] = useState(false)
    const [modalshow,setModalshow] = useState(false)

    let history = useHistory();

    const handleChange = (e) => {
        setPage({ ...page, [e.target.name]: e.target.value })
        setError(null)
    };

    useEffect(()=>{
        if (props.match.params.id) {
            API.get('/admin/landingpagecontent/item/'+props.match.params.id+(props.match.params.reverse?"/"+props.match.params.reverse:"")).then((response) => {
                setPage(response.data.data)
            })
        }
    },[])

    const submit = () => {
        setSaving(true)
        if (props.match.params.id) {
            API.put('/admin/landingpagecontent/'+props.match.params.id,page)
                .then((response) => {
                    setSaving(false)
                    setModalshow(false)
                    toastr.success('Success', 'Content was successfully saved')
                    history.push("/settings/landingpagecontent")
                })
                .catch(error => {
                    setError('Invalid data')
                    setSaving(false)
                    setModalshow(false)
                })
        }else {
            API.post('/admin/landingpagecontent',page)
                .then((response) => {
                    setSaving(false)
                    setModalshow(false)
                    toastr.success('Success', 'Content was successfully saved')
                    history.push("/settings/landingpagecontent")
                })
                .catch(error => {
                    setSaving(false)
                    setError('Invalid data')
                    setModalshow(false)
                })

        }
    }

    return (
        <Container fluid className="p-0">
            <Breadcrumb>
                <BreadcrumbItem>
                    <Link to="/settings/landingpagecontent">Landing page content</Link>
                </BreadcrumbItem>
                <BreadcrumbItem active>{props.match.params.id?'Edit':'New'} Content</BreadcrumbItem>
            </Breadcrumb>
            <Card>
                <CardBody>
                    {error &&
                        <div className="text-center text-danger">{error}</div>
                    }
                    <AvForm onValidSubmit={()=>setModalshow(true)}>
                        <FormGroup>
                            <Label>Title</Label>
                            <AvInput onChange={handleChange} required type="text" name="title" value={page.title} placeholder="Content title" />
                        </FormGroup>
                        <FormGroup>
                            <Label>Slug</Label>
                            <AvInput onChange={handleChange} required type="text" name="slug" value={page.slug} placeholder="Content slug" />
                        </FormGroup>
                        <FormGroup>
                            <JoditEditor
                                ref={editor}
                                value={page.content}
                                tabIndex={1}
                                onBlur={newContent => setPage({ ...page, ['content']: newContent })}
                                onChange={newContent => {}}
                            />
                        </FormGroup>
                        <div className="text-center">
                            <Button color="primary">Save</Button>
                        </div>
                    </AvForm>
                </CardBody>
            </Card>
            <React.Fragment>
                <Modal
                    isOpen={modalshow}
                    toggle={() => setModalshow(!modalshow)}
                    size="lg"
                    style={{maxWidth: '600px', width: '600px'}}
                >
                    <ModalHeader toggle={() => setModalshow(!modalshow)}>
                        Save changes
                    </ModalHeader>
                    <ModalBody style={{backgroundColor:"#F7F9FC"}}>
                        <h3>Are you sure you want to save these changes?</h3>
                        <label>Memo (not required)</label>
                        <textarea rows={5} className="form-control" name="note" onChange={handleChange} value={page.note}/>
                    </ModalBody>
                    <ModalFooter className="justify-content-center">
                        {saving ?
                            <Spinner color="primary" />
                            :
                            <Button color="secondary" onClick={() => submit()}>
                                Save
                            </Button>
                        }
                    </ModalFooter>
                </Modal>
            </React.Fragment>
        </Container>
    )
};

export default PageContent;
