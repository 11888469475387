import React from "react";
import ReactPaginate from "react-paginate";

const Pagination = ({ name, page = 1, totalPages, onChange, loading }) => {
  const changePage = (page) => {
    onChange(name, page.selected + 1);
  };

  return (
    <>
      {totalPages > 1 && (
        <div className="row justify-content-center">
          <div className={`col-auto ${!loading ? "d-block" : "d-none"}`}>
            <ReactPaginate
              breakLabel="..."
              nextLabel=">"
              onPageChange={changePage}
              pageRangeDisplayed={5}
              forcePage={page - 1}
              pageCount={totalPages}
              previousLabel="<"
              renderOnZeroPageCount={null}
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              breakClassName="page-item"
              breakLinkClassName="page-link"
              containerClassName="pagination"
              activeClassName="active"
            />
          </div>
        </div>
      )}
    </>
  );
};

export default Pagination;
