import React, {useEffect, useState} from "react";
import {
    Breadcrumb,
    BreadcrumbItem,
    Button,
    Card,
    CardBody,
    Container,
    FormGroup,
    Input,
    Label,
    Modal, ModalBody, ModalFooter, ModalHeader, Spinner
} from "reactstrap";
import API from "../../utils/API";
import {toastr} from "react-redux-toastr";
import {AvField, AvForm, AvInput} from "availity-reactstrap-validation";
import {Link, useHistory} from "react-router-dom";
import Product from "../../components/formfields/Product";
import Publisher from "../../components/formfields/Publisher";
import PixelType from "../../components/formfields/PixelType";
import PixelStatus from "../../components/formfields/PixelStatus";
import insertTextAtCursor from 'insert-text-at-cursor'

const PixelForm = (props) => {
    const [pixel, setPixel] = useState({title:null,publisher:null,product:null,pixeltype:null,status:null,content:null})
    const [error, setError] = useState(null)
    let history = useHistory()

    const [customParamModalShow, setCustomParamModalShow] = useState(false);
    const [customParameter, setCustomParameter] = useState(null)

    const pasteCustomParameter = () => {
        if (customParameter && customParameter.length > 0) {
            insertTextAtCursor(document.getElementById('pixelEditor'), `{{${customParameter}}}`);
            setCustomParameter(null);
            setCustomParamModalShow(false);
        }
    }

    const handleChange = (e) => {
        setPixel({ ...pixel, [e.target.name]: e.target.value })
        setError(null)
    }

    useEffect(() => {
        API.get('/admin/pixel/item/'+props.match.params.id).then((response) => {
            if (response.data.data) {
                setPixel(response.data.data)
            }
        });
    }, [props.match.params.id])

    const submit = () => {
        API.put(props.match.params.id?'/admin/pixel/item/'+props.match.params.id:'/admin/pixel/item',pixel)
            .then((response) => {
                toastr.success('Success', 'Pixel was successfully saved')
                history.push("/publisher/pixel/item/"+response.data.data.id)
            })
            .catch(error => {
                setError('Invalid pixel data')
            })
    }

    return (
        <Container fluid className="p-0">
            <Breadcrumb>
                <BreadcrumbItem>
                    <Link to="/publisher/pixel">Pixels</Link>
                </BreadcrumbItem>
                {(props.match.params.id && pixel) &&
                    <BreadcrumbItem>
                        <Link to={"/publisher/pixel/item/"+pixel.id}>{pixel.title}</Link>
                    </BreadcrumbItem>
                }
                <BreadcrumbItem active>{props.match.params.id?"Edit":"New"} pixel</BreadcrumbItem>
            </Breadcrumb>
            <Card>
                <CardBody>
                    {error &&
                        <div className="text-center text-danger">{error}</div>
                    }
                    <AvForm onValidSubmit={submit}>
                        <FormGroup>
                            <Label>Title</Label>
                            <AvInput onChange={handleChange} required type="text" name="title" value={pixel.title} placeholder="Pixel title" />
                        </FormGroup>
                        <FormGroup>
                            <Label>Publisher</Label>
                            <Publisher value={pixel.publisher} required={true} name={'publisher'} onChange={handleChange}/>
                        </FormGroup>
                        <FormGroup>
                            <Label>Product</Label>
                            <Product value={pixel.product} required={true} name={'product'} onChange={handleChange}/>
                        </FormGroup>
                        <FormGroup>
                            <Label>Pixel type</Label>
                            <PixelType value={pixel.pixeltype} required={true} name={'pixeltype'} onChange={handleChange}/>
                        </FormGroup>
                        <FormGroup>
                            <Label>Pixel status</Label>
                            <PixelStatus value={pixel.status} required={true} name={'status'} onChange={handleChange}/>
                        </FormGroup>
                        <FormGroup>
                            <Label>Pixel</Label>
                            <div className="row mb-1 ml-1">
                                <div className="col-auto m-0 p-0 mr-1">
                                    <button type="button" className="btn btn-secondary" onClick={() => {
                                        insertTextAtCursor(document.getElementById('pixelEditor'), '{{leadid}}')
                                    }}>Lead Id
                                    </button>
                                </div>
                                <div className="col-auto m-0 p-0 mr-1">
                                    <button type="button" className="btn btn-secondary" onClick={() => {
                                        insertTextAtCursor(document.getElementById('pixelEditor'), '{{subid}}')
                                    }}>Sub Id
                                    </button>
                                </div>
                                <div className="col-auto m-0 p-0 mr-1">
                                    <button type="button" className="btn btn-secondary" onClick={() => {
                                        insertTextAtCursor(document.getElementById('pixelEditor'), '{{cake}}')
                                    }}>Cake Id
                                    </button>
                                </div>
                                <div className="col-auto m-0 p-0 mr-1">
                                    <button type="button" className="btn btn-secondary" onClick={() => {
                                        insertTextAtCursor(document.getElementById('pixelEditor'), '{{price}}')
                                    }}>Price
                                    </button>
                                </div>
                                <div className="col-auto m-0 p-0">
                                    <button type="button" className="btn btn-secondary" onClick={() => setCustomParamModalShow(true)}>Custom tracking parameter</button>
                                </div>
                            </div>
                            <AvInput id="pixelEditor" onChange={handleChange} required type="textarea" name="content"
                                     value={pixel.content} rows={4} placeholder="Pixel content"/>
                        </FormGroup>
                        <div className="text-center">
                            <Button color="primary">Save</Button>
                        </div>
                    </AvForm>
                </CardBody>
            </Card>

            <React.Fragment>
                <Modal
                    isOpen={customParamModalShow}
                    toggle={() => setCustomParamModalShow(!customParamModalShow)}
                >
                    <ModalHeader toggle={() => setCustomParamModalShow(!customParamModalShow)}>
                        Add custom tracking parameter
                    </ModalHeader>
                    <ModalBody style={{ backgroundColor: "#F7F9FC" }}>
                        <label>Parameter name</label>
                        <input type="text" className="form-control" onChange={(e)=>setCustomParameter(e.target.value)} value={customParameter}/>
                    </ModalBody>
                    <ModalFooter className="justify-content-center">
                        <Button color="secondary" onClick={() => pasteCustomParameter()}>
                            Add
                        </Button>
                    </ModalFooter>
                </Modal>
            </React.Fragment>
        </Container>
    )
}

export default PixelForm
