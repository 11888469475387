import React,{useState,useEffect} from "react";
import API from "../../../utils/API";
import {Link} from "react-router-dom";
import {Breadcrumb, BreadcrumbItem, Card, CardBody, Container, Table} from "reactstrap";
import Loader from "../../../components/Loader";
import ListStat from "../../../components/ListStat";
import LeadID from "../../../components/fields/LeadID";
import Datetime from "../../../components/fields/Datetime";
import LeadStatus from "../../../components/status/LeadStatus";
import Product from "../../../components/fields/Product";
import Publisher from "../../../components/fields/Publisher";
import MoneyTotal from "../../../components/fields/MoneyTotal";
import MoneyPub from "../../../components/fields/MoneyPub";
import Pagination from "../../../components/Pagination";
import {toastr} from "react-redux-toastr";
import {AlertCircle} from "react-feather";
import Lender from "../../../components/fields/Lender";
import Order from "../../../components/fields/Order";

const RevenueUploadLogItem = (props) => {

    const [filtration,setFiltration] = useState({page:1,perPage:100})
    const [loading, setLoading] = useState(true)
    const [data,setData] = useState(null)
    const [upload,setUpload] = useState(null)
    const [items,setItems] = useState(null)

    const changeData = (name,value) => {
        setFiltration({ ...filtration, [name]: value })
    }

    useEffect(()=>{
        const queryParameters = new URLSearchParams(window.location.search)
        if (queryParameters.get("new") && queryParameters.get("new") === 'true') {
            toastr.success("Success","Revenue was successfully uploaded");
        }
        API.get('/tools/revenueupload/log/'+props?.match?.params?.id).then((response) => {
            setUpload(response.data.data)
        });
    },[])

    useEffect(() => {
        setLoading(true)
        API.post('/tools/revenueupload/logleads/'+props?.match?.params?.id,filtration).then((response) => {
            setItems(response.data.data.data)
            setData(response.data.data)
            setLoading(false)
        });
    }, [filtration])

    return <Container fluid className="p-0">
        <Breadcrumb>
            <BreadcrumbItem>
                <Link to="/tools/revenueupload">Revenue upload</Link>
            </BreadcrumbItem>
            <BreadcrumbItem>
                <Link to="/tools/revenueupload/log">Revenue upload log</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>Upload {props?.match?.params?.id}, details</BreadcrumbItem>
        </Breadcrumb>
        {upload &&
            <>
                <h5>Upload details</h5>
                <Card className="mt-2 p-2">
                    <div className="row">
                        <div className="col-2">
                            <label>Date:</label>
                            <div><strong>{upload.dateadd}</strong></div>
                        </div>
                        <div className="col-3">
                            <label>Lender:</label>
                            <div><strong><Lender lender={upload.lender}/></strong></div>
                        </div>
                        <div className="col-2">
                            <label>Performed by:</label>
                            <div><strong>{upload.user.name}</strong></div>
                        </div>
                    </div>
                    {(upload.memotext && upload.memotext.length > 0) &&
                        <div className="mt-3">
                            <AlertCircle size={14} color={"red"}/> {upload.memotext}
                        </div>
                    }
                </Card>
            </>
        }
        <h5>Affected leads</h5>
        <Card className="mt-2">
            {loading &&
                <Loader absolute={true}/>
            }
            {items?.length > 0 ?
                <>
                    <div className="table-responsive">
                        <ListStat data={data}/>
                        <Table>
                            <thead>
                            <tr>
                                <th>Lead</th>
                                <th>Date</th>
                                <th>Status</th>
                                <th>Product</th>
                                <th>Publisher</th>
                                <th>Order</th>
                                <th>Total revenue</th>
                                <th>Publisher revenue</th>
                                <th>Previous total revenue</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                items.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td><LeadID id={item.lead.id}/></td>
                                            <td><Datetime dateTime={item.lead.dateadd}/></td>
                                            <td><LeadStatus status={item.lead.status}/></td>
                                            <td><Product product={item.product}/></td>
                                            <td><Publisher publisher={item.publisher}/></td>
                                            <td><Order order={item.lenderorder}/></td>
                                            <td><MoneyTotal money={item.lenderposting.earntotal} showZero={true}/></td>
                                            <td><MoneyPub money={item.lenderposting.earnpub} showZero={true}/></td>
                                            <td><MoneyTotal money={item.revenueuploadlog.originalamount} showZero={true}/></td>
                                        </tr>
                                    )
                                })
                            }
                            </tbody>
                        </Table>
                    </div>
                </>
                :
                !loading &&
                <CardBody className="text-center"><h5>Data not found</h5></CardBody>
            }
        </Card>
        {(data?.page > 0 && data?.pages > 0) &&
            <Pagination
                onChange={changeData}
                name={"page"}
                page={data.page}
                totalPages={data.pages}/>
        }
    </Container>
}

export default RevenueUploadLogItem
