import React, {useEffect, useState} from "react";
import {Label} from "reactstrap";
import API from "../../utils/API";
import Loader from "../../components/Loader";

const SettingsForm = (props) => {
    const [settings, setSettings] = useState({})
    const [defaultSettings, setDefaultSettings] = useState({})
    const [loaded, setLoaded] = useState(false)
    const [tokenRequirements,setTokenRequirements] = useState(null)

    useEffect(() => {
        API.get('/system/token-requirements').then((response) => {
            setTokenRequirements(response.data.data)
        });
    }, [])

    const handleChange = (e) => {
        setSettings({...settings, [e.target.name]: e.target.value})
    }

    const changeData = (name, value) => {
        setSettings({...settings, [name]: value})
    }

    const handleCheckboxChange = (e) => {
        setSettings({...settings, [e.target.name]: e.target.checked ? e.target.value : null})
    }

    useEffect(() => {
        if (props.id) {
            API.get('/admin/publisher/settings/' + props.id).then((response) => {
                setDefaultSettings(response.data.data)
                setSettings(response.data.data)
                setLoaded(true)
            });
        }
    }, [props.id])

    const save = () => {
        if (props.id) {
            API.post('/admin/publisher/settings/' + props.id, settings).then((response) => {
                props.close()
            });
        }
    }

    return (
        <>{loaded ?
            <>
                <h5>Duplicate checker within a publisher</h5>
                <div className="row mt-3">
                    <div className="col">
                        <select className="form-control" name="duplicate" onChange={handleChange}
                                value={settings.duplicate}>
                            <option value={"0"}>Off</option>
                            <option value={"1"}>1 day</option>
                            <option value={"2"}>2 days</option>
                            <option value={"3"}>3 days</option>
                            <option value={"4"}>4 days</option>
                            <option value={"5"}>5 days</option>
                            <option value={"6"}>6 days</option>
                            <option value={"7"}>7 days</option>
                            <option value={"8"}>8 days</option>
                            <option value={"9"}>9 days</option>
                            <option value={"10"}>10 days</option>
                            <option value={"11"}>11 days</option>
                            <option value={"12"}>12 days</option>
                            <option value={"13"}>13 days</option>
                            <option value={"14"}>14 days</option>
                            <option value={"15"}>15 days</option>
                            <option value={"20"}>20 days</option>
                            <option value={"25"}>25 days</option>
                            <option value={"30"}>30 days</option>
                            <option value={"60"}>60 days</option>
                            <option value={"90"}>90 days</option>
                        </select>
                    </div>
                </div>
                <hr/>
                <h5 className="mt-3">Token requirement</h5>
                <div className="row mt-3">
                    <div className="col">
                        <select className="form-control" name="tokenrequirements" onChange={handleChange}
                                value={settings.tokenrequirements}>
                            <option value={""}>Select...</option>
                            {tokenRequirements && tokenRequirements.map((requirement, index) => {
                                return <option key={index} value={requirement.id}>{requirement.title}</option>
                            })}
                        </select>
                    </div>
                </div>
                <hr/>
                <h5 className="mt-3">Token requirement (<strong>PING</strong>)</h5>
                <div className="row mt-3">
                    <div className="col">
                        <select className="form-control" name="tokenrequirementsping" onChange={handleChange}
                                value={settings.tokenrequirementsping}>
                            <option value={""}>Select...</option>
                            {tokenRequirements && tokenRequirements.map((requirement, index) => {
                                return <option key={index} value={requirement.id}>{requirement.title}</option>
                            })}
                        </select>
                    </div>
                </div>
                <hr/>
                <h5 className="mt-3">Flag or Fail Bad leads (3rd party traffic)</h5>
                <div className="row mt-3">
                    <div className="col">
                        <select className="form-control" name="failflagrule" onChange={handleChange}
                                value={settings.failflagrule}>
                            <option value={"fail"}>Fail</option>
                            <option value={"flag"}>Flag</option>
                        </select>
                    </div>
                </div>
                <hr/>
                <h5 className="mt-3">Return</h5>
                <div className="row mt-3">
                    <div className="col">
                        <Label>Credit back to pub?</Label>
                        <input type="checkbox" className="ml-3 mp-1" size={30} name="creditreturn"
                               onChange={handleCheckboxChange} value="1" checked={settings.creditreturn == 1}/>
                    </div>
                </div>
                <hr/>
                <h5 className="mt-3">Copy failed leads</h5>
                <div className="row mt-3">
                    <div className="col">
                        <Label>Copy failed leads to RGR?</Label>
                        <input type="checkbox" className="ml-3 mp-1" size={30} name="copyfailedlead"
                               onChange={handleCheckboxChange} value="1" checked={settings.copyfailedlead == 1}/>
                    </div>
                </div>
                <hr/>
                <h5 className="mt-3">One To One</h5>
                <div className="row mt-3">
                    <div className="col">
                        <Label>One To One Complained? (3rd party leads)</Label>
                        <input type="checkbox" className="ml-3 mp-1" size={30} name="onetoone"
                               onChange={handleCheckboxChange} value="1" checked={settings.onetoone == 1}/>
                    </div>
                </div>
                <hr/>
                <h5 className="mt-3">Price reject variance</h5>
                <div className="row mt-3">
                    <div className="col">
                        <select className="form-control" name="pricerejectvariance" onChange={handleChange}
                                value={settings.pricerejectvariance}>
                            <option value={"0"}>Off</option>
                            <option value={"10"}>10%</option>
                            <option value={"20"}>20%</option>
                            <option value={"30"}>30%</option>
                            <option value={"40"}>40%</option>
                            <option value={"50"}>50%</option>
                            <option value={"60"}>60%</option>
                            <option value={"70"}>70%</option>
                            <option value={"80"}>80%</option>
                            <option value={"90"}>90%</option>
                        </select>
                    </div>
                </div>
                <hr/>
                <h5 className="mt-3">Traffic</h5>
                <div className="row mt-3">
                    <div className="col">
                        <Label>Lead type</Label>
                        <select className="form-control" name="leadtype" onChange={handleChange}
                                value={settings.leadtype}>
                            <option value={''}>Select...</option>
                            <option value={"shared"}>Shared</option>
                            <option value={"exclusive"}>Exclusive</option>
                        </select>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col">
                        <Label>Source: Landing page</Label>
                        <input type="checkbox" className="ml-3 mp-1" size={30} name="sourcetraffic"
                               onChange={handleCheckboxChange} value="1" checked={settings.sourcetraffic == 1}/>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col">
                        <Label>Source: Direct post</Label>
                        <input type="checkbox" className="ml-3 mp-1" size={30} name="sourcepost"
                               onChange={handleCheckboxChange} value="1" checked={settings.sourcepost == 1}/>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col">
                        <Label>Source: Ping/Post</Label>
                        <input type="checkbox" className="ml-3 mp-1" size={30} name="sourcepingpost"
                               onChange={handleCheckboxChange} value="1" checked={settings.sourcepingpost == 1}/>
                    </div>
                </div>

            <div className="row mt-2">
                <div className="col text-center">
                    <button className="btn btn-primary" onClick={save}>Save</button>
                </div>
            </div>
        </>
            :
            <Loader/>
        }
        </>
    )
}

export default SettingsForm
