import React from "react";
import {Badge} from 'reactstrap';

const PostingStatus = ({ status}) => {
    if (status === 'matched') {
        return <Badge color="success" className="mr-1 mb-1">
            Matched
        </Badge>
    }else if (status === 'unmatched') {
        return <Badge color="primary" className="mr-1 mb-1">
            Unmatched
        </Badge>
    }else {
        return <Badge color="secondary" className="mr-1 mb-1">
            {status}
        </Badge>
    }
};

export default PostingStatus;
