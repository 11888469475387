import React,{useState,useEffect} from "react";
import API from "../../utils/API";
import {Breadcrumb, BreadcrumbItem, Container, Nav, NavItem, NavLink, TabContent, TabPane, Badge} from "reactstrap";
import {Link, useHistory} from "react-router-dom";
import {Edit2} from "react-feather";
import classnames from "classnames";
import Publisher from "../../components/fields/Publisher";
import Datetime from "../../components/fields/Datetime";
import Product from "../../components/fields/Product";
import PixelStatus from "../../components/status/PixelStatus";
import PixelType from "../../components/fields/PixelType";
import PixelFilters from "./pixel/PixelFilters";
import PixelLog from "./pixel/PixelLog";
import Memo from "../../components/memo/Memo";
import SystemLog from "../../components/SystemLog";

const Pixel = (props) => {
    const [pixel,setPixel] = useState(null)

    const [activeTab,setActiveTab] = useState("activity")

    let history = useHistory();

    useEffect(()=>{
        if (props.match.params.id) {
            API.get('/admin/pixel/item/extended/' + props.match.params.id).then((response) => {
                setPixel(response.data.data)
            });
        }
    },[])

    return (
        <Container fluid className="p-0">
            {pixel &&
                <>
                    <Breadcrumb>
                        <BreadcrumbItem>
                            <Link to="/publisher/pixel">Pixels</Link>
                        </BreadcrumbItem>
                        <BreadcrumbItem active>{pixel.title}</BreadcrumbItem>
                    </Breadcrumb>
                    <div className="row">
                        <div className="col-md-4 col-sm-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-auto">
                                            <h4 className="h4 mb-3">#{pixel.id}, {pixel.title}</h4>
                                        </div>
                                    </div>
                                    <div className="card-actions float-right">
                                        <Edit2 onClick={() => {history.push("/publisher/pixel/form/"+pixel.id)}} className="align-middle mr-1 cursor-pointer" size={12} />
                                    </div>
                                    <ul className="list-unstyled mb-0">
                                        <li className="mb-1">
                                            <Publisher publisher={pixel.publisher}/>
                                        </li>
                                        <li className="mb-1">
                                            <Product product={pixel.product}/>
                                        </li>
                                        <li className="mb-1">
                                            Status: <PixelStatus status={pixel.status}/>
                                        </li>
                                        <li className="mb-1">
                                            Type: <PixelType type={pixel.pixeltype}/>
                                        </li>
                                        <li className="mb-1">
                                            Date add: <string><Datetime dateTime={pixel.dateadd}/></string>
                                        </li>
                                        <li className="mb-1">
                                            Date update: <string><Datetime dateTime={pixel.dateupdate}/></string>
                                        </li>
                                    </ul>
                                    <div className="mt-4"><strong>Pixel:</strong></div>
                                    <textarea readOnly={true} className="form-control">{pixel.content}</textarea>
                                    <Memo typeId={pixel.id} type={'pixel'}/>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8 col-sm-12">
                            <div className={"tab default"}>
                                <Nav tabs>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: activeTab === "activity" })}
                                            onClick={() => {
                                                setActiveTab("activity");
                                            }}
                                        >
                                            Activity
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: activeTab === "filters" })}
                                            onClick={() => {
                                                setActiveTab("filters");
                                            }}
                                        >
                                            <strong>Filters</strong>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: activeTab === "log" })}
                                            onClick={() => {
                                                setActiveTab("log");
                                            }}
                                        >
                                            <strong>Log</strong>
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                                <TabContent activeTab={activeTab}>
                                    <TabPane tabId="activity">
                                        <PixelLog pixel={pixel}/>
                                    </TabPane>
                                    <TabPane tabId="filters">
                                        <PixelFilters pixel={pixel}/>
                                    </TabPane>
                                    <TabPane tabId="log">
                                        <SystemLog pixel={[pixel.id]}/>
                                    </TabPane>
                                </TabContent>
                            </div>
                        </div>
                    </div>
                </>
            }
        </Container>
    )
}

export default Pixel
