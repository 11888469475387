import { Card, CardBody, Col, Container, Row, Table } from "reactstrap";
import React, { useEffect, useState } from "react";

import API from "../../utils/API";
import AdminSelect from "../../components/formfields/AdminSelect";
import DateRange from "../../components/formfields/DateRange";
import Dateday from "../../components/fields/Dateday";
import LenderSelect from "../../components/formfields/LenderSelect";
import LenderTitle from "../../components/fields/LenderTitle";
import Loader from "../../components/Loader";
import MoneyTotal from "../../components/fields/MoneyTotal";
import Order from "../../components/fields/Order";
import Product from "../../components/fields/Product";
import ProductMulti from "../../components/formfields/ProductMulti";
import SummaryChart from "../lenders/SummaryChart";
import dateFormat from "dateformat";
import { parseFilterValues } from "../../utils/helpers/filterHelper";
import { useHistory } from "react-router-dom";
import SortableTable from "../../components/SortableTable";

const OrderSummary = () => {
  const history = useHistory();
  const defaultDate =
    dateFormat(new Date().setDate(new Date().getDate() - 6), "mmm d, yyyy") +
    " to " +
    dateFormat(new Date(), "mmm d, yyyy");
  const defaultFiltrationState = {
    date: defaultDate,
  };

  const urlParams = new URLSearchParams(history.location.search);
  const parsedParams = Object.fromEntries(urlParams.entries());
  const filtersParsed = parseFilterValues(parsedParams);

  const [filtration, setFiltration] = useState({
    ...defaultFiltrationState,
    ...filtersParsed,
  });
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [dataDate, setDataDate] = useState(null);

  let totalLeads = 0,
    totalMatched = 0,
    totalUnmatched = 0,
    totalError = 0,
    totalReturned = 0,
    totalTimeout = 0,
    totalTotal = 0;

  const changeData = (name, value) => {
    setFiltration({ ...filtration, [name]: value });

    history.push({
      pathname: history.location.pathname,
      search: new URLSearchParams({ ...filtration, [name]: value }).toString(),
    });
  };

  const changeNativeData = (e) => {
    setFiltration({ ...filtration, [e.target.name]: e.target.value });

    history.push({
      pathname: history.location.pathname,
      search: new URLSearchParams({
        ...filtration,
        [e.target.name]: e.target.value,
      }).toString(),
    });
  };

  const columns = [
    {
      name: "Date",
      selector: (row) => row.date,
      sortable: true,
    },
    {
      name: "Lender",
      selector: (row) => row.order.lender.title.toLowerCase(),
      cell: (row) => (
        <LenderTitle title={row.order.lender.title} id={row.order.lender.id} />
      ),
      sortable: true,
    },
    {
      name: "Order",
      selector: (row) => row.order.id,
      cell: (row) => <Order order={row.order} />,
      sortable: true,
    },
    {
      name: "Product",
      selector: (row) => row.order.product.id,
      cell: (row) => <Product product={row.order.product} />,
      sortable: true,
    },
    {
      name: "Sent",
      selector: (row) => row.leads,
      cell: (row) => <span className="font-weight-bold">{row.leads}</span>,
      sortable: true,
    },
    {
      name: "Matched",
      selector: (row) => row.matched,
      cell: (row) => (
        <span
          className={`font-weight-bold ${row.matched ? "text-success" : ""}`}
        >
          {row.matched}
        </span>
      ),
      sortable: true,
    },
    {
      name: "Unmatched",
      selector: (row) => row.unmatched,
      sortable: true,
    },
    {
      name: "Returned",
      selector: (row) => row.returned,
      cell: (row) => (
        <span className={`${row.returned ? "text-primary" : ""}`}>
          {row.returned}
        </span>
      ),
      sortable: true,
    },
    {
      name: "Error",
      selector: (row) => row.error,
      cell: (row) => (
        <span className={`${row.error ? "text-warning" : ""}`}>
          {row.error}
        </span>
      ),
      sortable: true,
    },
    {
      name: "Timeout",
      selector: (row) => row.timeout,
      cell: (row) => (
        <span className={`${row.timeout ? "text-danger" : ""}`}>
          {row.timeout}
        </span>
      ),
      sortable: true,
    },
    {
      name: "Total",
      selector: (row) => row.total,
      cell: (row) => (row.total ? <MoneyTotal money={row.total} /> : "$0"),
      sortable: true,
    },
  ];

  useEffect(() => {
    if (filtration.date) {
      setLoading(true);
      API.post("/admin/lender/summaryorder", filtration).then((response) => {
        setData(response.data.data.report);
        setDataDate(response.data.data.reportDate);
        setLoading(false);
      });
    }
  }, [filtration]);

  return (
    <Container fluid className="p-0">
      <h1 className="h3 mb-3">Orders summary</h1>
      <Row form className={"mb-2"}>
        <Col md={3}>
          <DateRange
            onChange={changeData}
            name={"date"}
            value={filtration?.date}
          />
        </Col>
        <Col md={3}>
          <ProductMulti
            name={"product"}
            onChange={changeData}
            value={filtration?.product}
          />
        </Col>
        <Col md={3}>
          <LenderSelect
            name={"lender"}
            onChange={changeData}
            value={filtration?.lender}
          />
        </Col>
        <Col md={3}>
          <AdminSelect
            name={"representative"}
            onChange={changeNativeData}
            required={false}
            value={filtration?.representative}
            placeholder={"Account representative..."}
          />
        </Col>
      </Row>
      {loading && <Loader />}
      {!loading &&
      data &&
      data.length > 0 &&
      dataDate &&
      dataDate.length > 0 ? (
        <>
          {dataDate.length > 2 && (
            <div>
              <SummaryChart inputData={dataDate} />
            </div>
          )}
          <SortableTable columns={columns} data={data} pagination={false} />
          <Card className="flex-fill w-100 p-3">
            {data.map((dataItem, dataIndex) => {
              totalLeads += dataItem.leads;
              totalMatched += dataItem.matched;
              totalUnmatched += dataItem.unmatched;
              totalError += dataItem.error;
              totalReturned += dataItem.returned;
              totalTimeout += dataItem.timeout;
              totalTotal += dataItem.total;
            })}
            <h4>Summary:</h4>
            <ul className="bull list-unstyled mb-0">
              <li>
                Sent: <span className="font-weight-bold">{totalLeads}</span>
              </li>
              <li>
                Matched:{" "}
                <span
                  className={`font-weight-bold ${
                    totalMatched ? "text-success" : ""
                  }`}
                >
                  {totalMatched}
                </span>
              </li>
              <li>
                Unmatched:{" "}
                <span className="font-weight-bold">{totalUnmatched}</span>
              </li>
              <li>
                Returned:{" "}
                <span
                  className={`font-weight-bold ${
                    totalReturned ? "text-primary" : ""
                  }`}
                >
                  {totalReturned}
                </span>
              </li>
              <li>
                Error:{" "}
                <span
                  className={`font-weight-bold ${
                    totalError ? "text-warning" : ""
                  }`}
                >
                  {totalError}
                </span>
              </li>
              <li>
                Timeout:{" "}
                <span
                  className={`font-weight-bold ${
                    totalTimeout ? "text-danger" : ""
                  }`}
                >
                  {totalTimeout}
                </span>
              </li>
              <li>
                Total:{" "}
                <span className="font-weight-bold">
                  {totalTotal ? <MoneyTotal money={totalTotal} /> : "$0"}
                </span>
              </li>
            </ul>
          </Card>
        </>
      ) : (
        !loading && (
          <Card>
            <CardBody className="text-center">
              <h5>Data not found</h5>
            </CardBody>
          </Card>
        )
      )}
    </Container>
  );
};

export default OrderSummary;
