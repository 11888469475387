import React,{useState,useEffect} from "react";
import API from "../../utils/API";
import {Badge, Table} from "reactstrap";

const Subids = (props) => {
    const [subids,setSubids] = useState(null)

    useEffect(()=>{
        API.get('/subid/'+props.publisher).then((response) => {
            setSubids(response.data.data)
        });
    },props.publisher)

    return (
        <>
            { (subids && subids.length > 0) &&
                subids.map((subid,index)=>{
                    return (
                        <Badge key={index} color="success" className="mr-1 mb-1">{subid.subid}</Badge>
                    )
                })
            }
        </>
    )
}

export default Subids