import React, {useEffect, useState} from "react";
import {
    Button,
    FormGroup,
    Label,
} from "reactstrap";
import API from "../../utils/API";
import {toastr} from "react-redux-toastr";
import Select from "react-select";

const UserAssignForm = ({publisherId,closeForm}) => {
    const [availableUsers, setAvailableUsers] = useState(null);
    const [error, setError] = useState(null)
    const [user, setUser] = useState(null);

    const handleChange = (value) => {
        setUser(value.value)
        setError(null)
    };

    useEffect(()=>{
        API.get('/admin/publisher/availableusers/'+publisherId).then((response) => {
            setAvailableUsers(response.data.data?.map((item) => {return {value:item.id,label:`${item.name} ${item.email}`}}))
        });
    },[])

    const submit = () => {
        API.put('/admin/publisher/user/assign/'+publisherId,{userId:user})
            .then((response) => {
                toastr.success('Success', 'User was successfully assigned to publisher')
                closeForm(false)
            })
            .catch(error => {
                setError('Assignment error')
            })
    }

    return (
        <>
            <div className="mb-2 card-title">Assign user to publisher</div>
            {error &&
                <div className="text-center text-danger">{error}</div>
            }
            <div>
                <FormGroup>
                    <Label>Select user</Label>
                    {availableUsers &&
                    <Select
                        className="react-select-container"
                        classNamePrefix="react-select"
                        options={availableUsers}
                        onChange={handleChange}
                        isMulti={false}
                        placeholder="Select user"
                    />
                    }
                </FormGroup>
                <div className="text-center">
                    <Button color="primary" onClick={()=>submit()} disabled={!user}>Assign</Button>
                </div>
            </div>
        </>
    )
};

export default UserAssignForm;
