import React, { useEffect, useState } from "react";

import API from "../../utils/API";
import Select from "react-select";

const LenderSelect = ({
  name,
  value,
  onChange,
  isSingle,
  lenders: providedLenders,
}) => {
  const [allLenders, setAllLenders] = useState(null);
  const [filterValue, setFilterValue] = useState([]);

  const mapValuesToFilters = (value = [], lendersList) => {
    return value.map((item) => {
      const matchedFilter = lendersList.find((source) => source.value == item);
      return matchedFilter || item;
    });
  };

  useEffect(() => {
    if (!providedLenders) {
      API.get("/system/lenders").then((response) => {
        const options = response.data.data;
        const lenders = options
          ? options.map((option) => ({
              value: option.id,
              label: "#" + option.id + ", " + option.title,
            }))
          : [];

        const mappedFilters = mapValuesToFilters(value, lenders);
        setFilterValue(mappedFilters);
        setAllLenders(lenders);
      });
    }
  }, [providedLenders]);

  const lendersToUse = providedLenders
    ? providedLenders.map((lender) => ({
        value: lender.id,
        label: "#" + lender.id + ", " + lender.title,
      }))
    : allLenders;

  const changeSelect = (value) => {
    if (isSingle) {
      onChange(name, value ? value.value : null);
      const mappedFilters = mapValuesToFilters([value], allLenders);
      setFilterValue(mappedFilters);
    } else {
      const values = value ? value.map((item) => item.value) : [];
      onChange(name, values);
      const mappedFilters = mapValuesToFilters(values, allLenders);
      setFilterValue(mappedFilters);
    }
  };

    return (
        <Select
            className="react-select-container"
            classNamePrefix="react-select"
            options={lendersToUse}
            value={filterValue}
            onChange={changeSelect}
            isMulti={!isSingle}
            placeholder="Lender"
        />
    );
}

export default LenderSelect;
