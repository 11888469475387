import React,{useState,useEffect} from "react";
import {
    Button,
    Card,
    Col,
    Container,
    Row, Table
} from "reactstrap";
import API from "../../utils/API";
import Loader from "../../components/Loader";
import {Edit2, Trash, LogIn} from "react-feather";
import { confirmAlert } from 'react-confirm-alert';
import { useHistory } from "react-router-dom";
import AdminStatus from "../../components/status/AdminStatus";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import {toastr} from 'react-redux-toastr'
import {parseFilterValues} from "../../utils/helpers/filterHelper";
import Pagination from "../../components/Pagination";
import ListStat from "../../components/ListStat";
import LenderSelect from "../../components/formfields/LenderSelect";
import SendLenderInvitation from "./SendLenderInvitation";

const LenderUsers = () => {
    let history = useHistory();

    const defaultFiltrationState = {
        page: 1,
        perPage: 100,
    };
    const urlParams = new URLSearchParams(history.location.search);
    const parsedParams = Object.fromEntries(urlParams.entries());
    const filtersParsed = parseFilterValues(parsedParams);

    const [filtration, setFiltration] = useState({
        ...defaultFiltrationState,
        ...filtersParsed,
    });
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState(null);
    const [users, setUsers] = useState(null);

    const changeData = (name, value) => {
        setFiltration({ ...filtration, [name]: value });

        history.push({
            pathname: history.location.pathname,
            search: new URLSearchParams({ ...filtration, [name]: value }).toString(),
        });
    };

    const changeNativeData = (e) => {
        setFiltration({ ...filtration, [e.target.name]: e.target.value });

        history.push({
            pathname: history.location.pathname,
            search: new URLSearchParams({
                ...filtration,
                [e.target.name]: e.target.value,
            }).toString(),
        });
    };

    useEffect(() => {
        loadUsers();
    }, [filtration]);

    const oneClickLogIn = (userId) => {
        API.get('/admin/lender/oneclicklogin/'+userId).then((response) => {
            window.open(response.data.data.url);
        });
    }

    const loadUsers = () => {
        setLoading(true);
        API.post("/admin/lender/users", filtration).then((response) => {
            setData(response.data.data);
            setUsers(response.data.data.data);
            setLoading(false);
        });
    }

    const deleteUser = (userId) => {
        confirmAlert({
            title: 'Confirm to delete',
            message: 'Are you sure you want to delete this user?',
            buttons: [
                {
                    label: 'Yes',
                    className: 'btn btn-danger',
                    onClick: () => {
                        API.put('/admin/lender/user/'+userId).then((response) => {
                            toastr.success('Success', 'User was successfully deleted')
                            loadUsers()
                        });
                    }
                },
                {
                    label: 'No',
                    className: 'btn btn-primary'
                }
            ]
        });
    }

    const editUser = (userId) => {
        history.push("/user/lenders/item/"+userId);
    }

    const newUser = () => {
        history.push("/user/lenders/item")
    }

    return (
        <Container fluid className="p-0">
            <div className="row">
                <div className="col text-left">
                    <h1 className="h3 mb-3">Lender user management</h1>
                </div>
                <div className="col-auto text-right">
                    <Button color="primary" onClick={newUser} className="mt-n1">
                        <FontAwesomeIcon icon={faPlus}/> New user
                    </Button>
                </div>
            </div>
            <Row form className="mb-4">
                <Col md={4}>
                    <LenderSelect value={filtration.lender} name={'lender'} onChange={changeData}/>
                </Col>
                <Col md={4}>
                    <input
                        placeholder="Name"
                        className="form-control"
                        name="name"
                        value={filtration.name}
                        onChange={(e) => changeData(e.target.name, e.target.value)}
                    />
                </Col>
                <Col md={4}>
                    <input
                        placeholder="Email"
                        className="form-control"
                        name="email"
                        value={filtration.email}
                        onChange={(e) => changeData(e.target.name, e.target.value)}
                    />
                </Col>
            </Row>

            <Row>
                <Col>
                    {loading ?
                        <Loader/>
                        :
                        <>
                            <Card>
                                {users &&
                                    <div className="table-responsive">
                                        <ListStat data={data}/>
                                        <Table>
                                            <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th>Email</th>
                                                <th>Status</th>
                                                <th>Assigned lenders</th>
                                                <th>Date add</th>
                                                <th>Actions</th>
                                                <th>Log In</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                users.map((user, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td>{user.name}</td>
                                                            <td>{user.email}</td>
                                                            <td><AdminStatus status={user.status}/> {(!user.status || user.status === 0) &&
                                                                <SendLenderInvitation userId={user.id}/>
                                                            }</td>
                                                            <td>{user.lenders &&
                                                                user.lenders.map(lender => {
                                                                    return <><span className="badge badge-secondary" style={{cursor:"pointer"}} key={lender.id} onClick={()=>history.push("/lender/item/"+lender.id)}>{lender.title}</span><br/></>
                                                                })
                                                            }</td>
                                                            <td>{user.dateadd}</td>
                                                            <td className="table-action">
                                                                <Edit2 onClick={() => {
                                                                    editUser(user.id)
                                                                }} className="align-middle mr-1 cursor-pointer text-primary"
                                                                       size={18}/>
                                                                <Trash onClick={() => {
                                                                    deleteUser(user.id)
                                                                }} className="cursor-pointer align-middle text-danger"
                                                                       size={18}/>
                                                            </td>
                                                            <td>
                                                                {(user.status === 1 && user?.lenders?.length > 0) &&
                                                                    <button className="btn btn-warning ml-3"
                                                                            onClick={() => {
                                                                                oneClickLogIn(user.id)
                                                                            }}>
                                                                        <nobr><LogIn className="cursor-pointer align-middle" size={18}/> Log In</nobr>
                                                                    </button>
                                                                }
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                            </tbody>
                                        </Table>
                                    </div>
                                }
                            </Card>
                            <>
                                {data?.page > 0 && data?.pages > 0 && (
                                    <Pagination
                                        onChange={changeData}
                                        name={"page"}
                                        page={data.page}
                                        totalPages={data.pages}
                                        loading={loading}
                                    />
                                )}
                            </>
                        </>
                    }

                </Col>
            </Row>
        </Container>
    )
}

export default LenderUsers;
