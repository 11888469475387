import React from "react";
import LeadStatus from "../../../components/status/LeadStatus";
import Publisher from "../../../components/fields/Publisher";
import Product from "../../../components/fields/Product";
import SubID from "../../../components/fields/SubID";
import MoneyTotal from "../../../components/fields/MoneyTotal";
import MoneyPub from "../../../components/fields/MoneyPub";
import RCID from "../../../components/fields/RCID";
import ProcessTime from "../../../components/fields/ProcessTime";

const PingDetailsPage = ({ping}) => {
    return (
        <>
            <ul className="list-unstyled mb-0">
                <li className="mt-1"><LeadStatus status={ping.status}/></li>
                {}
                {ping.earntotal > 0 &&
                    <li className="mt-1">Total earning: <MoneyTotal money={ping.earntotal}/></li>
                }
                {ping.earnpub > 0 &&
                    <li className="mt-1">Publisher earning: <MoneyPub money={ping.earnpub}/></li>
                }
                <li className="mt-1">Date: <strong>{ping.dateadd}</strong></li>
                <li className="mt-1">Process time: <strong><ProcessTime dateAdd={ping.dateadd} dateUpdate={ping.dateupdate}/></strong></li>
                <li className="mt-1">Publisher: <strong><Publisher publisher={ping.publisher}/></strong> </li>
                <li className="mt-1">Product: <strong><Product product={ping.product}/></strong> </li>
                {ping.subid &&
                    <li className="mt-1">Sub ID: <strong><SubID subid={ping.subid}/></strong></li>
                }
                {ping.rcid &&
                    <li className="mt-1">RCID: <strong><RCID id={ping.rcid}/></strong></li>
                }
            </ul>
        </>
    )
}

export default PingDetailsPage
