import React,{useState, useEffect} from "react";
import {
    Breadcrumb,
    BreadcrumbItem,
    Button, Card,
    CardBody,
    Container,
    FormGroup,
    Label,
} from "reactstrap";
import {
    AvForm,
    AvInput
} from "availity-reactstrap-validation";
import API from "../../utils/API";
import {Link, useHistory} from "react-router-dom";
import {toastr} from "react-redux-toastr";
import LenderSelectForm from "../../components/formfields/LenderSelectForm";

const SourceItem = (props) => {
    const [source, setSource] = useState({slug:null,title:null,brands:null});
    const [error, setError] = useState(null)

    let history = useHistory();

    const handleChange = (e) => {
        setSource({ ...source, [e.target.name]: e.target.value })
        setError(null)
    };

    const handleLenderSelect = (name,value) => {
        setSource({ ...source, [name]: value })
        setError(null)
    };

    useEffect(() => {
        if (props.match.params.id) {
            API.get('/admin/leadsource/'+props.match.params.id).then((response) => {
                setSource(response.data.data)
            })
        }
    }, [props]);

    const submit = () => {
        if (props.match.params.id) {
            API.put(`/admin/leadsource/${props.match.params.id}`,source)
                .then((response) => {
                    toastr.success('Success', 'Source was successfully saved')
                    history.push("/lead/source")
                })
                .catch(error => {
                    setError('User data')
                })
        }else {
            API.post(`/admin/leadsource`,source)
                .then((response) => {
                    toastr.success('Success', 'Source was successfully added')
                    history.push("/lead/source")
                })
                .catch(error => {
                    setError('User data')
                })
        }
    }

    return (
        <Container fluid className="p-0">
            <Breadcrumb>
                <BreadcrumbItem>
                    <Link to="/lead/source">Source management</Link>
                </BreadcrumbItem>
                <BreadcrumbItem active>{props.match.params.id?'Edit':'New'} Lead Source</BreadcrumbItem>
            </Breadcrumb>
            <Card>
                <CardBody>
                    {error &&
                        <div className="text-center text-danger">{error}</div>
                    }
                    <AvForm onValidSubmit={submit}>
                        <FormGroup>
                            <Label>Slug</Label>
                            {props.match.params.id ?
                                <input type={'text'} className={'form-control'} readOnly={true} value={source.slug}/>
                                :
                                <AvInput onChange={handleChange} readonly={true} required type="text" name="slug" value={source.slug} placeholder="Slug" />
                            }
                        </FormGroup>
                        <FormGroup>
                            <Label>Title</Label>
                            {props.match.params.id ?
                                <input type={'text'} className={'form-control'} readOnly={true} value={source.title}/>
                                :
                                <AvInput onChange={handleChange} required type="text" name="title" value={source.title} placeholder="Title" />
                            }
                        </FormGroup>
                        <FormGroup>
                            <Label>Assigned lenders</Label>
                            <LenderSelectForm value={source.brands??[]} onChange={handleLenderSelect} name={'brands'}/>
                        </FormGroup>
                        <div className="text-center">
                            <Button color="primary">{props.match.params.id?'Save':'Create'}</Button>
                        </div>
                    </AvForm>
                </CardBody>
            </Card>
        </Container>
    )
};

export default SourceItem;
