import React, { useEffect, useState } from "react";
import { Card, CardBody, Col, Container, Row, Table } from "reactstrap";
import API from "../../utils/API";
import Loader from "../../components/Loader";
import dateFormat from "dateformat";
import DateRange from "../../components/formfields/DateRange";
import ProductMulti from "../../components/formfields/ProductMulti";
import RCIDByPublisher from "../../components/formfields/RCIDByPublisher";
import RCIDDisplay from "../../components/fields/RCID";
import SubIDByPublisher from "../../components/formfields/SubIDByPublisher";
import PublisherDisplay from "../../components/fields/Publisher";
import MoneyTotal from "../../components/fields/MoneyTotal";
import MoneyPub from "../../components/fields/MoneyPub";
import CSVDownlaod from "../../components/CsvDownload";
import SubIDDetail from "../../components/fields/SubIDDetail";
import { parseFilterValues } from "../../utils/helpers/filterHelper";
import { useHistory } from "react-router-dom";
import PublisherSelect from "../../components/formfields/PublisherSelect";
import SortableTable from "../../components/SortableTable";

const SubIDReport = () => {
  const defaultDate =
    dateFormat(new Date().setDate(new Date().getDate() - 6), "mmm d, yyyy") +
    " to " +
    dateFormat(new Date(), "mmm d, yyyy");
  let history = useHistory();
  const defaultFiltrationState = {
    date: defaultDate,
    rcid: [],
    subid: [],
  };
  const urlParams = new URLSearchParams(history.location.search);
  const parsedParams = Object.fromEntries(urlParams.entries());
  const filtersParsed = parseFilterValues(parsedParams);
  const [filtration, setFiltration] = useState({
    ...defaultFiltrationState,
    ...filtersParsed,
  });
  const [pub, setPub] = useState(null);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);

  let totalLeads = 0,
    totalMatched = 0,
    totalUnmatched = 0,
    totalFlagged = 0,
    totalDuplicate = 0,
    totalProfit = 0,
    totalTotal = 0,
    totalPub = 0,
    totalFailed = 0;

  const changeData = (name, value) => {
    setData(null);
    let updatedFiltration = { ...filtration };
    if (name === "publisher" && value.length === 0) {
      updatedFiltration = {
        date: defaultDate,
        rcid: [],
        subid: [],
        publisher: [],
      };
    } else if (name === "publisher" && pub !== value) {
      setPub(value);
      updatedFiltration = {
        ...filtration,
        publisher: value,
        rcid: [],
        subid: [],
      };
    } else {
      updatedFiltration = { ...filtration, [name]: value };
    }

    setFiltration(updatedFiltration);
    history.push({
      pathname: history.location.pathname,
      search: new URLSearchParams(updatedFiltration).toString(),
    });
  };

  const columns = [
    {
      name: "Date",
      selector: (row) => row.date,
      sortable: true,
    },
    {
      name: "Publisher",
      selector: (row) => row.publisher.name.toLowerCase(),
      cell: (row) => (
        <span>
          <PublisherDisplay publisher={row.publisher} />
        </span>
      ),
      sortable: true,
    },
    {
      name: "RCID",
      selector: (row) => row.rcid && row.rcid.id,
      cell: (row) => (row.rcid ? <RCIDDisplay id={row.rcid.id} /> : ""),
      sortable: true,
    },
    {
      name: "SubID",
      selector: (row) =>
        row.subid ? row.subid.subid.toLowerCase() : "No subid".toLowerCase(),
      cell: (row) => <SubIDDetail subid={row.subid} item={row} />,
      sortable: true,
    },
    {
      name: "Total Leads",
      selector: (row) => row.leads,
      cell: (row) => <span className="font-weight-bold">{row.leads}</span>,
      sortable: true,
    },
    {
      name: "Matched",
      selector: (row) => row.matched,
      cell: (row) => (
        <span className="text-success font-weight-bold">{row.matched}</span>
      ),
      sortable: true,
    },
    {
      name: "Unmatched",
      selector: (row) => row.unmatched,
      sortable: true,
    },
    {
      name: "Flagged",
      selector: (row) => row.flagged,
      cell: (row) => <span className="text-primary">{row.flagged}</span>,
      sortable: true,
    },
    {
      name: "Failed",
      selector: (row) => row.failed,
      sortable: true,
    },
    {
      name: "Success Rate",
      selector: (row) => row.successRate,
      cell: (row) => (row.successRate ? row.successRate : "0") + "%",
      sortable: true,
    },
    {
      name: "Total",
      selector: (row) => row.total && parseFloat(row.total),
      cell: (row) => (
        <span className="text-success">
          {row.total ? (
            <MoneyTotal money={row.total} moneyOriginal={row.totaloriginal} />
          ) : (
            "$0"
          )}
        </span>
      ),
      sortable: true,
    },
    {
      name: "Rev Per Lead",
      selector: (row) => row.revPerLead,
      cell: (row) => "$" + row.revPerLead,
      sortable: true,
    },
    {
      name: "Publisher",
      selector: (row) => row.pub && row.pub,
      cell: (row) => (
        <span>
          {row.pub ? (
            <MoneyPub money={row.pub} moneyOriginal={row.puboriginal} />
          ) : (
            "$0"
          )}
        </span>
      ),
      sortable: true,
    },
    {
      name: "Profit",
      selector: (row) => row.total && row.pub && row.total - row.pub,
      cell: (row) => (
        <span>
          {row.total || row.pub ? (
            <MoneyTotal money={row.total - row.pub} />
          ) : (
            "$0"
          )}
        </span>
      ),
      sortable: true,
    },
    {
      name: "Margin",
      selector: (row) => row.margin,
      cell: (row) => <span>{row.margin ? row.margin : "0"}%</span>,
      sortable: true,
    },
  ];

  useEffect(() => {
    if (!pub && filtration.publisher) {
      setPub(filtration.publisher);
    }
    setLoading(true);
    if (filtration.date) {
      API.post("/admin/subid/list", filtration).then((response) => {
        setData(response.data.data.report);
        setLoading(false);
      });
    }
  }, [filtration]);

  return (
    <Container fluid className="p-0">
      <div className="row">
        <div className="col text-left">
          <h1 className="h3 mb-3">SubID Report</h1>
        </div>
        <div className="col-auto d-inline-flex">
          SubID Report -{" "}
          <CSVDownlaod
            url="/admin/subid/report/subidsummaryreportcsv"
            filename="Sub ID Report"
            filtration={filtration}
          />
        </div>
        <div className="d-inline-flex">
          Complete Lead List -{" "}
          <CSVDownlaod
            url="/admin/lead/detailreportfulldatacsv"
            filename="Sub ID Report - Lead List"
            filtration={filtration}
          />
        </div>
      </div>
      <Row form>
        <Col md={3}>
          <DateRange
            onChange={changeData}
            name={"date"}
            value={filtration?.date}
          />
        </Col>
        <Col md={3}>
          <PublisherSelect
            name={"publisher"}
            onChange={changeData}
            value={filtration.publisher}
            placeholder={"Publisher"}
            required={false}
            isClearable={true}
            isMulti={false}
          />
        </Col>
        <Col md={3}>
          <ProductMulti
            name={"product"}
            onChange={changeData}
            value={filtration.product}
          />
        </Col>
        <Col md={3}>
          <RCIDByPublisher
            name={"rcid"}
            onChange={changeData}
            publisher={filtration.publisher}
            value={filtration.rcid}
          />
        </Col>
      </Row>
      <Row form className="mt-1 mb-2">
        <Col md={3}>
          <SubIDByPublisher
            name={"subid"}
            onChange={changeData}
            publisher={filtration.publisher}
            value={filtration.subid}
          />
        </Col>
      </Row>
      {loading && <Loader />}
      {!loading && data && data.length > 0 ? (
        <>
          <SortableTable columns={columns} data={data} pagination={false} />
          <Card className="flex-fill w-100 p-3">
            <h4>Summary:</h4>
            {data.map((dataItem, dataIndex) => {
              totalLeads += dataItem.leads;
              totalMatched += dataItem.matched;
              totalUnmatched += dataItem.unmatched;
              totalFlagged += dataItem.flagged;
              totalDuplicate += dataItem.duplicate;
              totalFailed += dataItem.failed;
              totalProfit += dataItem.total - dataItem.pub;
              totalTotal += dataItem.total;
              totalPub += dataItem.pub;
            })}
            <ul className="bull list-unstyled mb-0">
              <li>
                Leads: <strong>{totalLeads}</strong>
              </li>
              <li>
                Matched: <strong>{totalMatched}</strong>
              </li>
              <li>
                Unmatched: <strong>{totalUnmatched}</strong>
              </li>
              <li>
                Flagged: <strong>{totalFlagged}</strong>
              </li>
              <li>
                Failed: <strong>{totalFailed}</strong>
              </li>
              <li>
                Revenue: {totalTotal ? <MoneyTotal money={totalTotal} /> : "$0"}
              </li>
              <li>
                Publisher revenue:{" "}
                {totalPub ? <MoneyPub money={totalPub} /> : "$0"}
              </li>
              <li>
                Profit:{" "}
                {totalProfit ? <MoneyTotal money={totalProfit} /> : "$0"}
              </li>
              <li>
                Margin:{" "}
                {totalTotal
                  ? parseFloat((100 * totalProfit) / totalTotal).toFixed(2)
                  : "0"}
                %
              </li>
              <li>
                Success rate:{" "}
                {totalLeads
                  ? parseFloat((100 * totalMatched) / totalLeads).toFixed(2)
                  : "0"}
                %
              </li>
            </ul>
          </Card>
        </>
      ) : (
        !loading && (
          <Card className="mt-2">
            <CardBody className="text-center">
              <h5>Data not found</h5>
            </CardBody>
          </Card>
        )
      )}
    </Container>
  );
};

export default SubIDReport;
