import React from "react";
import {AlertCircle} from "react-feather"
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';

const MoneyTotal = ({money,showZero,moneyOriginal}) => {
    const value = (Math.round(money * 100) / 100).toFixed(2)

    let valueOriginal = false

    if (moneyOriginal) {
        valueOriginal = (Math.round(moneyOriginal * 100) / 100).toFixed(2)
    }

    if (value > 0) {
        return <nobr><span className="text-success font-weight-bold">${value}</span><TotalAdjustmentInfo money={value} moneyOriginal={valueOriginal}/></nobr>;
    }else if (value < 0) {
        return <nobr><span className="text-danger font-weight-bold">${value}</span><TotalAdjustmentInfo money={value} moneyOriginal={valueOriginal}/></nobr>;
    }else {
        if (valueOriginal !== false && showZero) {
            return <nobr>
                <span className="text-secondary font-weight-bold">$0</span>
                <TotalAdjustmentInfo money={value} moneyOriginal={valueOriginal}/>
            </nobr>
        }else {
            if (showZero) {
                return <span className="text-secondary font-weight-bold">${value}</span>;
            }else {
                return <></>;
            }
        }
    }
};

const TotalAdjustmentInfo = ({money,moneyOriginal}) => {
    // console.log(moneyOriginal);
    if (moneyOriginal !== false && moneyOriginal !== money) {
        return <Tippy content={<span>Original total:<br/>${moneyOriginal}</span>}><AlertCircle size={14} color={"red"}/></Tippy>
    }else {
        return <></>
    }
}

export default MoneyTotal;
