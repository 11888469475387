import React,{useState,useEffect} from "react";
import {
    Badge,
    Button,
    Card,
    CardBody,
    Col,
    Container,
    Row, Table
} from "reactstrap";
import API from "../../utils/API";
import Loader from "../../components/Loader";
import {Edit2, Trash, UserCheck} from "react-feather";
import { confirmAlert } from 'react-confirm-alert';
import { useHistory } from "react-router-dom";
import AdminStatus from "../../components/status/AdminStatus";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import {toastr} from 'react-redux-toastr'

const Admins = () => {
    const [users, setUsers] = useState(null)
    const [loading, setLoading] = useState(true)
    let history = useHistory();

    useEffect(() => {
        API.get('/users/all/admin').then((response) => {
            setUsers(response.data.data)
            setLoading(false)
        });
    }, [])

    const deleteUser = (userId) => {
        confirmAlert({
            title: 'Confirm to delete',
            message: 'Are you sure you want to delete this user?',
            buttons: [
                {
                    label: 'Yes',
                    className: 'btn btn-danger',
                    onClick: () => {

                    }
                },
                {
                    label: 'No',
                    className: 'btn btn-primary'
                }
            ]
        });
    }

    const editUser = (userId) => {
        history.push("/user/admins/item/"+userId);
    }

    const newUser = () => {
        history.push("/user/admins/item")
    }

    return (
    <Container fluid className="p-0">
        <Button color="primary" onClick={newUser} className="float-right mt-n1">
            <FontAwesomeIcon icon={faPlus}/> New user
        </Button>
        <h1 className="h3 mb-3">Admin management</h1>
        <Row>
            <Col>
                {loading &&
                    <Loader />
                }
                <Card>
                    {users &&
                        <div className="table-responsive">
                        <Table>
                            <thead>
                            <tr>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Permission</th>
                                <th>Status</th>
                                <th>Date add</th>
                                <th>Actions</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                users.map((user, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{user.name}</td>
                                            <td>{user.email}</td>
                                            <td>{user.superuser ?
                                                <Badge color="primary" className="mr-1 mb-1">
                                                    Super User
                                                </Badge>
                                                :
                                                <>
                                                {user.permissionrole &&
                                                    <strong>{user.permissionrole.title}</strong>
                                                }
                                                </>
                                            }</td>
                                            <td><AdminStatus status={user.status}/></td>
                                            <td>{user.dateadd}</td>
                                            <td className="table-action">
                                                <Edit2 onClick={() => {editUser(user.id)}} className="align-middle mr-1 cursor-pointer text-primary" size={18} />
                                                <Trash onClick={() => {deleteUser(user.id)}} className="cursor-pointer align-middle text-danger" size={18} />
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                            </tbody>
                        </Table>
                        </div>
                    }
                </Card>
            </Col>
        </Row>
    </Container>
    )
}

export default Admins;
