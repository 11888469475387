import React from "react";
import LeadStatus from "../../../components/status/LeadStatus";
import Publisher from "../../../components/fields/Publisher";
import Product from "../../../components/fields/Product";
import Source from "../../../components/fields/Source";
import SubID from "../../../components/fields/SubID";
import MoneyTotal from "../../../components/fields/MoneyTotal";
import MoneyPub from "../../../components/fields/MoneyPub";
import RCID from "../../../components/fields/RCID";
import PingId from "../../../components/fields/PingId";
import {AlertCircle,Info} from "react-feather"
import {Link} from "react-router-dom";
import ProcessTime from "../../../components/fields/ProcessTime";
import {RemovedPostingLog} from "../RemovedPostingLog";

const LeadDetailsPage = ({lead,ping}) => {
    return (
            <>
                <ul className="list-unstyled mb-0">
                    {lead.pingid &&
                        <li className="mt-1">Ping Id: <strong><PingId id={lead.pingid}/></strong></li>
                    }
                    <li className="mt-1"><LeadStatus status={lead.status}/></li>
                    {lead.earntotal > 0 &&
                        <li className="mt-1">Total earning: <MoneyTotal money={lead.earntotal}/></li>
                    }
                    {lead.earnpub > 0 &&
                        <>
                        <li className="mt-1">
                            Publisher earning: <MoneyPub money={lead.earnpub}/>
                        </li>
                            {lead.costUpload &&
                                <li className="mt-1">
                                    <AlertCircle size={14} color={"red"}/> <span className="text-black-50">Cost uploaded on</span> <strong>{lead.costUpload.date}</strong> <span className="text-black-50">by</span> <strong>{lead.costUpload.user}</strong><span className="text-black-50">, original cost</span> <strong>${lead.earnpuboriginal}</strong> <a href={`/tools/costupload/logitem/${lead.costUpload.log}`} target="_blank" className="text-primary">log</a>
                                </li>
                            }
                        </>
                    }
                    <li className="mt-1">Date: <strong>{lead.dateadd}</strong></li>
                    <li className="mt-1">Process time: <strong><ProcessTime dateAdd={lead.dateadd} dateUpdate={lead.dateupdate}/></strong></li>
                    {ping &&
                        <li className="mt-1">Process time, ping: <strong><ProcessTime dateAdd={ping.dateadd} dateUpdate={ping.dateupdate}/></strong></li>
                    }
                    <li className="mt-1">Publisher: <strong><Publisher publisher={lead.publisher}/></strong> </li>
                    <li className="mt-1">Product: <strong><Product product={lead.product}/></strong> </li>
                    <li className="mt-1">Source: <strong><Source source={lead.leadsource}/></strong> </li>
                    {lead.subid &&
                        <li className="mt-1">Sub ID: <strong><SubID subid={lead.subid}/></strong></li>
                    }
                    {lead.rcid &&
                        <li className="mt-1">RCID: <strong><RCID id={lead.rcid}/></strong></li>
                    }
                    {lead?.exceptions?.map((exception,excKey)=>{
                        return <li key={excKey} className="mt-1"><Info size={14} color={"red"}/> <span>{exception}</span></li>
                    })}
                    {lead?.returnreason?.map((reason,reasonKey)=>{
                        return <li key={reasonKey} className="mt-1"><Info size={14} color={"red"}/> <span>return reason: {reason}</span></li>
                    })}
                    <RemovedPostingLog leadId={lead.id}/>
                </ul>
            </>
    )
}

export default LeadDetailsPage
