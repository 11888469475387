import React, { useState } from "react";
import { Edit2 } from "react-feather";

const TrafficLog = ({ data, onSave }) => {
    const [editableData, setEditableData] = useState(data);
    const [originalData, setOriginalData] = useState(data);
    const [isEditing, setIsEditing] = useState(false);

    const handleChange = (field, value) => {
        setEditableData({
            ...editableData,
            [field]: value
        });
    };

    const handleEdit = (field) => {
        setIsEditing(field);
    };

    const handleSave = () => {
        setIsEditing(false);
        setOriginalData(editableData);
        onSave(editableData);
    };

    const handleCancel = () => {
        setEditableData(originalData);
        setIsEditing(false);
    };

    return (
        <>
            <ul className="list-unstyled mb-0">
                {
                    Object.keys(editableData).map((field, index) => {
                        return (
                            <li key={index} className="d-flex align-items-center mt-1">
                                <span className="flex-grow-1">
                                    {field === 'ad_id' && isEditing === field ? (
                                        <>
                                            <strong>{field}: </strong>
                                            <input
                                                type="text"
                                                value={editableData[field]}
                                                onChange={(e) => handleChange(field, e.target.value)}
                                                className="form-control w-50"
                                            />
                                        </>

                                    ) : (
                                        <>
                                            <strong>{field}: {editableData[field]}</strong>
                                            {field === 'ad_id' && (
                                                <Edit2 className="cursor-pointer zoomHover ml-2" size={20} onClick={() => handleEdit(field)} />
                                            )}
                                        </>
                                    )}
                                </span>
                            </li>
                        )
                    })
                }
            </ul>
            {isEditing && (
                <div className="mt-2">
                    <button onClick={handleSave} className="btn btn-primary mr-2">Save</button>
                    <button onClick={handleCancel} className="btn btn-secondary">Cancel</button>
                </div>
            )}
        </>
    )
}

export default TrafficLog