import React,{useState,useEffect} from "react";
import API from "../../../utils/API";
import {FormGroup, Input, Label} from "reactstrap";

const TokenRequirements = ({title,type,elementId,onClose}) => {

    const [ping,setPing] = useState(0)
    const [typer,setTyper] = useState('')
    const [options,setOptions] = useState([])

    useEffect(() => {
        API.post('/admin/filter',{elementId:elementId,type:type,filterTitle:title}).then((response) => {

            setTyper(response.data.data.data.type)
            if (response.data.data.data?.ping) {
                setPing(response.data.data.data.ping)
            }
            if (response.data.data.options) {
                setOptions(response.data.data.options)
            }
        });
    }, [title,type])

    const saveFilter = () => {
        API.put('/admin/filter',{elementId:elementId,type:type,filterTitle:title,data:{ping:ping,type:typer}}).then((response) => {
            onClose()
        });
    }

    return (
        <>
            <div className="row">
                <div className="col pt-2">
                    <h5>Filter by: {title}</h5>
                </div>
                <div className="col-auto text-right">
                    <button onClick={onClose} className="btn btn-outline-secondary mb-2">X</button><br/>
                </div>
            </div>
            <div className="mt-3">
                <div className="row">
                    <div className="col-6">
                        <select onChange={(event)=>setPing(event.target.value)} className="form-control" value={ping}>
                            <option value="0">On post only</option>
                            <option value="1">On ping and post</option>
                        </select>
                    </div>
                    <div className="col-6">
                        {options.length > 0 &&
                            <select className="form-control" value={typer} onChange={(e) => setTyper(e.target.value)}>
                                <option value={''}>No required</option>
                                {
                                    options.map((option, index) => {
                                        return (
                                            <option value={option} key={index}>{option}</option>
                                        )
                                    })
                                }
                            </select>
                        }
                    </div>
                </div>
            </div>
            <div className="mt-3 text-center">
                <button className="btn btn-primary" onClick={saveFilter}>Save</button>
            </div>
        </>
    )
}

export default TokenRequirements;
