import React,{useEffect,useState} from "react";
import {Button, Card, Col, Container, Row, Table} from "reactstrap";
import API from "../../utils/API";
import {Edit2, Trash} from "react-feather";
import Loader from "../../components/Loader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import {useHistory} from "react-router-dom";
import Lender from "../../components/fields/Lender";

const Source = () => {
    const [source,setSource] = useState(null)
    const [loading,setLoading] = useState(true)

    let history = useHistory();

    useEffect(() => {
        API.get('/admin/leadsource').then((response) => {
            setSource(response.data.data)
            setLoading(false)
        });
    }, [])

    const editSource = (sourceId) => {
        history.push("/lead/source/item/"+sourceId);
    }

    const newSource = () => {
        history.push("/lead/source/item")
    }

    return (
        <Container fluid className="p-0">
            <Button color="primary" className="float-right mt-n1" onClick={newSource}>
                <FontAwesomeIcon icon={faPlus}/> New source
            </Button>
            <h1 className="h3 mb-3">Source management</h1>
            <Row>
                <Col>
                    {loading &&
                        <Loader />
                    }
                    <Card>
                        {source &&
                            <div className="table-responsive">
                                <Table>
                                    <thead>
                                    <tr>
                                        <th>Id</th>
                                        <th>Slug</th>
                                        <th>Title</th>
                                        <th>Assigned lenders</th>
                                        <th></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        source.map((sourceItem, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{sourceItem.id}</td>
                                                    <td>{sourceItem.slug}</td>
                                                    <td>{sourceItem.title}</td>
                                                    <td>{sourceItem.brands &&
                                                        sourceItem.brands.map((lender) => {
                                                            return <div><Lender lender={lender}/></div>
                                                        })
                                                    }</td>
                                                    <td><Edit2 onClick={() => {editSource(sourceItem.id)}} className="align-middle mr-1 cursor-pointer text-primary" size={18} /></td>
                                                </tr>
                                            )
                                        })
                                    }
                                    </tbody>
                                </Table>
                            </div>
                        }
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}

export default Source
