import React,{useState,useEffect} from "react";
import API from "../../utils/API";
import Select from "react-select";

const IndicatedPublisherSelect = ({name,value,onChange,products}) => {
    const [publishers,setPublishers] = useState(null)
    const changeSelect = (value) => {
        let i,values = []
        for (i=0;i<value?.length;i++) {
            if(value[i] === null || value[i].value === '') continue
            values.push(value[i].value)
        }
        if (name) {
            onChange(name,values)
        }
    }

    const initValues = (value, publisherList) => {
        for (let i = 0; i < value.length; ) {
            const publisher = publisherList.find(pub => pub.value === parseInt(value[i]));
            if (!publisher) {
                value.splice(i, 1);
            } else {
                value[i] = { value: value[i], label: publisher.label };
                i++;
            }
        }
        changeSelect(value)
    }

    useEffect(() => {
        if(name === 'order') {
            let options,i,publisherList = []

            API.post(`/admin/publisher/list`).then((response) => {
                if (response.data.data.data) {
                    options = response.data.data.data
                    for (i=0;i<options.length;i++) {
                        if(options[i].product && products === options[i].product.id && !publisherList.find(pub => pub.value === options[i].publisher.id )){
                            publisherList.push({value:options[i].publisher.id, label:`#${options[i].publisher.id} ${options[i].publisher.name} - [${options[i].product.title}]`})
                        }
                    }
                }
                
                value && initValues(value, publisherList)
                setPublishers(publisherList)
            })
        }else if (name === 'lender') {
            let options,i,publisherList = []

            API.post(`/admin/publisher/list`).then((response) => {
                if (response.data.data.data) {
                    options = response.data.data.data
                    for (i=0;i<options.length;i++) {
                        if(options[i].product && products.includes(options[i].product.id) && !publisherList.find(pub => pub.value === options[i].publisher.id )){
                            publisherList.push({value:options[i].publisher.id, label:`#${options[i].publisher.id} ${options[i].publisher.name} - [${options[i].product.title}]`})
                        }
                        else {
                            const objIndex = publisherList.findIndex((pub => pub.value === options[i].publisher.id));
                            if(objIndex >= 0)
                                publisherList[objIndex].label = publisherList[objIndex].label += ' [' + options[i].product.title + ']';
                        }
                    }
                }

                value && initValues(value, publisherList)
                setPublishers(publisherList)
            })
        }
    }, [])

    return (  
        <>
            <Select 
                className="react-select-container"
                classNamePrefix="react-select"
                options={publishers}
                defaultValue={value}
                onChange={(value)=>changeSelect(value)}
                isMulti
                placeholder="Publisher"
            />
        </>
    )
}

export default IndicatedPublisherSelect;