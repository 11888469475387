import React, {useEffect, useState} from "react";
import {Edit2} from "react-feather";
import JanglConnectForm from "./JanglConnectForm";
import API from "../../../utils/API";
import Loader from "../../../components/Loader";
import FieldsConfigForm from "./FieldsConfigForm";

const FieldsConfig = ({id}) => {

    const [edit,setEdit] = useState(false)
    const [data,setData] = useState(false)
    const [loaded, setLoaded] = useState(false)

    useEffect(()=>{
        API.get('/rcid/fields/' + id).then((response) => {
            setData(response.data.data)
            setLoaded(true)
        });
    },[edit])

    return (
        <>
            {loaded ?
                edit ?
                    <>
                        <div className="row">
                            <div className="col">
                                <FieldsConfigForm id={id} data={data} close={() => setEdit(false)}/>
                            </div>
                            <div className="col-auto">
                                <button onClick={() => {setEdit(false)}} className="btn btn-outline-secondary mb-2">X</button>
                            </div>
                        </div>
                    </>
                    :
                    <>
                        <div className="row">
                            <div className="col">
                                {data &&
                                    <>
                                    {data.map((field,index)=>{
                                        return (
                                            <div className="row" key={index}>
                                                <div className="col-4">
                                                    <strong>{field.field}</strong>
                                                </div>
                                                <div className="col">
                                                    {field.required === 0 &&
                                                        <>Not required</>
                                                    }
                                                    {field.required === 1 &&
                                                        <strong className="text-primary">Required on post</strong>
                                                    }
                                                    {field.required === 2 &&
                                                        <strong className="text-success">Required on ping and post</strong>
                                                    }
                                                </div>
                                            </div>
                                        )
                                    })}
                                    </>
                                }
                            </div>
                            <div className="col-auto text-right">
                                <Edit2 onClick={() => {
                                    setEdit(true)
                                }} className="align-middle mr-1 cursor-pointer" size={18}/>
                            </div>
                        </div>
                    </>
                :
                <Loader/>
            }
        </>
    )
}

export default FieldsConfig
