import { Card, CardBody, CardText, UncontrolledCollapse } from "reactstrap";

import React from "react";
import ReactJsonPrint from "react-json-print";

const LeadScrubbingLog = ({ log }) => {
  return (
    <>
      {log?.length > 0 && (
        <div className="accordion mt-4">
          <strong>Scrubbing</strong>
          {log.map((logItem, key) => {
            return (
              <Card>
                <div
                  className={
                    logItem.ispass ? "bg-success p-1" : "bg-danger p-1"
                  }
                >
                  <a
                    id={"collapse" + key}
                    className="text-white"
                    href={"#collapse" + key}
                  >
                    {logItem.scrubber}
                  </a>
                </div>
                <UncontrolledCollapse toggler={"#collapse" + key}>
                  <CardBody>
                    <CardText>
                      <ReactJsonPrint
                        dataObject={JSON.parse(logItem.data)}
                        expanded={true}
                      />
                    </CardText>
                  </CardBody>
                </UncontrolledCollapse>
              </Card>
            );
          })}
        </div>
      )}
    </>
  );
};

export default LeadScrubbingLog;
