import React,{useState,useEffect} from "react";
import API from "../../utils/API";
import Loader from "../../components/Loader";
import {Spinner} from "reactstrap";

const ManualPost = ({lead,runUpdate}) => {
    const [runManualPost,setRunManualPost] = useState(false);
    const [postInProcess,setPostProcess] = useState(false);

    const runManualPostLead = () => {
        setPostProcess(true);
        API.post(`/admin/lead/manualpostingall/${lead.id}`).then((response) => {
            setPostProcess(false);
            runUpdate();
        });
    }

    return <>
        {(lead.status !== 'matched') &&
            <div className="mt-3">
                {!runManualPost &&
                    <button className="btn btn-outline-primary" onClick={()=>setRunManualPost(true)}>Rematch lead</button>
                }
                {runManualPost &&
                    <>
                    {postInProcess ?
                        <Spinner color="primary" style={{zIndex:1}} />
                        :
                        <div className="row border-primary border p-2 shadow">
                            <div className="col-auto text-center">
                                <button className="btn btn-primary" onClick={runManualPostLead}>Start rematch
                                </button>
                            </div>
                            <div className="col-auto text-center">
                                <button className="btn btn-outline-secondary"
                                        onClick={() => setRunManualPost(false)}>Cancel
                                </button>
                            </div>
                        </div>
                    }
                    </>
                }
            </div>
        }
    </>
}

export default ManualPost
