import React, {useEffect, useState} from "react";
import {Edit2} from "react-feather";
import JanglConnectForm from "./JanglConnectForm";
import API from "../../../utils/API";
import Loader from "../../../components/Loader";
import FieldsConfigForm from "./FieldsConfigForm";

const JanglConnect = ({id}) => {

    const [edit,setEdit] = useState(false)
    const [data,setData] = useState(false)
    const [loaded, setLoaded] = useState(false)

    useEffect(()=>{
        API.get('/rcid/jangl/' + id).then((response) => {
            setData(response.data.data)
            setLoaded(true)
        });
    },[edit])

    return (
        <>
            {loaded ?
                edit ?
                    <>
                        <div className="row">
                            <div className="col">
                                <JanglConnectForm id={id} token={data.token} close={() => setEdit(false)}/>
                            </div>
                            <div className="col-auto">
                                <button onClick={() => {setEdit(false)}} className="btn btn-outline-secondary mb-2">X</button>
                            </div>
                        </div>
                    </>
                    :
                    <>
                        <div className="row">
                            <div className="col">
                                {data.token ?
                                    <>
                                        <div className="row">
                                            <div className="col-4">
                                                <strong>Token</strong>
                                            </div>
                                            <div className="col">
                                                {data.token}
                                            </div>
                                        </div>
                                        <div className="mt-4 mb-2">
                                            <h5>Production url</h5>
                                        </div>
                                        <div className="row">
                                            <div className="col-4">
                                                <strong>Direct post</strong>
                                            </div>
                                            <div className="col">
                                                {data.url.directPost}
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-4">
                                                <strong>Ping/Post, PING</strong>
                                            </div>
                                            <div className="col">
                                                {data.url.ping}
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-4">
                                                <strong>Ping/Post, POST</strong>
                                            </div>
                                            <div className="col">
                                                {data.url.post}
                                            </div>
                                        </div>
                                        <div className="mt-4 mb-2">
                                            <h5>Test url</h5>
                                        </div>
                                        <div className="row">
                                            <div className="col-4">
                                                <strong>Direct post</strong>
                                            </div>
                                            <div className="col">
                                                {data.testUrl.directPost}
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-4">
                                                <strong>Ping/Post, PING</strong>
                                            </div>
                                            <div className="col">
                                                {data.testUrl.ping}
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-4">
                                                <strong>Ping/Post, POST</strong>
                                            </div>
                                            <div className="col">
                                                {data.testUrl.post}
                                            </div>
                                        </div>
                                    </>
                                    :
                                    <span className="text-primary">Not connected</span>
                                }
                            </div>
                            <div className="col-auto text-right">
                                <Edit2 onClick={() => {
                                    setEdit(true)
                                }} className="align-middle mr-1 cursor-pointer" size={18}/>
                            </div>
                        </div>
                    </>
                :
                <Loader/>
            }
        </>
    )
}

export default JanglConnect
