import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Input,
  InputGroup,
  Row,
} from "reactstrap";
import { Edit2, Trash } from "react-feather";
import React, { useEffect, useState } from "react";

import API from "../../../utils/API";
import CSVDownlaod from "../../../components/CsvDownload";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Pagination from "../../../components/Pagination";
import SortableTable from "../../../components/SortableTable";
import { confirmAlert } from "react-confirm-alert";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { parseFilterValues } from "../../../utils/helpers/filterHelper";
import { useHistory } from "react-router-dom";

const PhoneNumberscrubbers = (props) => {
  let history = useHistory();
  const defaultFiltrationState = {
    page: 1,
    perPage: 50,
    column: "",
    order: "",
  };

  const urlParams = new URLSearchParams(history.location.search);
  const parsedParams = Object.fromEntries(urlParams.entries());
  const filtersParsed = parseFilterValues(parsedParams);

  const [phones, setPhones] = useState();
  const [filtration, setFiltration] = useState({
    ...defaultFiltrationState,
    ...filtersParsed,
  });
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [dir, setDir] = useState("asc");
  const [filterText, setFilterText] = React.useState(
    filtersParsed?.phone || ""
  );

  const newPhone = () => {
    history.push("/settings/phonenumberscrubber/item");
  };

  const editPhone = (id) => {
    history.push("/settings/phonenumberscrubber/item/" + id);
  };

  const deletePhone = (id) => {
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure you want to delete this phone?",
      buttons: [
        {
          label: "Yes",
          className: "btn btn-danger",
          onClick: () => {
            API.delete("/admin/badphone/" + id).then((response) => {
              loadPhones();
            });
          },
        },
        {
          label: "No",
          className: "btn btn-primary",
        },
      ],
    });
  };

  const loadPhones = () => {
    API.post("/admin/badphone/all", filtration).then((response) => {
      setPhones(response.data.data.data);
      setData(response.data.data);
      setLoading(false);
    });
  };

  const changeData = (name, value) => {
    if (name === "phone" && value) {
      setFiltration({ ...filtration, [name]: value, page: 1, start: 1 });
      history.push({
        pathname: history.location.pathname,
        search: new URLSearchParams({
          ...filtration,
          [name]: value,
          page: 1,
          start: 1,
        }).toString(),
      });
    } else {
      setFiltration({ ...filtration, [name]: value });
      history.push({
        pathname: history.location.pathname,
        search: new URLSearchParams({
          ...filtration,
          [name]: value,
        }).toString(),
      });
    }
  };

  const updateFilterText = (value) => {
    setFiltration({ ...filtration, searchText: value });
    changeData("phone", value);
    setFilterText(value);
  };

  useEffect(() => {
    loadPhones();
  }, [filtration]);

  const columns = [
    {
      name: "ID",
      selector: (row) => parseInt(row.id),
      sortable: true,
    },
    {
      name: "Phone",
      selector: (row) => row.phone,
      sortable: true,
    },
    {
      name: "Description",
      selector: (row) => row.description,
      sortable: true,
    },
    {
      name: "Date add",
      selector: (row) => row.dateadd,
      sortable: true,
    },
    {
      name: "Date update",
      selector: (row) => row.dateupdate,
      sortable: true,
    },
    {
      name: "User",
      selector: (row) => row.user.name,
      sortable: true,
    },
    {
      name: "Action",
      selector: (row) => (
        <Col>
          <Edit2
            onClick={() => {
              editPhone(row.id);
            }}
            className="align-middle mr-1 cursor-pointer text-primary"
            size={18}
          />
          <Trash
            onClick={() => {
              deletePhone(row.id);
            }}
            className="cursor-pointer align-middle text-danger"
            size={18}
          />
        </Col>
      ),

      sortable: false,
    },
  ];

  return (
    <Container fluid className="p-0">
      <div className="row justify-content-between">
        <div className="col-auto">
          <h3 className="h3 mb-3 float-left">Phone number scrubber</h3>
        </div>
        <div className="col-auto">
          <CSVDownlaod
            url="/admin/badphone/exportall"
            filename="BadPhonesList"
          />
        </div>
      </div>
      <Row form className="mt-1">
        <Col md={3}>
          <InputGroup>
            <Input
              name="phone"
              onChange={(e) => updateFilterText(e.target.value)}
              placeholder="Search..."
              value={filterText}
            />
          </InputGroup>
        </Col>
        <Col className="text-right">
          <Button
            color="primary"
            onClick={newPhone}
            className="float-right mt-n1"
          >
            <FontAwesomeIcon icon={faPlus} /> New phone number
          </Button>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col>
          <Card>
            {!loading && phones && phones.length > 0 ? (
              <SortableTable
                columns={columns}
                data={phones}
                pagination={false}
              />
            ) : (
              !loading && (
                <CardBody className="text-center">
                  <h5>Data not found</h5>
                </CardBody>
              )
            )}
          </Card>
          {data?.page > 0 && data?.pages > 0 && (
            <Pagination
              onChange={changeData}
              name={"page"}
              page={data.page}
              totalPages={data.pages}
              loading={loading}
            />
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default PhoneNumberscrubbers;
