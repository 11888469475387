import React,{useState,useEffect} from 'react';
import API from "../../utils/API";
import {Info} from "react-feather";
import Order from "../../components/fields/Order";
import Lender from "../../components/fields/Lender";
import MoneyPub from "../../components/fields/MoneyPub";
import MoneyTotal from "../../components/fields/MoneyTotal";

export const RemovedPostingLog = ({leadId}) => {
    const [log,setLog] = useState(null);
    const [showLog,setShowLog] = useState(false);

    useEffect(() => {
        API.get(`/admin/lead/removedpostinglog/${leadId}`).then(result => {
            setLog(result.data.data);
            result.data.data.map(log => {
               if (1*log.leadpostingremoved.earntotal >0 || 1*log.leadpostingremoved.earnpub !== 0) {
                   setShowLog(true);
               }
            });
        })
    }, [leadId]);

    return <>
        {(showLog && log && log.length > 0) &&
            log.map((log,key)=>{
                if (1*log.leadpostingremoved.earntotal >0 || 1*log.leadpostingremoved.earnpub !== 0) {
                    return <div className="">
                        <li key={key} className="mt-1"><Info size={14} color={"red"}/> Lender removed: <Lender lender={log.lender}/>, Pub: <MoneyPub money={log.leadpostingremoved.earnpub} showZero={true}/>, Total: <MoneyTotal money={log.leadpostingremoved.earntotal} showZero={true}/></li>
                    </div>
                }else {
                    return <></>
                }
            })
        }
    </>
}
