import React,{useState,useEffect} from "react";
import API from "../../utils/API";
import {AvField} from "availity-reactstrap-validation";

const Product = ({name,value,onChange,required}) => {
    const [products,setProducts] = useState(null)

    useEffect(() => {
        API.get('/system/products').then((response) => {
            setProducts(response.data.data)
        });
    }, [])

    return (
            <AvField
                type="select"
                name={name}
                value={value}
                onChange={onChange}
                required={required}
            >
                <option value="">Select...</option>
                {products &&
                    products.map((product, index) => (
                        <option key={index} selected={value === product.id} value={product.id}>{product.title}</option>
                    ))
                }
            </AvField>
    )

}

export default Product;
