import React,{useState,useEffect} from "react";
import API from "../../../utils/API";
import {AvForm, AvInput} from "availity-reactstrap-validation";
import {Badge, Button, FormGroup, Label} from "reactstrap";
import { Spinner } from "reactstrap";
import PostingStatus from "../../../components/status/PostingStatus";

const NewTestLead = (props) => {
    const [fields,setFields] = useState(false)
    const [testresult,setTestresult] = useState(false)
    const [loading,setLoading] = useState(false)
    const [sendTestError,setSendTestError] = useState(false)

    const handleChange = (e) => {
        setFields({ ...fields, [e.target.name]: e.target.value })
    };

    useEffect(()=>{
        API.get('/admin/order/testdata/'+props.order.id).then((response) => {
            setFields(response.data.data)
        });
    },[props.order.id])

    const submit = () => {
        setLoading(true)
        setSendTestError(false)
        API.post('/admin/order/test/'+props.order.id,{fields}).then((response) => {
            setTestresult(response.data.data)
            setLoading(false)
        }).catch(error => {
            if( error.response ) {
                console.log(error.response.data)
            }
            if (error?.response?.data?.data?.message) {
                setSendTestError(error?.response?.data?.data?.message);
            }
            setLoading(false)
        });
    }

    return (
        <>
            {fields &&
            <AvForm onValidSubmit={submit}>
                {testresult ?
                    <>
                    {testresult.pingstatus &&
                        <div className="row">
                            <div className="col-12 text-left">
                                <Badge color="secondary" className="mr-1 mb-1">Ping</Badge>
                            </div>
                            <div className="col-12 text-left">
                                <span>Status</span> <PostingStatus status={testresult.pingstatus}/>
                            </div>
                            {testresult.pingcomment &&
                                <div className="col-12 text-left">
                                    <span>Comment</span>
                                    <textarea className="form-control" rows="5">
                                    {testresult.pingcomment}
                                </textarea>
                                </div>
                            }
                            <div className="col-12 text-left">
                                <span>Time:</span> <strong>{testresult.pingtime}</strong> seconds
                            </div>
                            <div className="col-12 text-left">
                                <span>Request</span>
                                <textarea className="form-control" rows="5">
                                {testresult.pingrequest}
                            </textarea>
                            </div>
                            <div className="col-12 text-left">
                                <span>Response</span>
                                <textarea className="form-control" rows="5">
                                {testresult.pingresponse}
                            </textarea>
                            </div>
                        </div>
                    }
                    {testresult.status &&
                        <div className="row">
                            {testresult.pingstatus &&
                                <div className="col-12 text-left">
                                    <hr/>
                                    <Badge color="secondary" className="mr-1 mb-1">Post</Badge>
                                </div>
                            }
                            <div className="col-12 text-left">
                                <span>Status</span> <PostingStatus status={testresult.status}/>
                            </div>
                            {testresult.comment &&
                                <div className="col-12 text-left">
                                    <span>Comment</span>
                                    <textarea className="form-control" rows="5">
                                    {testresult.comment}
                                </textarea>
                                </div>
                            }
                            <div className="col-12 text-left">
                                <span>Time:</span> <strong>{testresult.time}</strong> seconds
                            </div>
                            <div className="col-12 text-left">
                                <span>Request</span>
                                <textarea className="form-control" rows="5">
                                {testresult.request}
                            </textarea>
                            </div>
                            <div className="col-12 text-left">
                                <span>Response</span>
                                <textarea className="form-control" rows="5">
                                {testresult.response}
                            </textarea>
                            </div>
                        </div>
                    }
                    </>
                    :
                    <div className="row justify-content-center">
                        {
                            Object.entries(fields).map(([key, value]) => (
                                <div className="col-12 text-left">
                                    <FormGroup>
                                        <Label>{key}</Label>
                                        <AvInput onChange={handleChange} required type="text" name={key} value={value} placeholder={key} />
                                    </FormGroup>
                                </div>
                            ))
                        }
                        {sendTestError &&
                            <div className="alert-message text-danger">
                                {sendTestError}
                            </div>
                        }
                        <div className="col-12 text-center">
                            {loading ?
                                <Spinner color="primary" />
                                :
                                <Button color="primary">Send</Button>
                            }
                        </div>
                    </div>
                }
            </AvForm>
            }
        </>
    )
}

export default NewTestLead
