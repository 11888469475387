import React from "react";
import {Badge} from "reactstrap";

const IsDataFeed = ({order}) => {
    return (
        order.isdatafeed ?
            <Badge color="info" className="mr-1 mb-1">
                Data Feed
            </Badge>
            :
            <></>
    )
}

export default IsDataFeed;
