import React, {useState} from "react";
import {Label} from "reactstrap";
import API from "../../../utils/API";

const BordeloConnectForm = ({id,bordeloId,close}) => {
    const [formBordeloId, setFormBordeloId] = useState(bordeloId)

    const save = () => {
        API.post(`/admin/bordelo/lender/${id}`, {bordeloid:formBordeloId}).then((response) => {
            close()
        });
    }

    return (
        <>
            <div className="row mt-3">
                <div className="col">
                    <Label>Bordelo Id</Label>
                    <input className="form-control" onChange={(e)=>setFormBordeloId(e.target.value)} type="text"
                           value={formBordeloId} placeholder="Enter Bordelo Id"/>
                </div>
            </div>
            <div className="row mt-2">
                <div className="col text-center">
                    <button className="btn btn-primary" onClick={save}>Save</button>
                </div>
            </div>
        </>
    )
}

export default BordeloConnectForm
