import React,{useState} from "react";
import {faFolderOpen} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import API from "../../utils/API";
import Loader from "../../components/Loader";
import Datetime from "./Datetime";
import LeadStatus from "../status/LeadStatus";

const RCIDTestLog = ({id}) => {
    const [modalshow,setModalshow] = useState(false)
    const [loading,setLoading] = useState(true)
    const [log,setLog] = useState(false)

    const showLog = () => {
        setModalshow(true)
        setLoading(true)
        API.get('/rcid/testlead/'+id).then((response) => {
            setLog(response.data.data)
            setLoading(false)
        });
    }

    return (
        <>
            <span onClick={()=>showLog()}>
                <FontAwesomeIcon
                    className="align-middle mr-2 cursor-pointer"
                    icon={faFolderOpen}
                    fixedWidth
                />
            </span>
            <React.Fragment>
                <Modal
                    isOpen={modalshow}
                    toggle={() => setModalshow(!modalshow)}
                    size="lg"
                >
                    <ModalHeader toggle={() => setModalshow(!modalshow)}>
                        Test log
                    </ModalHeader>
                    <ModalBody>
                        {loading &&
                        <Loader/>
                        }
                        {log &&
                        <>
                            <ul className="list-unstyled">
                                <li className="mt-1"><LeadStatus status={log.status}/></li>
                                <li className="mt-1"><Datetime dateTime={log.dateadd}/></li>
                            </ul>
                            <div className="mt-3">
                                <strong>Request</strong>
                                <textarea className="form-control" readOnly={true}>{log.request}</textarea>
                            </div>

                            <div className="mt-3">
                                <strong>Response</strong>
                                <textarea className="form-control" readOnly={true}>{log.response}</textarea>
                            </div>
                        </>
                        }
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={() => setModalshow(!modalshow)}>
                            Close
                        </Button>
                    </ModalFooter>
                </Modal>
            </React.Fragment>
        </>
    )
};

export default RCIDTestLog;
