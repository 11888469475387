import React, {useEffect, useState} from "react";
import {
    Breadcrumb,
    BreadcrumbItem,
    Button, Card,
    CardBody,
    Container,
    FormGroup,
    Label,
} from "reactstrap";
import {
    AvField,
    AvForm,
    AvInput
} from "availity-reactstrap-validation";
import API from "../../utils/API";
import {Link, useHistory} from "react-router-dom";
import {toastr} from "react-redux-toastr";
import LenderSelectForm from "../../components/formfields/LenderSelectForm";

const LenderUserForm = (props) => {
    const [user, setUser] = useState({name:null,email:null,status:null,sendemail:1,lenders:[]});
    const [error, setError] = useState(null)

    let history = useHistory();

    const handleChange = (e) => {
        setUser({ ...user, [e.target.name]: e.target.value })
        setError(null)
    };

    const handleLendersSelect = (name,value) => {
        setUser({ ...user, [name]: value })
        setError(null)
    };

    useEffect(() => {
        if (props.match.params.id) {
            API.get('/admin/lender/user/item/'+props.match.params.id).then((response) => {
                setUser(response.data.data)
            })
        }
    }, [props]);

    const submit = () => {
        if (props.match.params.id) {
            API.put('/admin/lender/user/item/edit/'+props.match.params.id,user)
                .then((response) => {
                    toastr.success('Success', 'User was successfully saved')
                    history.push("/user/lenders")
                })
                .catch(error => {
                    setError('User data')
                })
        }else {
            API.post('/admin/lender/user/item/add',user)
                .then((response) => {
                    toastr.success('Success', 'User was successfully added')
                    history.push("/user/lenders")
                })
                .catch(error => {
                    setError('User data')
                })

        }
    }

    return (
        <Container fluid className="p-0">
            <Breadcrumb>
                <BreadcrumbItem>
                    <Link to="/user/pubusers">Lender users</Link>
                </BreadcrumbItem>
                <BreadcrumbItem active>{props.match.params.id?'Edit':'New'} Lender User</BreadcrumbItem>
            </Breadcrumb>
            <Card>
                <CardBody>
                    {error &&
                        <div className="text-center text-danger">{error}</div>
                    }
                    <AvForm onValidSubmit={submit}>
                        <FormGroup>
                            <Label>Name</Label>
                            <AvInput onChange={handleChange} required type="text" name="name" value={user.name} placeholder="User full name" />
                        </FormGroup>
                        <FormGroup>
                            <Label>Email</Label>
                            <AvInput onChange={handleChange} required type="email" name="email" value={user.email} placeholder="User email" />
                        </FormGroup>
                        <FormGroup>
                            <Label>Assigned Lenders</Label>
                            <LenderSelectForm value={user.lenders} onChange={handleLendersSelect} name={'lenders'}/>
                        </FormGroup>
                        {props.match.params.id &&
                            <FormGroup>
                                <Label>Status</Label>
                                <AvField
                                    type="select"
                                    name="status"
                                    value={user.status}
                                    onChange={handleChange}
                                >
                                    <option value={""}>Not-activated</option>
                                    <option value={1}>Active</option>
                                    <option value={2}>Deactivated</option>
                                </AvField>
                            </FormGroup>
                        }
                        {(!props.match.params.id && user.lenders.length > 0) &&
                            <FormGroup>
                                <Label>Send invitation email</Label>
                                <AvField
                                    type="select"
                                    name="sendemail"
                                    value={user.sendemail}
                                    onChange={handleChange}
                                >
                                    <option value={1}>Yes</option>
                                    <option value={0}>No</option>
                                </AvField>
                            </FormGroup>
                        }
                        <div className="text-center">
                            <Button color="primary">Save</Button>
                        </div>
                    </AvForm>
                </CardBody>
            </Card>
        </Container>
    )
};

export default LenderUserForm;
