import React, { useEffect, useState } from "react";

import API from "../../utils/API";
import Select from "react-select";

const ProductSingular = ({ name, value, onChange }) => {
  const [products, setProducts] = useState(null);
  const [initValue, setInitValue] = useState(null);

  const mapValuesToFilters = (value = [], productList) => {
    return value.map((item) => {
      const matchedFilter = productList.find((pub) => pub.value == item);
      return matchedFilter || item;
    });
  };

  const changeSelect = (value) => {
    console.log(value);
    let values = [];
    values.push(value.value);
    setInitValue(value);
    if (name) {
      onChange(name, values);
      const mappedFilters = mapValuesToFilters(values, products);
      setInitValue(mappedFilters);
    }
  };

  useEffect(() => {
    API.get("/system/products").then((response) => {
      let options,
        i,
        productList = [];
      if (response.data.data) {
        options = response.data.data;

        for (i = 0; i < options.length; i++) {
          productList.push({ value: options[i].id, label: options[i].title });
        }
      }
      const mappedFilters = mapValuesToFilters(value, productList);
      setInitValue(mappedFilters);
      setProducts(productList);
    });
  }, []);

  return (
    <Select
      className="react-select-container"
      classNamePrefix="react-select"
      options={products}
      value={initValue}
      onChange={(value) => changeSelect(value)}
      placeholder="Product"
    />
  );
};

export default ProductSingular;
