import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Input,
  InputGroup,
  Row,
} from "reactstrap";
import { Edit2, Trash } from "react-feather";
import React, { useEffect, useState } from "react";

import API from "../../../utils/API";
import CSVDownlaod from "../../../components/CsvDownload";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Pagination from "../../../components/Pagination";
import SortableTable from "../../../components/SortableTable";
import { confirmAlert } from "react-confirm-alert";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { parseFilterValues } from "../../../utils/helpers/filterHelper";
import { useHistory } from "react-router-dom";

const BadFakeScurbbers = (props) => {
  let history = useHistory();
  const defaultFiltrationState = {
    page: 1,
    perPage: 50,
    column: "",
    order: "",
  };

  const urlParams = new URLSearchParams(history.location.search);
  const parsedParams = Object.fromEntries(urlParams.entries());
  const filtersParsed = parseFilterValues(parsedParams);

  const [words, setWords] = useState([]);
  const [filtration, setFiltration] = useState({
    ...defaultFiltrationState,
    ...filtersParsed,
  });
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [dir, setDir] = useState("asc");
  const [filterText, setFilterText] = React.useState(
    filtersParsed?.email || ""
  );

  const newWord = () => {
    history.push("/settings/badfakewordscrubber/item");
  };

  const editWord = (id) => {
    history.push("/settings/badfakewordscrubber/item/" + id);
  };

  const deleteWord = (id) => {
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure you want to delete this word?",
      buttons: [
        {
          label: "Yes",
          className: "btn btn-danger",
          onClick: () => {
            API.delete("/admin/badfakeword/" + id).then((response) => {
              loadWords();
            });
          },
        },
        {
          label: "No",
          className: "btn btn-primary",
        },
      ],
    });
  };

  const loadWords = () => {
    API.post("/admin/badfakeword/all", filtration).then((response) => {
      setWords(response.data.data.data);
      setData(response.data.data);
      setLoading(false);
    });
  };

  const changeData = (name, value) => {
    setFiltration({ ...filtration, [name]: value });

    history.push({
      pathname: history.location.pathname,
      search: new URLSearchParams({ ...filtration, [name]: value }).toString(),
    });
  };

  const updateFilterText = (value) => {
    changeData("word", value);
    setFilterText(value);
  };

  useEffect(() => {
    loadWords();
  }, [filtration]);

  const columns = [
    {
      name: "ID",
      selector: (row) => parseInt(row.id),
      sortable: true,
      sortField: "id",
    },
    {
      name: "Word",
      selector: (row) => row.word,
      sortable: true,
      sortField: "word",
    },
    {
      name: "Action Type",
      selector: (row) => row.actiontype,
      sortable: true,
      sortField: "actiontype",
    },
    {
      name: "Date add",
      selector: (row) => row.dateadd,
      sortable: true,
      sortField: "dateadd",
    },
    {
      name: "Date update",
      selector: (row) => row.dateupdate,
      sortable: true,
      sortField: "dateupdate",
    },
    {
      name: "User",
      selector: (row) => row.user.name,
      sortable: true,
      sortField: "user",
    },
    {
      name: "Action",
      selector: (row) => (
        <Col>
          <Edit2
            onClick={() => {
              editWord(row.id);
            }}
            className="align-middle mr-1 cursor-pointer text-primary"
            size={18}
          />
          <Trash
            onClick={() => {
              deleteWord(row.id);
            }}
            className="cursor-pointer align-middle text-danger"
            size={18}
          />
        </Col>
      ),

      sortable: false,
    },
  ];

  const handleSort = (e) => {
    if (e.sortField && filtration.column === e.sortField.toLowerCase()) {
      if (dir === "asc") {
        setFiltration({
          ...filtration,
          column: e.sortField.toLowerCase(),
          order: "desc",
        });
        setDir("desc");
      } else {
        setFiltration({
          ...filtration,
          column: e.sortField.toLowerCase(),
          order: "asc",
        });
        setDir("asc");
      }
    } else {
      if (dir === "asc") {
        setFiltration({
          ...filtration,
          column: e.sortField.toLowerCase(),
          order: dir,
        });
      } else {
        setFiltration({
          ...filtration,
          column: e.sortField.toLowerCase(),
          order: dir,
        });
      }
    }
  };

  return (
    <Container fluid className="p-0">
      <div className="row justify-content-between">
        <div className="col-auto">
          <h3 className="h3 mb-3 float-left">Bad/Fake Word Scrubbers</h3>
        </div>
        <div className="col-auto">
          <CSVDownlaod
            url="/admin/badfakeword/exportall"
            filename="BadFakeWordList"
          />
          {/* {!loadingCSV && logExport ?
                    <CsvDownloader datas={logExport} filename={"BadFakeWordList"}>
                    <FontAwesomeIcon
                        className="align-middle cursor-pointer"
                        icon={faFileCsv}
                        style={{fontSize:"20px"}}
                        fixedWidth
                    />
                    </CsvDownloader> : <Spinner />
                } */}
        </div>
      </div>
      <Row form className="mt-1">
        <Col md={3}>
          <InputGroup>
            <Input
              name="word"
              onChange={(e) => updateFilterText(e.target.value)}
              placeholder="Search..."
              value={filterText}
            />
          </InputGroup>
        </Col>
        <Col className="text-right">
          <Button
            color="primary"
            onClick={newWord}
            className="float-right mt-n1"
          >
            <FontAwesomeIcon icon={faPlus} /> New word
          </Button>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col>
          <Card>
            {!loading && words && words.length > 0 ? (
              <SortableTable
                columns={columns}
                data={words}
                sortServer={true}
                sortFunction={handleSort}
                pagination={false}
              />
            ) : (
              !loading && (
                <CardBody className="text-center">
                  <h5>Data not found</h5>
                </CardBody>
              )
            )}
          </Card>
          {data?.page > 0 && data?.pages > 0 && (
            <Pagination
              onChange={changeData}
              name={"page"}
              page={data.page}
              totalPages={data.pages}
              loading={loading}
            />
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default BadFakeScurbbers;
