import React,{useState,useEffect} from "react";
import API from "../../../utils/API";
import {Button, Card, CardBody, Col, Container, Row, Table} from "reactstrap";
import {Link} from "react-router-dom";
import Product from "../../../components/fields/Product";
import RCIDStatus from "../../../components/status/RCIDStatus";
import PriceModelDisplay from "../../../components/fields/PriceModelDisplay";
import Datetime from "../../../components/fields/Datetime";

const RcidPerPublisher = (props) => {
    const [rcid,setRcid] = useState(null)

    useEffect(()=>{
        API.get('/rcid/'+props.publisher).then((response) => {
            setRcid(response.data.data)
        });
    },props.publisher)

    return (
        <>
            { (rcid && rcid.length > 0) &&
            <div className="table-responsive mt-2">
                <Table>
                    <thead>
                    <tr>
                        <th>RCID</th>
                        <th>Product</th>
                        <th>Status</th>
                        <th>Price</th>
                        <th>Date add</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        rcid.map((rcidItem,index)=>{
                            return (
                                <tr key={index}>
                                    <td><Link to={"/publisher/rcid/item/"+rcidItem.id}>{rcidItem.id}</Link><h5>{rcidItem.title}</h5></td>
                                    <td><Product product={rcidItem.product}/></td>
                                    <td><RCIDStatus status={rcidItem.status}/></td>
                                    <td><PriceModelDisplay value={rcidItem.pricevalue} model={rcidItem.pricemodel}/></td>
                                    <td><Datetime dateTime={rcidItem.dateadd}/></td>
                                </tr>
                            )
                        })
                    }
                    </tbody>
                </Table>
            </div>
            }
        </>
    )
}

export default RcidPerPublisher
