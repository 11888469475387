import React, { useState, useEffect } from "react";
import API from "../../utils/API";
import {AvField} from "availity-reactstrap-validation";

const InvoiceMethod = ({ name, value, onChange, required, placeholder }) => {
  const [data, setData] = useState(null);

  useEffect(() => {
    API.get("/system/invoicemethods").then((response) => {
      setData(response.data.data);
    });
  }, []);

  return (
    <AvField
      type="select"
      name={name}
      value={value}
      onChange={onChange}
      required={required}
    >
      <option value="">{placeholder ?? "Select..."}</option>
      {data &&
        data.map((dataItem, index) => (
          <option
            key={index}
            selected={value === dataItem.id}
            value={dataItem.id}
          >
            {dataItem.title}
          </option>
        ))}
    </AvField>
  );
};

export default InvoiceMethod;
