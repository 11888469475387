import React from "react";

const Price = ({money}) => {
    if (money) {
        return '$'+money;
    }else {
        return '';
    }
};

export default Price;
