import React,{useState} from "react";
import {
    Breadcrumb,
    BreadcrumbItem,
    Button,
    Container,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Spinner
} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHistory,faExclamation} from "@fortawesome/free-solid-svg-icons";
import dateFormat from "dateformat";
import {useHistory} from "react-router-dom"
import API from "../../../utils/API";
import Loader from "../../../components/Loader";
import { FileUploader } from "react-drag-drop-files";
import revenueupload from "../../../assets/img/revenueupload.png"
import LenderSelect from "../../../components/formfields/LenderSelect";
import LeadID from "../../../components/fields/LeadID";
import Datetime from "../../../components/fields/Datetime";
import Product from "../../../components/fields/Product";
import Publisher from "../../../components/fields/Publisher";
import Lender from "../../../components/fields/Lender";
import Order from "../../../components/fields/Order";
import MoneyTotal from "../../../components/fields/MoneyTotal";
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import {AlertCircle} from "react-feather";
import PostingStatus from "../../../components/status/PostingStatus";

const RevenueUpload = (props) => {
    let history = useHistory()

    const [modalshow,setModalshow] = useState(false)
    const [file,setFile] = useState(null)
    const [lender,setLender] = useState(null)
    const [loading, setLoading] = useState(false)
    const [saving, setSaving] = useState(false)
    const [data,setData] = useState(null)
    const [memo,setMemo] = useState(null)
    const [hideOprions,setHideOptions] = useState(false)
    const [type,setType] = useState('replace')
    const [selected,setSelected] = useState([])
    const [zeroUploadResult,setZeroUploadResult] = useState(false)

    const cahngeLenderSelect = (fieldName,fieldValue) => {
        setLender(fieldValue)
    }

    const groupSelect = (checked) => {
        let newState = [];

        if (checked) {
            if (data?.length > 0) {
                let i,n = data.length
                for (i=0; i<n; i++) {
                    if (data[i]?.available) {
                        newState.push(data[i].id)
                    }
                }
            }
        }
        setSelected(newState);
    }

    const toggleCheck = (e,value) => {
        if (e.target.checked) {
            setSelected([...selected, value]);
        }else {
            setSelected(selected.filter((function(val) {
                return val !== value
            })))
        }
    };

    const preview = () => {
        setLoading(true)
        API.post('/tools/revenueupload/preview',{lender:lender,csvData:file,type:type})
            .then((response) => {
                setData(response.data.data)
                if (response.data.data.length) {
                    setHideOptions(true)
                    setZeroUploadResult(false)
                }else {
                    setZeroUploadResult(true)
                }
                setLoading(false)
            })
            .catch(error => {
                setLoading(false)
                setZeroUploadResult(true)
            })
    }

    const handleFileUpload = (uploadedFile) => {
        let reader = new FileReader();

        reader.onload = function(event) {
            setFile(event.target.result);
        };

        reader.readAsText(uploadedFile);
    };

    const save = () => {
        setSaving(true)
        API.post('/tools/revenueupload/save',{lender:lender,csvData:file,type:type,memo:memo,selected})
            .then((response) => {
                setSaving(false)
                history.push("/tools/revenueupload/logitem/"+response.data.data.id+"?new=true")
            })
            .catch(error => {
                setSaving(false)
            })
    }

    return <Container fluid className="p-0">
        <Button color="secondary" onClick={()=>history.push("/tools/revenueupload/log")} className="float-right mt-2">
            <FontAwesomeIcon icon={faHistory}/> Log
        </Button>
        <Breadcrumb>
            <BreadcrumbItem active>Revenue upload</BreadcrumbItem>
        </Breadcrumb>
        {!hideOprions &&
            <>
                <div className="row justify-content-center">
                    <div className="col-4">
                        <LenderSelect onChange={cahngeLenderSelect} name={'lender'} isSingle={true}/>
                    </div>
                </div>
                <div className="row justify-content-center mt-3">
                    <div className="col-4">
                        <select
                            className="form-control"
                            value={type}
                            onChange={(e)=>setType(e.target.value)}
                        >
                            <option value={'replace'}>Replace</option>
                            <option value={'add'}>Add</option>
                        </select>
                    </div>
                </div>
                <div className="row justify-content-center mt-3">
                    <div className="col-4">
                        <div className="row">
                            <div className="col">
                                <FileUploader handleChange={handleFileUpload} name="file" types={["CSV"]} label={"Upload or drop a CSV file right here"}/>
                            </div>
                            <div className="col-auto pt-2">
                                <Tippy content={<img src={revenueupload}/>}><AlertCircle size={14} color={"red"}/></Tippy>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-3 justify-content-center">
                    <div className="col-auto">
                        <Button color="primary" onClick={(e)=>preview()} disabled={(!file || !lender ||!type)}>Preview</Button>
                    </div>
                </div>
            </>
        }
        {loading &&
            <Loader/>
        }
        {(!loading && data?.length > 0) &&
            <>
            <div className="card">
                <div className="card-body">
                    <div className="table-responsive">
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <td><input type="checkbox" onChange={(e)=>groupSelect(e.target.checked)}/></td>
                                    <th>Lead</th>
                                    <th>Date</th>
                                    <th>Product</th>
                                    <th>Publisher</th>
                                    <th>Lender</th>
                                    <th>Order</th>
                                    <th>Post status</th>
                                    <th>Original total</th>
                                    <th>Uploading total</th>
                                    <th>New total</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                            {data.map((dataItem,dataKey)=>{
                                if (dataItem.data) {
                                    return <tr key={dataKey} className={'color-secondary'}>
                                        <td>
                                            {dataItem.available &&
                                            <input type="checkbox" onChange={(e)=>{toggleCheck(e,dataItem.id)}} checked={(selected && selected.includes(dataItem.id))?true:false}/>
                                            }
                                        </td>
                                        <td><LeadID id={dataItem.id}/></td>
                                        <td><Datetime dateTime={dataItem.data.lenderposting.date}/></td>
                                        <td><Product product={dataItem.data.product}/></td>
                                        <td><Publisher publisher={dataItem.data.publisher}/></td>
                                        <td><Lender lender={dataItem.data.lender}/></td>
                                        <td><Order order={dataItem.data.lenderorder}/></td>
                                        <td><PostingStatus status={dataItem.data.lenderposting.status}/></td>
                                        <td><MoneyTotal money={dataItem.data.lenderposting.earntotal}/></td>
                                        <td><MoneyTotal money={dataItem.amount} showZero={true}/></td>
                                        <td><MoneyTotal money={dataItem.newAmount} showZero={true}/></td>
                                        <td>
                                            {dataItem.pubRevenueUpdating &&
                                                <Tippy content={<span className="text-warning">Pub earnings would be updated</span>}><AlertCircle size={14} color={"red"}/></Tippy>
                                            }
                                        </td>
                                    </tr>
                                }else {
                                    return <tr key={dataKey}>
                                        <td></td>
                                        <td>{dataItem.id}</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td><MoneyTotal money={dataItem.amount} showZero={true}/></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                }
                            })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div className="mt-3">
                <div className="row justify-content-center">
                    <div className="col-auto">
                        <Button color="success" onClick={()=>setModalshow(true)} disabled={selected?.length?false:true}>Save revenue</Button>
                    </div>
                    <div className="col-auto">
                        <Button color="secondary" onClick={()=>{setData(null);setHideOptions(false)}}>Cancel</Button>
                    </div>
                </div>
            </div>
            </>
        }
        {zeroUploadResult &&
            <div className="card mt-3">
                <div className="card-body">
                    <h4>Data not found</h4>
                </div>
            </div>
        }
        <React.Fragment>
            <Modal
                isOpen={modalshow}
                toggle={() => setModalshow(!modalshow)}
                size="lg"
                style={{maxWidth: '600px', width: '600px'}}
            >
                <ModalHeader toggle={() => setModalshow(!modalshow)}>
                    Revenue upload
                </ModalHeader>
                <ModalBody style={{backgroundColor:"#F7F9FC"}}>
                    <label>Memo (not required)</label>
                    <textarea rows={5} className="form-control" name="memo" onChange={(e)=>setMemo(e.target.value)} value={memo}/>
                </ModalBody>
                <ModalFooter className="justify-content-center">
                    {saving ?
                        <Spinner color="primary" />
                        :
                        <Button color="secondary" onClick={() => save()}>
                            Upload
                        </Button>
                    }
                </ModalFooter>
            </Modal>
        </React.Fragment>
    </Container>
}

export default RevenueUpload
