import React,{useState,useEffect} from "react";
import API from "../../../utils/API";
import {Breadcrumb, BreadcrumbItem, Button, Card, CardBody, Container, FormGroup, Label} from "reactstrap";
import {Link, useHistory} from "react-router-dom";
import {AvField, AvForm} from "availity-reactstrap-validation";
import {toastr} from "react-redux-toastr";
import SendFormatPreview from "../../../components/fields/SendFormatPreview";
import SendFormatSelect from "../../../components/formfields/SendFormatSelect";

const SendFormatEdit = (props) => {
    const [order,setOrder] = useState(null)
    const [classes,setClasses] = useState(null)
    const [error, setError] = useState(null)
    const [sendconfig, setSendconfig] = useState({content:null,send:null,result:null})
    let history = useHistory();

    const changeData = (name,value) => {
        setSendconfig({ ...sendconfig, [name]: value })
    }

    useEffect(()=>{
        API.get('/admin/order/'+props.match.params.id).then((response) => {
            setOrder(response.data.data)
            if (response.data.data.sendconfig) {
                setSendconfig(response.data.data.sendconfig)
            }
        });
    },[props.match.params.id])

    useEffect(()=>{
        API.get('/admin/order/integrations').then((response) => {
            setClasses(response.data.data)
        });
    },[])

    const submit = () => {
        API.post('/admin/order/integration/'+props.match.params.id,sendconfig)
            .then((response) => {
                toastr.success('Success', 'Send format was successfully saved')
                history.push("/lender/order/item/"+props.match.params.id)
            })
            .catch(error => {
                setError('Send format error')
            })
    }

    return (
        <Container fluid className="p-0">
            {order &&
            <>
                <Breadcrumb>
                    <BreadcrumbItem>
                        <Link to="/lender/list">Lenders</Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem>
                        <Link to={"/lender/item/" + order.lender.id}>{order.lender.title}</Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem>
                        <Link to={"/lender/order/item/" + order.id}>{order.title}</Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem active>Send format</BreadcrumbItem>
                </Breadcrumb>
                {classes &&
                <Card>
                    <CardBody>
                        {error &&
                        <div className="text-center text-danger">{error}</div>
                        }
                        <AvForm onValidSubmit={submit}>
                            <FormGroup>
                                <Label>Data class</Label>
                                <div className="d-flex">
                                    <div className='w-100'>
                                        <SendFormatSelect                               
                                            name={"content"}
                                            onChange={changeData}
                                            value={sendconfig.content}
                                            data={classes.content}
                                        />
                                    </div>
                                    <SendFormatPreview namespace={sendconfig.content} className="d-block m-3 p-3"/>
                                </div>
                            </FormGroup>
                            <FormGroup>
                                <Label>Send class</Label>
                                <div className="d-flex">
                                    <div className='w-100'>
                                            <SendFormatSelect                               
                                            name={"send"}
                                            onChange={changeData}
                                            value={sendconfig.send}
                                            data={classes.send}
                                            />
                                    </div>
                                    <SendFormatPreview namespace={sendconfig.send} className="d-block m-3 p-3"/>
                                </div>
                            </FormGroup>
                            <FormGroup>
                                <Label>Result class</Label>
                                <div className="d-flex">
                                    <div className='w-100'>
                                        <SendFormatSelect                               
                                            name={"result"}
                                            onChange={changeData}
                                            value={sendconfig.result}
                                            data={classes.result}
                                            />
                                    </div>
                                    <SendFormatPreview namespace={sendconfig.result} className="d-block m-3 p-3"/>
                                </div>
                            </FormGroup>
                            <div className="text-center">
                                <Button color="primary">Save</Button>
                            </div>
                        </AvForm>
                    </CardBody>
                </Card>
                }
            </>
            }
        </Container>
    )
}

export default SendFormatEdit