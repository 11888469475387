import React,{useState,useEffect} from "react";
import "flatpickr/dist/themes/dark.css";
import Flatpickr from "react-flatpickr";
import dateFormat from "dateformat";

const DateRange = ({name,value,onChange}) => {

    const changeDate = (date) => {
        if (onChange && name && date?.length === 2) {
            date = dateFormat(date[0],"mmm d, yyyy")+" to "+dateFormat(date[1],"mmm d, yyyy")
            onChange(name,date)
        }
    }

    return <>
        <Flatpickr
        className="form-control"
        options={{
            mode: "range",
            dateFormat: "M j, Y",
            maxDate: "today"
        }}
        placeholder="Date range"
        value={value}
        onChange={changeDate}
    />
        </>
}

export default DateRange
