import React, { useEffect, useState } from "react";

import API from "../../utils/API";
import Select from "react-select";

const LenderSelectForm = ({ name, value = [], onChange }) => {
    const [lenders, setLenders] = useState(null);
    const [filterValue, setFilterValue] = useState([]);

    const mapValuesToFilters = (value = [], lenderList) => {
        return value.map((item) => {
            const matchedFilter = lenderList.find((len) => len.value == item);
            return matchedFilter || item;
        });
    };

    const changeSelect = (value) => {
        let i,
            values = [];
        for (i = 0; i < value?.length; i++) {
            values.push(value[i].value);
        }
        if (name) {
            onChange(name, values);
            const mappedFilters = mapValuesToFilters(values, lenders);
            setFilterValue(mappedFilters);
        }
    };

    useEffect(() => {
        API.get("/system/lenders").then((response) => {
            let options,
                i,
                lenderList = [];
            if (response.data.data) {
                options = response.data.data;
                for (i = 0; i < options.length; i++) {
                    lenderList.push({ value: options[i].id, label: options[i].title });
                }
            }
            const mappedFilters = mapValuesToFilters(value, lenderList);
            setFilterValue(mappedFilters);
            setLenders(lenderList);
        });
    }, [value]);

    return (
        <Select
            className="react-select-container"
            classNamePrefix="react-select"
            options={lenders}
            value={filterValue}
            onChange={(value) => changeSelect(value)}
            isMulti
            placeholder="Lender"
        />
    );
};

export default LenderSelectForm;
