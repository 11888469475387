import React, {useState} from "react";
import API from "../../utils/API";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader, TabContent} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faEye} from "@fortawesome/free-solid-svg-icons";
import Loader from "../../components/Loader";


const SendFormatPreview = ({namespace}) => {
    const [modalshow,setModalshow] = useState(false)

    const [data,setData] = useState('')
    const [loading,setLoading] = useState(true)
    const showModal = () => {
        setModalshow(true)
        API.post('/admin/order/integrations/preview/',{namespace}).then((response) => {
            setData(response.data.data);
            setLoading(false);
        });
    }

    return <>
        <a onClick={()=>showModal()}>
            <FontAwesomeIcon
                className="align-middle mr-8 cursor-pointer"
                icon={faEye}
                fixedWidth
            />
        </a>
        <React.Fragment>
            <Modal
                isOpen={modalshow}
                toggle={() => setModalshow(!modalshow)}
                size="lg"
                style={{maxWidth: '1200px', width: '90%'}}
            >
                <ModalHeader toggle={() => setModalshow(!modalshow)}>
                    Sendformat Preview: {namespace}
                </ModalHeader>
                <ModalBody style={{backgroundColor:"#F7F9FC"}}>
                    <div className={"tab default"}>
                        <TabContent>
                            {loading && <Loader/>}
                                <pre style={{whiteSpace: 'pre-wrap', padding: '2%'}}>
                                    {data}
                                </pre>
                        </TabContent>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={() => setModalshow(!modalshow)}>
                        Close
                    </Button>
                </ModalFooter>
            </Modal>
        </React.Fragment>
    </>;
};

export default SendFormatPreview;