import React from "react";
import {Table} from "reactstrap";
import Datetime from "../../../components/fields/Datetime";
import PostingStatus from "../../../components/status/PostingStatus";
import Order from "../../../components/fields/Order";
import LenderTitle from "../../../components/fields/LenderTitle";
import MoneyPub from "../../../components/fields/MoneyPub";
import MoneyTotal from "../../../components/fields/MoneyTotal";
import OrderPostingLog from "../../../components/fields/OrderPostingLog";
import {CheckCircle} from "react-feather";
const OneToOne = ({data}) => {
    return (
        <>
            {data.length > 0 &&
                <div className="table-responsive">
                    <Table>
                        <thead>
                        <tr>
                            <th>Lender</th>
                            <th>Price</th>
                            <th>Name</th>
                            <th>Selected</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            data.map((item, index) => {
                                return (
                                    <tr key={index}>
                                        <td>
                                            <LenderTitle title={item.lender.title} id={item.lender.id}/>
                                        </td>
                                        <td>
                                            {item.leadonetoone.price &&
                                                <MoneyTotal money={item.leadonetoone.price}/>
                                            }
                                        </td>
                                        <td>{item.leadonetoone.title}</td>
                                        <td>
                                            {parseInt(item.leadonetoone.selected) === 1 &&
                                                <CheckCircle className="text-success"/>
                                            }
                                        </td>
                                    </tr>
                                )
                            })
                        }
                        </tbody>
                    </Table>
                </div>
            }
        </>
    )
}

export default OneToOne
