import React,{useState,useEffect} from "react";
import API from "../../utils/API";
import {AvField} from "availity-reactstrap-validation";

const Pricemodel = ({name,value,onChange,required}) => {
    const [models,setModels] = useState(null)

    useEffect(() => {
        API.get('/system/pricemodels').then((response) => {
            setModels(response.data.data)
        });
    }, [])

    return (
        <AvField
            type="select"
            name={name}
            value={value}
            onChange={onChange}
            required={required}
        >
            <option value="">Select...</option>
            {models &&
            models.map((model, index) => (
                <option key={index} selected={value === model} value={model}>{model}</option>
            ))
            }
        </AvField>
    )
}

export default Pricemodel;
