import React,{useEffect,useState} from "react";
import API from "../../../utils/API";
import Datetime from "../../../components/fields/Datetime";
import PostingStatus from "../../../components/status/PostingStatus";
import Order from "../../../components/fields/Order";
import LenderTitle from "../../../components/fields/LenderTitle";
import {Badge, Spinner, Table} from "reactstrap";
import OrderStatus from "../../../components/status/OrderStatus";
import {faCheck} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Price from "../../../components/fields/Price";

const ManualProcess = ({id,runUpdate}) => {
    const [orders,setOrders] = useState(null)
    const [sendRow,setSendRow] = useState([])

    useEffect(()=>{
        if (id) {
            API.get('/admin/lead/manualposting/' + id).then((response) => {
                setOrders(response.data.data)
            });
        }
    },[id])

    const sendLead = (orderId) => {
        if (!sendRow.includes(orderId)) {
            setSendRow([orderId].concat(sendRow))
        }

        API.post('/admin/lead/manualposting',{lead:id,order:orderId}).then((response) => {
            setOrders(response.data.data)
            let sendRowTemp = sendRow

            for( let i = 0; i < sendRowTemp.length; i++){
                if ( sendRowTemp[i] === orderId) {
                    sendRowTemp.splice(i, 1);
                    i--;
                }
            }
            setSendRow(sendRowTemp)
            runUpdate()
        });
    }

    let bestMatch = null;

    return (
        <>
            {orders &&
                <div className="table-responsive">
                    <Table>
                        <thead>
                        <tr>
                            <th>Order</th>
                            <th>Price</th>
                            <th>Override price</th>
                            <th>Send Status</th>
                            <th className="text-center">Filter status</th>
                            <th style={{width:"100px"}}></th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            orders.map((order, index) => {
                                let selected = false
                                if (!bestMatch && !order.filterreport.length && !order.sentStatus && order.order.status === 'active' && order.lender.status === 'active') {
                                    selected = true
                                    bestMatch = true
                                }
                                return (
                                    <tr key={index} className={selected?"bg-light":""}>
                                        <td><OrderStatus status={order.order.status}/> <Order order={order.order} tags={true}/> - <LenderTitle title={order.lender.title} id={order.lender.id}/></td>
                                        <td className={selected?"font-weight-bold":""}><Price money={order.price}/></td>
                                        <td className={selected?"font-weight-bold":""}><Price money={order.overrideprice}/></td>
                                        <td>
                                            {order.sentPingStatus && <div><Badge color="secondary" className="mr-1 mb-1">Ping</Badge> <PostingStatus status={order.sentPingStatus}/> {order.sentPingDate && <> <Datetime dateTime={order.sentPingDate}/></>}</div>}
                                            {order.sentStatus && <PostingStatus status={order.sentStatus}/>}{order.sentDate && <> <Datetime dateTime={order.sentDate}/></>}
                                        </td>
                                        <td className="text-center">
                                            {order.filterreport.length ?
                                                <>
                                                    {order.filterreport.map((filter, filterIndex) => {
                                                        return (
                                                            <div className="text-danger" key={"filter_" + filterIndex}>{filter}</div>
                                                        )
                                                    })
                                                    }
                                                </>
                                                :
                                                <><FontAwesomeIcon
                                                    className="text-success"
                                                    icon={faCheck}
                                                    fixedWidth
                                                /> </>
                                            }
                                        </td>
                                        <td className="text-center">
                                            {sendRow.includes(order.order.id)
                                                ?
                                                <Spinner color="primary" />
                                                :
                                                <>
                                                    {order.sentStatus !== 'sold' &&
                                                        <>
                                                            {(order.order.status === 'active' && order.lender.status === 'active') ?
                                                                <>
                                                                    {order.oneToOne ?
                                                                        <button
                                                                            className={`btn ${(order.filterreport.length || order.sentStatus) ? 'btn-warning' : 'btn-success'}`}
                                                                            onClick={() => sendLead(order.order.id)}>Send</button>
                                                                        :
                                                                        <nobr><span className="text-danger">One To One</span></nobr>
                                                                    }
                                                                </>
                                                                :
                                                                <>
                                                                    {order.order.status !== 'active' &&
                                                                        <nobr><span className="text-warning">Order paused</span></nobr>
                                                                    }
                                                                    <br/>
                                                                    {order.lender.status !== 'active' &&
                                                                        <nobr><span className="text-warning">Lender paused</span></nobr>
                                                                    }
                                                                </>
                                                            }
                                                        </>
                                                    }
                                                </>
                                            }
                                        </td>
                                    </tr>
                                )
                            })
                        }
                        </tbody>
                    </Table>
                </div>
            }
        </>
    )
}

export default ManualProcess
