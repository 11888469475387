import React, {useState} from "react";
import API from "../../../utils/API";
import { Spinner } from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFilePdf} from "@fortawesome/free-solid-svg-icons";

const PostingSpecification = ({rcid,type}) => {

    const [loading,setLoading] = useState(false)

    const download = () => {
        setLoading(true)
        API.get('/rcid/postingspecsgenerate/' + rcid + '/' + type).then((response) => {
            setLoading(false)
            window.open(response.data.data.url)
        });
    }

    return <>
        {!loading ?
        <div onClick={()=>download()} className="cursor-pointer">
            <FontAwesomeIcon color={"#871b11"} icon={faFilePdf}/> {type === 'pingpost' ? <span className="text-secondary">Ping/Post</span> : <span className="text-secondary">Direct Post</span>}
        </div>
        :
            <><Spinner style={{ width: "15px", height: "15px" }} /> {type === 'pingpost' ? <span className="text-secondary">Ping/Post</span> : <span className="text-secondary">Direct Post</span>}</>
        }
    </>
}

export default PostingSpecification;
