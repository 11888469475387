import React, {useEffect, useState} from "react";
import {FormGroup, Input, Label} from "reactstrap";
import API from "../../../utils/API";
import Select from "react-select";

const FieldsConfigForm = ({id,data,close}) => {
    const [fieldsData, setFieldsData] = useState(data)

    const changeData = (name,value) => {
        setFieldsData(fieldsData.map((function(state) {
            if (state.field === name) {
                return {...state, ['required']: parseInt(value)}
            }
            return state
        })));
    }

    const save = () => {
        if (fieldsData) {
            API.post('/rcid/fields/' + id, {fieldsData}).then((response) => {
                close()
            });
        }
    }

    return (
        <>
            {fieldsData.map((field,index)=>{
                return (
                    <>
                    <div className="row mt-3" key={index}>
                        <div className="col-auto" style={{width:"200px"}}>
                            {field.field}
                        </div>
                        <div className="col">
                            <Input
                                name={field.field}
                                value={field.required}
                                type="select"
                                onChange={(e) => changeData(field.field,e.target.value)}
                            >
                                <option value={0}>
                                    Not required
                                </option>
                                <option value={1}>
                                    Required on post
                                </option>
                                <option value={2}>
                                    Required on ping and post
                                </option>
                            </Input>
                        </div>
                    </div>
                    <hr/>
                    </>
                )
            })}
            <div className="row mt-2">
                <div className="col text-center">
                    <button className="btn btn-primary" onClick={save}>Save</button>
                </div>
            </div>
        </>
    )
}

export default FieldsConfigForm
