import React, {useEffect, useState} from "react";
import {
    Breadcrumb,
    BreadcrumbItem,
    Button, Card,
    CardBody,
    Container,
    FormGroup,
    Label, Table,
} from "reactstrap";
import {
    AvForm,
    AvField
} from "availity-reactstrap-validation";
import API from "../../../utils/API";
import {Link, useHistory} from "react-router-dom";
import {toastr} from "react-redux-toastr";
import {FileUploader} from "react-drag-drop-files";
import Loader from "../../../components/Loader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamation} from "@fortawesome/free-solid-svg-icons";
import csvExample from "../../../assets/img/emailupload.png";

const Emailscrubber = (props) => {
    const [email, setEmail] = useState({email:null,description:null});
    const [error, setError] = useState(null);
    const [file, setFile] = useState(null);
    const [loading, setLoading] = useState(false);
    const [logs, setLogs] = useState(null);
    const [summary, setSummary] = useState(null);

    let history = useHistory();

    const handleChange = (e) => {
        setEmail({ ...email, [e.target.name]: e.target.value })
        setError(null)
    };

    const handleFileUpload = (uploadedFile) => {
        let reader = new FileReader();
        reader.onload = function (event) {
            setFile(event.target.result);
        };
        reader.readAsText(uploadedFile);
    };

    const uploadEmails = () => {
        setLoading(true);
        API.post("/admin/bademail/upload", { csv: file }).then((response) => {
            setLoading(false);
            setFile(false)
            setLogs(response.data.data.result)
            setSummary(response.data.data.summary)
        }).catch(error => {
            setError('Failed uploading')
        });
    };


    useEffect(()=>{
        if (props.match.params.id) {
            API.get('/admin/bademail/'+props.match.params.id).then((response) => {
                setEmail(response.data.data)
            })
        }
    },[])

    const submit = () => {
        if (props.match.params.id) {
            API.put('/admin/bademail/'+props.match.params.id,email)
                .then((response) => {
                    toastr.success('Success', 'Email was successfully saved')
                    history.push("/settings/emailscrubber")
                })
                .catch(error => {
                    setError('Duplicate email')
                })
        }else {
            API.post('/admin/bademail',email)
                .then((response) => {
                    toastr.success('Success', 'Email was successfully saved')
                    history.push("/settings/emailscrubber")
                })
                .catch(error => {
                    setError('Duplicate email')
                })
        }
    }

    return (
        <Container fluid className="p-0">
            <Breadcrumb>
                <BreadcrumbItem>
                    <Link to="/settings/emailscrubber">Email Scrubber</Link>
                </BreadcrumbItem>
                <BreadcrumbItem active>{props.match.params.id?'Edit':'New'} email</BreadcrumbItem>
            </Breadcrumb>
            <Card>
                <CardBody>
                    {error &&
                        <div className="text-center text-danger">{error}</div>
                    }
                    <AvForm onValidSubmit={submit}>
                        <FormGroup>
                            <Label>Email</Label>
                            <AvField onChange={handleChange} type="text" name="email" value={email.email} placeholder="Email address"
                            validate={{
                                    required: {value: true, errorMessage: 'Please enter an email address'},
                                    pattern: {value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(?:, ?[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})*$/, errorMessage: 'Please enter email address(es), separated by commas, with either one space or no space between the email addresses.'},
                                }}/>
                        </FormGroup>
                        <FormGroup>
                            <Label>Description</Label>
                            <AvField
                                type="select"
                                name="description"
                                value={email.description}
                                onChange={handleChange}
                                validate={{
                                    required: {value: true, errorMessage: 'Please select a description'},

                                }}
                                placeholder={<div>Type to search</div>}
                            >
                                <option value={''} hidden>Select...</option>
                                <option value={'Litigator'}>Litigator</option>
                                <option value={'Do Not Email'}>Do Not Email</option>
                            </AvField>
                        </FormGroup>
                        
                        <div className="text-center">
                            <Button color="primary">Save</Button>
                        </div>
                    </AvForm>
                    <div className="mt-4 mb-2 row justify-content-center">
                        <div className="col-auto">
                            <FileUploader
                                handleChange={handleFileUpload}
                                name="file"
                                types={["CSV"]}
                                label={"Upload or drop a CSV file right here"}
                            />
                            {loading && <Loader absolute={true} />}
                            <div className="mt-2 mb-2 text-center">
                                <FontAwesomeIcon icon={faExclamation}/> Example
                                format <br/>
                                <img src={csvExample} style={{width: "300px"}}/>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-2 justify-content-center">
                        <div className="col-auto">
                            <button
                                className="btn btn-success"
                                onClick={() => {
                                    uploadEmails();
                                }}
                                disabled={!file}
                            >
                                Upload
                            </button>
                        </div>
                    </div>
                    {summary && <Card className="mt-4 flex-fill w-100 p-3">
                        <h4>Summary:</h4>
                        <ul className="bull list-unstyled mb-0">
                            <li>
                                Total records: <strong>{summary.total}</strong>
                            </li>
                            <li>
                                Successful records: <strong>{summary.success}</strong>
                            </li>
                            <li>
                                Unsuccessful records: <strong>{summary.failure}</strong>
                            </li>
                        </ul>
                    </Card>}
                    {logs?.length > 0 && (
                        <div className="mb-2 table-responsive">
                            <Table>
                                <thead>
                                <tr>
                                    <th>Email address</th>
                                    <th>Description</th>
                                    <th>Result</th>
                                    <th>Reason</th>
                                </tr>
                                </thead>
                                <tbody>
                                {logs.filter(log => log.result === 'failure').map((log, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{log.email}</td>
                                            <td>{log.description}</td>
                                            <td>{log.result}</td>
                                            <td>{log.reason}</td>
                                        </tr>
                                    );
                                })}
                                </tbody>
                            </Table>
                        </div>
                    )}
                </CardBody>
            </Card>
        </Container>
    )
};

export default Emailscrubber;
