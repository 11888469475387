import React, {useEffect, useState} from "react";
import API from "../../../utils/API";
import {
    Button,
    Container, FormGroup, Label,
} from "reactstrap";
import {AvField, AvForm, AvInput} from "availity-reactstrap-validation";
import LeadID from "../../../components/fields/LeadID";

export const LandingPage = () => {
    const [products, setProducts] = useState(null);
    const [product, setProduct] = useState(null);
    const [editConfig, setEditConfig] = useState(null);
    const [leadBody, setLeadBody] = useState({});

    const [response, setResponse] = useState(null);
    const [responsePostMatch, setResponsePostMatch] = useState(null);
    const [responsePostMatchSecond, setResponsePostMatchSecond] = useState(null);

    const [errors, setErrors] = useState(null);

    const [oneToOne, setOneToOne] = useState(null);
    const [oneToOneSelected, setOneToOneSelected] = useState([]);
    const [leadId, setLeadId] = useState(null);

    const [oneToOneSecond, setOneToOneSecond] = useState(null);
    const [oneToOneSelectedSecond, setOneToOneSelectedSecond] = useState([]);

    const handleChange = (e) => {
        setLeadBody({ ...leadBody, [e.target.name]: e.target.value });
    };

    useEffect(() => {
        API.get('/system/products').then((response) => {
            setProducts(response.data.data);
        });
    }, []);

    useEffect(() => {
        setEditConfig(null);
        setOneToOne(null);
        setLeadId(null);
        setOneToOne(null);
        setOneToOneSecond(null);
        setOneToOneSelected([]);
        setOneToOneSelectedSecond([]);
        setResponsePostMatchSecond(null);
        setResponsePostMatch(null);
        setResponse(null);

        if (product) {
            API.get(`/testautomation/productfields/${product}/traffic`).then((response) => {
                const leadDataTemp = {};
                response.data.data.map((field) => {
                    leadDataTemp[field.name] = field.value;
                });
                setLeadBody(leadDataTemp);
                setEditConfig(response.data.data);
            });
        }
    }, [product]);

    const submit = () => {
        API.post(`/lead/webprematch`,leadBody).then((response) => {
            setProduct(null);
            setLeadBody(null);
            setEditConfig(null);
            setResponse(response.data);

            setOneToOneSelected([]);
            setLeadId(response.data.lead_id);
            setOneToOne(response.data.oneToOne);
            setResponsePostMatch(null);
        });
    }

    const handleSelect = (e) => {
        const checked = e.target.checked;
        const value = e.target.value;
        setOneToOneSelected(
            checked
                ? [...oneToOneSelected, value]
                : oneToOneSelected.filter((item) => item !== value)
        );
    };

    const handleSelectSecond = (e) => {
        const checked = e.target.checked;
        const value = e.target.value;
        setOneToOneSelectedSecond(
            checked
                ? [...oneToOneSelectedSecond, value]
                : oneToOneSelectedSecond.filter((item) => item !== value)
        );
    };

    const submitPostMatch = () => {
        API.post(`/lead/webonetoone`,{lead:leadId, brand_consent: oneToOneSelected}).then((response) => {
            setProduct(null);
            setLeadBody(null);
            setEditConfig(null);

            setOneToOneSelected([]);
            setResponsePostMatch(response.data);

            setOneToOneSelectedSecond([]);
            setOneToOneSecond(response.data.oneToOne);
        });
    }

    const submitPostMatchSecond = () => {
        API.post(`/lead/webonetoone`,{lead:leadId, brand_consent: oneToOneSelectedSecond}).then((response) => {
            setProduct(null);
            setLeadBody(null);
            setEditConfig(null);

            setOneToOneSelectedSecond([]);
            setResponsePostMatchSecond(response.data);

            setOneToOneSelectedSecond([]);
        });
    }

    return <Container fluid className="p-0">
        <h1 className="h3 mb-3">QA Traffic Lead Generator</h1>
        {products &&
        <div className="row">
            {products.map((productItem, index) => {
                return <div className="col" key={index}>
                    <button disabled={productItem.classkey === product} className={`btn ${productItem.classkey === product ? 'btn-secondary' : 'btn-primary'} w-100`} onClick={()=>setProduct(productItem.classkey)}>{productItem.title}</button>
                </div>
            })}
        </div>
        }
        {(!product && response) &&
            <>
            <div className="m-3">
                <h3>Response</h3>
                <pre><code>{JSON.stringify(response)}</code></pre>
            </div>
                {leadId &&
                    <div className="m-3">Lead <LeadID id={leadId}/></div>
                }
            {(leadId && oneToOne && oneToOne.length) &&
                <>
                    <div className="m-3">
                        <h3>Select One To One</h3>
                        {oneToOne.map((item,key) => {
                            return <div className="m-1" key={key}>
                                <input type="checkbox" value={item.name} onClick={handleSelect}/> {item.name}
                            </div>
                        })}
                        {!responsePostMatch &&
                        <Button color="primary" onClick={submitPostMatch}>Send</Button>
                        }
                    </div>
                    {responsePostMatch &&
                        <div className="m-3">
                            <h3>Response</h3>
                            <pre><code>{JSON.stringify(responsePostMatch)}</code></pre>
                        </div>
                    }
                </>
            }
                {(leadId && oneToOneSecond && oneToOneSecond.length > 0) &&
                    <>
                        <div className="m-3">
                            <h3>Select One To One</h3>
                            {oneToOneSecond.map((item,key) => {
                                return <div className="m-1" key={key}>
                                    <input type="checkbox" value={item.name} onClick={handleSelectSecond}/> {item.name}
                                </div>
                            })}
                            {responsePostMatchSecond === null &&
                                <Button color="primary" onClick={submitPostMatchSecond}>Send</Button>
                            }
                        </div>
                        {responsePostMatchSecond &&
                            <div className="m-3">
                                <h3>Response</h3>
                                <pre><code>{JSON.stringify(responsePostMatchSecond)}</code></pre>
                            </div>
                        }
                    </>
                }
            </>
        }
        {editConfig &&
            <div className={'mt-3'}>
                <AvForm>
                <div className="text-center">
                    <Button color="primary" onClick={submit}>Send</Button>
                </div>
                {editConfig.map((field, index) => {
                    if (field.type === "string") {
                        return (
                            <FormGroup key={index}>
                                <Label>{field.name}</Label>
                                <AvInput
                                    className={errors && errors[field.name] && "border-danger"}
                                    onChange={handleChange}
                                    required
                                    type="text"
                                    name={field.name}
                                    value={leadBody[field.name]}
                                    placeholder={"Enter " + field.name}
                                />
                                {errors && errors[field.name] && (
                                    <div className="text-danger font-size-sm">
                                        {errors[field.name]}
                                    </div>
                                )}
                            </FormGroup>
                        );
                    } else if (field.type === "options") {
                        return (
                            <FormGroup key={index}>
                                <Label>{field.name}</Label>
                                <AvField
                                    className={errors && errors[field.name] && "border-danger"}
                                    type="select"
                                    name={field.name}
                                    value={leadBody[field.name]}
                                    onChange={handleChange}
                                    required
                                >
                                    <option value="">Select...</option>
                                    {field.options.map((option, index) => (
                                        <option key={index} value={option}>
                                            {option}
                                        </option>
                                    ))}
                                </AvField>
                                {errors && errors[field.name] && (
                                    <div className="text-danger font-size-sm">
                                        {errors[field.name]}
                                    </div>
                                )}
                            </FormGroup>
                        );
                    }
                })}
            </AvForm>
        </div>
        }
    </Container>
}
