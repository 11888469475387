import React, {useEffect, useState} from "react";
import {Breadcrumb, BreadcrumbItem, Button, Card, CardBody, Container, FormGroup, Label} from "reactstrap";
import API from "../../../utils/API";
import {toastr} from "react-redux-toastr";
import {AvField, AvForm, AvInput} from "availity-reactstrap-validation";
import {Link, useHistory} from "react-router-dom";

const OrderEditForm = (props) => {
    const [order, setOrder] = useState(false);
    const [error, setError] = useState(null)
    let history = useHistory();

    const handleChange = (e) => {
        setOrder({ ...order, [e.target.name]: e.target.value })
        setError(null)
    };

    useEffect(() => {
        API.get('/admin/order/'+props.match.params.id).then((response) => {
            setOrder(response.data.data)
        });
    }, [props.match.params.id])

    const submit = () => {
        API.put('/admin/order/'+props.match.params.id,order)
            .then((response) => {
                toastr.success('Success', 'Order was successfully saved')
                history.push("/lender/order/item/"+props.match.params.id)
            })
            .catch(error => {
                setError('Invalid order data')
            })
    }

    return (
        <Container fluid className="p-0">
            {order &&
            <>
                <Breadcrumb>
                    <BreadcrumbItem>
                        <Link to="/lender/list">Lenders</Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem>
                        <Link to={"/lender/item/" + order.lender.id}>{order.lender.title}</Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem>
                        <Link to={"/lender/order/item/" + order.id}>{order.title}</Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem active>Edit</BreadcrumbItem>
                </Breadcrumb>
                <Card>
                    <CardBody>
                        {error &&
                        <div className="text-center text-danger">{error}</div>
                        }
                        <AvForm onValidSubmit={submit}>
                            <FormGroup>
                                <Label>Title</Label>
                                <AvInput onChange={handleChange} required type="text" name="title" value={order.title}
                                         placeholder="Order title"/>
                            </FormGroup>
                            <FormGroup>
                                <Label>Status</Label>
                                <AvField
                                    type="select"
                                    name="status"
                                    value={order.status}
                                    onChange={handleChange}
                                >
                                    <option value={'active'}>Active</option>
                                    <option value={'paused'}>Not active</option>
                                    <option value={'archive'}>Archive</option>
                                </AvField>
                            </FormGroup>
                            <FormGroup>
                                <Label>Is ping post</Label>
                                <AvField
                                    type="select"
                                    name="ispingpost"
                                    value={order.ispingpost}
                                    onChange={handleChange}
                                >
                                    <option value={'0'}>No</option>
                                    <option value={'1'}>Yes</option>
                                </AvField>
                            </FormGroup>
                            <FormGroup>
                                <Label>Minimum ping price</Label>
                                <AvInput onChange={handleChange} disabled={parseInt(order.ispingpost) !== 1 } type="text" name="minpingprice" value={!parseInt(order.ispingpost)?'':order.minpingprice} pattern={/^[0-9]\d*(((,\d{3}){1})?(\.\d{0,2})?)$/}/>
                            </FormGroup>
                            <FormGroup>
                                <Label>Is data feed</Label>
                                <AvField
                                    type="select"
                                    name="isdatafeed"
                                    value={order.isdatafeed}
                                    onChange={handleChange}
                                >
                                    <option value={'0'}>No</option>
                                    <option value={'1'}>Yes</option>
                                </AvField>
                            </FormGroup>
                            <FormGroup>
                                <Label>Price</Label>
                                <AvInput onChange={handleChange} required type="text" name="price" value={order.price}
                                         placeholder="Order price" pattern={/^[0-9]\d*(((,\d{3}){1})?(\.\d{0,2})?)$/}/>
                            </FormGroup>
                            <FormGroup>
                                <Label>Return cap</Label>
                                <AvField
                                    type="select"
                                    name="returncap"
                                    value={order.returncap?.toString()}
                                    onChange={handleChange}
                                >
                                    <option value={'not'}>Not set</option>
                                    <option value={'0'}>0%</option>
                                    <option value={'1'}>1%</option>
                                    <option value={'2'}>2%</option>
                                    <option value={'3'}>3%</option>
                                    <option value={'4'}>4%</option>
                                    <option value={'5'}>5%</option>
                                    <option value={'6'}>6%</option>
                                    <option value={'7'}>7%</option>
                                    <option value={'8'}>8%</option>
                                    <option value={'9'}>9%</option>
                                    <option value={'10'}>10%</option>
                                    <option value={'11'}>11%</option>
                                    <option value={'12'}>12%</option>
                                    <option value={'13'}>13%</option>
                                    <option value={'14'}>14%</option>
                                    <option value={'15'}>15%</option>
                                    <option value={'16'}>16%</option>
                                    <option value={'17'}>17%</option>
                                    <option value={'18'}>18%</option>
                                    <option value={'19'}>19%</option>
                                    <option value={'20'}>20%</option>
                                    <option value={'21'}>21%</option>
                                    <option value={'22'}>22%</option>
                                    <option value={'23'}>23%</option>
                                    <option value={'24'}>24%</option>
                                    <option value={'25'}>25%</option>
                                    <option value={'26'}>26%</option>
                                    <option value={'27'}>27%</option>
                                    <option value={'28'}>28%</option>
                                    <option value={'29'}>29%</option>
                                    <option value={'30'}>30%</option>
                                    <option value={'31'}>31%</option>
                                    <option value={'32'}>32%</option>
                                    <option value={'33'}>33%</option>
                                    <option value={'34'}>34%</option>
                                    <option value={'35'}>35%</option>
                                    <option value={'36'}>36%</option>
                                    <option value={'37'}>37%</option>
                                    <option value={'38'}>38%</option>
                                    <option value={'39'}>39%</option>
                                    <option value={'40'}>40%</option>
                                    <option value={'41'}>41%</option>
                                    <option value={'42'}>42%</option>
                                    <option value={'43'}>43%</option>
                                    <option value={'44'}>44%</option>
                                    <option value={'45'}>45%</option>
                                    <option value={'46'}>46%</option>
                                    <option value={'47'}>47%</option>
                                    <option value={'48'}>48%</option>
                                    <option value={'49'}>49%</option>
                                    <option value={'50'}>50%</option>
                                    <option value={'51'}>51%</option>
                                    <option value={'52'}>52%</option>
                                    <option value={'53'}>53%</option>
                                    <option value={'54'}>54%</option>
                                    <option value={'55'}>55%</option>
                                    <option value={'56'}>56%</option>
                                    <option value={'57'}>57%</option>
                                    <option value={'58'}>58%</option>
                                    <option value={'59'}>59%</option>
                                    <option value={'60'}>60%</option>
                                    <option value={'61'}>61%</option>
                                    <option value={'62'}>62%</option>
                                    <option value={'63'}>63%</option>
                                    <option value={'64'}>64%</option>
                                    <option value={'65'}>65%</option>
                                    <option value={'66'}>66%</option>
                                    <option value={'67'}>67%</option>
                                    <option value={'68'}>68%</option>
                                    <option value={'69'}>69%</option>
                                    <option value={'70'}>70%</option>
                                    <option value={'71'}>71%</option>
                                    <option value={'72'}>72%</option>
                                    <option value={'73'}>73%</option>
                                    <option value={'74'}>74%</option>
                                    <option value={'75'}>75%</option>
                                    <option value={'76'}>76%</option>
                                    <option value={'77'}>77%</option>
                                    <option value={'78'}>78%</option>
                                    <option value={'79'}>79%</option>
                                    <option value={'80'}>80%</option>
                                    <option value={'81'}>81%</option>
                                    <option value={'82'}>82%</option>
                                    <option value={'83'}>83%</option>
                                    <option value={'84'}>84%</option>
                                    <option value={'85'}>85%</option>
                                    <option value={'86'}>86%</option>
                                    <option value={'87'}>87%</option>
                                    <option value={'88'}>88%</option>
                                    <option value={'89'}>89%</option>
                                    <option value={'90'}>90%</option>
                                    <option value={'91'}>91%</option>
                                    <option value={'92'}>92%</option>
                                    <option value={'93'}>93%</option>
                                    <option value={'94'}>94%</option>
                                    <option value={'95'}>95%</option>
                                    <option value={'96'}>96%</option>
                                    <option value={'97'}>97%</option>
                                    <option value={'98'}>98%</option>
                                    <option value={'99'}>99%</option>
                                    <option value={'100'}>100%</option>
                                </AvField>
                            </FormGroup>
                            <FormGroup>
                                <Label>Return rate price adjustment</Label>
                                <AvField
                                    type="select"
                                    name="returnpriceadj"
                                    value={order.returnpriceadj}
                                    onChange={handleChange}
                                >
                                    <option value={'not'}>Not set</option>
                                    <option value={'95'}>95%</option>
                                    <option value={'90'}>90%</option>
                                    <option value={'85'}>85%</option>
                                    <option value={'80'}>80%</option>
                                    <option value={'75'}>75%</option>
                                    <option value={'70'}>70%</option>
                                    <option value={'65'}>65%</option>
                                    <option value={'60'}>60%</option>
                                    <option value={'55'}>55%</option>
                                    <option value={'50'}>50%</option>
                                    <option value={'45'}>45%</option>
                                    <option value={'40'}>40%</option>
                                    <option value={'35'}>35%</option>
                                    <option value={'30'}>30%</option>
                                    <option value={'25'}>25%</option>
                                    <option value={'20'}>20%</option>
                                    <option value={'15'}>15%</option>
                                    <option value={'10'}>10%</option>
                                    <option value={'5'}>5%</option>
                                </AvField>
                            </FormGroup>
                            <FormGroup>
                                <Label>Internal override price</Label>
                                <AvInput onChange={handleChange} type="text" name="overrideprice" value={(order.returnpriceadj && order.returnpriceadj !== 'not')?'':order.overrideprice}
                                         disabled={(order.returnpriceadj && order.returnpriceadj !== 'not')} placeholder="Override price" pattern={/^[0-9]\d*(((,\d{3}){1})?(\.\d{0,2})?)$/}/>
                            </FormGroup>
                            <div className="text-center">
                                {props.match.params.id &&
                                    <Button color="secondary" onClick={() => {
                                        history.push("/lender/order/item/"+props.match.params.id)
                                    }} className="mr-2">
                                        Cancel
                                    </Button>
                                }
                                <Button color="primary">Save</Button>
                            </div>
                        </AvForm>
                    </CardBody>
                </Card>
            </>
            }
        </Container>
    )
}

export default OrderEditForm
