import React, {useEffect, useState} from "react";
import {Badge, Button, FormGroup, Label} from "reactstrap";
import {Edit2,X} from "react-feather";
import {AvField, AvForm, AvInput} from "availity-reactstrap-validation";
import Select from "react-select";
import API from "../../utils/API";

const humanizeDuration = require("humanize-duration");

const Scrubber = ({publisherId,data}) => {

    const [edit,setEdit] = useState(false)
    const [config, setConfig] = useState(data.config)
    const [enabled, setEnabled] = useState(data.enabled)

    const handleChange = (e) => {
        setConfig({ ...config, [e.target.name]: e.target.value })
    };

    const submit = () => {
        API.put('/admin/scrubber/'+publisherId,{name:data.name,enabled,config:config}).then((response) => {
            setEdit(false)
        });
    }

    return (
        <div className="col-sm-12 col-md-6">
            <div className="border shadow p-2 m-2">
                <div className="row">
                    <div className="col">
                        <h5>{data.name}</h5>
                    </div>
                    <div className="col-auto">
                        {!edit ?
                            <Edit2 onClick={() => {
                                setEdit(true)
                            }} className="align-middle mr-1 cursor-pointer" size={18}/>
                            :
                            <X onClick={() => {
                                setEdit(false)
                            }} className="align-middle mr-1 cursor-pointer" size={18}/>
                        }
                    </div>
                </div>
                {edit ?
                    <AvForm onValidSubmit={submit}>
                        <FormGroup>
                            <AvField
                                type="select"
                                name="enabled"
                                value={enabled?'1':'0'}
                                onChange={(e)=>{
                                    if (e.target.value === '1') {
                                        setEnabled(true)
                                    }else {
                                        setEnabled(false)
                                    }
                                }}
                            >
                                <option value={'1'}>Enabled</option>
                                <option value={'0'}>Disabled</option>
                            </AvField>
                        </FormGroup>
                        {
                            data.fields.map((field, index) => {
                                if (field.type==='string') {
                                    return (
                                        <FormGroup key={index}>
                                            <Label>{field.name} {field.name === 'Lead age, maximum' && <span className="text-black-50">(in seconds, 3600=1 hour) {config[field.name] && <div>{humanizeDuration(config[field.name]*1000)}</div>}</span>}</Label>
                                            <AvInput onChange={handleChange} type="text" name={field.name} value={config[field.name]} placeholder={"Enter "+field.name} />
                                        </FormGroup>
                                    )
                                }else if (field.type==='options') {
                                    return (
                                        <FormGroup key={index}>
                                            <Label>{field.name}</Label>
                                            <AvField
                                                type="select"
                                                name={field.name}
                                                value={config[field.name]}
                                                onChange={handleChange}
                                                required
                                            >
                                                <option value="">Select...</option>
                                                {
                                                    field.options.map((option, index) => (
                                                        <option key={index} value={option}>{option}</option>
                                                    ))
                                                }
                                            </AvField>
                                        </FormGroup>
                                    )
                                }else if (field.type==='flagoptions') {
                                    return (
                                        <FormGroup key={index}>
                                            <Label>{field.name}</Label>
                                            <AvField
                                                type="select"
                                                name={field.name}
                                                value={config[field.name]}
                                                onChange={handleChange}
                                                required
                                                className={parseInt(config[field.name])===3?`bg-danger text-white`:(parseInt(config[field.name])===2?`bg-warning text-white`:``)}
                                            >
                                                <option value="">Select...</option>
                                                {
                                                    field.options.map((option, index) => (
                                                        <option key={index} value={option}>
                                                            {option === '2' && <span className="text-warning">Fail on yellow</span>}
                                                            {option === '3' && <>Fail on red</>}
                                                        </option>
                                                    ))
                                                }
                                            </AvField>
                                        </FormGroup>
                                    )
                                }else if (field.type==='multioptions') {
                                    let optionsPrepared = []
                                    for (let i in field.options) {
                                        optionsPrepared.push({
                                            label:field.options[i],
                                            value:field.options[i]
                                        })
                                    }
                                    let valuePrepared = []
                                    for (let i in config[field.name]) {
                                        valuePrepared.push({
                                            label:config[field.name][i],
                                            value:config[field.name][i]
                                        })
                                    }
                                    return (
                                        <FormGroup key={index}>
                                            <Label>{field.name}</Label>
                                            <Select
                                                className="react-select-container"
                                                classNamePrefix="react-select"
                                                options={optionsPrepared}
                                                value={valuePrepared}
                                                onChange={(value)=>{
                                                    let valueSavePrepared = []
                                                    for (let i in value) {
                                                        valueSavePrepared.push(value[i].value)
                                                    }
                                                    setConfig({ ...config, [field.name]: valueSavePrepared })
                                                }}
                                                isMulti
                                                placeholder={field.name}
                                            />
                                        </FormGroup>
                                    )
                                }
                            })
                        }
                        <div className="text-center">
                            <Button color="primary">Save</Button>
                        </div>
                    </AvForm>
                    :
                    <>
                        {enabled ?
                            <Badge color="success" className="mr-1 mb-1 badge-pill">Enabled</Badge>
                            :
                            <Badge color="secondary" className="mr-1 mb-1 badge-pill">Disabled</Badge>
                        }
                        {config &&
                            <ul className="list-unstyled mb-0">
                                {Object.keys(config).map(key => {
                                    if (key.indexOf('flag value') >= 0) {
                                        return (<li className="mb-1"
                                                    key={key}>{key}: <strong>{config[key] === "2" && <span className="badge badge-warning">Fail on yellow</span>}{config[key] === "3" && <span className="badge badge-danger">Fail on red</span>}</strong>
                                        </li>)
                                    }else if (key === 'Lead age, maximum') {
                                        return (<li className="mb-1"
                                                    key={key}>{key}: <strong>{config[key]} {config[key] && <span className="text-black-50">({humanizeDuration(config[key]*1000)})</span>}</strong>
                                        </li>)
                                    }else {
                                        return (<li className="mb-1"
                                                key={key}>{key}: <strong>{Array.isArray(config[key]) ? config[key].join(", ") : config[key]}</strong>
                                            </li>)
                                    }
                                })}
                            </ul>
                        }
                    </>
                }
            </div>
        </div>
    )
}

export default Scrubber
