import React from "react";
import {
    Table
} from "reactstrap";
import Datetime from "../../../components/fields/Datetime";
import Order from "../../../components/fields/Order";
import LenderTitle from "../../../components/fields/LenderTitle";

const LeadFilterLog = ({filterlog}) => {
    return (
        <>
            {filterlog.length > 0 &&
                <div className="table-responsive">
                    <Table>
                        <thead>
                        <tr>
                            <th>Date</th>
                            <th></th>
                            <th>Order</th>
                            <th>Lender</th>
                            <th>Filtered by</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            filterlog.map((log, index) => {
                                return (
                                    <tr key={index}>
                                        <td><Datetime dateTime={log.date}/></td>
                                        <td><span className="badge badge-warning">Filtered</span></td>
                                        <td><Order order={log.lenderorder}/></td>
                                        <td><LenderTitle title={log.lenderorder.lender.title} id={log.lenderorder.lender.id}/></td>
                                        <td>{log.comment}</td>
                                    </tr>
                                )
                            })
                        }
                        </tbody>
                    </Table>
                </div>
            }
        </>
    )
}

export default LeadFilterLog
