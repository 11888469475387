import React,{useState,useEffect} from "react";
import API from "../../utils/API";
import {AvField} from "availity-reactstrap-validation";

const Publisher = ({name,value,onChange,required}) => {
    const [publishers,setPublishers] = useState(null)

    useEffect(() => {
        API.get('/system/publishers').then((response) => {
            setPublishers(response.data.data)
        });
    }, [])

    return (
        <AvField
            type="select"
            name={name}
            value={value}
            onChange={onChange}
            required={required}
        >
            <option value="">Select...</option>
            {publishers &&
                publishers.map((publisher, index) => (
                    <option key={index} selected={value === publisher.id} value={publisher.id}># {publisher.id}, {publisher.name}</option>
                ))
            }
        </AvField>
    )

}

export default Publisher;
