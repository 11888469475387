export const eHawkStatusCodes = {
  0: "Success - no errors",
  5: `API requires either IP, email, or domain inputs
    or 'IP input error' (if IP required for API key)`,
  "-1": "network congestion (reduced fidelity)",
  "-3": "Invalid API key",
  "-4": "Account Suspended",
  "-6": "IP not in ACL",
  "-8": "illegal content (data not sent as UTF-8 character sets)",
  "-12": "Out of Vetting Credits",
  "-13": "Exceeded Max API Call Rate",
  "-99": "Database connection error (System issues)",
};

export const infutorPhoneTypes = {
  w: "Wireless",
  l: "Landline",
  v: "VoIP",
  o: "Other",
};

export const eHawkRiskDescriptions = {
  "No Reverse PTR":
    "No Reverse DNS Pointer. This means the IP address does not map to a domain name, which can sometimes hint at shady* leads * - (TM) Silas",
  "Accept All Mailbox":
    "This mailbox is a catchall that accepts all email from the domain, even if the address doesn't exist",
  "Contains Numbers": "Email address has numbers in it",
  Disposable: "Email appears to be disposable",
  "Email and Name Gender Different":
    "The assumed gender of the email name and the assumed gender of the lead name do not match. This can have false positives.",
  Free: "This email is on a free domain. This domain has a higher instance of fraud than the 'Free Domain' risk factor.",
  "Free Domain": "This email is on a free domain.",
  "Local All Numbers":
    "The name portin of the email address is all numbers, such as 12345@gmail.com.",
  "MX Record Bad": "The DNS record for this email server is bad or incorrect",
  "Moving Period":
    "This email address has appeared before, but with a period in a different place, such as jim.smith@gmail.com to jims.mith@gmail.com",
  "Name Not Similar": "The email address isn't similar to the actual name",
  "Role Based Email": "This is a role-based email, such as sales@example.com",
  Suspect: "Email domain is on a suspect or high abuse list.",
  "Suspect Local": "Name portion of the email is suspect, such as bad words.",
  Temporary:
    "Temporary email from a large domain, such as yahoo.com, or gmail.com.",
  "Two First Names":
    "Email address is comprised of 2 first names, such as rickybobby@nascar.com.",
  Undeliverable: "Email would likely bounce.",
  "Website: Malware or Phishing":
    "The website on this email's domain has been reported for malware or phishing",
  "email domain != domain":
    "The email's domain does not match the domain of the lead",
  Questionable:
    "This is generally a false positive. It means that the domain is unlikely to be owned by the lead, such as gmail.com.",
  "1 Repeat": "We have received this lead once before.",
  "2 Repeats": "We have received this lead twice before.",
  "3 Repeats": "We have received this lead three times before.",
  "4 Repeats": "We have received this lead four times before.",
  "5+ Repeats": "We have received this lead five or more times before.",
  "Lead Source or Campaign Velocity Issue":
    "This lead has a velocity issue. If less than 30 days old, we can check the activity report in the E-Hawk portal for the lead's email or IP address.",
};
