import React, {useEffect, useState} from "react";
import {Breadcrumb, BreadcrumbItem, Button, Card, CardBody, Container, FormGroup, Input, Label} from "reactstrap";
import API from "../../utils/API";
import {toastr} from "react-redux-toastr";
import {AvField, AvForm, AvInput} from "availity-reactstrap-validation";
import {Link, useHistory} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faWindowClose} from "@fortawesome/free-solid-svg-icons";
import Product from "../../components/formfields/Product";
import InvoiceMethod from "../../components/formfields/InvoiceMethod";
import AdminSelect from "../../components/formfields/AdminSelect";
import BillingType from "../../components/formfields/BillingType";

const LenderForm = (props) => {
    const [lender, setLender] = useState({title:null,status:'active',firstname:null,lastname:null,address:null,city:null,state:null,zip:null,email:null,phone:null,fax:null,returnpolicy:null,emailcopy:null,emailcopydestination:null,billingtype:null});
    const [error, setError] = useState(null)
    let history = useHistory();

    const handleChange = (e) => {
        setLender({ ...lender, [e.target.name]: e.target.value })
        setError(null)
    };

    useEffect(() => {
        if (props.match.params.id)
        {
            API.get('/admin/lender/' + props.match.params.id).then((response) => {
                setLender(response.data.data)
            });
        }
    }, [props.match.params.id])

    const submit = () => {
        API.put(props.match.params.id?'/admin/lender/save/'+props.match.params.id:'/admin/lender/add',lender)
        .then((response) => {
            toastr.success('Success', 'Lender was successfully saved')
            history.push("/lender/item/"+response.data.data.id)
        })
        .catch(error => {
            setError('Invalid lender data')
        })
    }

    return (
        <Container fluid className="p-0">
            <Breadcrumb>
                <BreadcrumbItem>
                    <Link to="/lender/list">Lenders</Link>
                </BreadcrumbItem>
                {(props.match.params.id && lender) ?
                    <>
                        <BreadcrumbItem>
                            <Link to={"/lender/item/" + lender.id}>{lender.title}</Link>
                        </BreadcrumbItem>
                        <BreadcrumbItem active>Edit</BreadcrumbItem>
                    </>
                    :
                    <BreadcrumbItem active>New lender</BreadcrumbItem>
                }
            </Breadcrumb>
            <Card>
                <CardBody>
                    {error &&
                    <div className="text-center text-danger">{error}</div>
                    }
                    <AvForm onValidSubmit={submit}>
                        <div className="row">
                            <div className="col-3">
                                <FormGroup>
                                    <Label>Title</Label>
                                    <AvInput onChange={handleChange} required type="text" name="title" value={lender.title} placeholder="Lender name" />
                                </FormGroup>
                            </div>
                            <div className="col-3">
                                <FormGroup>
                                    <Label>Status</Label>
                                    <AvField
                                        type="select"
                                        name="status"
                                        value={lender.status}
                                        onChange={handleChange}
                                        required
                                    >
                                        <option value={'active'}>Active</option>
                                        <option value={'inactive'}>Not active</option>
                                    </AvField>
                                </FormGroup>
                            </div>
                            <div className="col-2">
                                <FormGroup>
                                    <Label>Return policy</Label>
                                    <AvField
                                        type="select"
                                        name="returnpolicy"
                                        value={lender.returnpolicy}
                                        onChange={handleChange}
                                    >
                                        <option value={'0'}>No returns</option>
                                        <option value={'1'}>1 day</option>
                                        <option value={'2'}>2 days</option>
                                        <option value={'3'}>3 days</option>
                                        <option value={'4'}>4 days</option>
                                        <option value={'5'}>5 days</option>
                                        <option value={'6'}>6 days</option>
                                        <option value={'7'}>7 days</option>
                                        <option value={'8'}>8 days</option>
                                        <option value={'9'}>9 days</option>
                                        <option value={'10'}>10 days</option>
                                        <option value={'11'}>11 days</option>
                                        <option value={'12'}>12 days</option>
                                        <option value={'13'}>13 days</option>
                                        <option value={'14'}>14 days</option>
                                        <option value={'15'}>15 days</option>
                                        <option value={'16'}>16 days</option>
                                        <option value={'17'}>17 days</option>
                                        <option value={'18'}>18 days</option>
                                        <option value={'19'}>19 days</option>
                                        <option value={'20'}>20 days</option>
                                        <option value={'21'}>21 days</option>
                                        <option value={'22'}>22 days</option>
                                        <option value={'23'}>23 days</option>
                                        <option value={'24'}>24 days</option>
                                        <option value={'25'}>25 days</option>
                                        <option value={'26'}>26 days</option>
                                        <option value={'27'}>27 days</option>
                                        <option value={'28'}>28 days</option>
                                        <option value={'29'}>29 days</option>
                                        <option value={'30'}>30 days</option>
                                        <option value={'31'}>31 days</option>
                                        <option value={'32'}>32 days</option>
                                        <option value={'33'}>33 days</option>
                                        <option value={'34'}>34 days</option>
                                        <option value={'35'}>35 days</option>
                                        <option value={'36'}>36 days</option>
                                        <option value={'37'}>37 days</option>
                                        <option value={'38'}>38 days</option>
                                        <option value={'39'}>39 days</option>
                                        <option value={'40'}>40 days</option>
                                        <option value={'41'}>41 days</option>
                                        <option value={'42'}>42 days</option>
                                        <option value={'43'}>43 days</option>
                                        <option value={'44'}>44 days</option>
                                        <option value={'45'}>45 days</option>
                                        <option value={'46'}>46 days</option>
                                        <option value={'47'}>47 days</option>
                                        <option value={'48'}>48 days</option>
                                        <option value={'49'}>49 days</option>
                                        <option value={'50'}>50 days</option>
                                        <option value={'51'}>51 days</option>
                                        <option value={'52'}>52 days</option>
                                        <option value={'53'}>53 days</option>
                                        <option value={'54'}>54 days</option>
                                        <option value={'55'}>55 days</option>
                                        <option value={'56'}>56 days</option>
                                        <option value={'57'}>57 days</option>
                                        <option value={'58'}>58 days</option>
                                        <option value={'59'}>59 days</option>
                                        <option value={'60'}>60 days</option>
                                        <option value={'90'}>90 days</option>
                                    </AvField>
                                </FormGroup>
                            </div>
                            <div className="col-2">
                                <FormGroup>
                                    <Label>Invoice method</Label>
                                    <InvoiceMethod value={lender.invoicemethod} name={'invoicemethod'} required={true} onChange={handleChange}/>
                                </FormGroup>
                            </div>
                            <div className="col-2">
                                <FormGroup>
                                    <Label>Billing type</Label>
                                    <BillingType value={lender.billingtype} name={'billingtype'} required={true} onChange={handleChange}/>
                                </FormGroup>
                            </div>
                        </div>

                        <div className="row mt-2">
                            <div className="col-3">
                                <FormGroup>
                                    <Label>Representative</Label>
                                    <AdminSelect value={lender.representative} name={'representative'} required={false} onChange={handleChange}/>
                                </FormGroup>
                            </div>
                            <div className="col-3">
                                <FormGroup>
                                    <Label>Contact first name</Label>
                                    <AvInput onChange={handleChange} type="text" name="firstname" value={lender.firstname} placeholder="Contact first name" />
                                </FormGroup>
                            </div>
                            <div className="col-3">
                                <FormGroup>
                                    <Label>Contact last name</Label>
                                    <AvInput onChange={handleChange} type="text" name="lastname" value={lender.lastname} placeholder="Contact last name" />
                                </FormGroup>
                            </div>
                            <div className="col-3">
                                <FormGroup>
                                    <Label>Email</Label>
                                    <AvField onChange={handleChange} type="text" name="email" value={lender.email} placeholder="Lender email" validate={{
                                        pattern: (value) => {
                                            if(!value) return true;
                                            return /^([A-Z0-9.%+-]+@[A-Z0-9.-]+.[A-Z]{2,6})*([,;][\s]*([A-Z0-9.%+-]+@[A-Z0-9.-]+.[A-Z]{2,6}))*$/i.test(value)
                                            ? true
                                            : "Please input valid email(s) - separated by semicolon or comma";
                                        }
                                    }}/>
                                </FormGroup>
                            </div>
                        </div>

                        <div className="row mt-2">
                            <div className="col-3">
                                <FormGroup>
                                    <Label>Address</Label>
                                    <AvInput onChange={handleChange} type="text" name="address" value={lender.address} placeholder="Address" />
                                </FormGroup>
                            </div>
                            <div className="col-3">
                                <FormGroup>
                                    <Label>City</Label>
                                    <AvInput onChange={handleChange} type="text" name="city" value={lender.city} placeholder="City" />
                                </FormGroup>
                            </div>
                            <div className="col-3">
                                <FormGroup>
                                    <Label>State</Label>
                                    <AvInput onChange={handleChange} type="text" name="state" value={lender.state} placeholder="State" />
                                </FormGroup>
                            </div>
                            <div className="col-3">
                                <FormGroup>
                                    <Label>Zip</Label>
                                    <AvInput onChange={handleChange} type="text" name="zip" value={lender.zip} placeholder="Zip" />
                                </FormGroup>
                            </div>
                        </div>

                        <div className="row mt-2">
                            <div className="col-6">
                                <FormGroup>
                                    <Label>Phone</Label>
                                    <AvInput onChange={handleChange} type="text" name="phone" value={lender.phone} placeholder="Phone" />
                                </FormGroup>
                            </div>
                            <div className="col-6">
                                <FormGroup>
                                    <Label>Fax</Label>
                                    <AvInput onChange={handleChange} type="text" name="fax" value={lender.fax} placeholder="Fax" />
                                </FormGroup>
                            </div>
                        </div>

                        <div className="row mt-2">
                            <div className="col-6">
                                <FormGroup>
                                    <Label>Email delivery copy</Label>
                                    <AvField
                                        type="select"
                                        name="emailcopy"
                                        value={lender.emailcopy}
                                        onChange={handleChange}
                                    >
                                        <option value={'0'}>No</option>
                                        <option value={'1'}>Yes</option>
                                    </AvField>
                                </FormGroup>
                            </div>
                            <div className="col-6">
                                {lender.emailcopy === "1" &&
                                    <>
                                        <Label>Email copy destination <span className="text-black-50">(leave blank to use lender email)</span></Label>
                                        <AvField onChange={handleChange} type="text" name="emailcopydestination" value={lender.emailcopydestination} placeholder="Email copy destination" validate={{
                                            pattern: (value) => {
                                                if(!value) return true;
                                                return /^([A-Z0-9.%+-]+@[A-Z0-9.-]+.[A-Z]{2,6})*([,;][\s]*([A-Z0-9.%+-]+@[A-Z0-9.-]+.[A-Z]{2,6}))*$/i.test(value)
                                                    ? true
                                                    : "Please input valid email(s) - separated by semicolon or comma";
                                            }
                                        }}/>
                                    </>
                                }
                            </div>
                        </div>


                        <div className="text-center">
                            {props.match.params.id &&
                                <Button color="secondary" onClick={() => {
                                    history.push("/lender/item/"+props.match.params.id)
                                }} className="mr-2">
                                    Cancel
                                </Button>
                            }
                            <Button color="primary">Save</Button>
                        </div>
                    </AvForm>
                </CardBody>
            </Card>
        </Container>
    )
}

export default LenderForm
