import React from "react";
import {Badge} from "reactstrap";

const IsPingPost = ({order}) => {
    return (
        order.ispingpost ?
            <Badge color="primary" className="mr-1 mb-1">
                Ping Post
            </Badge>
            :
            <></>
    )
}

export default IsPingPost;
