import React,{useState,useEffect} from "react";
import {Breadcrumb, BreadcrumbItem, Container, Nav, NavItem, NavLink, TabContent, TabPane} from "reactstrap";
import API from "../../utils/API";
import {Link, useHistory} from "react-router-dom";
import classnames from "classnames";
import OrderStatus from "../../components/status/OrderStatus";
import Product from "../../components/fields/Product";
import {Calendar, Edit2, Copy} from "react-feather";
import Datetime from "../../components/fields/Datetime";
import LenderTitle from "../../components/fields/LenderTitle";
import SendFormat from "./order/SendFormat";
import Configuration from "./order/Configuration";
import Filters from "./order/Filters";
import TestLeads from "./order/TestLeads";
import Price from "../../components/fields/Price";
import IsPingPost from "../../components/fields/IsPingPost";
import CopyFunctionality from "./order/CopyFunctionality";
import IsDataFeed from "../../components/fields/IsDataFeed";
import BordeloConnect from "../bordelo/lenderorder/BordeloConnect";
import Memo from "../../components/memo/Memo";
import SystemLog from "../../components/SystemLog";

const Order = (props) => {
    const [order,setOrder] = useState(null)
    const [copy,setCopy] = useState(false)

    let history = useHistory();

    useEffect(()=>{
        API.get('/admin/order/'+props.match.params.id).then((response) => {
            setOrder(response.data.data)
        });
    },[props.match.params.id])

    const [activeTab,setActiveTab] = useState("sendformat")

    const editOrder = (orderId) => {
        history.push("/lender/order/edit/"+orderId)
    }

    return (
        <Container fluid className="p-0">
            {order &&
                <>
                    <Breadcrumb>
                        <BreadcrumbItem>
                            <Link to="/lender/list">Lenders</Link>
                        </BreadcrumbItem>
                        <BreadcrumbItem>
                            <Link to={"/lender/item/" + order.lender.id}>{order.lender.title}</Link>
                        </BreadcrumbItem>
                        <BreadcrumbItem active>{order.title}</BreadcrumbItem>
                    </Breadcrumb>
                    <CopyFunctionality order={order} copy={copy} setCopy={setCopy}/>
                    <div className="row">
                        <div className="col-md-4 col-sm-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="card-actions float-right">
                                        <Edit2 onClick={() => {editOrder(order.id)}} className="align-middle mr-1 cursor-pointer zoomHover" size={14} />
                                        <Copy onClick={() => {setCopy(true)}} className="align-middle mr-1 cursor-pointer zoomHover" size={14} />
                                    </div>
                                    <h1 className="h3 mb-3">{order.title}</h1>
                                    <h4 className="h4 mb-3">Id# {order.id}</h4>
                                    <OrderStatus status={order.status}/>
                                    <IsPingPost order={order}/>
                                    <IsDataFeed order={order}/>
                                    <ul className="list-unstyled mb-0">
                                        <li className="mb-1">
                                            <Product product={order.product}/>
                                        </li>
                                        <li className="mb-1">
                                            <LenderTitle title={order.lender.title} id={order.lender.id}/>
                                        </li>
                                        <li className="mb-1">
                                            Price: <Price money={order.price}/>
                                        </li>
                                        {order.returnpriceadj &&
                                            <li className="mb-1">
                                                Return rate price adjustment: <strong>{order.returnpriceadj+"%"}</strong>
                                            </li>
                                        }
                                        {order.overrideprice &&
                                            <li className="mb-1">
                                                Internal override price: <Price money={order.overrideprice}/>
                                            </li>
                                        }
                                        {(order.minpingprice && order.ispingpost) &&
                                            <li className="mb-1">
                                                Minimum ping price: <Price money={order.minpingprice}/>
                                            </li>
                                        }
                                        <li className="mb-1">
                                            <Calendar width={14} height={14} className="mr-1" /> <Datetime dateTime={order.dateadd}/>
                                        </li>
                                        <li className="mb-1">
                                            Return cap: <strong>{order.returncap?.toString()?(order.returncap+"%"):"Not set"}</strong>
                                        </li>
                                    </ul>
                                    <Memo typeId={order.id} type={'lenderorder'}/>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8 col-sm-12">
                            <div className={"tab default"}>
                                <Nav tabs>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: activeTab === "sendformat" })}
                                            onClick={() => {
                                                setActiveTab("sendformat");
                                            }}
                                        >
                                            <strong>Send format</strong>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: activeTab === "configuration" })}
                                            onClick={() => {
                                                setActiveTab("configuration");
                                            }}
                                        >
                                            <strong>Configuration</strong>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: activeTab === "filters" })}
                                            onClick={() => {
                                                setActiveTab("filters");
                                            }}
                                        >
                                            <strong>Filters</strong>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: activeTab === "testlead" })}
                                            onClick={() => {
                                                setActiveTab("testlead");
                                            }}
                                        >
                                            <strong>Test lead</strong>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: activeTab === "log" })}
                                            onClick={() => {
                                                setActiveTab("log");
                                            }}
                                        >
                                            <strong>Log</strong>
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                                <TabContent activeTab={activeTab}>
                                    <TabPane tabId="sendformat">
                                        <SendFormat order={order}/>
                                    </TabPane>
                                    <TabPane tabId="configuration">
                                        <Configuration order={order}/>
                                    </TabPane>
                                    <TabPane tabId="filters">
                                        <Filters order={order}/>
                                    </TabPane>
                                    <TabPane tabId="testlead">
                                        <TestLeads order={order}/>
                                    </TabPane>
                                    <TabPane tabId="log">
                                        <SystemLog order={[order.id]}/>
                                    </TabPane>
                                </TabContent>
                            </div>
                        </div>
                    </div>
                </>
            }
        </Container>
    )
}

export default Order
