import { Card, CardBody, Col, Container, Row, Table } from "reactstrap";
import React, { useEffect, useState } from "react";

import API from "../../utils/API";
import DateRange from "../../components/formfields/DateRange";
import Dateday from "../../components/fields/Dateday";
import LeadSourceSelect from "../../components/formfields/LeadSourceSelect";
import Loader from "../../components/Loader";
import Product from "../../components/fields/Product";
import ProductMulti from "../../components/formfields/ProductMulti";
import Publisher from "../../components/fields/Publisher";
import PublisherSelect from "../../components/formfields/PublisherSelect";
import Source from "../../components/fields/Source";
import dateFormat from "dateformat";
import { parseFilterValues } from "../../utils/helpers/filterHelper";
import { useHistory } from "react-router-dom";
import SortableTable from "../../components/SortableTable";

const Details = () => {
  const history = useHistory();
  const defaultDate =
    dateFormat(new Date().setDate(new Date().getDate() - 6), "mmm d, yyyy") +
    " to " +
    dateFormat(new Date(), "mmm d, yyyy");
  const defaultFiltrationState = {
    date: defaultDate,
  };

  const urlParams = new URLSearchParams(history.location.search);
  const parsedParams = Object.fromEntries(urlParams.entries());
  const filtersParsed = parseFilterValues(parsedParams);

  const [filtration, setFiltration] = useState({
    ...defaultFiltrationState,
    ...filtersParsed,
  });
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);

  const changeData = (name, value) => {
    setFiltration({ ...filtration, [name]: value });

    history.push({
      pathname: history.location.pathname,
      search: new URLSearchParams({ ...filtration, [name]: value }).toString(),
    });
  };

  const columns = [
    {
      name: 'ID',
      selector: row => row.date,
      cell: (row) => <Dateday dateDay={row.date} />,
      sortable: true,
    },
    {
      name: 'URL',
      selector: row => row.sourceurl,
      cell: (row) =>  <a href={row.sourceurl} target="_blank">{row.sourceurl}</a>,
      sortable: true,
    },
    {
      name: 'Product',
      selector: row => row.product.title.toLowerCase(),
      cell: (row) =>  <Product product={row.product} />,
      sortable: true,
    },
    {
      name: 'Publisher',
      selector: (row) => row.publisher.name.toLowerCase(),
      cell: (row) => <Publisher publisher={row.publisher} />,
      sortable: true,
    },
    {
      name: 'Source',
      selector: (row) => row.source.title.toLowerCase(),
      cell: (row) =><Source source={row.source} />,
      sortable: true,
    },
    {
      name: 'Leads',
      selector: (row) => parseInt(row.totalleads),
      cell: (row) => <strong>{row.totalleads}</strong>,
      sortable: true,
    },
  ];

  useEffect(() => {
    setLoading(true);
    API.post("/admin/lead/sourcereport", filtration).then((response) => {
      setData(response.data.data);
      setLoading(false);
    });
  }, [filtration]);

  return (
    <Container fluid className="p-0">
      <h1 className="h3 mb-3">Source report</h1>
      <Row form>
        <Col md={3}>
          <DateRange
            onChange={changeData}
            name={"date"}
            value={filtration?.date}
          />
        </Col>
        <Col md={3}>
          <ProductMulti
            name={"product"}
            onChange={changeData}
            value={filtration?.product}
          />
        </Col>
        <Col md={3}>
          <PublisherSelect
            name={"publisher"}
            onChange={changeData}
            value={filtration?.publisher}
          />
        </Col>
        <Col md={3}>
          <LeadSourceSelect
            name={"source"}
            onChange={changeData}
            value={filtration?.source}
          />
        </Col>
      </Row>
      {loading && <Loader />}
      <Card className="mt-2">
        {!loading && data?.length > 0 ? (
          <SortableTable
            columns={columns}
            data={data}
            pagination={false}
          />
        ) : (
          !loading && (
            <CardBody className="text-center">
              <h5>Data not found</h5>
            </CardBody>
          )
        )}
      </Card>
    </Container>
  );
};

export default Details;
