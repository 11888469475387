import React, {useEffect, useState} from "react";
import LeadID from "../../../components/fields/LeadID";
import Datetime from "../../../components/fields/Datetime";
import ProcessTime from "../../../components/fields/ProcessTime";
import LeadStatus from "../../../components/status/LeadStatus";
import Product from "../../../components/fields/Product";
import Publisher from "../../../components/fields/Publisher";
import SubID from "../../../components/fields/SubID";
import RCID from "../../../components/fields/RCID";
import Source from "../../../components/fields/Source";
import MoneyTotal from "../../../components/fields/MoneyTotal";
import MoneyPub from "../../../components/fields/MoneyPub";
import MoneySystem from "../../../components/fields/MoneySystem";
import {Table} from "reactstrap";

const BulkResult = ({viewEnabled,leads,loadData}) => {
    return <Table>
        <thead>
        <tr>
            <th>Id</th>
            <th>Date</th>
            <th>PT</th>
            <th>Status</th>
            {viewEnabled('exceptions') &&
                <th>Reason</th>
            }
            {viewEnabled('lenders') &&
                <th>Lenders</th>
            }
            <th>Product</th>
            <th>Publisher</th>
            <th>Sub ID</th>
            <th>RCID</th>
            <th>Source</th>
            <th>Total</th>
            <th>Pub</th>
            <th>System</th>
        </tr>
        </thead>
        <tbody>
        {
            leads.map((lead, index) => {
                return (
                    <tr key={index}>
                        <td><LeadID id={lead.id} reloadList={loadData}/></td>
                        <td><Datetime dateTime={lead.dateadd}/></td>
                        <td><ProcessTime dateAdd={lead.dateadd} dateUpdate={lead.dateupdate} lessOneSeconds={''}/></td>
                        <td><LeadStatus status={lead.status}/></td>
                        {viewEnabled('exceptions') &&
                            <td>
                                {lead?.exceptions?.map((exception,excKey)=>{
                                    return <div key={excKey}><nobr>{exception}</nobr></div>
                                })}
                            </td>
                        }
                        {viewEnabled('lenders') &&
                            <td>
                                {lead?.lenders?.map((lender,lenKey)=> {
                                    let color,display = true
                                    if (lender.status === 'matched') {
                                        color = "text-success"
                                    }else if (lender.status === 'unmatched') {
                                        color = "text-black-50"
                                    }else {
                                        color = "text-danger"
                                    }
                                    return <div key={lenKey} className={color}><nobr>{lender.lenderorder}::{lender.lender}</nobr></div>
                                })}
                            </td>
                        }
                        <td className=""><Product product={lead.product}/></td>
                        <td><Publisher publisher={lead.publisher}/></td>
                        <td><SubID subid={lead.subid}/> </td>
                        <td><RCID id={lead.rcid}/></td>
                        <td><Source source={lead.leadsource}/></td>
                        <td><MoneyTotal money={lead.earntotal} moneyOriginal={lead.earntotaloriginal}/></td>
                        <td><MoneyPub money={lead.earnpub} moneyOriginal={lead.earnpuboriginal}/></td>
                        <td><MoneySystem money={lead.earntotal-lead.earnpub}/></td>
                    </tr>
                )
            })
        }
        </tbody>
    </Table>
}

export default BulkResult;
