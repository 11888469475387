import React, {useEffect, useState, useRef} from "react";
import {
    Breadcrumb,
    BreadcrumbItem,
    Button, Card,
    CardBody,
    Container,
    FormGroup,
    Label,
} from "reactstrap";
import {
    AvField,
    AvForm,
    AvInput
} from "availity-reactstrap-validation";
import API from "../../../utils/API";
import {Link, useHistory} from "react-router-dom";
import {toastr} from "react-redux-toastr";
import ProductMulti from "../../../components/formfields/ProductMulti";

const Returnreason = (props) => {
    const [reason, setReason] = useState({title:null,products:null});
    const [error, setError] = useState(null);
    const [loading ,setLoading] = useState(true);

    let history = useHistory();

    const handleChange = (e) => {
        setReason({ ...reason, [e.target.name]: e.target.value })
        setError(null)
    };

    const handleManualChange = (name,value) => {
        setReason({ ...reason, [name]: value })
        setError(null)
    };

    useEffect(()=>{
        if (props.match.params.id) {
            API.get('/admin/returnreason/'+props.match.params.id).then((response) => {
                setReason(response.data.data)
                setLoading(false);
            })
        }else{
            setLoading(false);
        }
    },[])

    const submit = () => {
        if (props.match.params.id) {
            API.put('/admin/returnreason/'+props.match.params.id,reason)
                .then((response) => {
                    toastr.success('Success', 'Return reason was successfully saved')
                    history.push("/settings/returnreason")
                })
                .catch(error => {
                    setError('Invalid data')
                })
        }else {
            API.post('/admin/returnreason',reason)
                .then((response) => {
                    toastr.success('Success', 'Return reason was successfully saved')
                    history.push("/settings/returnreason")
                })
                .catch(error => {
                    setError('Invalid data')
                })

        }
    }

    return (
        <Container fluid className="p-0">
            <Breadcrumb>
                <BreadcrumbItem>
                    <Link to="/settings/returnreason">Return reasons</Link>
                </BreadcrumbItem>
                <BreadcrumbItem active>{props.match.params.id?'Edit':'New'} return reason</BreadcrumbItem>
            </Breadcrumb>
            <Card>
                <CardBody>
                    {error &&
                        <div className="text-center text-danger">{error}</div>
                    }
                    {!loading &&
                    <AvForm onValidSubmit={submit}>
                        <FormGroup>
                            <Label>Reject reason</Label>
                            <AvInput onChange={handleChange} required type="text" name="title" value={reason.title} placeholder="Reject reason" />
                        </FormGroup>
                        <FormGroup>
                            <Label>Product</Label>
                            <ProductMulti name={'products'} onChange={handleManualChange} value={reason.products}/>
                        </FormGroup>
                        <FormGroup>
                            <Label>Is internal only</Label>
                            <AvField
                                type="select"
                                name="internal"
                                value={reason.internal}
                                onChange={handleChange}
                            >
                                <option value={'0'}>No</option>
                                <option value={'1'}>Yes</option>
                            </AvField>
                        </FormGroup>
                        <div className="text-center">
                            <Button color="primary">Save</Button>
                        </div>
                    </AvForm>
                    }
                </CardBody>
            </Card>
        </Container>
    )
};

export default Returnreason;
