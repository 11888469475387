import {
  Badge,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
  Table,
} from "reactstrap";
import React, { useEffect, useState } from "react";

import API from "../../../utils/API";
import DateRange from "../../../components/formfields/DateRange";
import Datetime from "../../../components/fields/Datetime";
import LeadID from "../../../components/fields/LeadID";
import Lender from "../../../components/fields/Lender";
import LenderSelect from "../../../components/formfields/LenderSelect";
import ListStat from "../../../components/ListStat";
import Loader from "../../../components/Loader";
import Order from "../../../components/fields/Order";
import Pagination from "../../../components/Pagination";
import ProductMulti from "../../../components/formfields/ProductMulti";
import ProgressBar from "react-bootstrap/ProgressBar";
import ReturnStatus from "../../../components/status/ReturnStatus";
import ReturnStatusSelect from "../../../components/formfields/ReturnStatusSelect";
import User from "../../../components/fields/User";
import dateFormat from "dateformat";
import { parseFilterValues } from "../../../utils/helpers/filterHelper";
import { toastr } from "react-redux-toastr";
import { useHistory } from "react-router-dom";
import ReasonEditable from "./ReasonEditable";
import Publisher from "../../../components/fields/Publisher";
import Product from "../../../components/fields/Product";
import ReturnStatistic from "../../../components/fields/ReturnStatistic";
import SortDirection from "../../../components/SortDirection";

const Requests = () => {
  const history = useHistory();
  const defaultDate =
    dateFormat(new Date().setDate(new Date().getDate() - 6), "mmm d, yyyy") +
    " to " +
    dateFormat(new Date(), "mmm d, yyyy");
  const defaultFiltrationState = {
    page: 1,
    perPage: 100,
    date: defaultDate,
  };
  const urlParams = new URLSearchParams(history.location.search);
  const parsedParams = Object.fromEntries(urlParams.entries());
  const filtersParsed = parseFilterValues(parsedParams);

  const [filtration, setFiltration] = useState({
    ...defaultFiltrationState,
    ...filtersParsed,
  });
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [requests, setRequests] = useState(null);

  const [modalShow, setModalShow] = useState(false);
  const [requestStatus, setRequestStatus] = useState(null);
  const [requestId, setRequestId] = useState(null);
  const [requestNote, setRequestNote] = useState(null);
  const [savingRequest, setSavingRequest] = useState(false);
  const [lenderEmailSending, setLenderEmailSending] = useState(false);

  const [reasons,setReasons] = useState(null)

  useEffect(()=>{
    API.get('/system/returnreason').then((response) => {
        setReasons(response.data.data)
    });
  },[])

  const changeData = (name, value) => {
    setFiltration({ ...filtration, [name]: value });

    history.push({
      pathname: history.location.pathname,
      search: new URLSearchParams({ ...filtration, [name]: value }).toString(),
    });
  };

  const saveRequest = () => {
    setSavingRequest(true);
    let action = requestStatus;
    API.post("/admin/return/request/" + requestStatus, {
      id: requestId,
      note: requestNote,
    }).then((response) => {
      setRequestStatus(null);
      setRequestNote(null);
      setRequestId(null);
      setModalShow(false);
      setSavingRequest(false);
      toastr.success("Success", "Request was successfully " + action + "ed");
      loadData();
    });
  };

  const startReview = (id, status) => {
    setRequestStatus(status);
    setRequestNote(null);
    setRequestId(id);
    setModalShow(true);
  };

  const loadData = () => {
    setLoading(true);
    setRequests(null);
    setData(null);
    API.post("/admin/return/requests", filtration).then((response) => {
      setData(response.data.data);
      setRequests(response.data.data.data);
      setLoading(false);
    });
  };

  const sendLenderEmail = (id) => {
    setLenderEmailSending(true);
    API.post("/admin/return/senlenderemails").then((response) => {
      setLenderEmailSending(false);
    });
  };

  useEffect(() => {
    if (filtration.date) {
      loadData();
    }
  }, [filtration]);

  return (
    <Container fluid className="p-0">
      <div className="row">
        <div className="col">
          <h1 className="h3 mb-3">Return requests</h1>
        </div>
        <div className="col-auto">
          <button
            className="btn btn-primary"
            style={{ width: "250px" }}
            disabled={lenderEmailSending}
            onClick={() => sendLenderEmail(true)}
          >
            {lenderEmailSending ? "Sending" : "Send lender notification emails"}
          </button>
        </div>
      </div>

      <Row form className={"mb-2"}>
        <Col md={3}>
          <DateRange
            onChange={changeData}
            name={"date"}
            value={filtration?.date}
          />
        </Col>
        <Col md={3}>
          <ReturnStatusSelect
            name={"status"}
            onChange={changeData}
            value={filtration?.status}
          />
        </Col>
        <Col md={3}>
          <ProductMulti
            name={"product"}
            onChange={changeData}
            value={filtration?.product}
          />
        </Col>
        <Col md={3}>
          <LenderSelect
            name={"lender"}
            onChange={changeData}
            value={filtration?.lender}
          />
        </Col>
      </Row>
      <Card className="mt-2">
        {loading && <Loader absolute={true} />}
        {requests?.length > 0 ? (
          <div className="table-responsive">
            <ListStat data={data} />
            <Table>
              <thead>
              <tr>
                <th></th>
                <th>Lead<SortDirection fieldName={'lead'} filters={filtration} changeFilter={changeData}/></th>
                <th>Lender<SortDirection fieldName={'lender'} filters={filtration} changeFilter={changeData}/></th>
                <th>Order<SortDirection fieldName={'order'} filters={filtration} changeFilter={changeData}/></th>
                <th>Return %</th>
                <th>Publisher<SortDirection fieldName={'publisher'} filters={filtration} changeFilter={changeData}/>
                </th>
                <th>Product<SortDirection fieldName={'product'} filters={filtration} changeFilter={changeData}/></th>
                <th>Sent<SortDirection fieldName={'sent'} filters={filtration} changeFilter={changeData}/></th>
                <th>Requested<SortDirection fieldName={'requested'} filters={filtration} changeFilter={changeData}/>
                </th>
                <th>Reason<SortDirection fieldName={'reason'} filters={filtration} changeFilter={changeData}/></th>
                <th>
                  <nobr>Request status<SortDirection fieldName={'status'} filters={filtration}
                                                     changeFilter={changeData}/></nobr>
                </th>
                <th>
                  <nobr>Requested By<SortDirection fieldName={'user'} filters={filtration} changeFilter={changeData}/>
                  </nobr>
                </th>
              </tr>
              </thead>
              <tbody>
              {requests.map((request, index) => {
                return (
                    <tr key={index}>
                      <th>{request.returnitem.status === 'requested' &&
                          <nobr>
                            <button type="button" className="btn btn-primary"
                                    onClick={() => startReview(request.returnitem.id, 'accept')}>Accept
                            </button>
                            <button type="button" className="btn btn-danger"
                                    onClick={() => startReview(request.returnitem.id, 'decline')}>Decline
                            </button>
                          </nobr>
                      }</th>
                      <td><LeadID id={request.lead.id}/></td>
                      <td><Lender lender={request.lender}/> {request.lender.returnpolicy && <>
                        <strong>{request.lender.returnpolicy} days</strong></>}</td>
                      <td>
                        <Order order={request.lenderorder}/> {request.lenderorder.returncap && <>
                        <strong>{request.lenderorder.returncap}%</strong></>}<br/>
                        <br/>
                        {(request.lenderorder.returncap && request.returnitem.status === 'requested' && request.stat) &&
                            <>
                              <ProgressBar
                                  variant={request.stat.percent >= request.lenderorder.returncap ? 'danger' : 'primary'}
                                  now={request.stat.percent} label={`${request.stat.percent}%`}/>
                              <div className="row m-0 p-0">
                                <div className="col-5">Total: <strong>{request.stat.sent}</strong></div>
                                <div className="col-7 text-right">Returned: <strong>{request.stat.returned}
                                  <small>({request.stat.percent}%)</small></strong></div>
                              </div>
                            </>
                        }
                      </td>
                      <td><ReturnStatistic statistic={request.returnstatistics}/></td>
                      <td><Publisher publisher={request.publisher}/></td>
                      <td><Product product={request.product}/></td>
                      <td><Datetime dateTime={request.lenderposting.date}/></td>
                      <td><Datetime dateTime={request.returnitem.dateadd}/></td>
                      <td><ReasonEditable returnitem={request.returnitem} reasons={reasons}
                                          product={request.lead.product}/></td>
                      <td><ReturnStatus
                          status={request.returnitem.status}/>{request.returnitem.status !== 'requested' &&
                          <>
                            <br/><Datetime dateTime={request.returnitem.datefinalized}/>
                            <br/>by <User user={request.userfinalized}/>
                            {request.returnitem.note &&
                                <>
                                  <br/><strong>Note:</strong> {request.returnitem.note}
                                </>
                            }
                          </>
                      }</td>
                      <td><User user={request.userrequested}/>{request.userrequested.role === 'admin' &&
                          <Badge color="success" className="ml-1 mb-1">Admin</Badge>}</td>
                    </tr>
                );
              })}
              </tbody>
            </Table>
          </div>
        ) : (
            !loading && (
                <CardBody className="text-center">
                  <h5>Data not found</h5>
                </CardBody>
            )
        )}
      </Card>
      {data?.page > 0 && data?.pages > 0 && (
          <Pagination
              onChange={changeData}
              name={"page"}
              page={data.page}
              totalPages={data.pages}
          />
      )}
      <React.Fragment>
        <Modal
            isOpen={modalShow}
            toggle={() => setModalShow(!modalShow)}
            size="lg"
            style={{maxWidth: "600px", width: "600px"}}
        >
          <ModalHeader
              toggle={() => setModalShow(!modalShow)}
              className={`${
                  requestStatus === "accept" ? "bg-success" : "bg-danger"
              }`}
          >
            <span className="text-white text-uppercase">
              {requestStatus} request
            </span>
          </ModalHeader>
          <ModalBody style={{backgroundColor: "#F7F9FC"}}>
            <h3>Are you sure you want to {requestStatus} this request?</h3>
            <label>Memo (not required)</label>
            <textarea
              rows={5}
              className="form-control"
              name="note"
              onChange={(e) => setRequestNote(e.target.value)}
              value={requestNote}
            />
          </ModalBody>
          <ModalFooter className="justify-content-center">
            {savingRequest ? (
              <Spinner color="primary" />
            ) : (
              <Button color="secondary" onClick={() => saveRequest()}>
                Proceed
              </Button>
            )}
          </ModalFooter>
        </Modal>
      </React.Fragment>
    </Container>
  );
};

export default Requests;
