import React, { useState, useEffect } from "react";
import API from "../../utils/API";
import {toastr} from "react-redux-toastr";
import {Spinner} from "reactstrap";
import {Mail} from "react-feather";

const SendLenderInvitation = ({userId}) => {
    const [sendingEmail, setSendingEmail] = useState(false)

    const sendInvitation = () => {
        setSendingEmail(true)
        API.post('/admin/lender/user/sendinvitation',{user:userId}).then((response) => {
            setSendingEmail(false)
            toastr.success('Success', 'Invitation email was successfully sent')
        });
    }

    return <>
        {sendingEmail ?
            <Spinner size={"sm"}/>
            :
            <Mail onClick={() => {
                sendInvitation()
            }} className="cursor-pointer align-middle text-success mr-2"
                  size={18}/>
        }
    </>
}

export default SendLenderInvitation;
