import React from "react";
import {Badge} from "reactstrap";

const Source = ({source}) => {
    if (source) {
        if (source.title === 'Direct post') {
            return <Badge color="primary" className="mr-1 mb-1">Direct post</Badge>
        }else if (source.title === 'Ping post') {
            return <Badge color="secondary" className="mr-1 mb-1">Ping/Post</Badge>
        }

        return source.title
    }else {
        return '';
    }
};

export default Source;
