import { Card, CardBody, Col, Container, Row, Table } from "reactstrap";
import React, { useEffect, useState } from "react";

import API from "../../utils/API";
import CsvDownloader from "react-csv-downloader";
import DateRange from "../../components/formfields/DateRange";
import Datetime from "../../components/fields/Datetime";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LeadID from "../../components/fields/LeadID";
import LeadStatus from "../../components/status/LeadStatus";
import LeadStatusSelect from "../../components/formfields/LeadStatusSelect";
import ListStat from "../../components/ListStat";
import Loader from "../../components/Loader";
import MoneyPub from "../../components/fields/MoneyPub";
import MoneyTotal from "../../components/fields/MoneyTotal";
import Pagination from "../../components/Pagination";
import PingID from "../../components/fields/PingId";
import Product from "../../components/fields/Product";
import ProductMulti from "../../components/formfields/ProductMulti";
import Publisher from "../../components/fields/Publisher";
import PublisherSelect from "../../components/formfields/PublisherSelect";
import RCID from "../../components/fields/RCID";
import RCIDSelect from "../../components/formfields/RCIDSelect";
import { Spinner } from "react-bootstrap";
import dateFormat from "dateformat";
import { faFileCsv } from "@fortawesome/free-solid-svg-icons";
import { parseFilterValues } from "../../utils/helpers/filterHelper";
import { useHistory } from "react-router-dom";
import SortDirection from "../../components/SortDirection";

const Pings = () => {
  const history = useHistory();
  const defaultDate =
    dateFormat(new Date().setDate(new Date().getDate() - 1), "mmm d, yyyy") +
    " to " +
    dateFormat(new Date(), "mmm d, yyyy");
  const defaultFiltrationState = {
    page: 1,
    perPage: 100,
    date: defaultDate,
  };

  const urlParams = new URLSearchParams(history.location.search);
  const parsedParams = Object.fromEntries(urlParams.entries());
  const filtersParsed = parseFilterValues(parsedParams);

  const [filtration, setFiltration] = useState({
    ...defaultFiltrationState,
    ...filtersParsed,
  });
  const [pings, setPings] = useState(null);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  // const [logExport,setLogExport] = useState(false)
  // const [csvLoading, setCsvLoading] = useState(true)

  const changeData = (name, value) => {
    setFiltration({ ...filtration, [name]: value });

    history.push({
      pathname: history.location.pathname,
      search: new URLSearchParams({ ...filtration, [name]: value }).toString(),
    });
  };

  useEffect(() => {
    setLoading(true);
    // setLogExport(false)
    // setCsvLoading(true)

    API.post("/admin/ping/detailreport", filtration).then((response) => {
      setPings(response.data.data.data);
      setData(response.data.data);
      setLoading(false);
    });

    // API.post('/admin/ping/detailreportcsv',filtration).then((response) => {
    //     setLogExport(response.data.data)
    //     setCsvLoading(false)
    // });
  }, [filtration]);

  return (
    <Container fluid className="p-0">
      <div className="row justify-content-between">
        <div className="col-auto">
          <h3 className="h3 mb-3 float-left">Pings</h3>
        </div>
        {/* <div className="col-auto">
                    {!csvLoading && logExport ?
                        <CsvDownloader datas={logExport} filename={"PingReport"}>
                            <FontAwesomeIcon
                                className="align-middle cursor-pointer"
                                icon={faFileCsv}
                                style={{fontSize:"20px"}}
                                fixedWidth
                            />
                        </CsvDownloader> : <Spinner />
                    }
                </div> */}
      </div>
      <Row form>
        <Col md={4}>
          <DateRange
            onChange={changeData}
            name={"date"}
            value={filtration.date}
          />
        </Col>
        <Col md={4}>
          <ProductMulti
            name={"product"}
            onChange={changeData}
            value={filtration?.product}
          />
        </Col>
        <Col md={4}>
          <PublisherSelect
            name={"publisher"}
            onChange={changeData}
            value={filtration?.publisher}
          />
        </Col>
      </Row>
      <Row form className="mt-1">
        <Col md={4}>
          <LeadStatusSelect
            name={"status"}
            onChange={changeData}
            value={filtration?.status}
          />
        </Col>
        <Col md={4}>
          <RCIDSelect
            name={"rcid"}
            onChange={changeData}
            product={filtration?.product}
            publisher={filtration?.publisher}
            value={filtration?.rcid}
          />
        </Col>
      </Row>
      {loading && <Loader />}
      <Card className="mt-2">
        {!loading && pings?.length > 0 ? (
          <div className="table-responsive">
            <ListStat data={data} />
            <Table>
              <thead>
                <tr>
                  <th>Ping Id<SortDirection fieldName={'pingid'} filters={filtration} changeFilter={changeData}/></th>
                  <th>Lead Id</th>
                  <th>Date<SortDirection fieldName={'dateadd'} filters={filtration} changeFilter={changeData}/></th>
                  <th>Status<SortDirection fieldName={'status'} filters={filtration} changeFilter={changeData}/></th>
                  <th>Product<SortDirection fieldName={'product'} filters={filtration} changeFilter={changeData}/></th>
                  <th>Publisher<SortDirection fieldName={'publisher'} filters={filtration} changeFilter={changeData}/></th>
                  <th>RCID<SortDirection fieldName={'rcid'} filters={filtration} changeFilter={changeData}/></th>
                  <th>Total<SortDirection fieldName={'total'} filters={filtration} changeFilter={changeData}/></th>
                  <th>Pub<SortDirection fieldName={'pub'} filters={filtration} changeFilter={changeData}/></th>
                </tr>
              </thead>
              <tbody>
                {pings.map((ping, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        <PingID id={ping.id} />
                      </td>
                      <td>
                        {ping.lead_id ? <LeadID id={ping.lead_id} /> : ""}
                      </td>
                      <td>
                        <Datetime dateTime={ping.dateadd} />
                      </td>
                      <td>
                        <LeadStatus status={ping.status} />
                      </td>
                      <td>
                        <Product product={ping.product} />
                      </td>
                      <td>
                        <Publisher publisher={ping.publisher} />
                      </td>
                      <td>
                        <RCID id={ping.rcid} />
                      </td>
                      <td>
                        <MoneyTotal money={ping.earntotal} />
                      </td>
                      <td>
                        <MoneyPub money={ping.earnpub} />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        ) : (
          !loading && (
            <CardBody className="text-center">
              <h5>Data not found</h5>
            </CardBody>
          )
        )}
      </Card>
      {data?.page > 0 && data?.pages > 0 && (
        <Pagination
          onChange={changeData}
          name={"page"}
          page={data.page}
          totalPages={data.pages}
          loading={loading}
        />
      )}
    </Container>
  );
};

export default Pings;
