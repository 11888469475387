import React from "react";
import { connect } from "react-redux";
import { toggleSidebar } from "../redux/actions/sidebarActions";
import { logout } from "../redux/actions/userActions";
import {
  Collapse,
  Navbar,
  Nav,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Form,
  Input
} from "reactstrap";
import {
  Settings,
  User,
  Key, Lock
} from "react-feather";
import {
  useHistory
} from "react-router-dom";
import API from "../utils/API";

const NavbarComponent = ({ userData,dispatch }) => {

  const signOut = () => {
    API.post('/user/signout', {}).then(()=>{
      dispatch(logout());
    });
  }

  let history = useHistory();

  return (
    <Navbar color="white" light expand>
      <span
        className="sidebar-toggle d-flex mr-2"
        onClick={() => {
          dispatch(toggleSidebar());
        }}
      >
        <i className="hamburger align-self-center" />
      </span>

      <Form inline>
        <Input
          type="text"
          placeholder="Search..."
          aria-label="Search"
          className="form-control-no-border mr-sm-2"
        />
      </Form>

      <Collapse navbar>
        <Nav className="ml-auto" navbar>
          <UncontrolledDropdown nav inNavbar>
            <span className="d-inline-block d-sm-none">
              <DropdownToggle nav caret>
                <Settings size={18} className="align-middle" />
              </DropdownToggle>
            </span>
            <span className="d-none d-sm-inline-block">
              <DropdownToggle nav caret>
                <span className="text-dark">{userData.user.name}</span>
              </DropdownToggle>
            </span>
            <DropdownMenu right>
              <DropdownItem onClick={()=>history.push('/profile')}><User size={18} className="align-middle mr-2" /> Profile settings</DropdownItem>
              <DropdownItem onClick={()=>history.push('/profile/password')}><Lock size={18} className="align-middle mr-2" /> Change password</DropdownItem>
              <DropdownItem onClick={()=>history.push('/profile/mfa')}><Key size={18} className="align-middle mr-2" /> Multi-Factor Authentication</DropdownItem>
              <DropdownItem divider />
              <DropdownItem onClick={signOut}>Sign out</DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Nav>
      </Collapse>
    </Navbar>
  );
};

export default connect(store => ({
  app: store.app,
  userData: store.user
}))(NavbarComponent);
