import React from "react";
import {Badge} from 'reactstrap';

const TokenRequirement = ({ requirement}) => {
    if (requirement === 'tf_only') {
        return <Badge color="primary" className="mr-1 mb-1">
            Trusted Form Only
        </Badge>
    }else if (requirement === 'jornaya_only') {
        return <Badge color="primary" className="mr-1 mb-1">
            Jornaya Only
        </Badge>
    }else if (requirement === 'both_required') {
        return <Badge color="success" className="mr-1 mb-1">
            Both Required
        </Badge>
    }else if (requirement === 'either_or_required') {
        return <Badge color="primary" className="mr-1 mb-1">
            Either Or Required
        </Badge>
    }else {
        return <>{requirement}</>
    }
};

export default TokenRequirement;
