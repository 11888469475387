import React,{useState,useEffect} from "react";
import API from "../../../utils/API";

const StatusInput = ({orderId,currentStatus,save,updateChangedData,removeChangedData}) => {
    const [value,setValue] = useState('')
    const [prevValue,setPrevValue] = useState('')
    const [serverCapData, setServerCapData] = useState(null)
    const [valueChanged, setValueChanged] = useState(false);

    const handleChange = (e) => {
        setValue(e.target.value);
    };

    useEffect(() => {
        setValue(currentStatus);
        setPrevValue(currentStatus);
    }, []);

    useEffect(() => {
        setValueChanged(value !== prevValue);
    },[value,prevValue])

    useEffect(()=>{
        if (valueChanged) {
            updateChangedData(orderId,'status');
        }else {
            removeChangedData(orderId,'status');
        }
    },[valueChanged])

    useEffect(()=> {
        if (save && valueChanged) {
            API.post('/admin/capmanagement/save', {lenderorder:orderId,type:'status',value:value}).then((response) => {
                setServerCapData(response.data.data)
                removeChangedData(orderId,'status')
            })
        }
    },[save])

    useEffect(()=> {
        if(serverCapData && serverCapData?.value){
            setPrevValue(serverCapData?.value);
        }
    },[serverCapData])

    return <div>
        <select name={'status'} style={{width: "100px"}} onChange={(e) => handleChange(e)}
                className={`form-control ${valueChanged ? 'bg-warning' : (value === 'active' ? 'bg-success' : '')}`}>
            <option value='active' selected={value === 'active'}>Active</option>
            <option value='paused' selected={value === 'paused'}>Paused</option>
            <option value='archive' selected={value === 'archive'}>Archived</option>
        </select>
    </div>
}

export default StatusInput;
