import React,{useEffect, useState} from "react";
import API from "../../../utils/API";
import Filter from "../../../components/filter/Filter";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader, TabContent} from "reactstrap";
import FilterCopy from "./FilterCopy";
import {titleFormatter} from "../../../components/filter/utils";

const Filters = ({order}) => {

    const [filters,setFilters] = useState(null)
    const [currentFilterTitle,setCurrentFilterTitle] = useState(null)
    const [reRender,setReRender] = useState(true)

    const [filterCopyModal,setFilterCopyModal] = useState(false)
    const [filterCopyFilter,setFilterCopyFilter] = useState(null)

    useEffect(() => {
        API.get('/admin/filter/'+order.id+'/order').then((response) => {
            setFilters(response.data.data)
        });
    }, [order.id,reRender])

    return (
        <>
            {currentFilterTitle ?
                <Filter title={currentFilterTitle} type={"order"} elementId={order.id} onClose={()=>{setCurrentFilterTitle(null);setReRender(!reRender)}}/>
                :
                filters &&
                filters.map((filter, index) => {
                    return (
                        <>
                        <div className="row m-3 border-bottom pb-1" key={"filter_"+index}>
                            <div className="col-auto">
                                <strong>{titleFormatter(filter.title)}</strong>
                            </div>
                            <div className="col" dangerouslySetInnerHTML={{__html: filter.displayData}}/>
                            <div className="col-auto text-right">
                                <button className="btn btn-primary" onClick={()=>{setCurrentFilterTitle(filter.title)}}>Set up</button>
                            </div>
                            <div className="col-auto text-right">
                                <button className="btn btn-secondary" onClick={()=>{
                                    setFilterCopyFilter(filter);
                                    setFilterCopyModal(true);
                                }}>Copy</button>
                            </div>
                        </div>
                        </>
                    )
                })
            }
            <Modal
                isOpen={filterCopyModal}
                toggle={() => setFilterCopyModal(!filterCopyModal)}
                size="lg"
                style={{maxWidth: '1200px', width: '90%'}}
            >
                <ModalHeader toggle={() => setFilterCopyModal(!filterCopyModal)}>
                    Copy filter: {filterCopyFilter?.title}
                </ModalHeader>
                <ModalBody>
                    <FilterCopy filter={filterCopyFilter} orderId={order.id}/>
                </ModalBody>
            </Modal>
        </>
    )
}

export default Filters
