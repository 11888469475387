import { Card, Col, Container, Row, CardBody } from "reactstrap";
import React, { useEffect, useState } from "react";

import API from "../../utils/API";
import CSVDownlaod from "../../components/CsvDownload";
import DateRange from "../../components/formfields/DateRange";
import Dateday from "../../components/fields/Dateday";
import LeadSourceSelect from "../../components/formfields/LeadSourceSelect";
import Loader from "../../components/Loader";
import MoneyPub from "../../components/fields/MoneyPub";
import MoneyTotal from "../../components/fields/MoneyTotal";
import ProductMulti from "../../components/formfields/ProductMulti";
import PublisherSelect from "../../components/formfields/PublisherSelect";
import RCIDSelect from "../../components/formfields/RCIDSelect";
import SummaryChart from "./SummaryChart";
import dateFormat from "dateformat";
import { parseFilterValues } from "../../utils/helpers/filterHelper";
import { useHistory } from "react-router-dom";
import SortableTable from "../../components/SortableTable";

const Summary = () => {
  const history = useHistory();
  const defaultDate =
    dateFormat(new Date().setDate(new Date().getDate() - 6), "mmm d, yyyy") +
    " to " +
    dateFormat(new Date(), "mmm d, yyyy");
  const defaultFiltrationState = {
    date: defaultDate,
  };

  const urlParams = new URLSearchParams(history.location.search);
  const parsedParams = Object.fromEntries(urlParams.entries());
  const filtersParsed = parseFilterValues(parsedParams);

  const [filtration, setFiltration] = useState({
    ...defaultFiltrationState,
    ...filtersParsed,
  });
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);

  let totalLeads = 0,
    totalMatched = 0,
    totalUnmatched = 0,
    totalFlagged = 0,
    totalFailed = 0,
    totalProfit = 0,
    totalTotal = 0,
    totalPub = 0;

  const changeData = (name, value) => {
    setFiltration({ ...filtration, [name]: value });

    history.push({
      pathname: history.location.pathname,
      search: new URLSearchParams({ ...filtration, [name]: value }).toString(),
    });
  };

  const columns = [
    {
      name: "Date",
      selector: (row) => row.date,
      sortable: true,
    },
    {
      name: "Total leads",
      selector: (row) => row.leads,
      cell: (row) => <span className="font-weight-bold">{row.leads}</span>,
      sortable: true,
    },
    {
      name: "Matched",
      selector: (row) => row.matched,
      cell: (row) => (
        <span
          className={`font-weight-bold ${row.matched ? "text-success" : ""}`}
        >
          {row.matched}
        </span>
      ),
      sortable: true,
    },
    {
      name: "Unmatched",
      selector: (row) => row.unmatched,
      sortable: true,
    },
    {
      name: "Flagged",
      selector: (row) => row.flagged,
      cell: (row) => (
        <span className={`${row.flagged ? "text-primary" : ""}`}>
          {row.flagged}
        </span>
      ),
      sortable: true,
    },
    {
      name: "Failed",
      selector: (row) => row.failed,
      sortable: true,
    },
    {
      name: "Total",
      selector: (row) => row.total,
      cell: (row) =>
        row.total ? (
          <MoneyTotal money={row.total} moneyOriginal={row.totaloriginal} />
        ) : (
          "$0"
        ),
      sortable: true,
    },
    {
      name: "Publisher",
      selector: (row) => row.publisher,
      cell: (row) =>
        row.publisher ? (
          <MoneyPub
            money={row.publisher}
            moneyOriginal={row.publisheroriginal}
          />
        ) : (
          "$0"
        ),
      sortable: true,
    },
    {
      name: "Profit",
      selector: (row) => row.total - row.publisher,
      cell: (row) =>
        row.total - row.publisher ? (
          <MoneyTotal money={row.total - row.publisher} />
        ) : (
          "$0"
        ),
      sortable: true,
    },
  ];

  useEffect(() => {
    if (filtration.date) {
      setLoading(true);

      API.post("/admin/lead/summaryreport", filtration).then((response) => {
        setData(response.data.data);
        setLoading(false);
      });
    }
  }, [filtration]);

  return (
    <Container fluid className="p-0">
      <div className="row justify-content-between">
        <div className="col-auto">
          <h3 className="h3 mb-3 float-left">Leads summary</h3>
        </div>
        <div className="col-auto">
          <CSVDownlaod
            url="/admin/lead/summaryreportcsv"
            filename="LeadsSummary"
            filtration={filtration}
          />
        </div>
      </div>
      <Row form className={"mb-2"}>
        <Col md={3}>
          <DateRange
            onChange={changeData}
            name={"date"}
            value={filtration.date}
          />
        </Col>
        <Col md={3}>
          <ProductMulti
            name={"product"}
            onChange={changeData}
            value={filtration?.product}
          />
        </Col>
        <Col md={2}>
          <PublisherSelect
            name={"publisher"}
            onChange={changeData}
            value={filtration?.publisher}
          />
        </Col>
        <Col md={2}>
          <LeadSourceSelect
            name={"source"}
            onChange={changeData}
            value={filtration?.source}
          />
        </Col>
        <Col md={2}>
          <RCIDSelect
            name={"rcid"}
            onChange={changeData}
            product={filtration?.product}
            publisher={filtration?.publisher}
            value={filtration?.rcid}
          />
        </Col>
      </Row>
      {loading && <Loader />}
      {!loading && data && data.length > 0 && (
        <>
          {!loading && data.length > 2 && (
            <div>
              <SummaryChart inputData={data} />
            </div>
          )}
          <Card className="flex-fill w-100">
            {!loading && data && data.length > 0 ? (
              <>
                <Card>
                  <SortableTable
                    columns={columns}
                    data={data}
                    pagination={false}
                  />
                </Card>
                <Card className="flex-fill w-100 p-3">
                  {data.map((dataItem, dataIndex) => {
                    totalLeads += dataItem.leads;
                    totalMatched += dataItem.matched;
                    totalUnmatched += dataItem.unmatched;
                    totalFlagged += dataItem.flagged;
                    totalFailed += dataItem.failed;
                    totalProfit += dataItem.total - dataItem.publisher;
                    totalTotal += dataItem.total;
                    totalPub += dataItem.publisher;
                  })}
                  <h4>Summary:</h4>
                  <ul className="bull list-unstyled mb-0">
                    <li>
                      Leads:{" "}
                      <span className="font-weight-bold">{totalLeads}</span>
                    </li>
                    <li>
                      Matched:{" "}
                      <span
                        className={`font-weight-bold ${
                          totalMatched ? "text-success" : ""
                        }`}
                      >
                        {totalMatched}
                      </span>
                    </li>
                    <li>
                      Unmatched:{" "}
                      <span className="font-weight-bold">{totalUnmatched}</span>
                    </li>
                    <li>
                      Flagged:{" "}
                      <span
                        className={`font-weight-bold ${
                          totalFlagged ? "text-primary" : ""
                        }`}
                      >
                        {totalFlagged}
                      </span>
                    </li>
                    <li>
                      Failed:{" "}
                      <span
                        className={`font-weight-bold ${
                          totalFailed ? "text-warning" : ""
                        }`}
                      >
                        {totalFailed}
                      </span>
                    </li>
                    <li>
                      Total:{" "}
                      <span className="font-weight-bold">
                        {totalTotal ? <MoneyTotal money={totalTotal} /> : "$0"}
                      </span>
                    </li>
                    <li>
                      Publisher:{" "}
                      <span className="font-weight-bold">
                        {totalPub ? <MoneyPub money={totalPub} /> : "$0"}
                      </span>
                    </li>
                    <li>
                      Profit:{" "}
                      <span className="font-weight-bold">
                        {totalProfit ? (
                          <MoneyTotal money={totalProfit} />
                        ) : (
                          "$0"
                        )}
                      </span>
                    </li>
                  </ul>
                </Card>
              </>
            ) : (
              !loading && (
                <CardBody className="text-center">
                  <h5>Data not found</h5>
                </CardBody>
              )
            )}
          </Card>
        </>
      )}
    </Container>
  );
};

export default Summary;
