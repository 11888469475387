import { Card, CardBody, Col, Container, Row, Table } from "reactstrap";
import React, { useEffect, useState } from "react";

import API from "../../utils/API";
import DateRange from "../../components/formfields/DateRange";
import Datetime from "../../components/fields/Datetime";
import LeadID from "../../components/fields/LeadID";
import LenderSelect from "../../components/formfields/LenderSelect";
import LenderTitle from "../../components/fields/LenderTitle";
import ListStat from "../../components/ListStat";
import Loader from "../../components/Loader";
import Pagination from "../../components/Pagination";
import Publisher from "../../components/fields/Publisher";
import PublisherSelect from "../../components/formfields/PublisherSelect";
import RedirectSelect from "../../components/formfields/RedirectSelect";
import dateFormat from "dateformat";
import { parseFilterValues } from "../../utils/helpers/filterHelper";
import { useHistory } from "react-router-dom";

const ThankYouPageClicks = () => {
  const history = useHistory();
  const defaultDate =
    dateFormat(new Date().setDate(new Date().getDate() - 6), "mmm d, yyyy") +
    " to " +
    dateFormat(new Date(), "mmm d, yyyy");
  const defaultFiltrationState = {
    page: 1,
    perPage: 100,
    date: defaultDate,
  };

  const urlParams = new URLSearchParams(history.location.search);
  const parsedParams = Object.fromEntries(urlParams.entries());
  const filtersParsed = parseFilterValues(parsedParams);

  const [filtration, setFiltration] = useState({
    ...defaultFiltrationState,
    ...filtersParsed,
  });
  const [clicks, setClicks] = useState(null);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);

  const changeData = (name, value) => {
    setFiltration({ ...filtration, [name]: value });

    history.push({
      pathname: history.location.pathname,
      search: new URLSearchParams({ ...filtration, [name]: value }).toString(),
    });
  };

  useEffect(() => {
    setLoading(true);
    API.post("/admin/report/redirect/thankyoupage", filtration).then(
      (response) => {
        setClicks(response.data.data.data);
        setData(response.data.data);
        setLoading(false);
      }
    );
  }, [filtration]);

  return (
    <Container fluid className="p-0">
      <h1 className="h3 mb-3">Thank You Page Clicks</h1>
      <Row form>
        <Col md={4}>
          <DateRange
            onChange={changeData}
            name={"date"}
            value={filtration?.date}
          />
        </Col>
        <Col md={4}>
          <Col>
            <LenderSelect
              name={"lender"}
              onChange={changeData}
              value={filtration?.lender}
            />
          </Col>
        </Col>
        <Col md={4}>
          <PublisherSelect
            name={"publisher"}
            onChange={changeData}
            value={filtration?.publisher}
          />
        </Col>
      </Row>
      <Row form className="mt-1">
        <Col md={12}>
          <RedirectSelect
            name={"link"}
            onChange={changeData}
            value={filtration?.link}
          />
        </Col>
      </Row>
      {loading && <Loader />}
      <Card className="mt-2">
        {!loading && clicks?.length > 0 ? (
          <div className="table-responsive">
            <ListStat data={data} />
            <Table>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Lead</th>
                  <th>Publisher</th>
                  <th>Lender</th>
                  <th>Link</th>
                  <th>User Agent</th>
                  <th>IP</th>
                </tr>
              </thead>
              <tbody>
                {clicks.map((click, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        <nobr>
                          <Datetime dateTime={click.date} />
                        </nobr>
                      </td>
                      <td>
                        <LeadID id={click.lead} />
                      </td>
                      <td>
                        <nobr>
                          <Publisher publisher={click.publisher} />
                        </nobr>
                      </td>
                      <td>
                        <nobr>
                          <LenderTitle
                            title={click.lender.title}
                            id={click.lender.id}
                          />
                        </nobr>
                      </td>
                      <td>
                        <nobr>{click.url}</nobr>
                      </td>
                      <td>
                        <nobr>{click.useragent}</nobr>
                      </td>
                      <td>{click.ip}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        ) : (
          !loading && (
            <CardBody className="text-center">
              <h5>Data not found</h5>
            </CardBody>
          )
        )}
      </Card>
      {data?.page > 0 && data?.pages > 0 && (
        <Pagination
          onChange={changeData}
          name={"page"}
          page={data.page}
          totalPages={data.pages}
          loading={loading}
        />
      )}
    </Container>
  );
};

export default ThankYouPageClicks;
