import { Badge } from "reactstrap";
import React from "react";

const Product = ({ product }) => {
  if (product?.id == 1) {
    return (
      <Badge color="info" className="mr-1 mb-1 badge-pill">
        {product.title}
      </Badge>
    );
  } else if (product?.id == 2) {
    return (
      <Badge color="info" className="mr-1 mb-1 badge-pill">
        {product.title}
      </Badge>
    );
  } else if (product?.id == 3) {
    return (
      <Badge color="info" className="mr-1 mb-1 badge-pill">
        {product.title}
      </Badge>
    );
  } else if (product?.id == 4) {
    return (
      <Badge color="info" className="mr-1 mb-1 badge-pill">
        {product.title}
      </Badge>
    );
  } else if (product?.id == 5) {
    return (
      <Badge color="warning" className="mr-1 mb-1 badge-pill">
        {product.title}
      </Badge>
    );
  } else if (product?.id == 6) {
    return (
      <Badge color="danger" className="mr-1 mb-1 badge-pill">
        {product.title}
      </Badge>
    );
  } else if (product?.id == 7) {
    return (
      <Badge color="success" className="mr-1 mb-1 badge-pill">
        {product.title}
      </Badge>
    );
  }

  return <Badge color="primary" className="mr-1 mb-1 badge-pill">
    {product.title}
  </Badge>
};

export default Product;
