import React,{useEffect,useState} from "react";
import enLocale from 'time-delta/locales/en';
import * as timeDelta from 'time-delta';

const ProcessTime = ({dateAdd,dateUpdate,lessOneSeconds='<1 second'}) => {
    timeDelta.addLocale(enLocale);
    const instance = timeDelta.create({
        locale: 'en',
    });
    const [pt,setPt] = useState(null)

    useEffect(()=>{
        let date1 = new Date(dateAdd);
        let date2 = new Date(dateUpdate);
        let pt = instance.format(date1, date2)

        setPt(pt);
    },[])

    return <nobr>{pt ? <>{pt}</>:<>{lessOneSeconds}</>}</nobr>;
};

export default ProcessTime;