import React, { useEffect, useState } from "react";

import API from "../../utils/API";
import Select from "react-select";
import isEmpty from "lodash/isEmpty";

const OrderSelect = ({ name, value = [], onChange, lender }) => {
  const [order, setOrder] = useState(null);
  const [filterValue, setFilterValue] = useState([]);

  const mapValuesToFilters = (value = [], orders) => {
    return value.map((item) => {
      const matchedFilter = orders.find((pub) => pub.value == item);
      return matchedFilter || item;
    });
  };

  const changeSelect = (value) => {
    let i,
      values = [];
    for (i = 0; i < value?.length; i++) {
      values.push(value[i].value);
    }
    if (name) {
      onChange(name, values);
      const mappedFilters = mapValuesToFilters(values, order);
      setFilterValue(mappedFilters);
    }
  };

  useEffect(() => {
    let data = {};
    if (!isEmpty(lender)) {
      const idsToString = lender.map((id) => id.toString());
      data.lender = idsToString;
    }

    const fetchOrders = async () => {
      try {
        const response = await API.post("/system/orders", data);
        const options = response.data.data;
        const orderList = options?.map((option) => ({
          value: option.id,
          label: option.title,
        }));
        const mappedFilters = mapValuesToFilters(value, orderList);
        setFilterValue(mappedFilters);
        setOrder(orderList);
      } catch (error) {
        console.error("Error fetching orders:", error);
      }
    };

    fetchOrders();
  }, [lender]);

  return (
    <Select
      className="react-select-container"
      classNamePrefix="react-select"
      options={order}
      value={filterValue}
      onChange={(value) => changeSelect(value)}
      isMulti
      placeholder="Order"
    />
  );
};

export default OrderSelect;
