import React,{useEffect,useState} from 'react';
import dateFormat from "dateformat";
import API from "../utils/API";
import ListStat from "./ListStat";
import {Card, CardBody, Col, Row, Table} from "reactstrap";
import Pagination from "./Pagination";
import SortDirection from "./SortDirection";
import Datetime from "./fields/Datetime";
import User from "./fields/User";
import Device from "./fields/Device";
import DateRange from "./formfields/DateRange";
import PublisherSelect from "./formfields/PublisherSelect";
import RCIDSelect from "./formfields/RCIDSelect";
import LenderSelect from "./formfields/LenderSelect";
import OrderSelect from "./formfields/OrderSelect";
import AdminSelect from "./formfields/AdminSelect";
import Publisher from "./fields/Publisher";
import Pixel from "./fields/Pixel";
import Order from "./fields/Order";
import Lender from "./fields/Lender";
import RCID from "./fields/RCID";
import LeadID from "./fields/LeadID";
import {parseFilterValues} from "../utils/helpers/filterHelper";
import {useHistory} from "react-router-dom";
import Loader from "./Loader";
import LogCategorySelect from "./formfields/LogCategorySelect";
import Returnreason from "./fields/Returnreason";

const SystemLog = ({user,lender,order,publisher,rcid,pixel,lead,urlParse = false, hideindividual}) => {
    const history = useHistory();
    const [loading, setLoading] = useState(true);

    const defaultDate =
        dateFormat(new Date().setDate(new Date().getDate() - 7), "mmm d, yyyy") +
        " to " +
        dateFormat(new Date(), "mmm d, yyyy");

    const [defaultFiltrationState,setDefaultFiltrationState]=useState({
        user: null,
        publisher: publisher??[],
        category: [],
        rcid: rcid??[],
        pixels: pixel??null,
        lender: lender??[],
        lenderorder: order??[],
        users: user??[],
        lead: lead??null,
        keyword: '',
        page: 1,
        perPage: 100,
        date: defaultDate,
        hideindividual
    });

    const urlParams = new URLSearchParams(history.location.search);
    const parsedParams = Object.fromEntries(urlParams.entries());
    const filtersParsed = urlParse ? parseFilterValues(parsedParams) : [];

    const [filtration, setFiltration] = useState({
        ...defaultFiltrationState,
        ...filtersParsed,
    });

    const [data, setData] = useState(null);
    const [logs, setLogs] = useState(null);

    const loadData = () => {
        setLoading(true);

        API.post("/system/log", filtration).then((response) => {
            setLogs(response.data.data.data);
            setData(response.data.data);
            setLoading(false);
        });
    }

    useEffect(() => {
        loadData();
    }, [filtration]);

    const changeData = (name, value) => {
        setFiltration({ ...filtration, [name]: value });

        if (urlParse) {
            history.push({
                pathname: history.location.pathname,
                search: new URLSearchParams({...filtration, [name]: value}).toString(),
            });
        }
    };

    const changeNativeData = (e) => {
        setFiltration({ ...filtration, [e.target.name]: e.target.value });

        if (urlParse) {
            history.push({
                pathname: history.location.pathname,
                search: new URLSearchParams({...filtration, [e.target.name]: e.target.value}).toString(),
            });
        }
    };

    return <>
        <Row form>
            <Col md={3} className="mt-2">
                <DateRange
                    onChange={changeData}
                    name={"date"}
                    value={filtration?.date}
                />
            </Col>
            {!user &&
            <Col md={3} className="mt-2">
                <AdminSelect
                    name={"user"}
                    onChange={changeNativeData}
                    value={filtration?.user}
                    required={false}
                    placeholder={"User"}
                />
            </Col>
            }
            <Col md={3} className="mt-2">
                <input
                    placeholder="Search by keyword"
                    className="form-control"
                    id="keyword"
                    name="keyword"
                    value={filtration.keyword}
                    onChange={(e) => changeData(e.target.name, e.target.value)}
                />
            </Col>
            {(!publisher && !lender && !order && !rcid && !pixel && !lead) &&
            <Col md={3} className="mt-2">
                <PublisherSelect
                    name={"publisher"}
                    onChange={changeData}
                    value={filtration?.publisher}
                />
            </Col>
            }
            {(!rcid && !lender && !order && !pixel && !lead) &&
            <Col md={3} className="mt-2">
                <RCIDSelect
                    name={"rcid"}
                    onChange={changeData}
                    publisher={filtration?.publisher}
                    value={filtration?.rcid}
                />
            </Col>
            }
            {(!lender && !order && !rcid && !pixel && !publisher) &&
            <Col md={3} className="mt-2">
                <LenderSelect
                    name={"lender"}
                    onChange={changeData}
                    value={filtration?.lender}
                />
            </Col>
            }
            {(!order && !rcid && !pixel && !publisher && !lead) &&
            <Col md={3} className="mt-2">
                <OrderSelect
                    name={"lenderorder"}
                    onChange={changeData}
                    value={filtration?.lenderorder}
                    lender={filtration?.lender}
                />
            </Col>
            }
            <Col md={3} className="mt-2">
                <LogCategorySelect
                    name={"category"}
                    onChange={changeData}
                    value={filtration?.category}
                />
            </Col>
        </Row>
        {loading && <Loader/>}
        <Card className="mt-2">
            {!loading && logs?.length > 0 ? (
                <div className="table-responsive">
                    <ListStat data={data} />
                    <Table>
                        <thead>
                        <tr>
                            <th>Date <SortDirection fieldName={'dateadd'} filters={filtration}
                                                    changeFilter={changeData}/></th>
                            <th>User <SortDirection fieldName={'user'} filters={filtration} changeFilter={changeData}/>
                            </th>
                            <th>Entity</th>
                            <th>Action <SortDirection fieldName={'title'} filters={filtration}
                                                      changeFilter={changeData}/></th>
                            <th>Details <SortDirection fieldName={'description'} filters={filtration}
                                                       changeFilter={changeData}/></th>
                            <th>IP <SortDirection fieldName={'ipaddress'} filters={filtration}
                                                  changeFilter={changeData}/></th>
                            <th>User Agent<SortDirection fieldName={'useragent'} filters={filtration}
                                                         changeFilter={changeData}/></th>
                        </tr>
                        </thead>
                        <tbody>
                        {logs.map((item,key) => {
                            return (<tr key={key}>
                                <td><Datetime dateTime={item.systemlog.dateadd}/></td>
                                <td><User user={item.user}/></td>
                                <td>
                                    {(item.systemlog?.relatedlead !== null && parseInt(item.systemlog?.relatedlead) > 0) &&
                                        <><nobr>Lead: <LeadID id={item.systemlog?.relatedlead}/></nobr><br/></>
                                    }
                                    {item.publisherpixel?.id !== null &&
                                        <><nobr>Pixel: <Pixel pixel={item.publisherpixel}/></nobr><br/></>
                                    }
                                    {item.publisher?.id !== null &&
                                        <><nobr>Publisher: <Publisher publisher={item.publisher}/></nobr><br/></>
                                    }
                                    {item.lenderorder?.id !== null &&
                                        <><nobr>Order: <Order order={item.lenderorder} tags={false}/></nobr><br/></>
                                    }
                                    {item.lender?.id !== null &&
                                        <><nobr>Lender: <Lender lender={item.lender}/></nobr><br/></>
                                    }
                                    {item.publisherrcid?.id !== null &&
                                        <><nobr>RCID: <RCID id={item.publisherrcid?.id}/></nobr><br/></>
                                    }
                                    {item.relateduser?.id !== null &&
                                        <><nobr>User: <User user={item.relateduser}/></nobr><br/></>
                                    }
                                    {item.relatedreturnreason?.id !== null &&
                                        <><nobr>Return reason: <Returnreason returnreason={item.relatedreturnreason}/></nobr><br/></>
                                    }
                                </td>
                                <td>
                                    <div dangerouslySetInnerHTML={{__html: item.systemlog.title}}/>
                                </td>
                                <td>
                                    <div dangerouslySetInnerHTML={{__html: item.systemlog.description}}/>
                                </td>
                                <td>{item.systemlog.ipaddress}</td>
                                <td><Device userAgent={item.systemlog.useragent}/></td>
                            </tr>);
                        })}
                        </tbody>
                    </Table>
                </div>
            ) : (
                !loading && (
                    <CardBody className="text-center">
                        <h5>Data not found</h5>
                    </CardBody>
                )
            )}
        </Card>
        {!loading && data?.page > 0 && data?.pages > 0 && (
            <Pagination
                onChange={changeData}
                name={"page"}
                page={data.page}
                totalPages={data.pages}
            />
        )}
    </>
}

export default SystemLog;
