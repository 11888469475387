import { Link } from "react-router-dom";
import React from "react";

const Returnreason = ({ returnreason }) => {
    if (returnreason) {
        return (
            <nobr><Link to={"/settings/returnreason/item/" + returnreason.id} className="text-primary">
                {returnreason.title}
            </Link></nobr>
                );
    } else {
        return "";
    }
};

export default Returnreason;
