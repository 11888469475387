import { useState, useEffect } from 'react';
import { useSelector } from "react-redux";

const usePermissions = (resource) => {
    const user = useSelector(state => {
        return state.user.user
    });
    const [haveAccess, setHaveAccess] = useState(false);

    useEffect(() => {
        if (user) {
            setHaveAccess(user.permissions.includes(resource));
        }
    }, [user]);

    return haveAccess;
}

export default usePermissions;
