import React from "react";

const User = ({user}) => {
    if (user) {
        return user.name;
    }else {
        return '';
    }
};

export default User;
