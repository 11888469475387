import React, {useState, useEffect, useRef} from "react";
import API from "../../utils/API";
import {CheckCircle, Edit2, Info, XCircle} from "react-feather";
import JoditEditor from 'jodit-react';
import {AvField,AvForm} from "availity-reactstrap-validation";
import {CloseButton} from "react-bootstrap";

const LenderOneToOne = ({lenderId, reload}) => {
    const [settings,setSettings] = useState(null)
    const editor = useRef(null);
    const [edit,setEdit] = useState(false)

    const loadSettings = () => {
        API.get('/admin/lender/onetoone/'+lenderId).then((response) => {
            setSettings(response.data.data)
        });
    }

    useEffect(()=>{
        loadSettings()
    },[lenderId])

    const handleChange = (e) => {
        setSettings({ ...settings, [e.target.name]: e.target.value })
    };

    const handleCheckboxChange = (e) => {
        setSettings({...settings, [e.target.name]: e.target.checked ? e.target.value : null});

        if (e.target.checked) {
            setEdit(true);
        }
    }

    const save = () => {
        API.post('/admin/lender/onetoone/'+lenderId,settings).then((response) => {
            setEdit(false);
            reload();
        });
    }

    const handleImageSelect = (event) => {
        let files = event.target.files;
        let reader = new FileReader();
        if (files[0]) {
            reader.readAsDataURL(files[0]);

            reader.onload = (e) => {
                API.post('/admin/image', {image: e.target.result}).then((response) => {
                    handleChange({target: {name: "logo", value: response.data.data.url}})
                });
            }
        }
    }

    return <>
        {settings &&
            <div style={{minHeight: "25px"}}>
                {
                    edit ?
                        <>
                            <AvForm>
                                <div className="row justify-content-end">
                                    <div className="col-auto">
                                        <button onClick={() => {
                                            setEdit(false)
                                        }} className="btn btn-outline-secondary mb-2">X
                                        </button>
                                    </div>
                                </div>
                                <div className="row justify-content-center mt-2 mb-2">
                                    <div className="col-2 text-right">
                                        FCC One To One:
                                    </div>
                                    <div className="col-8 text-left">
                                        <input type="checkbox" className="ml-3 mp-1" size={30} name="compliance"
                                               onChange={handleCheckboxChange} value="1"
                                               checked={settings.compliance == 1}/>
                                    </div>
                                </div>
                                {settings.compliance &&
                                    <>
                                        <div className="row justify-content-center mb-2">
                                            <div className="col-2 text-right">
                                                Display name:
                                            </div>
                                            <div className="col-8 text-left">
                                                <input type="text" onChange={handleChange} name="title"
                                                       className="form-control" value={settings.title}/>
                                            </div>
                                        </div>
                                        <div className="row mt-2 justify-content-center mb-2">
                                            <div className="col-2 text-right">
                                                Description:
                                            </div>
                                            <div className="col-8 text-left">
                                                <JoditEditor
                                                    ref={editor}
                                                    value={settings.description}
                                                    tabIndex={1}
                                                    onBlur={newContent => setSettings({
                                                        ...settings,
                                                        ['description']: newContent
                                                    })}
                                                    onChange={newContent => {
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="row mt-2 justify-content-center mb-2">
                                            <div className="col-2 text-right">
                                                Logo:
                                            </div>
                                            <div className="col-8 text-left">
                                                {settings.logo &&
                                                    <img src={settings.logo} className="img-fluid"
                                                         style={{maxWidth: "400px"}}/>
                                                }
                                                <label>
                                                    <Edit2 className="align-middle mr-1 cursor-pointer zoomHover"
                                                           color={"black"} size={24}/>
                                                    <input type="file" onChange={handleImageSelect}
                                                           style={{display: "none"}}/>
                                                </label>
                                            </div>
                                        </div>
                                    </>
                                }
                                <div className="row mt-2 justify-content-center">
                                    <div className="col-auto">
                                        <button className="btn btn-primary" onClick={() => save()}>Save</button>
                                    </div>
                                </div>
                            </AvForm>
                        </>
                        :
                        <>
                            <div className="float-right">
                                <Edit2 onClick={() => {
                                    setEdit(true)
                                }} className="align-middle mr-1 cursor-pointer zoomHover" size={14}/>
                            </div>
                            <div className="row justify-content-center mb-2">
                                <div className="col-2 text-right">
                                    One To One:
                                </div>
                                <div className="col-8 text-left">
                                    {settings.compliance ? <CheckCircle className={'text-success'}/> : <XCircle className={'text-danger'}/>}
                                </div>
                            </div>
                            {settings.compliance &&
                                <>
                                {settings.title &&
                                    <div className="row justify-content-center mb-2">
                                        <div className="col-2 text-right">
                                            Display name:
                                        </div>
                                        <div className="col-8 text-left">
                                            {settings.title}
                                        </div>
                                    </div>
                                }
                                {settings.description &&
                                    <div className="row mt-2 justify-content-center mb-2">
                                        <div className="col-2 text-right">
                                            Description:
                                        </div>
                                        <div className="col-8 text-left">
                                            <div dangerouslySetInnerHTML={{__html: settings.description}}/>
                                        </div>
                                    </div>
                                }
                                {settings.logo &&
                                    <div className="row mt-2 justify-content-center">
                                        <div className="col-2 text-right">
                                            Logo:
                                        </div>
                                        <div className="col-8 text-left">
                                            <img src={settings.logo} className="img-fluid" style={{maxWidth: "150px"}}/>
                                        </div>
                                    </div>
                                }
                                </>
                            }
                        </>
                }
            </div>
        }
    </>
}

export default LenderOneToOne
